import React from 'react';

import {
  Div,
  Form,
  Row,
} from 'nexcomponent-ui';
import { useSelector } from 'react-redux';

import {
  tableSearchMappingByTypeStateGroup,
} from '../../../../../Store/StateGroup/TableMappingByTypeStateGroup';
import HeaderSearch from '../../../Search/HeaderSearch/HeaderSearch';
import SearchList from '../../../TableList/SearchList/SearchList';
import style from './SearchForm.module.css';

const SearchForm = props => {
  const [, , currentPage, rowLimit] = useSelector(
    tableSearchMappingByTypeStateGroup
  );
  return (
    <Div className={style.Form}>
      <HeaderSearch
        title={props.title}
        t={props.t}
        dispatch={props.dispatch}
        mappingCode={props.mappingCode}
        navigate={props.navigate}
        advance={props.advance}
        option={props.option}
        backNavigate={props.backNavigate}
        searchFunc={(filter, column) =>
          props.searchFunc( currentPage, rowLimit,  filter, column,
            props.dispatch,)
        }
      />
      <Form>
        <Row>{props.field}</Row>
      </Form>
      <Div className={style.CoverTable}>
        <SearchList
          t={props.t}
          dispatch={props.dispatch}
          navigate={props.navigate}
          mappingCode={props.mappingCode}
          columnDefault={props.columnDefault}
          searchFunc={( currentPage,rowLimit,filter, column,dispatch) =>
            props.searchFunc( currentPage, rowLimit,  filter, column,dispatch)
          }
        />
      </Div>
    </Div>
  );
};

export default SearchForm;
