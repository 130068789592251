import React from 'react';

import {
  Div,
  nexIcon,
  Span,
} from 'nexcomponent-ui';

export default function Chip(props) {
  return (
    <Div
        role="button"
        clickable="true"
        aria-label="delete-value"
        onClick={props.deleteAction}
        onKeyPress={props.deleteAction}
        tabIndex="0"
      >
        <Span value={props.value} />
        <nexIcon.NcClose size={6} color="white" />
      </Div>
   
    
  );
}
