import React from 'react';

import {
  Button,
  Div,
  Image,
  ModalDialog,
  Paragraph,
} from 'nexcomponent-ui';

import { BUTTON } from '../../../../Enum/IdSet';
import { SIZESET } from '../../../../Enum/Props/SizeSet';
import { BTN_STYLE_TYPE } from '../../../../Enum/Style/ButtonTypeStyle';
import failImg from './Danger.png';
import style from './ResultDeleteModal.module.css';
import successImg from './Success.png';

const ResultDeleteModal = props => {
  return (
    <ModalDialog
      id={props.id}
      classHeader={style.Header}
      classBody={style.Body}
      classFooter={style.Footer}
      className={style.Modal}
      title={
        <Div>
          <Image src={!props.success ? failImg : successImg} />
          <Paragraph
            className={style.Title}
            value={props.success ? props.t("COMMON:SUCCESS_TITLE") : props.t("COMMON:FAILED_TITLE")}
          />
        </Div>
      }
      content={props.success ? props.t("COMMON:SUCCESS_DELETE_MESSAGE") : props.t("COMMON:USED_NOTIFICATION")}
      onClose={props.onClose}
      hideClose={true}
      show={props.show}
      footer={
        <Div id={props.idFooter}>
          {props.success ? (
            <Button
              id={BUTTON.CANCEL + props.idFooter}
              className={`${style.FooterButton} ${style.SuccessButton} `}
              size={SIZESET.SM}
              typeStyle={BTN_STYLE_TYPE.SECONDARY}
              label={
                props.sucessBtnLabel === undefined
                  ? props.t("COMMON:BACK")
                  : props.sucessBtnLabel
              }
              onClick={props.navigate}
            />
          ) : (
            <Button
              id={BUTTON.CANCEL + props.idFooter}
              className={`${style.FooterButton} ${style.FailButton} `}
              size={SIZESET.SM}
              typeStyle={BTN_STYLE_TYPE.SECONDARY}
              label={props.t("COMMON:TRY_AGAIN")}
              onClick={props.onClose}
            />
          )}
        </Div>
      }
    />
  );
};

export default ResultDeleteModal;
