export const personProfileColumn = (t) => [
  {
    name: t("COMMON:FIELD.DATE_TIME"),
    selector: "created_at",
    key: "created_at",
    sortable: true,
    width: "20vw",
  },
  {
    name: t("COMMON:FIELD.PERSON_PROFILE_ID"),
    selector: "person_profile_id",
    key: "person_profile_id",
    
    
    width: "10vw",
  },
  {
    name: t("COMMON:FIELD.FIRST_NAME"),
    selector: "first_name",
    key: "first_name",
    width: "25vw",
  },
  {
    name: t("COMMON:FIELD.LAST_NAME"),
    selector: "last_name",
    key: "last_name",
    width: "25vw",
  },
  {
    name: t("COMMON:FIELD.RESOURCE"),
    selector: "resource",
    key: "resource",
    width: "20vw",
  },
];
