export const companyProfileAadvanceSearch = t => [
  {title: t("COMMON:FIELD.ADDRESS3"), code: "address_3", type: "text"},
  {title: t("COMMON:FIELD.COUNTRY_NAME"), code: "country_name", type: "text"},
  {title: t("COMMON:FIELD.COUNTRY_ID"), code: "country_id", type: "number"},
  {title: t("COMMON:FIELD.PROVINCE_CODE"), code: "province_code", type: "text"},
  {title: t("COMMON:FIELD.PROVINCE_NAME"), code: "province_name", type: "text"},
  {title: t("COMMON:FIELD.PROVINCE_ID"), code: "province_id", type: "number"},
  {title: t("COMMON:FIELD.DISTRICT_CODE"), code: "district_code", type: "text"},
  {title: t("COMMON:FIELD.DISTRICT_NAME"), code: "district_name", type: "text"},
  {title: t("COMMON:FIELD.DISTRICT_ID"), code: "district_id", type: "number"},
  {title: t("COMMON:FIELD.SUB_DISTRICT_CODE"), code: "sub_district_code", type: "text"},
  {title: t("COMMON:FIELD.SUB_DISTRICT_NAME"), code: "sub_district_name", type: "text"},
  {title: t("COMMON:FIELD.SUB_DISTRICT_ID"), code: "sub_district_id", type: "number"},
  {title: t("COMMON:FIELD.URBAN_VILLAGE_CODE"), code: "urban_village_code", type: "text"},
  {title: t("COMMON:FIELD.URBAN_VILLAGE_NAME"), code: "urban_village_name", type: "text"},
  {title: t("COMMON:FIELD.URBAN_VILLAGE_ID"), code: "urban_village_id", type: "number"},
  {title: t("COMMON:FIELD.POSTAL_CODE_CODE"), code: "postal_code_code", type: "text"},
  {title: t("COMMON:FIELD.POSTAL_CODE_ID"), code: "postal_code_id", type: "number"},
  {title: t("COMMON:FIELD.ISLAND_NAME"), code: "island_name", type: "text"},
  {title: t("COMMON:FIELD.ISLAND_CODE"), code: "island_code", type: "text"},
  {title: t("COMMON:FIELD.ISLAND_ID"), code: "island_id", type: "number"},
  {title: t("COMMON:FIELD.LATITUDE"), code: "latitude", type: "text"},
  {title: t("COMMON:FIELD.LONGITUDE"), code: "longitude", type: "text"},
  {title: t("COMMON:FIELD.FAX"), code: "fax", type: "text"},
  {title: t("COMMON:FIELD.EMAIL"), code: "email", type: "text"},
  {title: t("COMMON:FIELD.ALTERNATIVE_EMAIL"), code: "alternative_email", type: "text"},
  {title: t("COMMON:FIELD.PKP_STATUS"), code: "pkp_status", type: "text"},
  {title: t("COMMON:FIELD.BUMN_STATUS"), code: "bumn_status", type: "text"},
  {title: t("COMMON:FIELD.PBF_STATUS"), code: "pbf_status", type: "text"},
  {title: t("COMMON:FIELD.PBF_LICENSE_NUMBER"), code: "pbf_license_number", type: "text"},
  {title: t("COMMON:FIELD.PBF_LICENSE_END_DATE"), code: "pbf_license_end_date", type: "date"},
  {title: t("COMMON:FIELD.PBF_TRUSTEE_SIPA_NUMBER"), code: "pbf_trustee_sipa_number", type: "text"},
  {
    title: t("COMMON:FIELD.PBF_SIPA_LICENSE_END_DATE"),
    code: "pbf_sipa_license_end_date",
    type: "date",
  },
  {title: t("COMMON:FIELD.ADDITIONAL_INFO"), code: "additional_info", type: "text"},
  {title: t("COMMON:FIELD.PARENT_COMPANY_NPWP"), code: "parent_company_npwp", type: "text"},
  {title: t("COMMON:FIELD.PARENT_COMPANY_NAME"), code: "parent_company_name", type: "text"},
  {title: t("COMMON:FIELD.PARENT_COMPANY_ID"), code: "parent_company_id", type: "number"},
];
