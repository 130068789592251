import React, { useMemo } from "react";

import { Div } from "nexcomponent-ui";

import { mainBreadCrumb } from "../../../../Enum/Breadcrumb/CommonBreadcrumb";
import { productColumn } from "../../../../Enum/Column/ProductColumn";
import { PAGE } from "../../../../Enum/IdSet";
import { MAPPING_CODE } from "../../../../Enum/MappingCode/MappingCode";
import { ROUTER_CONSTANT } from "../../../../Enum/RouterConstant/RouterConstant";
import {
  fetchDataProductList,
  fetchProductMappingForm,
} from "../../../../Fetch/FetchData/ProductData";
import MappingTabContent from "../../../Organisms/TableList/MappingTabContent/MappingTabContent";
import MappingTabList from "../../../Organisms/TableList/MappingTabList/MappingTabList";
import {
  fetchDataProductPrincipalList,
  fetchProductPrincipalMappingForm,
} from "../../../../Fetch/FetchData/ProductPrincipalData";

const ProductPage = (props) => {
  const tabList = useMemo(()=>[
    {
      title: props.t("COMMON:PRINCIPAL"),
      component:(load)=> (
        <MappingTabContent
          notify={props.notify}
          t={props.t}
          dispatch={props.dispatch}
          navigate={props.navigate}
          mappingCode={MAPPING_CODE.PRODUCT}
          principal={true}
          load={load}
          uniqueKey="principal_product_id"
          rejectNavigate={ROUTER_CONSTANT.PRODUCT.PRINCIPAL.REJECT_REASON}
          mappingNavigate={(NewId, ExistId, List) =>
            fetchProductPrincipalMappingForm(
              props.validToken(),
              props.dispatch,
              "principal_product_id",
              NewId,
              ExistId,
              List,
              props.notify,
              () => props.navigate(`${ROUTER_CONSTANT.PRODUCT.PRINCIPAL.MAPPING}`)
            )
          }
          column={productColumn(props.t)}
          validToken={props.validToken}
          listFunc={(
            totalRow,
            countData,
            token,
            currentPage,
            rowLimit,
            sortDate,
            dispatch,
            notify
          ) =>
            fetchDataProductPrincipalList(
              totalRow,
              countData,
              token,
              currentPage,
              rowLimit,
              sortDate,
              dispatch,
              notify
            )
          }
        />
      ),
      isShow: true,
    },
    {
      title: props.t("COMMON:MAPAN"),
      component:(load)=> (
        <MappingTabContent
          notify={props.notify}
          t={props.t}
          dispatch={props.dispatch}
          navigate={props.navigate}
          mappingCode={MAPPING_CODE.PRODUCT}
          uniqueKey="product_id"
          load={load}
          rejectNavigate={ROUTER_CONSTANT.PRODUCT.REJECT_REASON}
          mappingNavigate={(NewId, ExistId, List) =>
            fetchProductMappingForm(
              props.validToken(),
              props.dispatch,
              "product_id",
              NewId,
              ExistId,
              List,
              props.notify,
              () => props.navigate(ROUTER_CONSTANT.PRODUCT.MAPPING)
            )
          }
          column={productColumn(props.t)}
          validToken={props.validToken}
          listFunc={(
            totalRow,
            countData,
            token,
            currentPage,
            rowLimit,
            sortDate,
            dispatch,
            notify
          ) =>
            fetchDataProductList(
              totalRow,
              countData,
              token,
              currentPage,
              rowLimit,
              sortDate,
              dispatch,
              notify
            )
          }
        />
      ),
      isShow: false,
    },
  ],[props]);
  return (
    <Div id={PAGE.LIST}>
      <MappingTabList
        dispatch={props.dispatch}
        listBreadcrumb={mainBreadCrumb(props.t)}
        toTitlePage={ROUTER_CONSTANT.INDEX}
        title={props.t("PRODUCT:TITLE")}
        defaultBreadcrumb={props.t("PRODUCT:TITLE")}
        list={tabList}
      />
    </Div>
  );
};

export default ProductPage;
