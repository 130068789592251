import axios from 'axios';

import {
  API_PREFIX,
  API_URL,
  API_VERSION,
  MOCK_API,
} from '../../Enum/ApiConstant';
import { errorHandler } from '../../Utils/Handler/ErrorHandler';

const api = `${API_URL}/${API_VERSION}/${API_PREFIX}`;

export const doGet = async (endpoint, token, params, data, notify, dispatch, action, errAction) => {
  const url = api + endpoint;
  const method = "GET";
  const timeout = 6000;
  const headers = {Authorization: token};
  const request = {url, method, headers, params, data, timeout};
 
  try {
    const res = await axios(request);
    if (action !== undefined) return action(res);
    return res;
  } catch (err) {
    if (errAction !== undefined) return errorHandler(err, dispatch, notify, error =>errAction(error));
    return errorHandler(err, dispatch, notify);
  }
};

export const doPost = async (endpoint, token, data, notify, dispatch, action, errAction) => {
  const url = api + endpoint;
  const method = "POST";
  const timeout = 60000;
  const headers = {Authorization: token};
  const request = {url, method, headers, data, timeout};
  try {
    const res = await axios(request);
    if (action !== undefined) return action(res);
    return res;
  } catch (err) {
    if (errAction !== undefined) return errorHandler(err, dispatch, notify, error =>errAction(error));
    return errorHandler(err, dispatch, notify);
  }
};
export const doPostRegional = async (endpoint, token, data, notify, dispatch, action, errAction) => {
  const url = "https://staging-master.nexcloud.id/v1/masterdata" + endpoint;
  const method = "POST";
  const timeout = 60000;
  const headers = {Authorization: token};
  const request = {url, method, headers, data, timeout};
  try {
    const res = await axios(request);
    if (action !== undefined) return action(res);
    return res;
  } catch (err) {
    if (errAction !== undefined) return errorHandler(err, dispatch, notify, error =>errAction(error));
    return errorHandler(err, dispatch, notify);
  }
};
export const mockGet = (endpoint, token, params) => {
  const url = MOCK_API + endpoint;
  const method = "GET";
  const headers = {Authorization: token};
  const request = {url, method, headers, params};

  return axios(request);
};

export const doDelete = async (endpoint, token, data, notify, dispatch, action, errAction) => {
  const url = api + endpoint;
  const method = "DELETE";
  const timeout = 60000;
  const headers = {Authorization: token};
  const request =  {url, method, headers, data, timeout}
  try {
    const res = await axios(request);
    if (action !== undefined) return action(res);
    return res;
  } catch (err) {
    if (errAction !== undefined) return errorHandler(err, dispatch, notify, error =>errAction(error));
    return errorHandler(err, dispatch, notify);
  }
}

export const doPut = async (endpoint, token, data, notify, dispatch, action, errAction) => {
  const url = api + endpoint;
  const method = "PUT";
  const timeout = 60000;
  const headers = {Authorization: token};
  const request = {url, method, headers, data, timeout};
  try {
    const res = await axios(request);
    if (action !== undefined) return action(res);
    return res;
  } catch (err) {
    if (errAction !== undefined) return errorHandler(err, dispatch, notify, error =>errAction(error));
    return errorHandler(err, dispatch, notify);
  }
}
