import { NotifyType } from '../../Enum/Notification/NotificationType';
import { utilsActions } from '../../Store/Reducers/UtilsReducer';

export const errorHandler = async (err, dispatch, notify, action) => {
  if (action !== undefined) {
    return action(err);
  } else {
    if (err.code === "ECONNABORTED") {
      await dispatch(utilsActions.setTimeOut(true));
    } else if (err.message === "Network Error") {
      notify({
        type: NotifyType.ERROR,
        message: err.message,
      });
    } else {
      notify({
        type: NotifyType.ERROR,
        message: err.response.data.nexsoft.payload.status.message,
      });
    }

    return err;
  }
};
