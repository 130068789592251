import { COMMON } from "../../Enum/EndPoint/CommonEndpoint";

export const REJECT_URL = Object.freeze({
  INITIATE: (filter) => COMMON.REJECT + COMMON.INITIATE + COMMON.FILTER(filter),
  LIST: (page, limit, order, filter) =>
    COMMON.REJECT_LIST + COMMON.LIST_FILTER(page, limit, order, filter),
  DETAIL: (type, id) => COMMON.REJECT + COMMON.INDEX + type + COMMON.INDEX + id,
  ADMIN: COMMON.INFINITE + COMMON.USER,
  EXPORT: (order, filter) => COMMON.EXPORT + COMMON.REJECT + COMMON.EXPORT_FILTER(order, filter),
});
