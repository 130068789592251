export const notificationBreadcrumb= t => [
    { value: t("COMMON:FIELD.SYSTEM_SETTING"), to: "/" },
    { value: t("COMMON:FIELD.NOTIFICATION.TITLE"), to: "/notification" }
];

export const notificationAfterListBreadcrumb= t => [
  { value: t("COMMON:FIELD.SYSTEM_SETTING"), to: "/" },
  { value: t("COMMON:FIELD.NOTIFICATION.TITLE"), to: "/notification" },
  { value: t("NOTIFICATION:TITLE_LIST"), to: "/notification" },
];
  