import React from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';

import { closeModal } from '../../../../Store/Action/ModalAction';
import { mappingActions } from '../../../../Store/Reducers/MappingReducer';
import { utilsActions } from '../../../../Store/Reducers/UtilsReducer';
import Modal from '../Modal/Modal';

function ModalClear(props) {
  const submitMapping = useSelector(state => state.mapping.submitMapping);
  const mappingForm = useSelector(state => state.mapping.mappingForm);
  const existing = useSelector(state => state.mapping.existing);

  const clear = async e => {
    e.preventDefault();
    
    await props.dispatch(utilsActions.setLoading(true));
    let tempForm = props.mappingForm.map(datas => {
      return {...datas, exist: null};
    });
    var field = Object.keys(existing);
    const exist = {};
    field.map(datas => (exist[datas] = null));
    let mapping = {};
    _.map(mappingForm, a =>
      a.key === props.uniqueKey
        ? (mapping[props.uniqueKey] = null)
        : a.isChecked === true
        ? (mapping[a.key] = a.new)
        : (mapping[a.key] = exist[a.key])
    );

    await props.dispatch(mappingActions.setMappingForm(tempForm));
    await props.dispatch(mappingActions.setSubmitMapping({...submitMapping, ...mapping}));
    await props.dispatch(mappingActions.setExisting(exist));
    await props.dispatch(utilsActions.setModal(false));
    await props.dispatch(utilsActions.setOverwrite(false));
    await props.dispatch(utilsActions.setLoading(false));
  };
  const modal = useSelector(state => state.utils.modal);

  const handleCancel = () => {
    closeModal(props.dispatch);
  };
  return (
    <Modal
      title={props.t("COMMON:CLEAR_DATA")}
      content={props.t("COMMON:REJECT_MESSAGE")}
      onClose={handleCancel}
      show={modal}
      t={props.t}
      yesAction={e => clear(e)}
      labelYes={props.t("COMMON:YES_CLEAR")}
    />
  );
}

export default ModalClear;
