export const fieldValid = (value, pattern) => {
  let regex = new RegExp(pattern);
  let valid = null;
  if (!regex.test(value)) {
    valid = regex.test(value);
  }
  if(value===""|| value===null){
    valid = null;
  }
  return valid
};
