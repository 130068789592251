import React from "react";

import { Div } from "nexcomponent-ui";

import { mainBreadCrumb } from "../../../../Enum/Breadcrumb/CommonBreadcrumb";
import { productGroupColumn } from "../../../../Enum/Column/ProductGroupColumn";
import { PAGE } from "../../../../Enum/IdSet";
import { MAPPING_CODE } from "../../../../Enum/MappingCode/MappingCode";
import { ROUTER_CONSTANT } from "../../../../Enum/RouterConstant/RouterConstant";
import {
  fetchDataProductGroupList,
  fetchProductGroupMappingForm,
} from "../../../../Fetch/FetchData/ProductGroupData";
import MappingTabContent from "../../../Organisms/TableList/MappingTabContent/MappingTabContent";
import MappingTabList from "../../../Organisms/TableList/MappingTabList/MappingTabList";
import { fetchDataProductGroupPrincipalList, fetchProductGroupPrincipalMappingForm } from "../../../../Fetch/FetchData/ProductGroupPrincipalData";

const ProductGroupPage = (props) => {
  const tabList = [
    {
      title: props.t("COMMON:PRINCIPAL"),
      component: (load)=>(
        <MappingTabContent
          notify={props.notify}
          t={props.t}
          load={load}
          dispatch={props.dispatch}
          navigate={props.navigate}
          mappingCode={MAPPING_CODE.PRODUCT_GROUP}
          principal={true}
          uniqueKey="principal_product_group_id"
          rejectNavigate={ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.REJECT_REASON}
          mappingNavigate={(NewId, ExistId, List) =>
            fetchProductGroupPrincipalMappingForm(
              props.validToken(),
              props.dispatch,
              "principal_product_group_id",
              NewId,
              ExistId,
              List,
              props.notify,
              () => props.navigate(`${ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.PRINCIPAL.MAPPING}`)
            )
          }
          column={productGroupColumn(props.t)}
          validToken={props.validToken}
          listFunc={(
            totalRow,
            countData,
            token,
            currentPage,
            rowLimit,
            sortDate,
            dispatch,
            notify
          ) =>
            fetchDataProductGroupPrincipalList(
              totalRow,
              countData,
              token,
              currentPage,
              rowLimit,
              sortDate,
              dispatch,
              notify
            )
          }
        />
      ),
      isShow: true,
    },
    {
      title: props.t("COMMON:MAPAN"),
      component: (load)=>(
        <MappingTabContent
          notify={props.notify}
          t={props.t}
          load={load}
          dispatch={props.dispatch}
          navigate={props.navigate}
          mappingCode={MAPPING_CODE.PRODUCT_GROUP}
          uniqueKey="product_group_hierarchy_id"
          rejectNavigate={ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.REJECT_REASON}
          mappingNavigate={(NewId, ExistId, List) =>
            fetchProductGroupMappingForm(
              props.validToken(),
              props.dispatch,
              "product_group_hierarchy_id",
              NewId,
              ExistId,
              List,
              props.notify,
              () => props.navigate(ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.MAPPING)
            )
          }
          column={productGroupColumn(props.t)}
          validToken={props.validToken}
          listFunc={(
            totalRow,
            countData,
            token,
            currentPage,
            rowLimit,
            sortDate,
            dispatch,
            notify
          ) =>
            fetchDataProductGroupList(
              totalRow,
              countData,
              token,
              currentPage,
              rowLimit,
              sortDate,
              dispatch,
              notify
            )
          }
        />
      ),
      isShow: false,
    },
  ];
  return (
    <Div id={PAGE.LIST}>
      <MappingTabList
        dispatch={props.dispatch}
        listBreadcrumb={mainBreadCrumb(props.t)}
        toTitlePage={ROUTER_CONSTANT.INDEX}
        title={props.t("PRODUCT_GROUP:TITLE")}
        defaultBreadcrumb={props.t("PRODUCT_GROUP:TITLE")}
        list={tabList}
      />
    </Div>
  );
};

export default ProductGroupPage;
