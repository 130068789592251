import { useEffect } from 'react';

import { ListEmpty } from 'nexcomponent-ui';
import { useSelector } from 'react-redux';

import SpinnerLoad
  from '../../../../Components/Molecules/Loader/SpinnerLoad/SpinnerLoad';
import { TABLE } from '../../../../Enum/IdSet';
import { utilsActions } from '../../../../Store/Reducers/UtilsReducer';
import {
  searchMappingColumnState,
} from '../../../../Store/StateGroup/SearchMappingColumnGroup';
import {
  tableSearchMappingByTypeStateGroup,
} from '../../../../Store/StateGroup/TableMappingByTypeStateGroup';
import { mappingNavigate } from '../../../../Utils/Other/MappingNavigate';
import TableList from '../TableList';
import useQueryRoute from '../../../../Hooks/useQueryRoute';

function SearchList(props) {
  const { principal } = useQueryRoute();

  const searchList = useSelector(state => state.mapping.searchList);
  const loading = useSelector(state => state.utils.loading);
  const initiate = useSelector(state => state.dashboard.initiate);
  const applyAdvanceSearch = useSelector(state => state.utils.applyAdvanceSearch);
  const searchData = useSelector(state => state.utils.searchData);
  const additionalColumn = useSelector(state => state.utils.searchColumn);
  const searchColumn = useSelector(state => searchMappingColumnState(state, props.columnDefault));
  const navigation = data =>{ props.navigate(`${principal ? "/principal" : ""}${mappingNavigate(props.mappingCode)}`, {state: {data}});
}
  const [totalRow, , currentPage, rowLimit] = useSelector(tableSearchMappingByTypeStateGroup);
  const sortDateAsc = useSelector(state => state.utils.sortDateAsc);
  const [sort] = sortDateAsc.split(" ");

  useEffect(() => {
    if(loading){
      props.searchFunc(currentPage, rowLimit,  { ...applyAdvanceSearch, ...searchData }, additionalColumn, props.dispatch);
    }

    if (sort === "updated_at") {
      props.dispatch(utilsActions.setSortDateAsc("created_at asc"));
      props.dispatch(utilsActions.setLoading(false));
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ loading ]);

  return (
    <>
      <TableList
        sort={false}
        id={TABLE.SEARCH_LIST}
        columns={searchColumn}
        data={loading ? null : searchList}
        limitation={initiate.valid_limit}
        totalRow={totalRow}
        footer={false}
        t={props.t}
        dispatch={props.dispatch}
        rowLink={true}
        rowClick={data => navigation(data)}
        overflowX="auto"
        overflowY="auto"
        fixFirstCol={true}
        fixHeaderBg="#F5EBF3"
        empty={
          loading ? (
            <ListEmpty value={<SpinnerLoad t={props.t} />} />
          ) : (
            <ListEmpty value={props.t("COMMON:LIST_EMPTY")} />
          )
        }
      />
    </>
  );
}

export default SearchList;
