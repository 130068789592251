import React from 'react';

import {
  Div,
  Field,
} from 'nexcomponent-ui';

import DatePicker
  from '../../../../../Components/UnstableComponent/DatePicker/DatePicker/DatePicker';
import { numerical } from '../../../../../Enum/Character/Numerical';
import { fieldMessage } from '../../../../../Utils/Action/Field/FieldMessage';
import { fieldValid } from '../../../../../Utils/Action/Field/FieldValid';
import style from './Field.module.css';

const FieldSearch = props => {
  const onlyNumber = evt => !numerical.includes(evt.key) && evt.preventDefault();
  return (
    <Div className={style.Field}>
      {props.type === "date" ? (
        <DatePicker
          label={props.label}
          onChange={props.onChange}
          classInput={style.Input}
          placeholder={props.placeholder}
          name={props.name}
        />
      ) : (
        <Field
          classInput={props.className}
          classLabel={props.className}
          classMessage={props.className}
          label={props.label}
          type={props.type === "textNumber" ? "text" : props.type}
          name={props.name}
          placeholder={props.placeholder}
          onChange={props.onChange}
          valid={fieldValid(props.valid)}
          pattern={props.pattern}
          maxLength={props.maxLength}
          onKeyDown={props.type === "number" || props.type === "textNumber" ? onlyNumber : () => {}}
          message={fieldMessage(props.valid, props.message)}
        />
      )}
    </Div>
  );
};

export default FieldSearch;
