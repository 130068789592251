import { Row, Image } from "nexcomponent-ui"
import { Icon } from '../../Assets/Icons'
import { openModal } from '../../Store/Action/ModalAction'
import { notificationActions } from '../../Store/Reducers/NotificationReducer'
import { Link } from "react-router-dom";
import { ROUTER_CONSTANT } from "../RouterConstant/RouterConstant";

export const notificationColumn = (t, dispatch) => [
    {
        name: t("COMMON:FIELD:NOTIFICATION.CODE"),
        selector: "notification_code",
        key: "notification_code",
        sortable: true,
        width: "20vw",
    },
    {
        name: t("COMMON:FIELD:NOTIFICATION.NAME"),
        selector: "notification_name",
        key: "notification_name",
        sortable: true,
        width: "25vw",
    },
    {
        name: t("COMMON:FIELD:NOTIFICATION.DATA_TYPE"),
        selector: "data_type",
        key: "data_type",
        width: "25vw",
    },
    {
        name: t("COMMON:FIELD:NOTIFICATION.DATE_CHANGED"),
        selector: "parsed_updated_at",
        key: "updated_at",
        sortable: true,
        width: "20vw",
    },
    {
        name: t("COMMON:FIELD:NOTIFICATION.STATUS"),
        selector: "status",
        key: "status",
        sortable: true,
        width: "20vw",
    },
    {
        name: t("COMMON:FIELD:NOTIFICATION.ACTION"),
        key: "action",
        width: "20vw",
        component: true,
        selector: row => {
            return <Row>
                <div onClick={async () => {
                    await dispatch(notificationActions.setSelectedNotification(row))
                    openModal(dispatch)
                }}>
                    <Image src={Icon.Delete} />
                </div>
                
                <div
                    style={{ 
                        marginLeft: '10px'
                    }}
                    onClick={async () => {
                        await dispatch(notificationActions.setSelectedNotification(row))
                    }}
                >
                    <Link to={ROUTER_CONSTANT.NOTIFICATION.EDIT}>
                        <Image src={Icon.Edit} />
                    </Link>
                </div>
            </Row>;
        },
    }
  ];
  