import { formatDD_MMMM_YYYY } from '../../Utils/Date/DateFormater';
import { itemDetailValue } from '../../Utils/Value';

export const detailBroadcastCompanyProfile = (t, data) => [
  {
    id: "tslcompanyprofileid",
    title: t("COMPANY_PROFILE:FIELD.ID"),
    value: itemDetailValue(data.id),
  },
  {
    id: "tslcompanytitlename",
    title: t("COMPANY_PROFILE:FIELD.COMPANY_TITLE_NAME"),
    value: itemDetailValue(data.company_title_name),
  },
  {
    id: "tslcompanytitleid",
    title: t("COMPANY_PROFILE:FIELD.COMPANY_TITLE_ID"),
    value: itemDetailValue(data.company_title_id),
  },
  {
    id: "tslnpwp",
    title: t("COMPANY_PROFILE:FIELD.NPWP"),
    value: itemDetailValue(data.npwp),
  },
  {
    id: "tslauthuserid",
    title: t("COMPANY_PROFILE:FIELD.NAME"),
    value: itemDetailValue(data.name),
  },
  {
    id: "tsladdress1",
    title: t("COMPANY_PROFILE:FIELD.ADDRESS_1"),
    value: itemDetailValue(data.address_1),
  },
  {
    id: "tsladdress2",
    title: t("COMPANY_PROFILE:FIELD.ADDRESS_2"),
    value: itemDetailValue(data.address_2),
  },
  {
    id: "tsladdress3",
    title: t("COMPANY_PROFILE:FIELD.ADDRESS_3"),
    value: itemDetailValue(data.address_3),
  },
  {
    id: "tslhamlet",
    title: t("COMPANY_PROFILE:FIELD.HAMLET"),
    value: itemDetailValue(data.hamlet),
  },
  {
    id: "tslneighbourhood",
    title: t("COMPANY_PROFILE:FIELD.NEIGHBOURHOOD"),
    value: itemDetailValue(data.neighbourhood),
  },
  {
    id: "tslcountryname",
    title: t("PERSON_PROFILE:FIELD.COUNTRY_NAME"),
    value: itemDetailValue(data.country_name),
  },
  {
    id: "tslcountryid",
    title: t("PERSON_PROFILE:FIELD.COUNTRY_ID"),
    value: itemDetailValue(data.country_id),
  },
  {
    id: "tslislancode",
    title: t("PERSON_PROFILE:FIELD.ISLAND_CODE"),
    value: itemDetailValue(data.island_code),
  },
  {
    id: "tslislandname",
    title: t("PERSON_PROFILE:FIELD.ISLAND_NAME"),
    value: itemDetailValue(data.island_name),
  },
  {
    id: "tslislandid",
    title: t("PERSON_PROFILE:FIELD.ISLAND_ID"),
    value: itemDetailValue(data.island_id),
  },
  {
    id: "tslprovincecode",
    title: t("PERSON_PROFILE:FIELD.PROVINCE_CODE"),
    value: itemDetailValue(data.province_code),
  },
  {
    id: "tslprovincename",
    title: t("PERSON_PROFILE:FIELD.PROVINCE_NAME"),
    value: itemDetailValue(data.province_name),
  },
  {
    id: "tslprovinceid",
    title: t("PERSON_PROFILE:FIELD.PROVINCE_ID"),
    value: itemDetailValue(data.province_id),
  },
  {
    id: "tsldistrictcode",
    title: t("PERSON_PROFILE:FIELD.DISTRICT_CODE"),
    value: itemDetailValue(data.district_code),
  },
  {
    id: "tsldistricname",
    title: t("PERSON_PROFILE:FIELD.DISTRICT_NAME"),
    value: itemDetailValue(data.district_name),
  },
  {
    id: "tsl",
    title: t("PERSON_PROFILE:FIELD.DISTRICT_ID"),
    value: itemDetailValue(data.district_id),
  },
  {
    id: "tslsubdistrictcode",
    title: t("PERSON_PROFILE:FIELD.SUBDISTRICT_CODE"),
    value: itemDetailValue(data.sub_district_code),
  },
  {
    id: "tslsub_districname",
    title: t("PERSON_PROFILE:FIELD.SUBDISTRICT_NAME"),
    value: itemDetailValue(data.sub_district_name),
  },
  {
    id: "tsl",
    title: t("PERSON_PROFILE:FIELD.SUBDISTRICT_ID"),
    value: itemDetailValue(data.sub_district_id),
  },
  {
    id: "tslurbanvillagecode",
    title: t("PERSON_PROFILE:FIELD.URBAN_VILLAGE_CODE"),
    value: itemDetailValue(data.urban_village_code),
  },
  {
    id: "tslurbavillagname",
    title: t("PERSON_PROFILE:FIELD.URBAN_VILLAGE_NAME"),
    value: itemDetailValue(data.urban_village_name),
  },
  {
    id: "tsl",
    title: t("PERSON_PROFILE:FIELD.URBAN_VILLAGE_ID"),
    value: itemDetailValue(data.urban_village_id),
  },
  {
    id: "tslpostacode",
    title: t("PERSON_PROFILE:FIELD.POSTAL_CODE_CODE"),
    value: itemDetailValue(data.postal_code),
  },
  {
    id: "tslpostalcodeid",
    title: t("PERSON_PROFILE:FIELD.POSTAL_CODE_ID"),
    value: itemDetailValue(data.postal_code_id),
  },
  {
    id: "tsllatitude",
    title: t("COMPANY_PROFILE:FIELD.LATITUDE"),
    value: itemDetailValue(data.latitude),
  },
  {
    id: "tsllongitude",
    title: t("COMPANY_PROFILE:FIELD.LONGITUDE"),
    value: itemDetailValue(data.longitude),
  },
  {
    id: "tslphone",
    title: t("COMPANY_PROFILE:FIELD.PHONE"),
    value: itemDetailValue(data.phone),
  },
  {
    id: "tslfax",
    title: t("COMPANY_PROFILE:FIELD.FAX"),
    value: itemDetailValue(data.fax),
  },
  {
    id: "tslemail",
    title: t("COMPANY_PROFILE:FIELD.EMAIL"),
    value: itemDetailValue(data.email),
  },
  {
    id: "tslalternativeemail",
    title: t("COMPANY_PROFILE:FIELD.ALTERNATIVE_EMAIL"),
    value: itemDetailValue(data.alternative_email),
  },
  {
    id: "tslpkpstatus",
    title: t("COMPANY_PROFILE:FIELD.PKP_STATUS"),
    value: itemDetailValue(data.pkp_status),
  },
  {
    id: "tslbumnstatus",
    title: t("COMPANY_PROFILE:FIELD.BUMN_STATUS"),
    value: itemDetailValue(data.bumn_status),
  },
  {
    id: "tslpbfstatus",
    title: t("COMPANY_PROFILE:FIELD.PBF_STATUS"),
    value: itemDetailValue(data.pbf_status),
  },
  {
    id: "tslpbflicensenumber",
    title: t("COMPANY_PROFILE:FIELD.PBF_LICENSE_NUMBER"),
    value: itemDetailValue(data.pbf_license_number),
  },
  {
    id: "tslpbflicencseenddate",
    title: t("COMPANY_PROFILE:FIELD.PBF_LICENSE_END_DATE"),
    value: itemDetailValue(formatDD_MMMM_YYYY(data.pbf_license_end_date)),
  },
  {
    id: "tslpbftrusteesipanumber",
    title: t("COMPANY_PROFILE:FIELD.PBF_TRUSTEE_SIPA_NUMBER"),
    value: itemDetailValue(data.pbf_trustee_sipa_number),
  },
  {
    id: "tslpbfsipalicenseenddate",
    title: t("COMPANY_PROFILE:FIELD.PBF_SIPA_LICENCE_END_DATE"),
    value: itemDetailValue(formatDD_MMMM_YYYY(data.pbf_sipa_license_end_date)),
  },
  {
    id: "tsladdtionalinfo",
    title: t("COMPANY_PROFILE:FIELD.ADDITIONAL_INFORMATION"),
    value: itemDetailValue(data.additional_info),
    obj:true
  },
  {
    id: "tslparentcompanynpwp",
    title: t("COMPANY_PROFILE:FIELD.PARENT_COMPANY_NPWP"),
    value: itemDetailValue(data.parent_company_npwp),
  },
  {
    id: "tslparentcompanyname",
    title: t("COMPANY_PROFILE:FIELD.PARENT_COMPANY_NAME"),
    value: itemDetailValue(data.parent_company_name),
  },
  {
    id: "tslparentcompanyid",
    title: t("COMPANY_PROFILE:FIELD.PARENT_COMPANY_ID"),
    value: itemDetailValue(data.parent_company_id),
  },
  {
    id: "tslresource",
    title: t("COMPANY_PROFILE:FIELD.RESOURCE"),
    value: itemDetailValue(data.resource),
  },
];
