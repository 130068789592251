export const searchPersonProfileColumn = t => [
  // {
  //   name: "Resource",
  //   selector: "resource",
  //   width: "100px",
  // },
  {
    name: "Person Profile ID",
    selector: "person_profile_id",
    width: "100px",
  },
  {
    name: "Client ID",
    selector: "client_id",
    width: "100px",
  },
  {
    name: "First Name",
    selector: "first_name",
    width: "200px",
  },
  {
    name: "Last Name",
    selector: "last_name",
    width: "200px",
  },
  {
    name: "NIK",
    selector: "nik",
    width: "100px",
  },
  {
    name: "NPWP",
    selector: "npwp",
    width: "100px",
  },
  {
    name: "Phone",
    selector: "phone",
    width: "100px",
  },
  {
    name: "Alternative Phone",
    selector: "alternative_phone",
    width: "100px",
  },
  {
    name: "Address 1",
    selector: "address_1",
    width: "100px",
  },
  {
    name: "Email",
    selector: "email",
    width: "100px",
  },
];
