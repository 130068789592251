import React from 'react';

import { Div } from 'nexcomponent-ui';

import {
  productCategoryBreadCrumb,
} from '../../../../Enum/Breadcrumb/ProductCategoryBreadcrumb';
import {
  productCategoryColumn,
} from '../../../../Enum/Column/ProductCategoryColumn';
import { PAGE } from '../../../../Enum/IdSet';
import { MAPPING_CODE } from '../../../../Enum/MappingCode/MappingCode';
import {
  ROUTER_CONSTANT,
} from '../../../../Enum/RouterConstant/RouterConstant';
import {
  postRejectProductCategoryList,
} from '../../../../Fetch/Api/ProductCategoryAPI';
import {
  fetchProductCategoryMappingForm,
} from '../../../../Fetch/FetchData/ProductCategoryData';
import CancelRejectButton
  from '../../../Organisms/Button/CancelRejectButton/CancelRejectButton';
import HeaderPage from '../../../Organisms/Header/HeaderPage/HeaderPage';
import ModalReject from '../../../Organisms/Modal/ModalReject/ModalReject';
import RejectReasonList
  from '../../../Organisms/TableList/RejectReasonList/RejectReasonList';

const ProductCategoryRejectReasonPage = props => (
  <Div id={PAGE.REJECT_LIST}>
    <ModalReject
      onReject={true}
      t={props.t}
      dispatch={props.dispatch}
      mappingCode={MAPPING_CODE.PRODUCT_CATEGORY}
      navigate={props.navigate}
      rejectFunc={(data, notify, dispatch) =>
        postRejectProductCategoryList(props.validToken(), data, notify, dispatch)
      }
      uniqueKey={"product_category_id"}
      nextMapping={(newId, existId, mappingList, nav) =>
        fetchProductCategoryMappingForm(
          props.validToken(),
          props.dispatch,
          "product_category_id",
          newId,
          existId,
          mappingList,
          props.notify,
          () => props.navigate(nav)
        )
      }
    />
    <HeaderPage
      title={props.t("PRODUCT_CATEGORY:TITLE")}
      listBreadcrumb={productCategoryBreadCrumb(props.t)}
      defaultBreadcrumb={props.t("COMMON:REJECT_REASON")}
      toTitlePageto={ROUTER_CONSTANT.PRODUCT_CATEGORY.LIST}
    />
    <RejectReasonList
      t={props.t}
      dispatch={props.dispatch}
      column={productCategoryColumn(props.t)}
      mappingCode={MAPPING_CODE.PRODUCT_CATEGORY}
    />
    <CancelRejectButton
      backPage={ROUTER_CONSTANT.PRODUCT_CATEGORY.LIST}
      mappingCode={MAPPING_CODE.PRODUCT_CATEGORY}
      onReject={true}
      t={props.t}
      dispatch={props.dispatch}
      navigate={props.navigate}
    />
  </Div>
);

export default ProductCategoryRejectReasonPage;
