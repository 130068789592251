import { itemDetailValue } from '../../Utils/Value';

export const detailBroadcastPPUDC = (t, data) => [
  {
    id: "tslid",
    title: t("PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY:FIELD.ID"),
    value: itemDetailValue(data.id),
  },
  {
    id: "tslcode",
    title: t("PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY:FIELD.CODE"),
    value: itemDetailValue(data.code),
  },
  {
    id: "tslcategorynumber",
    title: t("PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY:FIELD.CATEGORY_NUMBER"),
    value: itemDetailValue(data.category_number),
  },
  {
    id: "tslvalue",
    title: t("PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY:FIELD.VALUE"),
    value: itemDetailValue(data.value),
  },
  {
    id: "tslprincipalcode",
    title: t("PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY:FIELD.PRINCIPAL_CODE"),
    value: itemDetailValue(data.principal_code),
  },
  {
    id: "tslprincipalname",
    title: t("PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY:FIELD.PRINCIPAL_NAME"),
    value: itemDetailValue(data.principal_name),
  },
  {
    id: "tslprincipalid",
    title: t("PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY:FIELD.PRINCIPAL_ID"),
    value: itemDetailValue(data.principal_id),
  },
  {
    id: "tslresourse",
    title: t("PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY:FIELD.RESOURCE"),
    value: itemDetailValue(data.resource),
  },
];
