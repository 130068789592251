import React, {
  useEffect,
  useState,
} from 'react';

import _ from 'lodash';
import {
  Div,
  Li,
  nexIcon,
  Span,
  Ul,
} from 'nexcomponent-ui';

import useComponentVisible from '../Multi/useComponentVisible';
import style from './Select.module.css';

const Select = props => {
  const {
    ref: componentRef,
    isComponentVisible: menuOpen,
    setIsComponentVisible: setMenuOpen,
  } = useComponentVisible({
    initialIsVisible: false,
    onClickOutside: props.onMenuClose,
  });
  const openMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const [evt, setEvt] = useState({
    target: {
      id: props.id,
      name: props.name,
      value: null,
      label: null,
    },
  });
  const getActiveClass = () => {
    const el = componentRef.current;
    var rect = el.getBoundingClientRect();
    if (rect.top < 400) {
    }
    let position = style.SlcOptionDown;
    return position;
  };

  const getWidth = () => {
    const el = componentRef.current;
    var rect = el.getBoundingClientRect();
    let position = rect.width;

    return position;
  };

  const handleVisibility = menuOpen => {
    let display = "none";
    if ( menuOpen) {
      display = "block";
    }
    return display;
  };
  const [first, setFirst] = useState(true);
  const [opt, setOpt] = useState([]);
  useEffect(() => {
    // if(defaultSelect===!)
    if (props.option.length > 0) {
      if (props.index !== undefined) {
        setEvt({
          target: {
            id: props.id,
            name: props.name,
            value: props.option[props.index][props.valueKey],
            label: props.option[props.index][props.labelKey],
          },
        });
        setOpt(
          props.option.filter(object => {
            return object[props.valueKey] !== props.option[props.index][props.valueKey];
          })
        );
      }
      if (props.defaultSelect !== undefined) {
        if (first) {
          setEvt({
            target: {
              id: props.id,
              name: props.name,
              value: props.defaultSelect[props.valueKey],
              label: props.defaultSelect[props.labelKey],
            },
          });
          setOpt(
            props.option.filter(object => {
              return object[props.valueKey] !== props.defaultSelect[props.valueKey];
            })
          );
          setFirst(false);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evt.target.value, first, props.option, props.valueKey, props.index]);

  const handleSelect = (val, lbl) => {
    setMenuOpen(false);
    setEvt({target: {id: props.id, name: props.name, value: val, label: lbl}});
    setOpt(
      props.option.filter(object => {
        return object[props.valueKey] !== val;
      })
    );
    props.onChange({target: {id: props.id, name: props.name, value: val, label: lbl}});
  };

  return (
    <Div
      id={props.id}
      ref={componentRef}
      onClick={openMenu}
      tabIndex="0"
      className={style.SlcContainer}
    >
      <Div
        id={"box" + props.id}
        className={`${style.SlcInput} ${props.className} ${menuOpen ? "select-input-active" : ""}`}
      >
        {evt.target.label === undefined || evt.target.label === "" ? (
          <Div
            id={"tsl" + props.id}
            data-placeholder={props.placeholder}
            className={`${style.SlcInputs} `}
          >"ted"</Div>
        ) : (
          <Span id={"tsl" + props.id} value={evt.target.label===null?"Select Your Option":evt.target.label} />
        )}
        <nexIcon.NcSelectOption className={style.IconSelect} width={10} height={10} />
      </Div>
      <Div
        id={"ddl" + props.id}
        style={{
          display: handleVisibility(menuOpen),
          width: menuOpen && getWidth(),
        }}
        display={""}
        className={`${style.SlcOption} ${menuOpen && getActiveClass()} `}
      >
        <Ul id={"ul" + props.id} className={style.SlcOptionUl}>
          {_.map(opt, (detail, index) => (
            <Li
              id={"li" + props.id}
              className={`${style.SlcOptionLi} select-option-li`}
              key={index}
              onClick={() => handleSelect(detail[props.valueKey], detail[props.labelKey])}
            >
            {detail[props.labelKey]}
            </Li>
          ))}
        </Ul>
      </Div>
    </Div>
  );
};

export default Select;
Select.defaultProps = {
  onMenuClose: () => {},
  id: "",
  option:[]

};
