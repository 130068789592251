import _ from 'lodash';

export const valueMappingField = (mappingForm, key, item) => {
  try {
    const value =  _.find(mappingForm, ["key", key]);
    return value[item];
  } catch (error) {
     window.location.href = "/"
  }
};
