import React, { useEffect } from 'react';

import { ListEmpty } from 'nexcomponent-ui';
import { useSelector } from 'react-redux';

import SpinnerLoad
  from '../../../../Components/Molecules/Loader/SpinnerLoad/SpinnerLoad';
import { MappingColumn } from '../../../../Enum/Column/MappingColumn';
import { mappingType } from '../../../../Enum/DataTypeContant';
import { TABLE } from '../../../../Enum/IdSet';
import {
  fetchDataMappingList,
} from '../../../../Fetch/FetchData/DashboardData';
import { dashboardAction } from '../../../../Store/Reducers/DashboardReducer';
import { utilsActions } from '../../../../Store/Reducers/UtilsReducer';
import { tableAllMappingStateGroup } from '../../../../Store/StateGroup';
import { loadingData } from '../../../../Utils/Handler/LoadingData';
import TableList from '../TableList';

function AllMappingList(props) {
  const [initiate, totalRow, mappingList, currentPage, loading, rowLimit, sortDate] =
    useSelector(tableAllMappingStateGroup);
  const [sort] = sortDate.split(" ");
  useEffect(() => {
   
      fetchDataMappingList(
        totalRow,
        initiate.count_data,
        props.validToken(),
        currentPage,
        rowLimit,
        sortDate,
        props.dispatch,
        props.notify
      );
    
    if (sort === "updated_at") {
      props.dispatch(utilsActions.setSortDateAsc("created_at asc"));
    }
  }, [
    currentPage,
    initiate.count_data,
    loading,
    props,
    props.dispatch,
    rowLimit,
    sort,
    sortDate,
    totalRow,
  ]);
  loadingData(loading, props);
  const navigation = data => {
    props.dispatch(dashboardAction.setMenuLevel1("data"));
    props.dispatch(utilsActions.setSideMenu("data"));
    props.dispatch(utilsActions.setSideBarCollapse(true));
    props.navigate(mappingType(data.mapping_data_id));
  };

  return (
    <>
      <TableList
        id={TABLE.AL_MAPPING_LIST}
        sort={true}
        columns={MappingColumn(props.t)}
        data={mappingList}
        limitation={initiate.valid_limit}
        totalRow={initiate.count_data}
        rowLink={true}
        rowClick={data => navigation(data)}
        empty={
          !loading && mappingList === null ? (
            <ListEmpty value={props.t("COMMON:REQUEST_TIME_OUT.TITLE")} />
          ) : !loading && mappingList.length === 0 ? (
            <ListEmpty value={props.t("COMMON:LIST_EMPTY")} />
          ) : (
            <ListEmpty value={<SpinnerLoad t={props.t} />} />
          )
        }
        t={props.t}
        footer={false}
        dispatch={props.dispatch}
      />
    </>
  );
}

export default AllMappingList;
