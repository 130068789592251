import { ROUTER_CONSTANT } from '../RouterConstant/RouterConstant';

export const productGroupBreadCrumb= t => [
  {value: t("COMMON:DATA_PROCESS"), to: ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.LIST},
  {value: t("COMMON:MAPPINGS"), to: ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.LIST},
  {value: t("COMMON:FIELD.PRODUCT_GROUP"), to: ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.LIST},
];
export const productGroupSearchBreadCrumb =t=> [
  { value: t("COMMON:DATA_PROCESS"), to: "/" },
  { value: t("COMMON:MAPPINGS"), to: "/" },
  { value:t("COMMON:FIELD.PRODUCT_GROUP"), to: ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.LIST },
  { value: `${t("COMMON:MAPPINGS")} ${t("COMMON:FIELD.PRODUCT_GROUP")}`, to: ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.MAPPING },
];
export const productGroupPrincipalSearchBreadCrumb =t=> [
  { value: t("COMMON:DATA_PROCESS"), to: "/" },
  { value: t("COMMON:MAPPINGS"), to: "/" },
  { value:t("COMMON:FIELD.PRODUCT_GROUP"), to: ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.LIST },
  { value: `${t("COMMON:MAPPINGS")} ${t("COMMON:FIELD.PRODUCT_GROUP")}`, to: `${ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.PRINCIPAL.MAPPING}` },
];
