import React from 'react';

import { Div } from 'nexcomponent-ui';

import {
  companyProfileAadvanceSearch,
} from '../../../../Enum/AdvanceSearch/CompanyProfileAdvanceSearch';
import {
  companyProfileSearchBreadCrumb,
} from '../../../../Enum/Breadcrumb/CompanyProfileBreadcrumb';
import {
  searchCompanyProfileColumn,
} from '../../../../Enum/Column/SearchCompanyProfileColumn';
import { MAPPING_CODE } from '../../../../Enum/MappingCode/MappingCode';
import {
  ROUTER_CONSTANT,
} from '../../../../Enum/RouterConstant/RouterConstant';
import {
  fetchDataCompanyProfileSearchList,
} from '../../../../Fetch/FetchData/CompanyProfileData';
import FieldCompanyProfileSearchForm
  from '../../../Organisms/Form/SearchForm/FieldCompanyProfileSearchForm/FieldCompanyProfileSearchForm';
import SearchForm
  from '../../../Organisms/Form/SearchForm/SearchForm/SearchForm';
import HeaderPage from '../../../Organisms/Header/HeaderPage/HeaderPage';

function CompanyProfileSearchPage(props) {
  return (
    <Div>
      <HeaderPage
        title={props.t("COMPANY_PROFILE:SEARCH.TITLE")}
        listBreadcrumb={companyProfileSearchBreadCrumb(props.t)}
        defaultBreadcrumb={props.t("COMPANY_PROFILE:SEARCH.TITLE")}
        toTitlePage={ROUTER_CONSTANT.COMPANY_PROFILE.MAPPING}
      />
      <SearchForm
        title="COMPANY_PROFILE:SEARCH.TITLE"
        field={<FieldCompanyProfileSearchForm t={props.t} dispatch={props.dispatch} />}
        t={props.t}
        dispatch={props.dispatch}
        mappingCode={MAPPING_CODE.COMPANY_PROFILE}
        navigate={props.navigate}
        option={companyProfileAadvanceSearch(props.t)}
        advance={true}
        columnDefault={searchCompanyProfileColumn(props.t)}
        backNavigate={ROUTER_CONSTANT.COMPANY_PROFILE.MAPPING}
        searchFunc={(currentPage, rowLimit,filter, column,dispatch) =>
          fetchDataCompanyProfileSearchList(
            props.validToken(),
            currentPage,
            rowLimit,
            filter,
            column,
            dispatch,
            props.notify
          )
        }
      />
    </Div>
  );
}

export default CompanyProfileSearchPage;
