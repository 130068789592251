export const productBrandFieldMapping=[
  "product_brand_id" ,
  "code" ,
  "name" ,
  "principal_id",
  "principal_name" ,
  "principal_code" ,
  "owned_start_date" ,
]
export const productBrandPrincipalFieldMapping=[
  "principal_product_brand_id" ,
  "code" ,
  "name" ,
  "principal_id",
  "principal_name" ,
  "principal_code" ,
  "owned_start_date" ,
]