export const ID ={
  CHECKBOX:"ckb",
  ICON:"ico",
  BUTTON:"btn",
  TEXT_SINGLE:"tsl",
  PAGE:"pge",
  BOX:"box",
  FORM:"form",
  LOGO:"logo",
  LABEL:"lbl",
  HYPERLINK:"hyp",
  CARD:"card",
  DROPDOWNLIST:"ddl",
  BREADCRUMB:"brd",
  TABLE:"tbl"
}