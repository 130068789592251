import { COMMON } from '../../Enum/EndPoint/CommonEndpoint';

export const PRODUCT_URL = Object.freeze({
  COUNT: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRODUCT + COMMON.COUNT,
  LIST: function list(page, limit, order) {
    return COMMON.MAPPING + COMMON.MAPPING_TYPE.PRODUCT + COMMON.LIST(page, limit, order);
  },
  REJECT: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRODUCT + COMMON.REJECT,
  DETAIL: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRODUCT + COMMON.INDEX,
  DETAIL_EXISTING: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRODUCT + COMMON.EXISTING,
  SEARCH: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRODUCT + COMMON.SEARCH,
  COUNT_SEARCH: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRODUCT + COMMON.SEARCH + COMMON.COUNT,
  MAPPING_APPROVE: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRODUCT + COMMON.APPROVE,

  PRINCIPAL:{
    COUNT: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRINCIPAL_PRODUCT + COMMON.COUNT,
    LIST: function list(page, limit, order) {
      return COMMON.MAPPING + COMMON.MAPPING_TYPE.PRINCIPAL_PRODUCT + COMMON.LIST(page, limit, order);
    },
    REJECT: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRINCIPAL_PRODUCT + COMMON.REJECT,
    DETAIL: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRINCIPAL_PRODUCT + COMMON.INDEX,
    DETAIL_EXISTING: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRINCIPAL_PRODUCT + COMMON.EXISTING,
    SEARCH: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRINCIPAL_PRODUCT + COMMON.SEARCH,
    COUNT_SEARCH: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRINCIPAL_PRODUCT + COMMON.SEARCH + COMMON.COUNT,
    MAPPING_APPROVE: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRINCIPAL_PRODUCT + COMMON.APPROVE,
  }
});
