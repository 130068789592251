export const REGEX_CONSTANT = Object.freeze({
  USERNAME: /^[a-z][a-z0-9_.]{5,}/,

  PASSWORD: /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$`~!^&*()_=+{}:;'<,.>/%-]).{8,20})/,

  NAME: "^[A-Z][a-z]+(([ ][A-Z][a-z])?[a-z]*)*$",
  LETTER: "[a-zA-Z]+",
  NAME_MAPPING: "^[A-Z0-9](([ ][A-Z0-9][a-z0-9])?([-_][A-Z0a-z0-9])?[a-z0-9]*)*$",
  NAME_NON_CAPITILIZE: "[a-zA-Z ]{0,20}$",
  FIST_NAME: "[a-zA-Z ]{0,20}$",
  LAST_NAME: "[a-zA-Z ]{0,35}$",
  NO_SPACE: /^[A-Za-z0-9-]{0,20}$/,
  SMALL_REGIONAL: /^[0-9]{0,3}$/,

  EMAIL: "^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$",

  PERMISSION: /(^[a-z]+[a-z.]+[a-z]+[:]+[a-z-]+$)/,

  DATA_SCOPE: /(^[a-z]+[a-z.]+[a-z]+[:]+[a-z-]+$)/,

  INTEGER: "^[0-9]*$",

  PHONE_COUNTRY_CODE: /^[0-9]{1,3}$/,
  PHONE_NUMBER: "^[+0-9]+(([ -][0-9])?[0-9]*)*$",
  FLOAT: /^-?\d{1,}(\.\d{1,})?$/,
  FLOATPLUS: /^[0-9]\d*(\.\d+)?$/,

  STRING_CHAR: /^[a-zA-Z\s./-]+$/,
  ANY_NON_WHITESAPCE: /\S+/,

  NIK: "[0-9]{16}",

  NPWP: "^(?:(0[0-9]{15})|([1-9]{1}[0-9]{5}((?:(0[1-9]|[12]\d|[4-6]\d|[37][01]))((0[1-9]|[1][0-2]))[0-9]{6}))|(?:[0-9]{15}))$",

  INFORMATION_KEY: /^[a-z0-9_.-]+$/,

  INFORMATION_VALUE: /^[a-z0-9_.-]+$/,

  SPECIAL_CHARACTER: /^[A-Z0-9][A-Za-z 0-9-_]+$/,

  SPECIAL_CHARACTER_2: /^[A-Za-z0-9][A-Za-z 0-9-_,.=()*&/@#$%{}[:;'"<>?!~`+|^]+$/,
});
export const REGEX_PHONE_NUMBER = /^(^\+62|62|^08)(\d{3,4}-?){2}\d{3,4}$/;
