import React from 'react';

import { Div } from 'nexcomponent-ui';

import {
  companyProfileBreadCrumb,
} from '../../../../Enum/Breadcrumb/CompanyProfileBreadcrumb';
import {
  companyProfileColumn,
} from '../../../../Enum/Column/CompanyProfileColumn';
import { PAGE } from '../../../../Enum/IdSet';
import { MAPPING_CODE } from '../../../../Enum/MappingCode/MappingCode';
import {
  ROUTER_CONSTANT,
} from '../../../../Enum/RouterConstant/RouterConstant';
import {
  postRejectCompanyProfileList,
} from '../../../../Fetch/Api/CompanyProfileAPI';
import {
  fetchCompanyProfileMappingForm,
} from '../../../../Fetch/FetchData/CompanyProfileData';
import CancelRejectButton
  from '../../../Organisms/Button/CancelRejectButton/CancelRejectButton';
import HeaderPage from '../../../Organisms/Header/HeaderPage/HeaderPage';
import ModalReject from '../../../Organisms/Modal/ModalReject/ModalReject';
import RejectReasonList
  from '../../../Organisms/TableList/RejectReasonList/RejectReasonList';

const CompanyProfileRejectReasonPage = props => (
  <Div id={PAGE.REJECT_LIST}>
    <ModalReject
      t={props.t}
      onReject={true}
      dispatch={props.dispatch}
      navigate={props.navigate}
      mappingCode={MAPPING_CODE.COMPANY_PROFILE}
      rejectFunc={(data, notify, dispatch) =>
        postRejectCompanyProfileList(props.validToken(), data, notify, dispatch)
      }
      uniqueKey={"company_profile_id"}
      nextMapping={(newId, existId, mappingList, nav) =>
        fetchCompanyProfileMappingForm(
          props.validToken(),
          props.dispatch,
          "company_profile_id",
          newId,
          existId,
          mappingList,
          props.notify,
          () => props.navigate(nav)
        )
      }
    />
    <HeaderPage
      title={props.t("COMPANY_PROFILE:TITLE")}
      listBreadcrumb={companyProfileBreadCrumb(props.t)}
      defaultBreadcrumb={props.t("COMMON:REJECT_REASON")}
      toTitlePageto={ROUTER_CONSTANT.COMPANY_PROFILE.LIST}
    />
    <RejectReasonList
      t={props.t}
      dispatch={props.dispatch}
      column={companyProfileColumn(props.t)}
      mappingCode={MAPPING_CODE.COMPANY_PROFILE}
    />
    <CancelRejectButton
      backPage={ROUTER_CONSTANT.COMPANY_PROFILE.LIST}
      mappingCode={MAPPING_CODE.COMPANY_PROFILE}
      onReject={true}
      t={props.t}
      dispatch={props.dispatch}
      navigate={props.navigate}
    />
  </Div>
);

export default CompanyProfileRejectReasonPage;
