export const searchPrincipalProductUserDefinedCategoryColumn = t => [
  // {
  //   name: t("COMMON:FIELD.RESOURCE"),
  //   selector: "resources",
  //   width: "100px",
  // },
  {
    name: t("PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY:FIELD.ID"),
    selector: "principal_product_user_defined_category_id",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.PRINCIPAL_CODE"),
    selector: "principal_code",
    width: "200px",
  },
  {
    name: t("COMMON:FIELD.PRINCIPAL_NAME"),
    selector: "principal_name",
    width: "200px",
  },
  {
    name: t("COMMON:FIELD.PRINCIPAL_ID"),
    selector: "principal_id",

    width: "100px",
  },
  {
    name: t("COMMON:FIELD.CODE"),
    selector: "code",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.CATEGORY_NUMBER"),
    selector: "category_number",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.VALUE"),

    selector: "value",
    width: "100px",
  },
];
