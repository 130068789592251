import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    notificationList: [],
    filter: "",
    totalRow: 0,
    selectedNotification: {},
    validLimit: [],
    currentPage: 0,
    searchKey: null,
    searchValue: ""
}

const notificationSlice = createSlice({
    name: "notification",
    initialState: initialState,
    reducers: {
        setNotificationList(state, data) {
            state.notificationList = data.payload
        },
        setNotificationFilter(state, data) {
            state.filter = data.payload
        },
        setNotificationTotalRow(state, data) {
            state.totalRow = data.payload
        },
        setSelectedNotification(state, data) {
            state.selectedNotification = data.payload
        },
        setNotificationValidLimit(state, data) {
            state.validLimit = data.payload
        },
        setSearchKey(state, data) {
            state.searchKey = data.payload
        },
        setSearchValue(state, data) {
            state.searchValue = data.payload
        }
    }
})

export const notificationActions = notificationSlice.actions
export default notificationSlice.reducer
