import React from 'react';

import { Div } from 'nexcomponent-ui';

import { mainBreadCrumb } from '../../../../Enum/Breadcrumb/CommonBreadcrumb';
import {
  principalProductUserDefinedCategoryColumn,
} from '../../../../Enum/Column/PrincipalProductUserDefinedCategoryColumn';
import { PAGE } from '../../../../Enum/IdSet';
import { MAPPING_CODE } from '../../../../Enum/MappingCode/MappingCode';
import {
  ROUTER_CONSTANT,
} from '../../../../Enum/RouterConstant/RouterConstant';
import {
  fetchDataPrincipalProductUserDefinedCategoryList,
  fetchPrincipalProductUserDefinedCategoryMappingForm,
} from '../../../../Fetch/FetchData/PrincipalProductUserDefinedCategoryData';
import HeaderPageList
  from '../../../Organisms/Header/HeaderPageList/HeaderPageList';
import MappingList from '../../../Organisms/TableList/MappingList/MappingList';

const PrincipalProductUserDefinedCategoryPage = props => (
  <Div id={PAGE.LIST}>
    <HeaderPageList
      notify={props.notify}
      t={props.t}
      dispatch={props.dispatch}
      navigate={props.navigate}
      listBreadcrumb={mainBreadCrumb(props.t)}
      toTitlePage={ROUTER_CONSTANT.INDEX}
      title={props.t("PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY:TITLE")}
      mappingCode={MAPPING_CODE.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY}
      defaultBreadcrumb={props.t("PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY:TITLE")}
      rejectNavigate={ROUTER_CONSTANT.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY.REJECT_REASON}
      uniqueKey="principal_product_user_defined_category_id"
      mappingNavigate={(NewId, ExistId, List) =>
        fetchPrincipalProductUserDefinedCategoryMappingForm(
          props.validToken(),
          props.dispatch,
          "principal_product_user_defined_category_id",
          NewId,
          ExistId,
          List,
          props.notify,
          () => props.navigate(ROUTER_CONSTANT.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY.MAPPING)
        )
      }
    />
    <MappingList
      t={props.t}
      column={principalProductUserDefinedCategoryColumn(props.t)}
      mappingCode={MAPPING_CODE.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY}
      validToken={props.validToken}
      dispatch={props.dispatch}
      navigate={props.navigate}
      notify={props.notify}
      listFunc={(totalRow, countData, token, currentPage, rowLimit, sortDate, dispatch, notify) =>
        fetchDataPrincipalProductUserDefinedCategoryList(
          totalRow,
          countData,
          token,
          currentPage,
          rowLimit,
          sortDate,
          dispatch,
          notify
        )
      }
    />
  </Div>
);

export default PrincipalProductUserDefinedCategoryPage;
