// import { createObjectCsvWriter } from "csv-writer";
import moment from "moment";
import { dashboardAction } from "../../Store/Reducers/DashboardReducer";
import { rejectsActions } from "../../Store/Reducers/RejectReducer";
import { utilsActions } from "../../Store/Reducers/UtilsReducer";
import { isContent, isContentErr } from "../../Utils/Response/Response";
import {
  getRejectAdminList,
  getRejectDetail,
  getRejectExport,
  getRejectInitiate,
  getRejectList,
} from "../Api/RejectAPI";
import { setDefaultPagination } from "./DashboardData";
import { NotifyType } from "../../Enum/Notification/NotificationType";

const errContent = (err) => {
  const { content } = err.response.data.nexsoft.payload.data;
  return content;
};
export const setInitiateReject = (res, dispatch) => {
  dispatch(rejectsActions.setRejectInitiate(isContent(res)));
};
export const fetchDataInitiateReject = (token, dispatch, filter, notify) => {
  getRejectInitiate(token, filter, notify, dispatch, (res) => setInitiateReject(res, dispatch));
};
const setListReject = (res, dispatch) => {
  dispatch(rejectsActions.setRejectList(isContent(res)));
};
const setListRejectErr = (res, dispatch) => {
  dispatch(rejectsActions.setRejectList(errContent(res)));
};
const setDetalReject = (res, dispatch) => {
  dispatch(rejectsActions.setRejectDetail(res));
};
export const fetchDataRejectDetail = async (token, type, id, dispatch, notify) => {
  await getRejectDetail(
    token,
    type,
    id,
    notify,
    dispatch,
    (res) => setDetalReject(isContent(res), dispatch),
    (err) => setDetalReject(isContentErr(err), dispatch)
  );
};

export const fetchDataRejectAdmin = async (token, page, limit, dispatch, notify) => {
  let arr = (list) =>
    list.map((datas) => {
      return { ...datas, isChecked: false };
    });
  const setAdminList = (res) => dispatch(rejectsActions.setRejectAdmin(arr(isContent(res))));
  await getRejectAdminList(token, page, limit, null, notify, dispatch, (response) =>
    setAdminList(response)
  );
};
export async function fetchDataRejectList(
  totalRow,
  countData,
  token,
  currentPage,
  rowLimit,
  sortDate,
  filter,
  dispatch,
  notify
) {
  if (totalRow === countData) {
    await getRejectInitiate(token, filter, notify, dispatch, (res) =>
      setInitiateReject(res, dispatch)
    );
    listData(token, currentPage, rowLimit, sortDate, filter, dispatch, notify);
  } else {
    await setDefaultPagination(dispatch);
    await dispatch(dashboardAction.setTotalRow(countData));
    await getRejectInitiate(token, filter, notify, dispatch, (res) =>
      setInitiateReject(res, dispatch)
    );
    listData(token, 1, 10, sortDate, filter, dispatch, notify);
  }
}
const listData = async (token, currentPage, rowLimit, sortDate, filter, dispatch, notify) => {
  await getRejectList(
    token,
    currentPage,
    rowLimit,
    sortDate,
    filter,
    notify,
    dispatch,
    (res) => setListReject(res, dispatch),
    (err) => setListRejectErr(err, dispatch)
  );
  dispatch(utilsActions.setLoading(false));
};

export const fetchRejectExport = async (token, order, filter, notify, dispatch, t) => {
  await getRejectExport(
    token,
    order,
    filter,
    notify,
    dispatch,
    (res) => {
      const now = moment();
      const formattedDate = now.format("DDMMYYYYHHmmss");
      const blob = new Blob([res.data], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `Reject - ${formattedDate}.csv`;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
      notify({ type: NotifyType.SUCCESS, message: t("COMMON:SUCCESS_EXPORT") });
    },
    () => notify({ type: NotifyType.ERROR, message: t("COMMON:FAILED_EXPORT") })
  );
};
