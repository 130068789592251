import React, {
  useEffect,
  useState,
} from 'react';

import { ListEmpty } from 'nexcomponent-ui';
import { useSelector } from 'react-redux';

import CheckBoxAll
  from '../../../../Components/Molecules/CheckBox/CheckBoxAll/CheckBoxAll';
import CheckBoxPartial
  from '../../../../Components/Molecules/CheckBox/CheckBoxPartial/CheckBoxPartial';
import SpinnerLoad
  from '../../../../Components/Molecules/Loader/SpinnerLoad/SpinnerLoad';
import { TABLE } from '../../../../Enum/IdSet';
import { mappingActions } from '../../../../Store/Reducers/MappingReducer';
import { utilsActions } from '../../../../Store/Reducers/UtilsReducer';
import { tableMappingByTypeStateGroup } from '../../../../Store/StateGroup';
import TableList from '../TableList';
import style from './MappingList.module.css';
import { dashboardAction } from '../../../../Store/Reducers/DashboardReducer';

const MappingList = props => {
  const [clearCounter, setClearCounter] = useState(true);
  const [
    initiate,
    checkedArray,
    action,
    totalRow,
    countData,
    list,
    currentPage,
    loading,
    rowLimit,
    sortDate,
  ] = useSelector(tableMappingByTypeStateGroup);

  const onClear = () => {
    props.dispatch(mappingActions.setMappingCount(0));
    props.dispatch(mappingActions.setMappingSum(0));
    props.dispatch(mappingActions.setMappingList([]));
    props.dispatch(mappingActions.setRejectList([]));
    
  };
  const [sort] = sortDate.split(" ");
  
  useEffect(() => {
    if (clearCounter) {
      setClearCounter(false);
      props.dispatch(mappingActions.setMappingCount(0));
      props.dispatch(mappingActions.setMappingSum(0));
    }

    if (sort === "updated_at") {
      props.dispatch(utilsActions.setSortDateAsc("created_at asc"));
    }
    
    if(loading) {
      props.listFunc(
        totalRow,
        countData,
        props.validToken(),
        currentPage,
        rowLimit,
        sortDate,
        props.dispatch,
        props.notify
      );
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading,clearCounter]);


  return (
    <>
      <TableList
        id={TABLE.MAPPING_LIST}
        sort={true}
        columns={props.column}
        data={props.load||loading ? null : list}
        limitation={initiate.valid_limit}
        totalRow={countData}
        selectable={true}
        footer={false}
        onClear={onClear}
        checkBoxAll={
          <CheckBoxAll
            className={style.CheckBox}
            data={props.load||loading ? null : list}
            dispatch={props.dispatch}
            actionType={action}
          />
        }
        checkBoxPartial={item => (
          <CheckBoxPartial
            className={style.CheckBox}
            mappingCode={props.mappingCode}
            item={item}
            data={checkedArray}
            value={item.id}
            t={props.t}
            dispatch={props.dispatch}
            actionType={action}
          />
        )}
        empty={
          loading||props.load ? (
            <ListEmpty value={<SpinnerLoad t={props.t} />} />
          ) : (
            <ListEmpty value={props.t("COMMON:LIST_EMPTY")} />
          )
        }
        t={props.t}
        dispatch={props.dispatch}
      />
    </>
  );
};

export default MappingList;
