import { Form, Button, Col, Div, Switch, Field, Row, Image } from "nexcomponent-ui";
import style from './NotificationCreateForm.module.css';
import { BUTTON, DROPDOWNLIST } from "../../../../Enum/IdSet";
import { TYPESET } from "../../../../Enum/Input";
import { useState } from "react";
import Select from '../../../../Components/UnstableComponent/Select/Select'
import { ROUTER_CONSTANT } from "../../../../Enum/RouterConstant/RouterConstant";
import { Icon } from "../../../../Assets/Icons";
import ModalSave from "../../Modal/ModalSave/ModalSave";
import { utilsActions } from "../../../../Store/Reducers/UtilsReducer";
import { updateNotification } from "../../../../Fetch/FetchData/NotificationData";

const NotificationEditForm = (props) => {
    const [data, setData] = useState(props.data)
    const [checkedStatus, setCheckedStatus] = useState(props.data.status === "A" ? true : false)
    const [listNotificationTo, setListNotificationTo] = useState(props.data.notification_to)

    const dataTypes = [
        {
            value: 'Person Profile'
        },
        {
            value: 'Company Profile'
        },
        {
            value: 'Division'
        },
        {
            value: 'Principal Product User Defined Category'
        },
        {
            value: 'Product Brand'
        },
        {
            value: 'Product Category'
        },
        {
            value: 'Product Key Account'
        },
        {
            value: 'Product'
        }
    ]

    const formHandler = e => {
        let {name, value} = e.target
        setData(prevsValue => {
            return {
                ...prevsValue,
                [name]: value,
            }
        })
    }

    const optionHandler = e => {
        let {value} = e.target
        setData(prevsValue => {
            return {
                ...prevsValue,
                "data_type": value,
            }
        })
    }

    const switchHandler = e => {
        setCheckedStatus(!checkedStatus)
    }

    const updateHandler = e => {
        var updatedNotificationTo = listNotificationTo.map(val => {
            if(val.includes("+62")) {
                return val
            } else {
                return countryCodes[0].value + val
            }
        })

        var status = checkedStatus === true ? "A" : "N";

        var updateData = {
            "id": data.id,
            "notification_code": data.notification_code,
            "notification_name": data.notification_name,
            "data_type": data.data_type,
            "status": status,
            "notification_to": updatedNotificationTo,
            "updated_at": data.updated_at
        }
        
        var result = updateNotification(updateData, props);

        props.dispatch(utilsActions.setResultModal(true));
        
        return result;
    }

    const countryCodes = [
        {
            value: '+62'
        }
    ]

    const handleInputListNotificationTo = (e, idx) => {
        const { value } = e.target
        const list = [...listNotificationTo]

        list[idx] = value

        setListNotificationTo(list)
    }

    const handleRemoveListNotificationTo = index => {
        const list = [...listNotificationTo]

        list.splice(index, 1)

        setListNotificationTo(list)
    }

    const handleAddListNotificationTo = () => {
        setListNotificationTo([...listNotificationTo, ""]);
    }

    const openModal = e => {
        e.preventDefault()

        props.dispatch(utilsActions.setModal(true))
    }

    return (
        <>
            <ModalSave
                t={props.t}
                mappingList={[]}
                dispatch={props.dispatch}
                navigate={props.navigate}
                approveFuc={() => updateHandler()}
                listNavigate={ROUTER_CONSTANT.NOTIFICATION.LIST}
                uniqueKey={props.uniqueKey}
                customSaveTitleCondition={true}
                customSaveTitle={props.t("COMMON:CHANGE_DATA_TITLE")}
                customLabelYesCondition={true}
                customLabelYes={props.t("COMMON:YES_SAVE")}
            />

            <Form onSubmit={openModal} className={style.FormCreateNotification}>
                <Div>
                    <Col>
                        <Div className={style.ContainerForm}>
                            <h5>
                                Notification Code<span className={style.MandatoryFlag}>*</span>
                            </h5>
                            <Field
                                name="notification_code"
                                placeholder="Type notification code"
                                onChange={formHandler}
                                defaultValue={data.notification_code}
                                className={style.FormInput}
                                disabled
                            />
                        </Div>
                        <Div className={style.ContainerForm}>
                            <h5>
                                Notification Name<span className={style.MandatoryFlag}>*</span>
                            </h5>
                            <Field
                                name="notification_name"
                                placeholder="Type notification name"
                                onChange={formHandler}
                                className={style.FormInput}
                                defaultValue={data.notification_name}
                            />
                        </Div>
                        <Div className={style.ContainerForm}>
                            <h5>
                                Data Type<span className={style.MandatoryFlag}>*</span>
                            </h5>
                            <Select
                                name="data_type"
                                id={DROPDOWNLIST.NOTIFICATION_DATA_TYPE}
                                placeholder="Select Data Type"
                                option={dataTypes}
                                defaultSelect={{ 
                                    value: data.data_type
                                }}
                                labelKey="value"
                                valueKey="value"
                                onChange={optionHandler}
                                className={style.Select}
                            />
                        </Div>
                        <Div className={style.ContainerForm}>
                            <h5>
                                Notification Status
                            </h5>
                            <Div className={style.Switch}>
                                <Switch onChange={switchHandler} checked={checkedStatus} />
                            </Div>
                        </Div>
                        <Div>
                            <Div>
                                <h5>
                                    Handphone Number List<span className={style.MandatoryFlag}>*</span>
                                </h5>
                            </Div>
                            {
                                listNotificationTo.map((val, idx) => {
                                    var parsedPhoneNumber = parseInt(val.replace("+62", ""));

                                    return (
                                        <Row className={style.ButtonRow}>
                                            <Div className={style.ListNotificationContainer}>
                                                <Select
                                                    name="country_code"
                                                    id={DROPDOWNLIST.NOTIFICATION_COUNTRY_CODE}
                                                    placeholder="Select Country Code"
                                                    option={countryCodes}
                                                    defaultSelect={countryCodes[0]}
                                                    labelKey="value"
                                                    valueKey="value"
                                                    className={style.ListNotificationSelect}
                                                />
                                                <Field
                                                    classInput={style.ListNotificationInput}
                                                    name="notification_to"
                                                    placeholder="Phone Number"
                                                    type="number"
                                                    value={parsedPhoneNumber}
                                                    onChange={e => handleInputListNotificationTo(e, idx)}
                                                />
                                            </Div>
                                            {
                                                listNotificationTo.length !== 1 &&
                                                <Button className={style.RemoveButton} type={TYPESET.BTN} onClick={() => handleRemoveListNotificationTo(idx)} icon={<Image src={Icon.TrashRed} />} />
                                            }
                                            {
                                                listNotificationTo.length - 1 === idx &&
                                                <Button className={style.AddButton} type={TYPESET.BTN} onClick={handleAddListNotificationTo} icon={<Image src={Icon.Add} />} />
                                            }
                                        </Row>
                                    )
                                })
                            }
                        </Div>
                    </Col>
                    <Div className={style.ButtonContainer}>
                        <Button id={BUTTON.CANCEL} label={props.t("COMMON:CANCEL")} type={TYPESET.BTN} typeStyle="secondary" onClick={() => window.location.href = ROUTER_CONSTANT.NOTIFICATION.LIST}/>
                        <Button
                            id={BUTTON.EDIT}
                            type={TYPESET.SUBMIT}
                            label={props.t("COMMON:SAVE")}
                            className={style.SaveButton}
                        />
                    </Div>
                </Div>
            </Form>
        </>
    )
}

export default NotificationEditForm;