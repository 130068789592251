import React from 'react';

import { Div } from 'nexcomponent-ui';

import { mainBreadCrumb } from '../../../../Enum/Breadcrumb/CommonBreadcrumb';
import {
  productCategoryColumn,
} from '../../../../Enum/Column/ProductCategoryColumn';
import { PAGE } from '../../../../Enum/IdSet';
import { MAPPING_CODE } from '../../../../Enum/MappingCode/MappingCode';
import {
  ROUTER_CONSTANT,
} from '../../../../Enum/RouterConstant/RouterConstant';
import {
  fetchDataProductCategoryList,
  fetchProductCategoryMappingForm,
} from '../../../../Fetch/FetchData/ProductCategoryData';
import HeaderPageList
  from '../../../Organisms/Header/HeaderPageList/HeaderPageList';
import MappingList from '../../../Organisms/TableList/MappingList/MappingList';

const ProductCategoryPage = props => (
  <Div id={PAGE.LIST}>
    <HeaderPageList
      notify={props.notify}
      t={props.t}
      dispatch={props.dispatch}
      navigate={props.navigate}
      listBreadcrumb={mainBreadCrumb(props.t)}
      toTitlePage={ROUTER_CONSTANT.INDEX}
      title={props.t("PRODUCT_CATEGORY:TITLE")}
      mappingCode={MAPPING_CODE.PRODUCT_CATEGORY}
      defaultBreadcrumb={props.t("PRODUCT_CATEGORY:TITLE")}
      rejectNavigate={ROUTER_CONSTANT.PRODUCT_CATEGORY.REJECT_REASON}
      uniqueKey="product_category_id"
      mappingNavigate={(NewId, ExistId, List) =>
        fetchProductCategoryMappingForm(
          props.validToken(),
          props.dispatch,
          "product_category_id",
          NewId,
          ExistId,
          List,
          props.notify,
          () => props.navigate(ROUTER_CONSTANT.PRODUCT_CATEGORY.MAPPING)
        )
      }
    />
    <MappingList
      t={props.t}
      column={productCategoryColumn(props.t)}
      mappingCode={MAPPING_CODE.PRODUCT_CATEGORY}
      validToken={props.validToken}
      dispatch={props.dispatch}
      navigate={props.navigate}
      notify={props.notify}
      listFunc={(totalRow, countData, token, currentPage, rowLimit, sortDate, dispatch, notify) =>
        fetchDataProductCategoryList(
          totalRow,
          countData,
          token,
          currentPage,
          rowLimit,
          sortDate,
          dispatch,
          notify
        )
      }
    />
  </Div>
);

export default ProductCategoryPage;
