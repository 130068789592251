import _ from 'lodash';

import { regexNumber } from '../Validation/Regexer';

export const value = ( array, key, item ) => {
  const value = _.find( array, [ key , key ] );
  return value[ item ];
};
export const resValue = (type, value) => {
  if (type === "number") {
    return Number(value);
  }
  return value;
};
export const ressValue=(value)=>{
  if(!regexNumber.test(value)|| value===""){
    return value
  }else{
    return Number(value)
  }
}