import React, {
  useEffect,
  useState,
} from 'react';

import {
  DataTable,
  TableLimit,
} from 'nexcomponent-ui';
import { useSelector } from 'react-redux';

import { mappingActions } from '../../../Store/Reducers/MappingReducer';
import { utilsActions } from '../../../Store/Reducers/UtilsReducer';
import { paginationSate } from '../../../Store/StateGroup/UtilsStateGroup';
import {
  firstPage,
  lastIndex,
  lastPage,
  nextPage,
  previousPage,
  selectRowlimit,
  setPageArray,
} from '../../../Utils/Other/Pagination';
import CustomPagination from './CustomPagination/CustomPagination';
import { notificationActions } from '../../../Store/Reducers/NotificationReducer';

const TableList = props => {
  const [rowLimit, selectLimit, currentPage, startPage, endPage, pageNumber] =
    useSelector(paginationSate);
  const sortDateAsc = useSelector(state => state.utils.sortDateAsc);
  const loading = useSelector(state => state.utils.loading);
  const sort = {asc: props.asc, desc: props.desc};
  const [sortQuery, setSortQuery] = useState("");

  const clearList = () => {
    props.dispatch(utilsActions.setLoading(true));
    props.onClear();
  };
  
  const previous = () => {
    clearList();
    previousPage(startPage, endPage, currentPage, lastIndex(props.pageNumber), props.dispatch);
  };

  const first = async() => {
    if (currentPage > 1) {
      await firstPage(props.dispatch);
      clearList();
    }
  };

  const last = async() => {
    if (currentPage < lastIndex(pageNumber)) { 
      await lastPage(lastIndex(pageNumber), props.dispatch);
      clearList();
    }
  };

  const next = () => {
    props.dispatch(utilsActions.setLoading(true));

    clearList();
    nextPage(startPage, endPage, lastIndex(pageNumber), currentPage, props.dispatch);
  };

  const paginate = page => {
    props.dispatch(utilsActions.setLoading(true));
    clearList();
    props.dispatch(utilsActions.setCurrentPage(page));
  };

  const selectOption = async evt => {
    await props.dispatch(utilsActions.setCurrentPage(1));
    await props.dispatch(utilsActions.setStarPage(0));
    await props.dispatch(utilsActions.setEndPage(4));
    await props.dispatch(utilsActions.setRowLimit(evt.target.value));
    clearList();
  };

  useEffect(() => {
    if (loading) {
      setPageArray(props.totalRow, rowLimit, props.dispatch);
      selectRowlimit(selectLimit, props.dispatch);
    }
  },  [loading, props.totalRow, rowLimit, selectLimit, props.dispatch]);;

  const handleSort = async () => {
    await props.dispatch(utilsActions.setLoading(true));
    await props.dispatch(mappingActions.setMappingList([]));
    await props.dispatch(mappingActions.setRejectList([]));
    props.dispatch(mappingActions.setMappingCount(0));
    props.dispatch(mappingActions.setMappingSum(0));

    if (sortDateAsc === sort.asc) {
      props.dispatch(utilsActions.setSortDateAsc(sort.desc));
    } else {
      props.dispatch(utilsActions.setSortDateAsc(sort.asc));
    }
  };

  const tempColumn = props.columns.map(datas => {
    return {...datas, asc: true, desc: false};
  });

  const [columns, setColumns] = useState(props.columns.length === 0 ? [] : tempColumn);

  const sortClick = async item => {
    if (props.sortingNotification === true) {
      let tempColumnClick = await columns.map(datas =>
        {
          if (item.asc === true) {
            setSortQuery(item.key + " desc")
          } else {
            setSortQuery(item.key + " asc")
          }

          return datas.key === item.key && item.asc === true
          ? {...datas, asc: false, desc: true}
          : {...datas, asc: true, desc: false}
        }
      );
  
      setColumns(tempColumnClick);

      handleSortNotification();
    } else {
      clearList();
      let tempColumnClick = await columns.map(datas =>
        {
          return datas.key === item.key && item.asc === true
          ? {...datas, asc: false, desc: true}
          : {...datas, asc: true, desc: false}
        }
      );
  
      setColumns(tempColumnClick);

      handleSort();
    }
  };

  const handleSortNotification = async () => {
    await props.dispatch(utilsActions.setLoading(true));
    await props.dispatch(notificationActions.setNotificationList([]));
    props.dispatch(utilsActions.setSortDateAsc(sortQuery));
  };

  return (
    <>
      <DataTable
        className={props.className}
        width="100%"
        customHeader={props.customHeader}
        id={props.id}
        columns={props.sort ? columns : props.columns}
        data={props.data}
        totalRow={props.totalRow}
        sortClick={item => sortClick(item)}
        empty={props.empty}
        selectable={props.selectable}
        checkBoxAll={props.checkBoxAll}
        checkBoxPartial={props.checkBoxPartial}
        rowLink={props.rowLink}
        footer={props.footer}
        height={props.height}
        overflowX={props.overflowX}
        overflowY={props.overflowY}
        fixFirstCol={props.fixFirstCol}
        fixHeader={props.fixHeader}
        fixHeaderBg={props.fixHeaderBg}
        rowClick={item => props.rowClick(item)}
        tableLimit={
          props.actionType === "reject" ? (
            ""
          ) : (
            <TableLimit
              id="ddllimit"
              defaultOption={rowLimit}
              option={props.limitation}
              selectClick={evt => selectOption(evt)}
            />
          )
        }
        pagination={
          props.customFooter !== undefined ? (
            ""
          ) : (
            <></>
            // <PaginationTable
            //   currentPage={currentPage}
            //   nextClick={next}
            //   startPage={startPage}
            //   endPage={endPage}
            //   rowLimit={rowLimit}
            //   previousClick={previous}
            //   firstClick={first}
            //   lastClick={last}
            //   pageClick={page => paginate(page)}
            //   totalRow={props.totalRow}
            //   pageNumber={pageNumber}
            // />
          )
        }
        customFooter={
          props.customFooter !== undefined ? (
            props.customFooter
          ) : (
            <CustomPagination
              id={"tbl"}
              length={props.columns.length}
              totalRow={props.totalRow}
              selectable={props.selectable}
              footerLabel={props.t("COMMON:VIEW_PER_PAGE")}
              limitation={props.limitation}
              defaultOption={rowLimit}
              selectClick={evt => selectOption(evt)}
              currentPage={currentPage}
              nextClick={next}
              startPage={startPage}
              endPage={endPage}
              rowLimit={rowLimit}
              previousClick={previous}
              firstClick={first}
              lastClick={last}
              pageClick={page => paginate(page)}
              pageNumber={pageNumber}
            />
          )
        }
      />
    </>
  );
};

export default TableList;
TableList.defaultProps = {
  rowClick: () => {},
  onClear: () => {},
  asc: "created_at asc",
  desc: "created_at desc",
};
