import React from 'react';

import {
  Div,
  Heading,
  Span,
} from 'nexcomponent-ui';
import { useSelector } from 'react-redux';

import {
  BOX,
  PAGE,
  TEXT_SINGLE,
} from '../../../Enum/IdSet';
import CardDashboard from '../../Organisms/Card/CardDashboard/CardDashboard';
import AllMappingList
  from '../../Organisms/TableList/AllMappingList/AllMappingList';
import style from './MainPage.module.css';

const MainPage = props => {
  const content = useSelector(state => state.authentication.content);

  return (
    <Div id={PAGE.HOME} className={style.Main}>
      <Heading
        id={TEXT_SINGLE.TITLE_PAGE}
        size={6}
        value={
          (content.first_name !== null && content.last_name !== null) ||
          (content.first_name !== null && content.last_name !== null)
            ? props.t("COMMON:Hello") + ", " + content.first_name + " " + content.last_name
            : props.t("COMMON:Hello") + props.t("COMMON:USER")
        }
      />
      <Span
        id={TEXT_SINGLE.SUB_TITLEPAGE}
        className={style.SubTitle}
        value={props.t("COMMON:WELCOME_WEBSITE")}
      />
      <CardDashboard
        id={BOX.CARD_MAPPING}
        t={props.t}
        validToken={props.validToken}
        dispatch={props.dispatch}
        navigate={props.navigate}
        notify={props.notify}
      />
      <AllMappingList
        t={props.t}
        validToken={props.validToken}
        dispatch={props.dispatch}
        navigate={props.navigate}
        notify={props.notify}
      />
    </Div>
  );
};

export default MainPage;
