import { COMMON } from "../../Enum/EndPoint/CommonEndpoint";

export const PRODUCT_BRAND_URL = Object.freeze({
  COUNT: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRODUCT_BRAND + COMMON.COUNT,
  LIST: function list(page, limit, order) {
    return COMMON.MAPPING + COMMON.MAPPING_TYPE.PRODUCT_BRAND + COMMON.LIST(page, limit, order);
  },
  REJECT: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRODUCT_BRAND + COMMON.REJECT,
  DETAIL: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRODUCT_BRAND + COMMON.INDEX,
  DETAIL_EXISTING: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRODUCT_BRAND + COMMON.EXISTING,
  SEARCH: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRODUCT_BRAND + COMMON.SEARCH,
  COUNT_SEARCH: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRODUCT_BRAND + COMMON.SEARCH + COMMON.COUNT,
  MAPPING_APPROVE: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRODUCT_BRAND + COMMON.APPROVE,

  PRINCIPAL: {
    COUNT: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRINCIPAL_PRODUCT_BRAND + COMMON.COUNT,
    LIST: function list(page, limit, order) {
      return (
        COMMON.MAPPING +
        COMMON.MAPPING_TYPE.PRINCIPAL_PRODUCT_BRAND +
        COMMON.LIST(page, limit, order)
      );
    },
    REJECT: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRINCIPAL_PRODUCT_BRAND + COMMON.REJECT,
    DETAIL: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRINCIPAL_PRODUCT_BRAND + COMMON.INDEX,
    DETAIL_EXISTING: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRINCIPAL_PRODUCT_BRAND + COMMON.EXISTING,
    SEARCH: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRINCIPAL_PRODUCT_BRAND + COMMON.SEARCH,
    COUNT_SEARCH:
      COMMON.MAPPING + COMMON.MAPPING_TYPE.PRINCIPAL_PRODUCT_BRAND + COMMON.SEARCH + COMMON.COUNT,
    MAPPING_APPROVE: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRINCIPAL_PRODUCT_BRAND + COMMON.APPROVE,
  },
});
