import React, { useEffect } from 'react';

import { CheckBox } from 'nexcomponent-ui';
import { useSelector } from 'react-redux';

import { CHECKBOX } from '../../../../Enum/IdSet';
import {
  checkedAllClick,
  checkedAllStatus,
} from '../../../../Store/Action/CheckBoxAction';
import { utilsActions } from '../../../../Store/Reducers/UtilsReducer';

function CheckBoxAll(props) {
  const checkedArray = useSelector(state => state.utils.checkedArray);
  const {data, dispatch} = props;
  useEffect(() => {
    if (data===null) {
      dispatch(utilsActions.setCheckedArray([]));
    } else {
      let tempUser = data.map(datas => {
        return {...datas, isChecked: false};
      });
      dispatch(utilsActions.setCheckedArray(tempUser));
    }
  }, [data, dispatch]);

  const handleCheckedAll = e => {
    checkedAllClick(e, props.data, props.dispatch, props.actionType);
  };
  const checkedAll = checkedArray => checkedAllStatus(checkedArray);
  
  return (
    <CheckBox
      id={CHECKBOX.All}
      size="md"
      className={props.className}
      name="checkboxAll"
      onChange={handleCheckedAll}
      checked={checkedAll(checkedArray)}
    />
  );
}

export default CheckBoxAll;
