import React, { useCallback, useMemo } from 'react';

import { Div } from 'nexcomponent-ui';

import {
  productGroupPrincipalSearchBreadCrumb,
  productGroupSearchBreadCrumb,
} from '../../../../Enum/Breadcrumb/ProductGroupBreadcrumb';
import {
  searchProductGroupColumn,
} from '../../../../Enum/Column/SearchProductGroupColumn';
import { MAPPING_CODE } from '../../../../Enum/MappingCode/MappingCode';
import {
  ROUTER_CONSTANT,
} from '../../../../Enum/RouterConstant/RouterConstant';
import {
  fetchDataProductGroupSearchList,
} from '../../../../Fetch/FetchData/ProductGroupData';
import FieldProductGroupSearchForm
  from '../../../Organisms/Form/SearchForm/FieldProductGroupSearchForm/FieldProductGroupSearchForm';
import SearchForm
  from '../../../Organisms/Form/SearchForm/SearchForm/SearchForm';
import HeaderPage from '../../../Organisms/Header/HeaderPage/HeaderPage';
import useQueryRoute from '../../../../Hooks/useQueryRoute';
import { fetchDataProductGroupPrincipalSearchList } from '../../../../Fetch/FetchData/ProductGroupPrincipalData';
import FieldProductGroupPrincipalSearchForm from '../../../Organisms/Form/SearchForm/FieldProductGroupPrincipalSearchForm/FieldProductGroupPrincipalSearchForm';
import { searchProductGroupPrincipalColumn } from '../../../../Enum/Column/SearchProductGroupPrincipalColumn';

function ProductGroupSearchPage(props) {
  const { principal } = useQueryRoute();

  const searchFunc=useCallback(
    (currentPage, rowLimit,filter, column,dispatch) =>{
      if(principal){
        return fetchDataProductGroupPrincipalSearchList(
          props.validToken(),
          currentPage,
          rowLimit,
          filter,
          column,
          dispatch,
          props.notify
        )}else{
          return fetchDataProductGroupSearchList(
            props.validToken(),
            currentPage,
            rowLimit,
            filter,
            column,
            dispatch,
            props.notify
            )
          }
        }
        ,[principal, props]
  )

  const field =useMemo(()=>{
    if(principal)
      return <FieldProductGroupPrincipalSearchForm t={props.t} dispatch={props.dispatch} />
    else
    return <FieldProductGroupSearchForm t={props.t} dispatch={props.dispatch} />
  },[principal, props])


  const columnDefault =useMemo(()=>{
    if(principal){
      return searchProductGroupPrincipalColumn(props.t)
    }else{
      return searchProductGroupColumn(props.t)
    }
  },[principal, props.t])

  const listBreadcrumb=useMemo(()=>{
    if(principal){
      return productGroupPrincipalSearchBreadCrumb(props.t)
    }else{
      return productGroupSearchBreadCrumb(props.t)
    }
  },[principal, props.t])

  return (
    <Div>
      <HeaderPage
        title={props.t("PRODUCT_GROUP:SEARCH.TITLE")}
        listBreadcrumb={listBreadcrumb}
        defaultBreadcrumb={props.t("PRODUCT_GROUP:SEARCH.TITLE")}
        toTitlePage={ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.MAPPING}
      />
      <SearchForm
        title="PRODUCT_GROUP:SEARCH.TITLE"
        field={field}
        t={props.t}
        dispatch={props.dispatch}
        mappingCode={MAPPING_CODE.PRODUCT_GROUP}
        navigate={props.navigate}
        advance={false}
        columnDefault={columnDefault}
        backNavigate={`${ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.PRINCIPAL.MAPPING}`}
        searchFunc={searchFunc}
      />
    </Div>
  );
}

export default ProductGroupSearchPage;
