import { combineReducers } from 'redux';

import AuthenticationReducer from './AuthenticationReducer';
import BroadcastReducer from './BroadcastReducer';
import DashboardReducer from './DashboardReducer';
import MappingReducer from './MappingReducer';
import RejectReducer from './RejectReducer';
import SearchMappingReducer from './SearchMappingReducer';
import UtilsReducer from './UtilsReducer';
import NotificationReducer from './NotificationReducer';

const reducers = combineReducers({
  authentication: AuthenticationReducer,
  broadcast:BroadcastReducer,
  reject:RejectReducer,
  dashboard: DashboardReducer,
  mapping:MappingReducer,
  utils: UtilsReducer,
  searchMapping: SearchMappingReducer,
  notification: NotificationReducer
});
const rootReducer = (state, action) => {
  if(action.type === "DESTROY_SESSION")
  state = undefined;
  return reducers(state, action)
}
export default rootReducer;
