import { useState } from "react";
import { useSelector } from 'react-redux';

import { Form, Button, Col, Div, Switch, Field, Row, Image, Option } from "nexcomponent-ui";
import style from './NotificationCreateForm.module.css';
import { BUTTON, DROPDOWNLIST } from "../../../../Enum/IdSet";
import { TYPESET } from "../../../../Enum/Input";
import Select from '../../../../Components/UnstableComponent/Select/Select'
import { createNotification } from "../../../../Fetch/FetchData/NotificationData";
import { ROUTER_CONSTANT } from "../../../../Enum/RouterConstant/RouterConstant";
import { Icon } from "../../../../Assets/Icons"
import { utilsActions } from "../../../../Store/Reducers/UtilsReducer";
import ModalSave from '../../Modal/ModalSave/ModalSave'

const NotificationCreateForm = (props) => {
    const [data, setData] = useState({})
    const [checkedStatus, setCheckedStatus] = useState(false)
    const [listNotificationTo, setListNotificationTo] = useState([""])

    const dataTypes = [
        {
            value: 'Person Profile'
        },
        {
            value: 'Company Profile'
        },
        {
            value: 'Division'
        },
        {
            value: 'Principal Product User Defined Category'
        },
        {
            value: 'Product Brand'
        },
        {
            value: 'Product Category'
        },
        {
            value: 'Product Key Account'
        },
        {
            value: 'Product'
        }
    ]

    const countryCodes = [
        {
            value: '+62'
        }
    ]

    const formHandler = e => {
        let {name, value} = e.target
        setData(prevsValue => {
            return {
                ...prevsValue,
                [name]: value,
            }
        })
    }

    const optionHandler = e => {
        let {value} = e.target
        setData(prevsValue => {
            return {
                ...prevsValue,
                "data_type": value,
            }
        })
    }

    const switchHandler = e => {
        setCheckedStatus(!checkedStatus)
    }

    const createHandler = () => {
        data.notification_to = listNotificationTo.map(val => {
            return countryCodes[0].value + val
        })
        data.status = checkedStatus === true ? "A" : "N"
        data.data_type = data.data_type === undefined ? "Person Profile" : data.data_type

        var result = createNotification(data, props);

        props.dispatch(utilsActions.setResultModal(true));
        
        return result;
    }

    const handleInputListNotificationTo = (e, idx) => {
        const { value } = e.target
        const list = [...listNotificationTo]

        list[idx] = value

        setListNotificationTo(list)
    }

    const handleRemoveListNotificationTo = index => {
        const list = [...listNotificationTo]

        list.splice(index, 1)

        setListNotificationTo(list)
    }

    const handleAddListNotificationTo = () => {
        setListNotificationTo([...listNotificationTo, ""]);
    }

    const openModal = e => {
        e.preventDefault()

        props.dispatch(utilsActions.setModal(true))
    }

    return (
        <>
            <ModalSave
                t={props.t}
                mappingList={[]}
                dispatch={props.dispatch}
                navigate={props.navigate}
                approveFuc={() => createHandler()}
                listNavigate={ROUTER_CONSTANT.NOTIFICATION.LIST}
                uniqueKey={props.uniqueKey}
                customSaveMessageCondition={true}
                customSaveMessage={props.t("COMMON:SAVE_NOTIFICATION_MESSAGE")}
                customSaveTitleCondition={true}
                customSaveTitle={props.t("COMMON:ADD_DATA_TITLE")}
                customLabelYesCondition={true}
                customLabelYes={props.t("COMMON:YES_SAVE")}
            />
            
            <Form onSubmit={openModal} className={style.FormCreateNotification}>
                <Div>
                    <Col>
                        <Div className={style.ContainerForm}>
                            <h5>
                                Notification Code<span className={style.MandatoryFlag}>*</span>
                            </h5>
                            <Field
                                name="notification_code"
                                placeholder="Type notification code"
                                onChange={formHandler}
                                classInput={style.FormInput}
                            />
                        </Div>
                        <Div className={style.ContainerForm}>
                            <h5>
                                Notification Name<span className={style.MandatoryFlag}>*</span>
                            </h5>
                            <Field
                                name="notification_name"
                                placeholder="Type notification name"
                                onChange={formHandler}
                                classInput={style.FormInput}
                            />
                        </Div>
                        <Div className={style.ContainerForm}>
                            <h5>
                                Data Type<span className={style.MandatoryFlag}>*</span>
                            </h5>
                            <Select
                                name="data_type"
                                id={DROPDOWNLIST.NOTIFICATION_DATA_TYPE}
                                placeholder="Select Data Type"
                                option={dataTypes}
                                defaultSelect={dataTypes[0]}
                                labelKey="value"
                                valueKey="value"
                                onChange={optionHandler}
                                className={style.Select}
                            />
                        </Div>
                        <Div className={style.ContainerForm}>
                            <h5>
                                Notification Status
                            </h5>
                            <Div className={style.Switch}>
                                <Switch onChange={switchHandler} checked={checkedStatus} />
                            </Div>
                        </Div>
                        <Div>
                            <Div>
                                <h5>
                                    Handphone Number List<span className={style.MandatoryFlag}>*</span>
                                </h5>
                            </Div>
                            <Div className={style.PhoneListColumn}>
                                <Div className={style.PhoneListColumnChild}>
                                    {
                                        listNotificationTo.filter((item, indexFilter) => indexFilter < 5).map((val, idx) => {
                                        return (
                                            <Row className={style.ButtonRow}>
                                                <Div className={style.ListNotificationContainer}>
                                                        <Select
                                                            name="country_code"
                                                            id={DROPDOWNLIST.NOTIFICATION_COUNTRY_CODE}
                                                            placeholder="Select Country Code"
                                                            option={countryCodes}
                                                            defaultSelect={countryCodes[0]}
                                                            labelKey="value"
                                                            valueKey="value"
                                                            className={style.ListNotificationSelect}
                                                        />
                                                        <Field
                                                            classInput={style.ListNotificationInput}
                                                            name="notification_to"
                                                            placeholder="Phone Number"
                                                            type="number"
                                                            value={val}
                                                            onChange={e => handleInputListNotificationTo(e, idx)}
                                                        />
                                                    </Div>
                                                    {
                                                        listNotificationTo.length !== 1 &&
                                                        <Button className={style.RemoveButton} type={TYPESET.BTN} onClick={() => handleRemoveListNotificationTo(idx)} icon={<Image src={Icon.TrashRed} />} />
                                                    }
                                                    {
                                                        listNotificationTo.length - 1 === idx &&
                                                        <Button className={style.AddButton} type={TYPESET.BTN} onClick={handleAddListNotificationTo} icon={<Image src={Icon.Add} />} />
                                                    }
                                            </Row>
                                        )
                                        })
                                    }
                                </Div>
                                <Div className={style.PhoneListColumnChildRight}>
                                    {
                                        listNotificationTo.filter((item, indexFilter) => indexFilter > 4).map((val, idx) => {
                                        return (
                                            <Row className={style.ButtonRow}>
                                                <Div className={style.ListNotificationContainer}>
                                                        <Select
                                                            name="country_code"
                                                            id={DROPDOWNLIST.NOTIFICATION_COUNTRY_CODE}
                                                            placeholder="Select Country Code"
                                                            option={countryCodes}
                                                            defaultSelect={countryCodes[0]}
                                                            labelKey="value"
                                                            valueKey="value"
                                                            className={style.ListNotificationSelect}
                                                        />
                                                        <Field
                                                            classInput={style.ListNotificationInput}
                                                            name="notification_to"
                                                            placeholder="Phone Number"
                                                            type="number"
                                                            value={val}
                                                            onChange={e => handleInputListNotificationTo(e, idx + 5)}
                                                        />
                                                    </Div>
                                                    {
                                                        listNotificationTo.length !== 1 &&
                                                        <Button className={style.RemoveButton} type={TYPESET.BTN} onClick={() => handleRemoveListNotificationTo(idx + 5)} icon={<Image src={Icon.TrashRed} />} />
                                                    }
                                                    {
                                                        listNotificationTo.length - 5 === idx + 1 && listNotificationTo.length < 10 &&
                                                        <Button className={style.AddButton} type={TYPESET.BTN} onClick={handleAddListNotificationTo} icon={<Image src={Icon.Add} />} />
                                                    }
                                            </Row>
                                        )
                                        })
                                    }
                                </Div>
                            </Div>
                        </Div>
                    </Col>
                    <Div className={style.ButtonContainer}>
                        <Button className={style.CancelButton} id={BUTTON.CANCEL} type={TYPESET.BTN} label={props.t("COMMON:CANCEL")} typeStyle="secondary" onClick={() => window.location.href = ROUTER_CONSTANT.NOTIFICATION.LIST}/>
                        <Button
                            id={BUTTON.SAVE}
                            type={TYPESET.SUBMIT}
                            label={props.t("COMMON:SAVE")}
                            className={style.SaveButton}
                        />
                    </Div>
                </Div>
            </Form>
        </>
    )
}

export default NotificationCreateForm;