import { defaultLimitSearch } from '../../../Enum/Limit/SearchAdvance';
import { resValue } from '../../Other/FindValues';
import { isContent } from '../../Response/Response';

export const DDSOnClick = (fetch, setLimitation, filter, validToken, dispatch, notify) => {
  fetch(validToken(), [], dispatch, {...defaultLimitSearch, ...filter}, notify);
  setLimitation(prevsValue => {
    return {
      ...prevsValue,
      page: 1,
      limit: 10,
    };
  });
};
export const DDSOnScroll = (
  fetch,
  prevList,
  setLimitation,
  limitation,
  filter,
  validToken,
  dispatch,
  notify
) => {
  setLimitation(prevsValue => {
    return {
      ...prevsValue,
      page: limitation.page + 1,
    };
  });
  fetch(
    validToken(),
    prevList,
    dispatch,
    {...limitation, page: limitation.page + 1, ...filter},
    notify
  );
};
export const recreateList = (res, idKey, nameKey, codeKey) => {
  let data = [];
  let content = isContent(res);
  for (var i = 0; i < content.length; i++) {
    data.push({
      parent_id: Number(content[i][idKey]),
      parent_name: content[i][nameKey],
      parent_code: content[i][codeKey],
    });
  }
  return data;
};
export const recreateListCP = (res, idKey, nameKey, codeKey) => {
  let data = [];
  let content = isContent(res);
  for (var i = 0; i < content.length; i++) {
    data.push({ 
      parent_company_id: content[i][idKey],
      parent_company_name: content[i][nameKey],
      parent_company_npwp: content[i][codeKey],
    });
  }
  return data;
};
export const recreateListPG = (res, idKey, nameKey, codeKey) => {
  let data = [];
  let content = isContent(res);
  for (var i = 0; i < content.length; i++) {
    data.push({
      product_group_hierarchy_id: content[i][idKey],
      product_group_hierarchy_name: content[i][nameKey],
      product_group_hierarchy_code: content[i][codeKey],
    });
  }
  return data;
};
export const DDSonChange = (
  e,
  type,
  validToken,
  fetch,
  setLimitation,
  setFilter,
  setOption,
  filter,
  notify,
  dispatch
) => {
  const {name, value} = e.target;

  setLimitation(prevsValue => {
    return {
      ...prevsValue,
      [name]: resValue(type, value),
      page: 1,
    };
  });
  setFilter(prevsValue => {
    return {
      ...prevsValue,
      [name]: resValue(type, value),
    };
  });
  fetch(
    validToken(),
    {page: 1, limit: 10, ...filter, [name]: resValue(type, value)},
    notify,
    dispatch,
    reponse => {
      if (isContent(reponse) !== null) {
        dispatch(setOption(isContent(reponse)));
      } else {
        dispatch(setOption([]));
      }
    }
  );
};

export const DDSonChangeProductBrandPrincipal = (
  e,
  type,
  validToken,
  fetch,
  setLimitation,
  setFilter,
  setOption,
  filter,
  notify,
  dispatch
) => {
  const {name, value} = e.target;

  const field =name==='principal_product_brand_id' ? 'principal_product_brand_id' : name.replace("principal_product_brand_","")

  setLimitation(prevsValue => {
    return {
      ...prevsValue,
      [field]: resValue(type, value),
      page: 1,
    };
  });
  setFilter(prevsValue => {
    return {
      ...prevsValue,
      [field]: resValue(type, value),
    };
  });
  fetch(
    validToken(),
    {page: 1, limit: 10, ...filter, [field]: resValue(type, value)},
    notify,
    dispatch,
    reponse => {
      if (isContent(reponse) !== null) {
        const result=isContent(reponse).length>0?isContent(reponse).map(data=>{
          return {principal_product_brand_id: data.principal_product_brand_id,
            principal_product_brand_code: data.code,
            principal_product_brand_name: data.name}
        }):[]
        
        dispatch(setOption(result));
      } else {
        dispatch(setOption([]));
      }
    }
  );
};

export const DDSonChangeProductGroupPrincipal = (
  e,
  type,
  validToken,
  fetch,
  setLimitation,
  setFilter,
  setOption,
  filter,
  notify,
  dispatch
) => {
  const {name, value} = e.target;

  const field =name==='principal_product_group_id' ? 'principal_product_group_id' : name.replace("principal_product_group_","")

  setLimitation(prevsValue => {
    return {
      ...prevsValue,
      [field]: resValue(type, value),
      page: 1,
    };
  });
  setFilter(prevsValue => {
    return {
      ...prevsValue,
      [field]: resValue(type, value),
    };
  });
  fetch(
    validToken(),
    {page: 1, limit: 10, ...filter, [field]: resValue(type, value)},
    notify,
    dispatch,
    reponse => {
      if (isContent(reponse) !== null) {
        const result=isContent(reponse).length>0?isContent(reponse).map(data=>{
          return {principal_product_group_id: data.principal_product_group_id,
            principal_product_group_code: data.code,
            principal_product_group_name: data.name}
        }):[]
        
        dispatch(setOption(result));
      } else {
        dispatch(setOption([]));
      }
    }
  );
};



export const DDSonChangeTypeParent = (
  e,
  type,
  validToken,
  fetch,
  setLimitation,
  setFilter,
  setOption,
  filter,
  notify,
  dispatch,
  idKey,
  nameKey,
  codeKey
) => {
  const {name, value} = e.target;

  setLimitation(prevsValue => {
    return {
      ...prevsValue,
      [defineName(name, idKey) ]: resValue(type, value),
      page: 1,
    };
  });
  setFilter(prevsValue => {
    return {
      ...prevsValue,
      [defineName(name, idKey)]: resValue(type, value),
    };
  });
  fetch(
    validToken(),
    {page: 1, limit: 10, ...filter, [defineName(name, idKey)]: resValue(type, value)},
    notify,
    dispatch,
    reponse => {
      if (isContent(reponse) !== null) {
        dispatch(setOption(recreateList(reponse, idKey, nameKey, codeKey)));
      } else {
        dispatch(setOption([]));
      }
    }
  );
};

export const DDSonChangeTypeProductGroupPrincipalParent = (
  e,
  type,
  validToken,
  fetch,
  setLimitation,
  setFilter,
  setOption,
  filter,
  notify,
  dispatch,
  idKey,
  nameKey,
  codeKey
) => {
  const {name, value} = e.target;
  const convertName=name==="parent_id"?"principal_product_group_id":name.replace("parent_","")
  setLimitation(prevsValue => {
    return {
      ...prevsValue,
      [convertName]: resValue(type, value),
      page: 1,
    };
  });
  setFilter(prevsValue => {
    return {
      ...prevsValue,
      [convertName]: resValue(type, value),
    };
  });
  fetch(
    validToken(),
    {page: 1, limit: 10, ...filter, [convertName]: resValue(type, value)},
    notify,
    dispatch,
    reponse => {
      if (isContent(reponse) !== null) {
        dispatch(setOption(recreateList(reponse, "principal_product_group_id", "name", "code")));
      } else {
        dispatch(setOption([]));
      }
    }
  );
};

const defineName = (nameKey, idKey) => {
  let name = "";
  if (nameKey === "parent_id" && idKey === "product_key_account_id") {
    name = "product_key_account_id";
  }
  if (nameKey === "parent_code" && idKey === "product_key_account_id") {
    name = "product_key_account_code";
  }
  if (nameKey === "parent_name" && idKey === "product_key_account_id") {
    name = "product_key_account_name";
  }
  if (nameKey === "parent_id" && idKey === "product_group_id") {
    name = "product_group_id";
  }
  if (nameKey === "parent_code" && idKey === "product_group_id") {
    name = "product_group_code";
  }
  if (nameKey === "parent_name" && idKey === "product_group_id") {
    name = "product_group_name";
  }
  if (nameKey === "parent_company_id" ) {
    name = "company_profile_id";
  }
  if (nameKey === "parent_company_name" ) {
    name = "company_profile_name";
  }
  if (nameKey === "parent_company_npwp" ) {
    name = "company_profile_npwp";
  }
  return name;
};
export const DDSonChangeProductGroup = (
  e,
  type,
  validToken,
  fetch,
  setLimitation,
  setFilter,
  setOption,
  filter,
  notify,
  dispatch,
  idKey,
  nameKey,
  codeKey
) => {
  const {name, value} = e.target;

  setLimitation(prevsValue => {
    return {
      ...prevsValue,
      [defineNamePG(name, idKey) ]: resValue(type, value),
      page: 1,
    };
  });
  setFilter(prevsValue => {
    return {
      ...prevsValue,
      [defineNamePG(name, idKey)]: resValue(type, value),
    };
  });
  fetch(
    validToken(),
    {page: 1, limit: 10, ...filter, [defineNamePG(name, idKey)]: resValue(type, value)},
    notify,
    dispatch,
    reponse => {
      if (isContent(reponse) !== null) {
        dispatch(setOption(recreateListPG(reponse, idKey, nameKey, codeKey)));
      } else {
        dispatch(setOption([]));
      }
    }
  );
};
export const DDSonChangeCompanyParent = (
  e,
  type,
  validToken,
  fetch,
  setLimitation,
  setFilter,
  setOption,
  filter,
  notify,
  dispatch,
  idKey,
  nameKey,
  codeKey
) => {
  const {name, value} = e.target;

  setLimitation(prevsValue => {
    return {
      ...prevsValue,
      [defineName(name, idKey) ]: resValue(type, value),
      page: 1,
    };
  });
  setFilter(prevsValue => {
    return {
      ...prevsValue,
      [defineName(name, idKey)]: resValue(type, value),
    };
  });
  fetch(
    validToken(),
    {page: 1, limit: 10, ...filter, [defineName(name, idKey)]: resValue(type, value)},
    notify,
    dispatch,
    reponse => {
      if (isContent(reponse) !== null) {
        dispatch(setOption(recreateListCP(reponse, idKey, nameKey, codeKey)));
      } else {
        dispatch(setOption([]));
      }
    }
  );
};
const defineNamePG = (nameKey) => {
  let name = "";
 
  if (nameKey === "product_group_hierarchy_id" ) {
    name = "product_group_id";
  }
  if (nameKey === "product_group_hierarchy_name" ) {
    name = "product_group_name";
  }
  if (nameKey === "product_group_hierarchy_code" ) {
    name = "product_group_code";
  }
  return name;
};