import { useState } from 'react';

import { useSelector } from 'react-redux';

import FieldComparison
  from '../../../../../../Components/Molecules/Field/FieldComparison/FieldComparison';
import FieldGroupComparison
  from '../../../../../../Components/Molecules/Field/FieldGroupComparison/FieldGroupComparison';
import { defaultLimitSearch } from '../../../../../../Enum/Limit/SearchAdvance';
import { REGEX_CONSTANT } from '../../../../../../Enum/RegexConstant';
import {
  postPrincipal,
  postProductGroup,
} from '../../../../../../Fetch/Api/SearchMappingAPI';
import {
  fetchPrincipal,
  fetchProductGroupParent,
} from '../../../../../../Fetch/FetchData/SearchMappingData';
import {
  searchMappingActions,
} from '../../../../../../Store/Reducers/SearchMappingReducer';
import {
  DDSonChange,
  DDSonChangeTypeParent,
  DDSOnClick,
  DDSOnScroll,
} from '../../../../../../Utils/Action/DropDownSearch/DropDownSearch';
import {
  fieldRequired,
} from '../../../../../../Utils/Action/Field/FieldRequired';
import {
  IgnorePrincipal,
} from '../../../../../../Utils/Action/Field/IgnorePrincipal';

const FieldProductGroupMappingForm = props => {
  const {t} = props;
  const levelNum = value => {
    let val = 0;
    if (value>0) {
      val = (value-1).toString();
    }
    return val;
  };
  const getPrincipal = () => {
    let checkId = props.value(props.mappingForm, "product_group_hierarchy_id", "exist");
    let principalId = Number(props.value(props.mappingForm, "principal_id", "new"));
    if (checkId !== null) {
      return (principalId = Number(props.value(props.mappingForm, "principal_id", "exist")));
    }
    return principalId;
  };
  const getLevel = () => {
    let checkId = props.value(props.mappingForm, "product_group_hierarchy_id", "exist");
    let checkLevel = props.value(props.mappingForm, "level", "isChecked");
    let level = Number(props.value(props.mappingForm, "level", "new"));
    if (checkId !== null && !checkLevel) {
      return (level = Number(props.value(props.mappingForm, "level", "exist")));
    }
    return level;
  };
  const principal = useSelector(state => state.searchMapping.principal);
  const parentList = useSelector(state => state.searchMapping.productGroup);
  
  const [filter, setFilter] = useState();
  const [limitation, setLimitation] = useState(defaultLimitSearch);
  const additionalFilter = {
    principal_id: getPrincipal(),
    level: levelNum(getLevel()),
  };
  const filters = {...filter, ...additionalFilter};

  const clickSelect = (fetch, filter) => {
    DDSOnClick(fetch, setLimitation, filter, props.validToken, props.dispatch, props.notify);
  };
  const onScroll = (fetch, prevList, filter) => {
    DDSOnScroll(
      fetch,
      prevList,
      setLimitation,
      limitation,
      filter,
      props.validToken,
      props.dispatch,
      props.notify
    );
  };
  const onChange = async (e, type, fetch, setOption) => {
    DDSonChange(
      e,
      type,
      props.validToken,
      fetch,
      setLimitation,
      setFilter,
      setOption,
      filter,
      props.notify,
      props.dispatch
    );
  };
  const onChangeTypeParent = async (e, type, fetch, setOption, idKey, nameKey, codeKey) => {
    DDSonChangeTypeParent(
      e,
      type,
      props.validToken,
      fetch,
      setLimitation,
      setFilter,
      setOption,
      filters,
      props.notify,
      props.dispatch,
      idKey,
      nameKey,
      codeKey
    );
  };
  const handleRequired = key =>
  fieldRequired(
    props.value(props.mappingForm, "product_group_hierarchy_id", "exist"),
    props.value(props.mappingForm, key, "isChecked")
  );
  return (
    <>
      <FieldComparison
        label={t("COMMON:FIELD.PRODUCT_GROUP_ID")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PRODUCT_GROUP_ID")})}
        typeNew="text"
        typeExist="text"
        nameNew="product_group_hierarchy_id"
        valueNew={props.value(props.mappingForm, "product_group_hierarchy_id", "new")}
        nameExist="product_group_hierarchy_id_exist"
        valueExist={props.value(props.mappingForm, "product_group_hierarchy_id", "exist")}
        checked={
          props.value(props.mappingForm, "product_group_hierarchy_id", "exist") !== null
            ? false
            : props.value(props.mappingForm, "product_group_hierarchy_id", "isChecked")
        }
        disabledCheck={
          props.value(props.mappingForm, "product_group_hierarchy_id", "exist") !== null ? true : false
        }
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        disabled={true}
      />
      <FieldComparison
        label={t("COMMON:FIELD.PRODUCT_GROUP_CODE")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PRODUCT_GROUP_CODE")})}
        typeNew="text"
        typeExist="text"
        nameNew="code"
        valueNew={props.value(props.mappingForm, "code", "new")}
        nameExist="code_id_exist"
        valueExist={props.value(props.mappingForm, "code", "exist")}
        checked={props.value(props.mappingForm, "code", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        pattern={REGEX_CONSTANT.NO_SPACE}
        message={t("COMMON:ERROR.NO_SPACE_SPECIAL", {
          field: t("COMMON:FIELD.PRODUCT_GROUP_CODE"),
        })}
       required={handleRequired("code")}
      />
      <FieldComparison
        label={t("COMMON:FIELD.PRODUCT_GROUP_NAME")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PRODUCT_GROUP_NAME")})}
        typeNew="text"
        typeExist="text"
        nameNew="name"
        valueNew={props.value(props.mappingForm, "name", "new")}
        nameExist="name_exist"
        valueExist={props.value(props.mappingForm, "name", "exist")}
        checked={props.value(props.mappingForm, "name", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        pattern={REGEX_CONSTANT.NAME_MAPPING}
        message={t("COMMON:ERROR.NAME", {
          field: t("COMMON:FIELD.PRODUCT_GROUP_NAME"),
          exp: "Product Group Abc",
        })}
       required={handleRequired("name")}
      />
      <FieldGroupComparison
        label={t("PRODUCT_GROUP:FIELD.PRINCIPAL")}
        t={t}
        detailDefault={props.detail}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        submitMapping={props.submitMapping}
        option={principal}
        mainKey="principal_id"
        secondKey="principal_name"
        thirdKey="principal_code"
        typeFirst="number"
        typeSecond="text"
        typeThird="text"
        onChange={(e, type) => onChange(e, type, postPrincipal, searchMappingActions.setPrincipal)}
        onClick={() => clickSelect(fetchPrincipal, filter)}
        onScroll={() => onScroll(fetchPrincipal, principal, filter)}
        subLabelOne={"PRODUCT_GROUP:FIELD.PRINCIPAL_ID"}
        subLabelTwo={"PRODUCT_GROUP:FIELD.PRINCIPAL_NAME"}
        subLabelThree={"PRODUCT_GROUP:FIELD.PRINCIPAL_CODE"}
        placeholderThird={t("COMMON:SELECT", {field: t("COMMON:FIELD.PRINCIPAL_CODE")})}
        placeholderSecond={t("COMMON:SELECT", {field: t("COMMON:FIELD.PRINCIPAL_NAME")})}
        placeholderFirst={t("COMMON:SELECT", {field: t("COMMON:FIELD.PRINCIPAL_ID")})}
        valueNew={(key, item) => props.value(props.mappingForm, key, item)}
        onCheck={e => props.checkedPartial(e)}
        dispatch={props.dispatch}
        disabledCheck={IgnorePrincipal(
          props.value(props.mappingForm, "product_group_hierarchy_id", "exist")
        )}
        disabled={IgnorePrincipal(
          props.value(props.mappingForm, "product_group_hierarchy_id", "exist")
        )}
       required={props.value(props.mappingForm, "principal_id", "exist")!==null?false:true}
      />
      <FieldComparison
        label={t("COMMON:FIELD.LEVEL")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.LEVEL")})}
        typeNew="number"
        typeExist="number"
        nameNew="level"
        valueNew={props.value(props.mappingForm, "level", "new")}
        nameExist="level_exist"
        valueExist={props.value(props.mappingForm, "level", "exist")}
        checked={props.value(props.mappingForm, "level", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
      />

      <FieldGroupComparison
        label={t("PRODUCT_GROUP:FIELD.PARENT")}
        t={t}
        detailDefault={props.detail}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        submitMapping={props.submitMapping}
        option={parentList}
        mainKey="parent_id"
        secondKey="parent_name"
        thirdKey="parent_code"
        typeFirst="number"
        typeSecond="text"
        typeThird="text"
        onChange={(e, type) =>
          onChangeTypeParent(
            e,
            type,
            postProductGroup,
            searchMappingActions.setProductGroup,
            "product_group_id",
            "product_group_name",
            "product_group_code"
          )
        }
        onClick={() => clickSelect(fetchProductGroupParent, filters)}
        onScroll={() => onScroll(fetchProductGroupParent, parentList, filters)}
        subLabelOne={"PRODUCT_GROUP:FIELD.PARENT_ID"}
        subLabelTwo={"PRODUCT_GROUP:FIELD.PARENT_NAME"}
        subLabelThree={"PRODUCT_GROUP:FIELD.PARENT_CODE"}
        placeholderThird={t("COMMON:SELECT", {field: t("COMMON:FIELD.PARENT_CODE")})}
        placeholderSecond={t("COMMON:SELECT", {field: t("COMMON:FIELD.PARENT_NAME")})}
        placeholderFirst={t("COMMON:SELECT", {field: t("COMMON:FIELD.PARENT_ID")})}
        resetSelect={true}
        valueNew={(key, item) => props.value(props.mappingForm, key, item)}
        onCheck={e => props.checkedPartial(e)}
        dispatch={props.dispatch}
        clearDds={getLevel()  <= 0 ? true : false}
        required={getLevel() > 0 ? true : false}
      />
    </>
  );
};

export default FieldProductGroupMappingForm;
