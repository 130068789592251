import { useState } from 'react';

import { useSelector } from 'react-redux';

import FieldComparison
  from '../../../../../../Components/Molecules/Field/FieldComparison/FieldComparison';
import FieldGroupComparison
  from '../../../../../../Components/Molecules/Field/FieldGroupComparison/FieldGroupComparison';
import { Max } from '../../../../../../Enum/DefaultProps/MaxLength';
import { REGEX_CONSTANT } from '../../../../../../Enum/RegexConstant';
import {
  postCountry,
  postDistrict,
  postIsland,
  postPersonTitle,
  postPostalCode,
  postProvince,
  postSubDistrict,
  postUrbanVillage,
} from '../../../../../../Fetch/Api/SearchMappingAPI';
import {
  fetchCountryList,
  fetchDistrictList,
  fetchIslandList,
  fetchPersonTitle,
  fetchPostalCodeList,
  fetchProvinceList,
  fetchSubDistrictList,
  fetchUrbanVillageList,
} from '../../../../../../Fetch/FetchData/SearchMappingData';
import {
  searchMappingActions,
} from '../../../../../../Store/Reducers/SearchMappingReducer';
import {
  personProfilesMappingSelect,
} from '../../../../../../Store/StateGroup/SerchMappingGroup';
import {
  DDSonChange,
  DDSOnClick,
  DDSOnScroll,
} from '../../../../../../Utils/Action/DropDownSearch/DropDownSearch';
import {
  fieldRequired,
} from '../../../../../../Utils/Action/Field/FieldRequired';
import {
  dateFormater,
  dateFormater3,
} from '../../../../../../Utils/Date/formater';

const FieldPersonProfileMappingForm = props => {
  const {t} = props;
  const [personTitle, country, postalCode, province, district, subDistrict, urbanVillage, island] =
    useSelector(personProfilesMappingSelect);

  const [filter, setFilter] = useState();
  const [regional, setRegion] = useState({
    country_id: 0,
    district_id: 0,
    province_id: 0,
    sub_district_id: 0,
    urban_village_id: 0,
  });
  const [limitation, setLimitation] = useState({page: 1, limit: 10});
  const regionalSelect = (e, key) => {
    setRegion({...regional, [key]: e[key]});
  };
  const clickSelect = fetch => {
    DDSOnClick(fetch, setLimitation, filter, props.validToken, props.dispatch, props.notify);
  };
  const clickSelectRegion = (fetch, key) => {
    DDSOnClick(
      fetch,
      setLimitation,
      {...filter, [key]: regional[key]},
      props.validToken,
      props.dispatch,
      props.notify
    );
  };
  const onScrollRegion = (fetch, prevList, key) => {
    DDSOnScroll(
      fetch,
      prevList,
      setLimitation,
      limitation,
      {...filter, [key]: regional[key]},
      props.validToken,
      props.dispatch,
      props.notify
    );
  };
  const onChangeRegion = async (e, type, fetch, setOption, key) => {
    DDSonChange(
      e,
      type,
      props.validToken,
      fetch,
      setLimitation,
      setFilter,
      setOption,
      {...filter, [key]: regional[key]},
      props.notify,
      props.dispatch
    );
  };
  const onScroll = (fetch, prevList) => {
    DDSOnScroll(
      fetch,
      prevList,
      setLimitation,
      limitation,
      filter,
      props.validToken,
      props.dispatch,
      props.notify
    );
  };
  const onChange = async (e, type, fetch, setOption) => {
    DDSonChange(
      e,
      type,
      props.validToken,
      fetch,
      setLimitation,
      setFilter,
      setOption,
      filter,
      props.notify,
      props.dispatch
    );
  };
  const handleRequired = key =>
    fieldRequired(
      props.value(props.mappingForm, "person_profile_id", "exist"),
      props.value(props.mappingForm, key, "isChecked")
    );
  return (
    <>
      <FieldComparison
        label={t("COMMON:FIELD.PERSON_PROFILE_ID")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PERSON_PROFILE_ID")})}
        typeNew="text"
        typeExist="text"
        nameNew="person_profile_id"
        valueNew={props.value(props.mappingForm, "person_profile_id", "new")}
        nameExist="person_profile_id_exist"
        valueExist={props.value(props.mappingForm, "person_profile_id", "exist")}
        checked={
          props.value(props.mappingForm, "person_profile_id", "exist") !== null
            ? false
            : props.value(props.mappingForm, "person_profile_id", "isChecked")
        }
        disabledCheck={
          props.value(props.mappingForm, "person_profile_id", "exist") !== null ? true : false
        }
        onCheck={props.checkedPartial}
        submitMapping={props.submitMapping}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        dispatch={props.dispatch}
        disabled={true}
      />
      <FieldGroupComparison
        label={t("PERSON_PROFILE:FIELD_PERSON_TITLE")}
        t={t}
        detailDefault={props.detail}
        mapping={props.mappingForm}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        option={personTitle}
        mainKey="person_title_id"
        secondKey="person_title_name"
        typeFirst="number"
        typeSecond="text"
        onChange={(e, type) =>
          onChange(e, type, postPersonTitle, searchMappingActions.setPersonTitle)
        }
        onClick={() => clickSelect(fetchPersonTitle)}
        onScroll={() => onScroll(fetchPersonTitle, personTitle)}
        subLabelOne={"PERSON_PROFILE:FIELD_PERSON_TITLE_ID"}
        subLabelTwo={"PERSON_PROFILE:FIELD_PERSON_TITLE_NAME"}
        placeholderSecond={t("COMMON:SELECT", {field: t("COMMON:FIELD.PERSON_TITLE_NAME")})}
        placeholderFirst={t("COMMON:SELECT", {field: t("COMMON:FIELD.PERSON_TITLE_ID")})}
        valueNew={(key, item) => props.value(props.mappingForm, key, item)}
        onCheck={e => props.checkedPartial(e)}
        dispatch={props.dispatch}
        required={handleRequired("person_title_id")}
      />

      <FieldComparison
        label={t("COMMON:FIELD.AUTH_USER_ID")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.AUTH_USER_ID")})}
        typeNew="number"
        typeExist="text"
        nameNew="auth_user_id"
        valueNew={props.value(props.mappingForm, "auth_user_id", "new")}
        nameExist="auth_user_id_exist"
        valueExist={props.value(props.mappingForm, "auth_user_id", "exist")}
        checked={props.value(props.mappingForm, "auth_user_id", "isChecked")}
        onCheck={props.checkedPartial}
        submitMapping={props.submitMapping}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.CLIENT_ID")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.CLIENT_ID")})}
        typeNew="text"
        typeExist="text"
        nameNew="client_id"
        valueNew={props.value(props.mappingForm, "client_id", "new")}
        nameExist="client_id_exist"
        valueExist={props.value(props.mappingForm, "client_id", "exist")}
        checked={props.value(props.mappingForm, "client_id", "isChecked")}
        onCheck={props.checkedPartial}
        submitMapping={props.submitMapping}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.NIK")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.NIK")})}
        typeNew="text"
        typeExist="text"
        nameNew="nik"
        valueNew={props.value(props.mappingForm, "nik", "new")}
        nameExist="nik_exist"
        valueExist={props.value(props.mappingForm, "nik", "exist")}
        checked={props.value(props.mappingForm, "nik", "isChecked")}
        onCheck={props.checkedPartial}
        submitMapping={props.submitMapping}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        dispatch={props.dispatch}
        maxLength={Max.length16}
        pattern={REGEX_CONSTANT.NIK}
        message={t("COMMON:ERROR.NIK", {field: t("COMMON:FIELD.NIK")})}
      />
      <FieldComparison
        label={t("COMMON:FIELD.NPWP")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.NPWP")})}
        typeNew="text"
        typeExist="text"
        nameNew="npwp"
        valueNew={props.value(props.mappingForm, "npwp", "new")}
        nameExist="npwp_exist"
        checked={props.value(props.mappingForm, "npwp", "isChecked")}
        valueExist={props.value(props.mappingForm, "npwp", "exist")}
        onCheck={props.checkedPartial}
        submitMapping={props.submitMapping}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        dispatch={props.dispatch}
        maxLength={Max.length30}
        pattern={REGEX_CONSTANT.NPWP}
        message={t("COMMON:ERROR.NPWP", {field: t("COMMON:FIELD.NPWP")})}
      />
      <FieldComparison
        label={t("COMMON:FIELD.FIRST_NAME")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.FIRST_NAME")})}
        typeNew="text"
        typeExist="text"
        nameNew="first_name"
        valueNew={props.value(props.mappingForm, "first_name", "new")}
        nameExist="first_name_exist"
        valueExist={props.value(props.mappingForm, "first_name", "exist")}
        checked={props.value(props.mappingForm, "first_name", "isChecked")}
        onCheck={props.checkedPartial}
        submitMapping={props.submitMapping}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        dispatch={props.dispatch}
        maxLength={Max.length20}
        pattern={REGEX_CONSTANT.NAME}
        message={t("COMMON:ERROR.NAME", {field: t("COMMON:FIELD.FIRST_NAME"), exp: "Thomas"})}
        required={handleRequired("first_name")}
      />
      <FieldComparison
        label={t("COMMON:FIELD.LAST_NAME")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.LAST_NAME")})}
        typeNew="text"
        typeExist="text"
        nameNew="last_name"
        valueNew={props.value(props.mappingForm, "last_name", "new")}
        nameExist="last_name_exist"
        valueExist={props.value(props.mappingForm, "last_name", "exist")}
        checked={props.value(props.mappingForm, "last_name", "isChecked")}
        onCheck={props.checkedPartial}
        submitMapping={props.submitMapping}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        dispatch={props.dispatch}
        maxLength={Max.length35}
        pattern={REGEX_CONSTANT.NAME}
        message={t("COMMON:ERROR.NAME", {field: t("COMMON:FIELD.LAST_NAME"), exp: "Alpha"})}
      />
      <FieldComparison
        label={t("COMMON:FIELD.SEX")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.SEX")})}
        typeNew="text"
        typeExist="text"
        nameNew="sex"
        valueNew={props.value(props.mappingForm, "sex", "new")}
        nameExist="sex_exist"
        valueExist={props.value(props.mappingForm, "sex", "exist")}
        checked={props.value(props.mappingForm, "sex", "isChecked")}
        onCheck={props.checkedPartial}
        submitMapping={props.submitMapping}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.ADDRESS1")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.ADDRESS1")})}
        typeNew="text"
        typeExist="text"
        nameNew="address_1"
        valueNew={props.value(props.mappingForm, "address_1", "new")}
        nameExist="address_1_exist"
        valueExist={props.value(props.mappingForm, "address_1", "exist")}
        checked={props.value(props.mappingForm, "address_1", "isChecked")}
        onCheck={props.checkedPartial}
        submitMapping={props.submitMapping}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        dispatch={props.dispatch}
        required={handleRequired("address_1")}
      />
      <FieldComparison
        label={t("COMMON:FIELD.ADDRESS2")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.ADDRESS2")})}
        typeNew="text"
        typeExist="text"
        nameNew="address_2"
        valueNew={props.value(props.mappingForm, "address_2", "new")}
        nameExist="address_2_exist"
        valueExist={props.value(props.mappingForm, "address_2", "exist")}
        checked={props.value(props.mappingForm, "address_2", "isChecked")}
        onCheck={props.checkedPartial}
        submitMapping={props.submitMapping}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.ADDRESS3")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.ADDRESS3")})}
        typeNew="text"
        typeExist="text"
        nameNew="address_3"
        valueNew={props.value(props.mappingForm, "address_3", "new")}
        nameExist="address_3_exist"
        valueExist={props.value(props.mappingForm, "address_3", "exist")}
        checked={props.value(props.mappingForm, "address_3", "isChecked")}
        onCheck={props.checkedPartial}
        submitMapping={props.submitMapping}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        dispatch={props.dispatch}
      />

      <FieldComparison
        label={t("COMMON:FIELD.HAMLET")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.HAMLET")})}
        typeNew="text"
        typeExist="text"
        nameNew="hamlet"
        valueNew={props.value(props.mappingForm, "hamlet", "new")}
        nameExist="hamlet_exist"
        valueExist={props.value(props.mappingForm, "hamlet", "exist")}
        checked={props.value(props.mappingForm, "hamlet", "isChecked")}
        onCheck={props.checkedPartial}
        submitMapping={props.submitMapping}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        dispatch={props.dispatch}
        maxLength={Max.length3}
        pattern={REGEX_CONSTANT.INTEGER}
        message={t("COMMON:ERROR.SMALL_REGIONAL", {field: t("COMMON:FIELD.HAMLET")})}
      />
      <FieldComparison
        label={t("COMMON:FIELD.NEIGHBOURHOOD")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.NEIGHBOURHOOD")})}
        typeNew="text"
        typeExist="text"
        nameNew="neighbourhood"
        valueNew={props.value(props.mappingForm, "neighbourhood", "new")}
        nameExist="neighbourhood_exist"
        valueExist={props.value(props.mappingForm, "neighbourhood", "exist")}
        checked={props.value(props.mappingForm, "neighbourhood", "isChecked")}
        onCheck={props.checkedPartial}
        submitMapping={props.submitMapping}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        dispatch={props.dispatch}
        maxLength={Max.length3}
        pattern={REGEX_CONSTANT.INTEGER}
        message={t("COMMON:ERROR.SMALL_REGIONAL", {field: t("COMMON:FIELD.NEIGHBOURHOOD")})}
      />

      <FieldGroupComparison
        label={t("PERSON_PROFILE:FIELD_COUNTRY")}
        t={t}
        detailDefault={props.detail}
        mapping={props.mappingForm}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        option={country}
        mainKey="country_id"
        secondKey="country_name"
        typeFirst="number"
        typeSecond="text"
        onChange={(e, type) => onChange(e, type, postCountry, searchMappingActions.setCountry)}
        onClick={() => clickSelect(fetchCountryList)}
        onScroll={() => onScroll(fetchCountryList, country)}
        onSelect={e => regionalSelect(e, "country_id")}
        subLabelOne={"PERSON_PROFILE:FIELD_COUNTRY_ID"}
        subLabelTwo={"PERSON_PROFILE:FIELD_COUNTRY_NAME"}
        placeholderSecond={t("COMMON:SELECT", {field: t("COMMON:FIELD.COUNTRY_NAME")})}
        placeholderFirst={t("COMMON:SELECT", {field: t("COMMON:FIELD.COUNTRY_ID")})}
        valueNew={(key, item) => props.value(props.mappingForm, key, item)}
        onCheck={e => props.checkedPartial(e)}
        dispatch={props.dispatch}
      />

      <FieldGroupComparison
        label={t("PERSON_PROFILE:FIELD_PROVINCE")}
        t={t}
        detailDefault={props.detail}
        mapping={props.mappingForm}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        option={province}
        mainKey="province_id"
        secondKey="province_name"
        thirdKey="province_code"
        typeFirst="number"
        typeSecond="text"
        typeThird="text"
        disabled={regional.country_id === 0 ? true : false}
        onSelect={e => regionalSelect(e, "province_id")}
        onChange={(e, type) =>
          onChangeRegion(e, type, postProvince, searchMappingActions.setProvince, "country_id")
        }
        onClick={() => clickSelectRegion(fetchProvinceList, "country_id")}
        onScroll={() => onScrollRegion(fetchProvinceList, province, "country_id")}
        subLabelOne={"PERSON_PROFILE:FIELD_PROVINCE_ID"}
        subLabelTwo={"PERSON_PROFILE:FIELD_PROVINCE_NAME"}
        subLabelThree={"PERSON_PROFILE:FIELD_PROVINCE_CODE"}
        placeholderThird={t("COMMON:SELECT", {field: t("COMMON:FIELD.PROVINCE_CODE")})}
        placeholderSecond={t("COMMON:SELECT", {field: t("COMMON:FIELD.PROVINCE_NAME")})}
        placeholderFirst={t("COMMON:SELECT", {field: t("COMMON:FIELD.PROVINCE_ID")})}
        valueNew={(key, item) => props.value(props.mappingForm, key, item)}
        onCheck={e => props.checkedPartial(e)}
        dispatch={props.dispatch}
      />

      <FieldGroupComparison
        label={t("PERSON_PROFILE:FIELD_DISTRICT")}
        t={t}
        detailDefault={props.detail}
        mapping={props.mappingForm}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        option={district}
        mainKey="district_id"
        secondKey="district_name"
        thirdKey="district_code"
        typeFirst="number"
        typeSecond="text"
        typeThird="text"
        disabled={regional.province_id === 0 ? true : false}
        onSelect={e => regionalSelect(e, "district_id")}
        onChange={(e, type) =>
          onChangeRegion(e, type, postDistrict, searchMappingActions.setDistrict, "province_id")
        }
        onClick={() => clickSelectRegion(fetchDistrictList, "province_id")}
        onScroll={() => onScrollRegion(fetchDistrictList, district, "province_id")}
        subLabelOne={"PERSON_PROFILE:FIELD_DISTRICT_ID"}
        subLabelTwo={"PERSON_PROFILE:FIELD_DISTRICT_NAME"}
        subLabelThree={"PERSON_PROFILE:FIELD_DISTRICT_CODE"}
        placeholderThird={t("COMMON:SELECT", {field: t("COMMON:FIELD.DISTRICT_CODE")})}
        placeholderSecond={t("COMMON:SELECT", {field: t("COMMON:FIELD.DISTRICT_NAME")})}
        placeholderFirst={t("COMMON:SELECT", {field: t("COMMON:FIELD.DISTRICT_ID")})}
        valueNew={(key, item) => props.value(props.mappingForm, key, item)}
        onCheck={e => props.checkedPartial(e)}
        dispatch={props.dispatch}
      />
      <FieldGroupComparison
        label={t("PERSON_PROFILE:FIELD_SUBDISTRICT")}
        t={t}
        detailDefault={props.detail}
        mapping={props.mappingForm}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        option={subDistrict}
        mainKey="sub_district_id"
        secondKey="sub_district_name"
        thirdKey="sub_district_code"
        typeFirst="number"
        typeSecond="text"
        typeThird="text"
        disabled={regional.district_id === 0 ? true : false}
        onSelect={e => regionalSelect(e, "sub_district_id")}
        onChange={(e, type) =>
          onChangeRegion(
            e,
            type,
            postSubDistrict,
            searchMappingActions.setSubDistrict,
            "district_id"
          )
        }
        onClick={() => clickSelectRegion(fetchSubDistrictList, "district_id")}
        onScroll={() => onScrollRegion(fetchSubDistrictList, subDistrict, "district_id")}
        subLabelOne={"PERSON_PROFILE:FIELD_SUBDISTRICT_ID"}
        subLabelTwo={"PERSON_PROFILE:FIELD_SUBDISTRICT_NAME"}
        subLabelThree={"PERSON_PROFILE:FIELD_SUBDISTRICT_CODE"}
        placeholderThird={t("COMMON:SELECT", {field: t("COMMON:FIELD.SUB_DISTRICT_CODE")})}
        placeholderSecond={t("COMMON:SELECT", {field: t("COMMON:FIELD.SUB_DISTRICT_NAME")})}
        placeholderFirst={t("COMMON:SELECT", {field: t("COMMON:FIELD.SUB_DISTRICT_ID")})}
        valueNew={(key, item) => props.value(props.mappingForm, key, item)}
        onCheck={e => props.checkedPartial(e)}
        dispatch={props.dispatch}
      />
      <FieldGroupComparison
        label={t("PERSON_PROFILE:FIELD_URBAN_VILLAGE")}
        t={t}
        detailDefault={props.detail}
        mapping={props.mappingForm}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        option={urbanVillage}
        mainKey="urban_village_id"
        secondKey="urban_village_name"
        thirdKey="urban_village_code"
        typeFirst="number"
        typeSecond="text"
        typeThird="text"
        disabled={regional.sub_district_id === 0 ? true : false}
        onSelect={e => regionalSelect(e, "urban_village_id")}
        onChange={(e, type) =>
          onChangeRegion(
            e,
            type,
            postUrbanVillage,
            searchMappingActions.setUrbanVillage,
            "sub_district_id"
          )
        }
        onClick={() => clickSelectRegion(fetchUrbanVillageList, "sub_district_id")}
        onScroll={() => onScrollRegion(fetchUrbanVillageList, urbanVillage, "sub_district_id")}
        subLabelOne={"PERSON_PROFILE:FIELD_URBAN_VILLAGE_ID"}
        subLabelTwo={"PERSON_PROFILE:FIELD_URBAN_VILLAGE_NAME"}
        subLabelThree={"PERSON_PROFILE:FIELD_URBAN_VILLAGE_CODE"}
        placeholderThird={t("COMMON:SELECT", {field: t("COMMON:FIELD.URBAN_VILLAGE_CODE")})}
        placeholderSecond={t("COMMON:SELECT", {field: t("COMMON:FIELD.URBAN_VILLAGE_NAME")})}
        placeholderFirst={t("COMMON:SELECT", {field: t("COMMON:FIELD.URBAN_VILLAGE_ID")})}
        valueNew={(key, item) => props.value(props.mappingForm, key, item)}
        onCheck={e => props.checkedPartial(e)}
        dispatch={props.dispatch}
      />
      <FieldGroupComparison
        label={t("PERSON_PROFILE:FIELD_POSTAL_CODE")}
        t={t}
        detailDefault={props.detail}
        mapping={props.mappingForm}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        option={postalCode}
        mainKey="postal_code_id"
        secondKey="postal_code"
        typeFirst="number"
        typeSecond="text"
        disabled={regional.urban_village_id === 0 ? true : false}
        onChange={(e, type) =>
          onChangeRegion(
            e,
            type,
            postPostalCode,
            searchMappingActions.setPostalCode,
            "urban_village_id"
          )
        }
        onClick={() => clickSelectRegion(fetchPostalCodeList, "urban_village_id")}
        onScroll={() => onScrollRegion(fetchPostalCodeList, postalCode, "urban_village_id")}
        subLabelOne={"PERSON_PROFILE:FIELD_POSTAL_CODE_ID"}
        subLabelTwo={"PERSON_PROFILE:FIELD_POSTAL_CODE_CODE"}
        placeholderSecond={t("COMMON:SELECT", {field: t("COMMON:FIELD.POSTAL_CODE_CODE")})}
        placeholderFirst={t("COMMON:SELECT", {field: t("COMMON:FIELD.POSTAL_CODE_ID")})}
        valueNew={(key, item) => props.value(props.mappingForm, key, item)}
        onCheck={e => props.checkedPartial(e)}
        dispatch={props.dispatch}
      />
      <FieldGroupComparison
        label={t("PERSON_PROFILE:FIELD_ISLAND")}
        t={t}
        detailDefault={props.detail}
        mapping={props.mappingForm}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        option={island}
        mainKey="island_id"
        secondKey="island_name"
        thirdKey="island_code"
        typeFirst="number"
        typeSecond="text"
        typeThird="text"
        disabled={regional.country_id === 0 ? true : false}
        onChange={(e, type) =>
          onChangeRegion(e, type, postIsland, searchMappingActions.setIsland, "country_id")
        }
        onClick={() => clickSelectRegion(fetchIslandList, "country_id")}
        onScroll={() => onScrollRegion(fetchIslandList, island, "country_id")}
        subLabelOne={"PERSON_PROFILE:FIELD_ISLAND_ID"}
        subLabelTwo={"PERSON_PROFILE:FIELD_ISLAND_NAME"}
        subLabelThree={"PERSON_PROFILE:FIELD_ISLAND_CODE"}
        placeholderThird={t("COMMON:SELECT", {field: t("COMMON:FIELD.ISLAND_CODE")})}
        placeholderSecond={t("COMMON:SELECT", {field: t("COMMON:FIELD.ISLAND_NAME")})}
        placeholderFirst={t("COMMON:SELECT", {field: t("COMMON:FIELD.ISLAND_ID")})}
        valueNew={(key, item) => props.value(props.mappingForm, key, item)}
        onCheck={e => props.checkedPartial(e)}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.PHONE")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PHONE")})}
        typeNew="text"
        typeExist="text"
        nameNew="phone"
        valueNew={props.value(props.mappingForm, "phone", "new")}
        nameExist="phone_exist"
        valueExist={props.value(props.mappingForm, "phone", "exist")}
        checked={props.value(props.mappingForm, "phone", "isChecked")}
        onCheck={props.checkedPartial}
        submitMapping={props.submitMapping}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        dispatch={props.dispatch}
        maxLength={Max.length35}
        pattern={REGEX_CONSTANT.PHONE_NUMBER}
        message={t("COMMON:ERROR.PHONE", {field: t("COMMON:FIELD.PHONE")})}
        required={handleRequired("phone")}
      />
      <FieldComparison
        label={t("COMMON:FIELD.ALTERNATIVE_PHONE")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.ALTERNATIVE_PHONE")})}
        typeNew="text"
        typeExist="text"
        nameNew="alternative_phone"
        valueNew={props.value(props.mappingForm, "alternative_phone", "new")}
        nameExist="alternative_phone_exist"
        valueExist={props.value(props.mappingForm, "alternative_phone", "exist")}
        checked={props.value(props.mappingForm, "alternative_phone", "isChecked")}
        onCheck={props.checkedPartial}
        submitMapping={props.submitMapping}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        dispatch={props.dispatch}
        maxLength={Max.length35}
        pattern={REGEX_CONSTANT.PHONE_NUMBER}
        message={t("COMMON:ERROR.PHONE", {field: t("COMMON:FIELD.ALTERNATIVE_PHONE")})}
      />
      <FieldComparison
        label={t("COMMON:FIELD.EMAIL")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.EMAIL")})}
        typeNew="text"
        typeExist="text"
        nameNew="email"
        valueNew={props.value(props.mappingForm, "email", "new")}
        nameExist="email_exist"
        valueExist={props.value(props.mappingForm, "email", "exist")}
        checked={props.value(props.mappingForm, "email", "isChecked")}
        onCheck={props.checkedPartial}
        submitMapping={props.submitMapping}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        dispatch={props.dispatch}
        maxLength={Max.length100}
        pattern={REGEX_CONSTANT.EMAIL}
        message={t("COMMON:ERROR.EMAIL", {field: t("COMMON:FIELD.EMAIL")})}
        required={handleRequired("email")}
      />
      <FieldComparison
        label={t("COMMON:FIELD.ALTERNATIVE_EMAIL")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.ALTERNATIVE_EMAIL")})}
        typeNew="text"
        typeExist="text"
        nameNew="alternative_email"
        valueNew={props.value(props.mappingForm, "alternative_email", "new")}
        nameExist="alternative_email_exist"
        valueExist={props.value(props.mappingForm, "alternative_email", "exist")}
        checked={props.value(props.mappingForm, "alternative_email", "isChecked")}
        onCheck={props.checkedPartial}
        submitMapping={props.submitMapping}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        dispatch={props.dispatch}
        maxLength={Max.length100}
        pattern={REGEX_CONSTANT.EMAIL}
        message={t("COMMON:ERROR.EMAIL", {field: t("COMMON:FIELD.ALTERNATIVE_EMAIL")})}
      />
      <FieldComparison
        label={t("COMMON:FIELD.BIRTH_PLACE")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.BIRTH_PLACE")})}
        typeNew="text"
        typeExist="text"
        nameNew="birth_place"
        valueNew={props.value(props.mappingForm, "birth_place", "new")}
        nameExist="birth_place_exist"
        valueExist={props.value(props.mappingForm, "birth_place", "exist")}
        checked={props.value(props.mappingForm, "birth_place", "isChecked")}
        onCheck={props.checkedPartial}
        submitMapping={props.submitMapping}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.BIRTH_DATE")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.BIRTH_DATE")})}
        typeNew="date"
        typeExist="text"
        nameNew="birth_date"
        valueNew={dateFormater(props.value(props.mappingForm, "birth_date", "new"))}
        nameExist="birth_date_exist"
        valueExist={dateFormater3(props.value(props.mappingForm, "birth_date", "exist"))}
        checked={props.value(props.mappingForm, "birth_date", "isChecked")}
        onCheck={props.checkedPartial}
        submitMapping={props.submitMapping}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.OCCUPATION")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.OCCUPATION")})}
        typeNew="text"
        typeExist="text"
        nameNew="occupation"
        valueNew={props.value(props.mappingForm, "occupation", "new")}
        nameExist="occupation_exist"
        valueExist={props.value(props.mappingForm, "occupation", "exist")}
        checked={props.value(props.mappingForm, "occupation", "isChecked")}
        onCheck={props.checkedPartial}
        submitMapping={props.submitMapping}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.SPOUSE_BIRTH_DATE")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:SPOUSE_FIELD.BIRTH_DATE")})}
        typeNew="date"
        typeExist="text"
        nameNew="spouse_birth_date"
        valueNew={dateFormater(props.value(props.mappingForm, "spouse_birth_date", "new"))}
        nameExist="spouse_birth_date_exist"
        valueExist={dateFormater3(props.value(props.mappingForm, "spouse_birth_date", "exist"))}
        checked={props.value(props.mappingForm, "spouse_birth_date", "isChecked")}
        onCheck={props.checkedPartial}
        submitMapping={props.submitMapping}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.SPOUSE_FIRST_NAME")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.SPOUSE_FIRST_NAME")})}
        typeNew="text"
        typeExist="text"
        nameNew="spouse_firstname"
        valueNew={props.value(props.mappingForm, "spouse_firstname", "new")}
        nameExist="spouse_firstname_exist"
        valueExist={props.value(props.mappingForm, "spouse_firstname", "exist")}
        checked={props.value(props.mappingForm, "spouse_firstname", "isChecked")}
        onCheck={props.checkedPartial}
        submitMapping={props.submitMapping}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        dispatch={props.dispatch}
        maxLength={Max.length20}
        pattern={REGEX_CONSTANT.NAME}
        message={t("COMMON:ERROR.NAME", {
          field: t("COMMON:FIELD.SPOUSE_FIRST_NAME"),
          exp: "Thomas",
        })}
      />
      <FieldComparison
        label={t("COMMON:FIELD.SPOUSE_LAST_NAME")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.SPOUSE_LAST_NAME")})}
        typeNew="text"
        typeExist="text"
        nameNew="spouse_lastname"
        valueNew={props.value(props.mappingForm, "spouse_lastname", "new")}
        nameExist="spouse_lastname_exist"
        valueExist={props.value(props.mappingForm, "spouse_lastname", "exist")}
        checked={props.value(props.mappingForm, "spouse_lastname", "isChecked")}
        onCheck={props.checkedPartial}
        submitMapping={props.submitMapping}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        dispatch={props.dispatch}
        pattern={REGEX_CONSTANT.NAME}
        message={t("COMMON:ERROR.NAME", {field: t("COMMON:FIELD.SPOUSE_LAST_NAME"), exp: "Thomas"})}
      />
      <FieldComparison
        label={t("COMMON:FIELD.ANNIVERSARY_DATE")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.ANNIVERSARY_DATE")})}
        typeNew="date"
        typeExist="text"
        nameNew="anniversary_date"
        valueNew={props.value(props.mappingForm, "anniversary_date", "new")}
        nameExist="anniversary_date_exist"
        valueExist={dateFormater3(props.value(props.mappingForm, "anniversary_date", "exist"))}
        checked={props.value(props.mappingForm, "anniversary_date", "isChecked")}
        onCheck={props.checkedPartial}
        submitMapping={props.submitMapping}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.EDUCATION_HISTORY")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.EDUCATION_HISTORY")})}
        typeNew="text"
        typeExist="text"
        nameNew="education_history"
        valueNew={props.value(props.mappingForm, "education_history", "new")}
        nameExist="education_history_exist"
        valueExist={props.value(props.mappingForm, "education_history", "exist")}
        checked={props.value(props.mappingForm, "education_history", "isChecked")}
        onCheck={props.checkedPartial}
        submitMapping={props.submitMapping}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.JOB_EXPERIENCE")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.JOB_EXPERIENCE")})}
        typeNew="text"
        typeExist="text"
        nameNew="job_experience_history"
        valueNew={props.value(props.mappingForm, "job_experience_history", "new")}
        nameExist="job_experience_history_exist"
        valueExist={props.value(props.mappingForm, "job_experience_history", "exist")}
        checked={props.value(props.mappingForm, "job_experience_history", "isChecked")}
        onCheck={props.checkedPartial}
        submitMapping={props.submitMapping}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.REMARK")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.REMARK")})}
        typeNew="text"
        typeExist="text"
        nameNew="remark"
        valueNew={props.value(props.mappingForm, "remark", "new")}
        nameExist="remark_exist"
        valueExist={props.value(props.mappingForm, "remark", "exist")}
        checked={props.value(props.mappingForm, "remark", "isChecked")}
        onCheck={props.checkedPartial}
        submitMapping={props.submitMapping}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        dispatch={props.dispatch}
      />

      <FieldComparison
        label={t("COMMON:FIELD.ADDITIONAL_INFO")}
        placeholderKey={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.KEY")})}
        placeholderValue={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.VALUE")})}
        typeNew="multiField"
        typeExist="text"
        nameNew="additional_info"
        valueNew={props.value(props.mappingForm, "additional_info", "new")}
        nameExist="additional_info_exist"
        valueExist={props.value(props.mappingForm, "additional_info", "exist")}
        checked={props.value(props.mappingForm, "additional_info", "isChecked")}
        onCheck={props.checkedPartial}
        submitMapping={props.submitMapping}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        dispatch={props.dispatch}
      />
    </>
  );
};

export default FieldPersonProfileMappingForm;
