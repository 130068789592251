import React from "react";

import _ from "lodash";
import { Button, Div } from "nexcomponent-ui";

import { mappingType } from "../../../../Enum/DataTypeContant";
import { BUTTON } from "../../../../Enum/IdSet";
import { BTN_STYLE_TYPE } from "../../../../Enum/Style";
import { mappingActions } from "../../../../Store/Reducers/MappingReducer";
import { utilsActions } from "../../../../Store/Reducers/UtilsReducer";
import style from "./CancelOverwrite.module.css";

function CancelOverwrite(props) {
  const findChecked = _.find(props.mappingForm, ["isChecked", true]);
  const disabled = (findChecked) => {
    if (findChecked === undefined) {
      return true;
    } else {
      return false;
    }
  };
  const cancel = async () => {
    await props.navigate(mappingType(props.mappingCode));
    await props.dispatch(mappingActions.setMappingList([]));
    props.dispatch(mappingActions.setMappingForm([]));
  };
  const save = async () => {
    props.dispatch(utilsActions.setModalType("Save"));
    props.dispatch(utilsActions.setModal(true));
  };
  const overwrites = async () => {
    props.dispatch(utilsActions.setModalType("Overwrite"));
    props.dispatch(utilsActions.setModal(true));
  };

  const checkAction = _.find(props.mappingForm, { key: props.idKey });

  return (
    <Div className={style.Button}>
      {checkAction.exist != null ? (
        <Button
          id={BUTTON.OVERWRITE}
          label={props.t("COMMON:OVERWRITE")}
          onClick={overwrites}
          disabled={disabled(findChecked)}
        />
      ) : (
        <Button
          id={BUTTON.SAVE}
          label={props.t("COMMON:SAVE")}
          onClick={save}
          disabled={disabled(findChecked)}
        />
      )}

      <Button
        id={BUTTON.CANCEL}
        typeStyle={BTN_STYLE_TYPE.SECONDARY}
        className={style.ButtonLeft}
        onClick={cancel}
        label={props.t("COMMON:CANCEL")}
      />
    </Div>
  );
}

export default CancelOverwrite;
