export const personProfileAdvanceSearch = t => [
  {title: t("COMMON:FIELD.ADDRESS2"), code: "address_2", type: "text"},
  {title: t("COMMON:FIELD.ADDRESS3"), code: "address_3", type: "text"},
  {title: t("COMMON:FIELD.AUTH_USER_ID"), code: "auth_user_id", type: "number"},
  {title: t("COMMON:FIELD.COUNTRY_ID"), code: "country_id", type: "number"},
  {title: t("COMMON:FIELD.COUNTRY_NAME"), code: "country_name", type:"text"},
  {title: t("COMMON:FIELD.DISTRICT_CODE"), code: "district_code",  type:"text"},
  {title: t("COMMON:FIELD.DISTRICT_ID"), code: "district_id",  type: "number"},
  {title: t("COMMON:FIELD.DISTRICT_NAME"), code: "district_name",  type:"text"},
  {title: t("COMMON:FIELD.HAMLET"), code: "hamlet",  type:"text"},
  {title: t("COMMON:FIELD.ISLAND_CODE"), code: "island_code",  type:"text"},
  {title: t("COMMON:FIELD.ISLAND_ID"), code: "island_id",  type: "number"},
  {title: t("COMMON:FIELD.ISLAND_NAME"), code: "island_name",  type:"text"},
  {title: t("COMMON:FIELD.NEIGHBOURHOOD"), code: "neighbourhood",  type:"text"},
  {title: t("COMMON:FIELD.POSTAL_CODE_CODE"), code: "postal_code_code",  type:"text"},
  {title: t("COMMON:FIELD.POSTAL_CODE_ID"), code: "postal_code_id",  type: "number"},
  {title: t("COMMON:FIELD.PROVINCE_CODE"), code: "province_code",  type:"text"},
  {title: t("COMMON:FIELD.PROVINCE_ID"), code: "province_id",  type: "number"},
  {title: t("COMMON:FIELD.PROVINCE_NAME"), code: "province_name",  type:"text"},
  {title: t("COMMON:FIELD.SEX"), code: "sex",  type:"text"},
  {title: t("COMMON:FIELD.SUB_DISTRICT_CODE"), code: "sub_district_code",  type:"text"},
  {title: t("COMMON:FIELD.SUB_DISTRICT_ID"), code: "sub_district_id",  type: "number"},
  {title: t("COMMON:FIELD.SUB_DISTRICT_NAME"), code: "sub_district_name",  type:"text"},
  {title: t("COMMON:FIELD.URBAN_VILLAGE_CODE"), code: "urban_village_code",  type:"text"},
  {title: t("COMMON:FIELD.URBAN_VILLAGE_ID"), code: "urban_village_id",  type: "number"},
  {title: t("COMMON:FIELD.URBAN_VILLAGE_NAME"), code: "urban_village_name",  type:"text"},
  {title: t("COMMON:FIELD.PERSON_TITLE_ID"), code: "person_title_id",  type: "number"},
];
