import { styleColumn } from './StyleColumn/StyleColumn';

export const rejectColumn = t => [
  {
    name: t("COMMON:FIELD.DATE_TIME"),
    selector: "updated_at",
    sortable: true,
    width: "20vw",
    padding: styleColumn.paddingColumnStart,
  },
  {
    name: t("COMMON:FIELD.MAPPING_DATA_TYPE"),
    selector: "mapping_data_type",
    width: "25vw",
  },
  {
    name: t("COMMON:FIELD.ID"),
    selector: "id",
    width: "15vw",
  },
  {
    name: t("COMMON:FIELD.ADMIN"),
    selector: "updated_by",
    width: "10vw",
    
    
  },
  {
    name: t("COMMON:FIELD.RESOURCE"),
    selector: "resource",
    width: "10vw",
    padding: styleColumn.paddingColumnEnd,
  },
  {
    name: t("COMMON:REJECT_REASON"),
    selector: "reject_reason",
    width: "20vw",
    padding: styleColumn.paddingColumnEnd,
  },
];
