import React from 'react';

import { Div } from 'nexcomponent-ui';

import style from './DetailItem.module.css';

const DetailItem = (props) => {
  return (
    <Div>
      <Div id={props.id+"title"}className={style.Title}>{props.title}</Div>
      <Div id={props.id+"value"} className={style.Value}>{props.value}</Div>
    </Div>
  )
}

export default DetailItem
