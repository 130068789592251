import _ from 'lodash';

import { mappingActions } from '../../../Reducers/MappingReducer';

export const mappingCheckedAll = async (
  e,
  mappingForm,
  submitMapping,
  existData,
  dispatch,
  idKey
) => {
  const {name, checked} = e.target;
  const checkAction = _.find(mappingForm, {key: idKey});

  const data = () => {
    let mapping = {};
    if (checked) {
      _.map(mappingForm, a =>
        a.key === idKey
          ? (mapping[idKey] = existData.id)
          : checkAction.exist != null &&
            (a.key === "principal_id" || a.key === "principal_code" || a.key === "principal_name")
          ? (mapping[a.key] = existData[a.key])
          : (mapping[a.key] = a.new)
      );
    } else {
      _.map(mappingForm, a =>
        a.key === idKey ? (mapping[idKey] = existData.id) : (mapping[a.key] = existData[a.key])
      );
    }
    if (mapping !== {}) {
      return {...submitMapping, ...mapping};
    }
  };
  dispatch(mappingActions.setSubmitMapping(data()));

  if (name === "checkboxAll") {
    let tempForm = await mappingForm.map(datas => {
      return {...datas, isChecked: checked};
    });
    await dispatch(mappingActions.setMappingForm(tempForm));
  }
};
