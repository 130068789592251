import _ from "lodash";

import { MAPPING_CODE } from "../../Enum/MappingCode/MappingCode";
import { dashboardAction } from "../../Store/Reducers/DashboardReducer";
import { mappingActions } from "../../Store/Reducers/MappingReducer";
import { utilsActions } from "../../Store/Reducers/UtilsReducer";
import { dateFormater2 } from "../../Utils/Date/formater";
import { isContent, isContentErr, isStatus, isStatusErr } from "../../Utils/Response/Response";
import { postRejectCompanyProfileList } from "../Api/CompanyProfileAPI";
import { postRejectDivisionList } from "../Api/DivisionAPI";
import { postRejectPersonProfileList } from "../Api/PersonProfileAPI";
import { postRejectPrincipalProductUserDefinedCategoryList } from "../Api/PrincipalProductUserDefinedCategoryAPI";
import { postRejectProductList } from "../Api/ProductAPI";
import { postRejectProductBrandList } from "../Api/ProductBrandAPI";
import { postRejectProductCategoryList } from "../Api/ProductCategoryAPI";
import { postRejectProductGroupList } from "../Api/ProductGroupAPI";
import { postRejectProductKeyAccountList } from "../Api/ProductKeyAccountAPI";
import { fetchDataApprovalCompanyProfile } from "./CompanyProfileData";
import { fetchDataApprovalDivision } from "./DivisionData";
import { fetchDataApprovalPersonProfile } from "./PersonProfileData";
import { fetchDataApprovalPrincipalProductUserDefinedCategory } from "./PrincipalProductUserDefinedCategoryData";
import { fetchDataApprovalProductBrand } from "./ProductBrandData";
import { fetchDataApprovalProductCategory } from "./ProductCategoryData";
import { fetchDataApprovalProduct } from "./ProductData";
import { fetchDataApprovalProductGroup } from "./ProductGroupData";
import { fetchDataApprovalProductKeyAccount } from "./ProductKeyAccountData";

export const approvalAction = async (res, mappingList, mappingCount, dispatch) => {
  let statusResult = { success: true, message: "" };
  const newMappingList = mappingList.slice(1, mappingList.length);
  if (newMappingList.length > 0) {
    await dispatch(mappingActions.setMappingCount(mappingCount + 1));
    await dispatch(mappingActions.setMappingList(newMappingList));
    statusResult = isStatus(res);
  } else {
    await dispatch(mappingActions.setMappingList([]));
    statusResult = isStatus(res);
  }
  dispatch(utilsActions.setResultModal(true));
  return statusResult;
};
export const approvalActionErr = async (err, dispatch) => {
  dispatch(utilsActions.setResultModal(true));

  return isStatusErr(err);
};
function isJson(item) {
  item = typeof item !== "string" ? JSON.stringify(item) : item;

  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }

  if (typeof item === "object" && item !== null) {
    return true;
  }
  return false;
}
export const setMappingForm = async (
  fieldMapping,
  list,
  uniqueKey,
  newData,
  existData,
  dispatch,
  formValidation
) => {
  dispatch(utilsActions.setLoading(true));
  let toNull = (val) => {
    let value = val;
    if (val === undefined) {
      value = null;
    }
    if (val === 0) {
      return null;
    }
    return value;
  };
  let addtional = (value) => {
    try {
      const twice_json = JSON.stringify(value);
      const json = JSON.parse(twice_json);
      if (
        isJson(value) === true &&
        JSON.parse(json).key !== undefined &&
        JSON.parse(json).value !== undefined
      ) {
        return json;
      } else if (isJson(value) === false) {
        const res = JSON.parse(json.replaceAll(/\\/g, ""));

        return res;
      }
    } catch (error) {
      return null;
    }
  };
  let parentReset = (valueCheck, value) => {
    let val = value;
    if (valueCheck <= 0) {
      val = null;
    }
    return val;
  };

  let tempMapping = _.map(fieldMapping, (a) => {
    return a === uniqueKey
      ? { key: a, new: toNull(newData.id), exist: toNull(existData.id), isChecked: false }
      : a === "parent_id" || a === "parent_code" || a === "parent_name"
      ? {
          key: a,
          new: parentReset(newData.level, newData[a]),
          exist: toNull(existData[a]),
          isChecked: false,
        }
      : { key: a, new: toNull(newData[a]), exist: toNull(existData[a]), isChecked: false };
  });

  const lastUpdate = (data) => {
    if (
      data === "" ||
      data === null ||
      data === undefined ||
      data === "0001-01-01" ||
      data === "0001-01-01T00:00:00Z"
    ) {
      return "-";
    } else {
      return dateFormater2(data);
    }
  };
  await dispatch(mappingActions.setDetail(newData));
  await dispatch(mappingActions.setExisting(existData));

  await dispatch(mappingActions.setLastUpdate(lastUpdate(existData.updated_at)));
  await dispatch(mappingActions.setMappingForm(tempMapping));
  const first = _.first(list);
  await dispatch(mappingActions.setRejectList([first]));
  await dispatch(mappingActions.setMappingList(list));
  let defaultMapping = { id: first.id, resource: first.resource };
  let mapping = {};
  _.map(fieldMapping, (a) =>
    a === uniqueKey
      ? (mapping[a] = toNull(existData.id))
      : a === "additional_info"
      ? (mapping[a] = addtional(toNull(existData[a])))
      : (mapping[a] = toNull(existData[a]))
  );
  await dispatch(mappingActions.setSubmitMapping({ ...defaultMapping, ...mapping }));
  await dispatch(mappingActions.setFormValidation(formValidation));
  dispatch(utilsActions.setLoading(false));
};
export const setMappingSearch = async (res, column, countData, dispatch) => {
  await dispatch(mappingActions.setSearchColumn(column));
  dispatch(mappingActions.setSearchList(isContent(res)));
  dispatch(dashboardAction.setTotalRow(countData));
  dispatch(utilsActions.setLoading(false));
};
export const setMappingSearchErr = async (res, countData, dispatch) => {
  dispatch(mappingActions.setSearchList(isContentErr(res)));
  dispatch(dashboardAction.setTotalRow(countData));
  dispatch(utilsActions.setLoading(false));
};
export async function fetchDataMappingApproval(
  token,
  data,
  mappingList,
  mappingCount,
  mappingCode,
  navigate,
  notify,
  dispatch
) {
  if (mappingCode === MAPPING_CODE.PERSON_PROFILE) {
    return fetchDataApprovalPersonProfile(
      token,
      data,
      mappingList,
      mappingCount,
      mappingCode,
      navigate,
      notify,
      dispatch
    );
  } else if (mappingCode === MAPPING_CODE.DIVISION) {
    return fetchDataApprovalDivision(
      token,
      data,
      mappingList,
      mappingCount,
      mappingCode,
      navigate,
      notify,
      dispatch
    );
  } else if (mappingCode === MAPPING_CODE.PRODUCT_BRAND) {
    return fetchDataApprovalProductBrand(
      token,
      data,
      mappingList,
      mappingCount,
      mappingCode,
      navigate,
      notify,
      dispatch
    );
  } else if (mappingCode === MAPPING_CODE.COMPANY_PROFILE) {
    return fetchDataApprovalCompanyProfile(
      token,
      data,
      mappingList,
      mappingCount,
      mappingCode,
      navigate,
      notify,
      dispatch
    );
  } else if (mappingCode === MAPPING_CODE.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY) {
    return fetchDataApprovalPrincipalProductUserDefinedCategory(
      token,
      data,
      mappingList,
      mappingCount,
      mappingCode,
      navigate,
      notify,
      dispatch
    );
  } else if (mappingCode === MAPPING_CODE.PRODUCT_GROUP) {
    return fetchDataApprovalProductGroup(
      token,
      data,
      mappingList,
      mappingCount,
      mappingCode,
      navigate,
      notify,
      dispatch
    );
  } else if (mappingCode === MAPPING_CODE.PRODUCT_CATEGORY) {
    return fetchDataApprovalProductCategory(
      token,
      data,
      mappingList,
      mappingCount,
      mappingCode,
      navigate,
      notify,
      dispatch
    );
  } else if (mappingCode === MAPPING_CODE.PRODUCT_KEY_ACCOUNT) {
    return fetchDataApprovalProductKeyAccount(
      token,
      data,
      mappingList,
      mappingCount,
      mappingCode,
      navigate,
      notify,
      dispatch
    );
  } else if (mappingCode === MAPPING_CODE.PRODUCT) {
    return fetchDataApprovalProduct(
      token,
      data,
      mappingList,
      mappingCount,
      mappingCode,
      navigate,
      notify,
      dispatch
    );
  }
}

export function fetchDataRejectAction(mappingCode, token, rejectData, navigate, notify) {
  if (mappingCode === MAPPING_CODE.PERSON_PROFILE) {
    return postRejectPersonProfileList(token, rejectData)
      .then((res) => {
        return res.data.nexsoft.payload.status;
      })
      .catch((err) => {
        return err.response.data.nexsoft.payload.status;
      });
  } else if (mappingCode === MAPPING_CODE.COMPANY_PROFILE) {
    return postRejectCompanyProfileList(token, rejectData)
      .then((res) => {
        return res.data.nexsoft.payload.status;
      })
      .catch((err) => {
        return err.response.data.nexsoft.payload.status;
      });
  } else if (mappingCode === MAPPING_CODE.DIVISION) {
    return postRejectDivisionList(token, rejectData)
      .then((res) => {
        return res.data.nexsoft.payload.status;
      })
      .catch((err) => {
        return err.response.data.nexsoft.payload.status;
      });
  } else if (mappingCode === MAPPING_CODE.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY) {
    return postRejectPrincipalProductUserDefinedCategoryList(token, rejectData)
      .then((res) => {
        return res.data.nexsoft.payload.status;
      })
      .catch((err) => {
        return err.response.data.nexsoft.payload.status;
      });
  } else if (mappingCode === MAPPING_CODE.PRODUCT_BRAND) {
    return postRejectProductBrandList(token, rejectData)
      .then((res) => {
        return res.data.nexsoft.payload.status;
      })
      .catch((err) => {
        return err.response.data.nexsoft.payload.status;
      });
  } else if (mappingCode === MAPPING_CODE.PRODUCT_CATEGORY) {
    return postRejectProductCategoryList(token, rejectData)
      .then((res) => {
        return res.data.nexsoft.payload.status;
      })
      .catch((err) => {
        return err.response.data.nexsoft.payload.status;
      });
  } else if (mappingCode === MAPPING_CODE.PRODUCT_GROUP) {
    return postRejectProductGroupList(token, rejectData)
      .then((res) => {
        return res.data.nexsoft.payload.status;
      })
      .catch((err) => {
        return err.response.data.nexsoft.payload.status;
      });
  } else if (mappingCode === MAPPING_CODE.PRODUCT_KEY_ACCOUNT) {
    return postRejectProductKeyAccountList(token, rejectData)
      .then((res) => {
        return res.data.nexsoft.payload.status;
      })
      .catch((err) => {
        return err.response.data.nexsoft.payload.status;
      });
  } else if (mappingCode === MAPPING_CODE.PRODUCT) {
    return postRejectProductList(token, rejectData)
      .then((res) => {
        return res.data.nexsoft.payload.status;
      })
      .catch((err) => {
        return err.response.data.nexsoft.payload.status;
      });
  }
}
