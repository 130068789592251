import React, { useEffect } from 'react';

import {
  Col,
  Div,
  Input,
  Row,
  Span,
} from 'nexcomponent-ui';
import { useSelector } from 'react-redux';

import Select from '../../../../Components/UnstableComponent/Select/Select';
import { BOOLEANSET } from '../../../../Enum/Boolean/BooleanSet';
import { Max } from '../../../../Enum/DefaultProps/MaxLength';
import {
  BOX,
  DROPDOWNLIST,
  TEXT_SINGLE,
} from '../../../../Enum/IdSet';
import { TYPESET } from '../../../../Enum/Input';
import { NAMESET } from '../../../../Enum/Input/NameSet';
import { SIZESET } from '../../../../Enum/Props';
import { fetchRejectReason } from '../../../../Fetch/FetchData/InitiateData';
import { utilsActions } from '../../../../Store/Reducers/UtilsReducer';
import style from './RejectSection.module.css';

const RejectSection = props => {
  const rejectReason = useSelector(state => state.dashboard.rejectReason);
  const rejectReasonSelect = useSelector(state => state.utils.rejectReasonSelect);
  const token = useSelector(state => state.authentication.token);
  const inputCustom = e => {
    props.dispatch(
      utilsActions.setRejectReasonSelect({
        reject_reason_option: 0,
        reject_reason_custom: e.target.value,
      })
    );
  };
  const handleOption = evt => {
    const value = Number(evt.target.value);
    if (Number(value) === 0) {
      props.dispatch(utilsActions.setCustomReason(true));
      props.dispatch(
        utilsActions.setRejectReasonSelect({reject_reason_option: 0, reject_reason_custom: ""})
      );
    } else {
      props.dispatch(utilsActions.setCustomReason(false));
      props.dispatch(
        utilsActions.setRejectReasonSelect({
          reject_reason_option: value,
          reject_reason_custom: evt.target.label,
        })
      );
    }
  };
  useEffect(() => {
    if (rejectReason === []) {
      fetchRejectReason(token, props.dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, props.dispatch]);

  return (
    <Div id={BOX.REJECT_SECTION} className={style.Container}>
      <Span
        id={TEXT_SINGLE.TITLE_REJECT_REASON}
        className={style.Title}
        value={props.t("COMMON:REJECT_REASON")}
      />
      <Row id={BOX.ROW} className={style.Row}>
        <Col id={BOX.COL_LEFT} className={style.Col} size={SIZESET.LG} width={SIZESET.SIX}>
          <Select
            name="reject_reason"
            id={DROPDOWNLIST.REJECT_REASON}
            placeholder={props.t("COMMON:PLACEHOLDER.SELECT_REASON")}
            option={rejectReason}
            defaultSelect={rejectReason[0]}
                labelKey="reject_reason_custom"
                valueKey="reject_reason_option"
            onChange={handleOption}
          />
        </Col>
        <Col id={BOX.COL_RIGHT} className={style.Col} size={SIZESET.LG} width={SIZESET.SIX}>
          {rejectReasonSelect.reject_reason_option === 0 ? (
            <Input
              id={TEXT_SINGLE.CUSTOM_REASON}
              type={TYPESET.TXT}
              size={SIZESET.LG}
              name={NAMESET.REJECT_REASON_CUSTOM}
              className={style.Reason}
              onChange={inputCustom}
              placeholder={props.t("COMMON:TYPE_YOUR_REASON_HERE")}
              required={BOOLEANSET.TRUE}
              maxLength={Max.length256}
            />
          ) : (
            ""
          )}
        </Col>
      </Row>
    </Div>
  );
};

export default RejectSection;
