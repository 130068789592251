import { REGEX_CONSTANT } from '../../Enum/RegexConstant';

export const regexNumber = new RegExp(REGEX_CONSTANT.INTEGER);
export const regexUsername = new RegExp(REGEX_CONSTANT.USERNAME);
export const regexPassword = new RegExp(REGEX_CONSTANT.PASSWORD);
export const regexName = new RegExp(REGEX_CONSTANT.NAME);
export const regexEmail = new RegExp(REGEX_CONSTANT.EMAIL);
export const regexPermission = new RegExp(REGEX_CONSTANT.PERMISSION);
export const regexDataScope = new RegExp(REGEX_CONSTANT.DATA_SCOPE);
export const regexIntger = new RegExp(REGEX_CONSTANT.INTEGER);
export const regexPhoneCountryCode = new RegExp(REGEX_CONSTANT.PHONE_COUNTRY_CODE);
export const regexPhoneNumber = new RegExp(REGEX_CONSTANT.PHONE_NUMBER);
export const regexFloat = new RegExp(REGEX_CONSTANT.FLOAT);
export const regexStringChar = new RegExp(REGEX_CONSTANT.STRING_CHAR);
export const regexNIK = new RegExp(REGEX_CONSTANT.NIK);
export const regexNPWP = new RegExp(REGEX_CONSTANT.NPWP);
export const regexInformationKey = new RegExp(REGEX_CONSTANT.INFORMATION_KEY);
export const regexInformationValue = new RegExp(REGEX_CONSTANT.INFORMATION_VALUE);
export const regexSpesialCharacter = new RegExp(REGEX_CONSTANT.SPECIAL_CHARACTER);
export const regexSpesialCharacter2 = new RegExp(REGEX_CONSTANT.SPECIAL_CHARACTER_2);
