import React from "react";

import { Breadcrumb, TitlePage } from "nexcomponent-ui";

import { BREADCRUMB, TEXT_SINGLE } from "../../../../Enum/IdSet";
import MappingTypeActionButton from "../../Button/MappingTypeActionButton/MappingTypeActionButton";
import style from "./HeaderPageList.module.css";

const HeaderPageList = (props) => {
  return (
    <>
      <Breadcrumb
        id={BREADCRUMB.HEADER}
        list={props.listBreadcrumb}
        default={props.defaultBreadcrumb}
      />
      <TitlePage
        id={TEXT_SINGLE.TITLE_PAGE}
        className={style.Title}
        to={props.toTitlePage}
        title={props.title}
      />
      <MappingTypeActionButton
        notify={props.notify}
        t={props.t}
        dispatch={props.dispatch}
        navigate={props.navigate}
        mappingCode={props.mappingCode}
        uniqueKey={props.uniqueKey}
        rejectNavigate={props.rejectNavigate}
        mappingNavigate={props.mappingNavigate}
      />
    </>
  );
};

export default HeaderPageList;
