import React from 'react';
import { useSelector } from 'react-redux'

import { Div } from 'nexcomponent-ui';

import {
  notificationAfterListBreadcrumb,
} from '../../../Enum/Breadcrumb/NotificationBreadcrumb';
import {
  ROUTER_CONSTANT,
} from '../../../Enum/RouterConstant/RouterConstant';
import HeaderPage from '../../Organisms/Header/HeaderPage/HeaderPage';
import NotificationEditForm from '../../Organisms/Form/NotificationForm/NotificationEditForm';

const EditNotificationPage = props => {
  const selectedNotification = useSelector(state => state.notification.selectedNotification)
  
  return (
    <Div>
      <HeaderPage
        title={props.t("COMMON:EDIT_NOTIFICATION")}
        listBreadcrumb={notificationAfterListBreadcrumb(props.t)}
        defaultBreadcrumb={props.t("NOTIFICATION:TITLE_EDIT")}
        toTitlePageto={ROUTER_CONSTANT.NOTIFICATION.LIST}
      />
      
      <NotificationEditForm
        t={props.t}
        data={selectedNotification}
        validToken={props.validToken}
        dispatch={props.dispatch}
        notify={props.notify}
        navigate={props.navigate}
      />
    </Div>
  );
};

export default EditNotificationPage;
