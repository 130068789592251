export const companyProfileColumn  = t => [
  {
    name:  t("COMMON:FIELD.DATE_TIME"),
    selector: "created_at",
    key: "created_at",
    sortable: true,
    width: "20vw",
  },
  {
    name:  t("COMMON:FIELD.COMPANY_NAME"),
    selector: "name",
    key: "name",
    width: "25vw",
  },
  {
    name:  t("COMMON:FIELD.COMPANY_ID"),
    selector: "company_profile_id",
    key: "company_profile_id",
    
    width: "10vw",
  },
  {
    name:  t("COMMON:FIELD.NPWP"),
    selector: "npwp",
    key: "npwp",
    width: "25vw",
  },
  {
    name:  t("COMMON:FIELD.RESOURCE"),
    selector: "resource",
    key: "resource",
    width: "20vw",
  },
];
