import {
  isStatus,
  isStatusErr,
} from '../../Utils/Response/Response';
import { PRODUCT_GROUP_URL } from '../EndPoint';
import * as BaseAPI from './BaseAPIs';

export const postProductGroupCountData = (token, notify, dispatch, action) =>
  BaseAPI.doPost(PRODUCT_GROUP_URL.COUNT, token, null, notify, dispatch, res => action(res));

export const postProductGroupList = (token, page, limit, sortDate,notify,dispatch,action) =>
  BaseAPI.doPost(PRODUCT_GROUP_URL.LIST(page, limit, sortDate), token, null,notify,dispatch,(res)=>action(res));


export const postRejectProductGroupList = (token, data,notify,dispatch) => 
BaseAPI.doPost(PRODUCT_GROUP_URL.REJECT, token, data,notify,dispatch,
  res=>isStatus(res),err=>isStatusErr(err))

export const getDetailProductGroup = (token, id,notify,dispatch,action,errAction) => 
  BaseAPI.doGet(PRODUCT_GROUP_URL.DETAIL + id, token, null,null,notify,dispatch,
  res=>action(res),err=>errAction(err));

export const getDetailExistingProductGroup = (token, id,notify,dispatch,action,errAction) => 
  BaseAPI.doGet(PRODUCT_GROUP_URL.DETAIL_EXISTING + id, token, null,null,notify,dispatch,
  res=>action(res),err=>errAction(err));
  
export const postSearchProductGroupList = (token, data,dispatch, notify,action,errAction) => {
  return BaseAPI.doPost(PRODUCT_GROUP_URL.SEARCH, token, data ,notify,dispatch,
    (res)=>action(res),err=>errAction(err));
};
export const postApproveMappingProductGroup = (token, data,notify,dispatch,action,errAction) => {
  return BaseAPI.doPost(PRODUCT_GROUP_URL.MAPPING_APPROVE,token, data,notify,dispatch,
      (res)=>action(res),err=>errAction(err));
};
export const postProductGroupSearchCountData = (token,data,notify,dispatch,action) =>  
  BaseAPI.doPost(PRODUCT_GROUP_URL.COUNT_SEARCH, token, data,notify,dispatch,(res)=>action(res));


// Principal
export const postProductGroupCountPrincipalData = (token, notify, dispatch, action) =>
  BaseAPI.doPost(PRODUCT_GROUP_URL.PRINCIPAL.COUNT, token, null, notify, dispatch, res => action(res));

export const postProductGroupPrincipalList = (token, page, limit, sortDate,notify,dispatch,action) =>
  BaseAPI.doPost(PRODUCT_GROUP_URL.PRINCIPAL.LIST(page, limit, sortDate), token, null,notify,dispatch,(res)=>action(res));


export const postRejectProductGroupPrincipalList = (token, data,notify,dispatch) => 
BaseAPI.doPost(PRODUCT_GROUP_URL.PRINCIPAL.REJECT, token, data,notify,dispatch,
  res=>isStatus(res),err=>isStatusErr(err))

export const getDetailProductGroupPrincipal = (token, id,notify,dispatch,action,errAction) => 
  BaseAPI.doGet(PRODUCT_GROUP_URL.PRINCIPAL.DETAIL + id, token, null,null,notify,dispatch,
  res=>action(res),err=>errAction(err));

export const getDetailExistingProductGroupPrincipal = (token, id,notify,dispatch,action,errAction) => 
  BaseAPI.doGet(PRODUCT_GROUP_URL.PRINCIPAL.DETAIL_EXISTING + id, token, null,null,notify,dispatch,
  res=>action(res),err=>errAction(err));
  
export const postSearchProductGroupPrincipalList = (token, data,dispatch, notify,action,errAction) => {
  return BaseAPI.doPost(PRODUCT_GROUP_URL.PRINCIPAL.SEARCH, token, data ,notify,dispatch,
    (res)=>action(res),err=>errAction(err));
};
export const postApproveMappingProductGroupPrincipal = (token, data,notify,dispatch,action,errAction) => {
  return BaseAPI.doPost(PRODUCT_GROUP_URL.PRINCIPAL.MAPPING_APPROVE,token, data,notify,dispatch,
      (res)=>action(res),err=>errAction(err));
};
export const postProductGroupSearchCountPrincipalData = (token,data,notify,dispatch,action) =>  
  BaseAPI.doPost(PRODUCT_GROUP_URL.PRINCIPAL.COUNT_SEARCH, token, data,notify,dispatch,(res)=>action(res));