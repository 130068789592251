import React from 'react';

import { Div } from 'nexcomponent-ui';

import {
  principalProductUserDefinedCategorySearchBreadCrumb,
} from '../../../../Enum/Breadcrumb/PrincipalProductUserDefinedCategoryBreadcrumb';
import {
  searchPrincipalProductUserDefinedCategoryColumn,
} from '../../../../Enum/Column/SearchPrincipalProductUserDefinedCategoryColumn';
import { MAPPING_CODE } from '../../../../Enum/MappingCode/MappingCode';
import {
  ROUTER_CONSTANT,
} from '../../../../Enum/RouterConstant/RouterConstant';
import {
  fetchDataPrincipalProductUserDefinedCategorySearchList,
} from '../../../../Fetch/FetchData/PrincipalProductUserDefinedCategoryData';
import FieldPPUDCSearchForm
  from '../../../Organisms/Form/SearchForm/FieldPPUDCSearchForm/FieldPPUDCSearchForm';
import SearchForm
  from '../../../Organisms/Form/SearchForm/SearchForm/SearchForm';
import HeaderPage from '../../../Organisms/Header/HeaderPage/HeaderPage';

function PrincipalProductUserDefinedCategorySearchPage(props) {
  return (
    <Div>
      <HeaderPage
        title={props.t("PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY:SEARCH.TITLE")}
        listBreadcrumb={principalProductUserDefinedCategorySearchBreadCrumb(props.t)}
        defaultBreadcrumb={props.t("PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY:SEARCH.TITLE")}
        toTitlePage={ROUTER_CONSTANT.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY.MAPPING}
      />
      <SearchForm
        title="PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY:SEARCH.TITLE"
        field={<FieldPPUDCSearchForm t={props.t} dispatch={props.dispatch} />}
        t={props.t}
        dispatch={props.dispatch}
        mappingCode={MAPPING_CODE.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY}
        navigate={props.navigate}
        advance={false}
        columnDefault={searchPrincipalProductUserDefinedCategoryColumn(props.t)}
        backNavigate={ROUTER_CONSTANT.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY.MAPPING}
        searchFunc={(currentPage, rowLimit,filter, column,dispatch) =>
          fetchDataPrincipalProductUserDefinedCategorySearchList(
            props.validToken(),
            currentPage,
            rowLimit,
            filter,
            column,
            dispatch,
            props.notify
          )
        }
      />
    </Div>
  );
}

export default PrincipalProductUserDefinedCategorySearchPage;
