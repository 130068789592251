import React from 'react';

import { CheckBox } from 'nexcomponent-ui';

import { CHECKBOX } from '../../../../Enum/IdSet';

function CheckBoxMapping(props) {
  return (
    <CheckBox
      id={CHECKBOX.Mapping + props.id}
      size={props.size}
      label={props.label}
      name={props.name}
      type="checkbox"
      onChange={props.onCheck}
      checked={props.checked}
      disabled={props.disabled}
      required={props.required}
    />
  );
}

export default CheckBoxMapping;
CheckBoxMapping.defaultProps = {
  id: "",
};
