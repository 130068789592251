import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import { configureStore } from '@reduxjs/toolkit';

import reducers from './Reducers';

const persistConfig = {
  key: "root",
  storage,
  expiration: {
    default: 12000,
  },
  whitelist: [
    "authentication",
    "broadcast",
    "reject",
    "mapping",
    "dashboard",
    "searchMapping",
    "notification"
  ],
};
const persistedReducer = persistReducer(persistConfig, reducers);

//Configuration Store
const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export default store;
