import {
  isStatus,
  isStatusErr,
} from '../../Utils/Response/Response';
import { PRODUCT_CATEGORY_URL } from '../EndPoint';
import * as BaseAPI from './BaseAPIs';

export const postProductCategoryCountData = (token, notify, dispatch, action) =>
  BaseAPI.doPost(PRODUCT_CATEGORY_URL.COUNT, token, null, notify, dispatch, res => action(res));


export const postProductCategoryList =  (token, page, limit, sortDate,notify,dispatch,action) =>
  BaseAPI.doPost(PRODUCT_CATEGORY_URL.LIST(page, limit, sortDate), token, null,notify,dispatch,(res)=>action(res));


export const postRejectProductCategoryList = (token, data,notify,dispatch) => 
BaseAPI.doPost(PRODUCT_CATEGORY_URL.REJECT, token, data,notify,dispatch,
  res=>isStatus(res),err=>isStatusErr(err))

export const getDetailProductCategory = (token, id,notify,dispatch,action,errAction) => 
  BaseAPI.doGet(PRODUCT_CATEGORY_URL.DETAIL + id, token, null,null,notify,dispatch,
  res=>action(res),err=>errAction(err));

export const getDetailExistingProductCategory = (token, id,notify,dispatch,action,errAction) => 
  BaseAPI.doGet(PRODUCT_CATEGORY_URL.DETAIL_EXISTING + id, token, null,null,notify,dispatch,
  res=>action(res),err=>errAction(err));

export const postSearchProductCategoryList = (token, data,dispatch, notify,action,errAction) => {
  return BaseAPI.doPost(PRODUCT_CATEGORY_URL.SEARCH,  token, data ,notify,dispatch,
    (res)=>action(res),err=>errAction(err));
};
export const postApproveMappingProductCategory = (token, data,notify,dispatch,action,errAction) => {
  return BaseAPI.doPost(PRODUCT_CATEGORY_URL.MAPPING_APPROVE, token, data,notify,dispatch,
      (res)=>action(res),err=>errAction(err));
};
export const postProductCategorySearchCountData = (token,data,notify,dispatch,action) =>  
  BaseAPI.doPost(PRODUCT_CATEGORY_URL.COUNT_SEARCH, token, data,notify,dispatch,(res)=>action(res));