import React, { useCallback, useMemo } from 'react';

import { Div } from 'nexcomponent-ui';

import {
  productBrandPrinciaplSearchBreadCrumb,
  productBrandSearchBreadCrumb,
} from '../../../../Enum/Breadcrumb/ProductBrandBreadcrumb';
import {
  searchProductBrandColumn,
} from '../../../../Enum/Column/SearchProductBrandColumn';
import { MAPPING_CODE } from '../../../../Enum/MappingCode/MappingCode';
import {
  ROUTER_CONSTANT,
} from '../../../../Enum/RouterConstant/RouterConstant';
import {
  fetchDataProductBrandSearchList,
} from '../../../../Fetch/FetchData/ProductBrandData';
import FieldProductBrandSearchForm
  from '../../../Organisms/Form/SearchForm/FieldProductBrandSearchForm/FieldProductBrandSearchForm';
import SearchForm
  from '../../../Organisms/Form/SearchForm/SearchForm/SearchForm';
import HeaderPage from '../../../Organisms/Header/HeaderPage/HeaderPage';
import useQueryRoute from '../../../../Hooks/useQueryRoute';
import { fetchDataProductBrandPrincipalSearchList } from '../../../../Fetch/FetchData/ProductBrandPrincipalData';
import FieldProductPrincipalSearchForm from '../../../Organisms/Form/SearchForm/FieldProductPrincipalSearchForm/FieldProductPrincipalSearchForm';
import { searchProductBrandPrincipalColumn } from '../../../../Enum/Column/SearchProductBrandPrincipalColumn';
import FieldProductBrandPrincipalSearchForm from '../../../Organisms/Form/SearchForm/FieldProductBrandPrincipalSearchForm/FieldProductBrandPrincipalSearchForm';

const ProductBrandSearchPage =(props)=> {
  const { principal } = useQueryRoute();

  const searchFunc=useCallback(
    (currentPage, rowLimit,filter, column,dispatch) =>{
      if(principal){
        return fetchDataProductBrandPrincipalSearchList(
          props.validToken(),
          currentPage,
          rowLimit,
          filter,
          column,
          dispatch,
          props.notify
        )}else{
          return fetchDataProductBrandSearchList(
            props.validToken(),
            currentPage,
            rowLimit,
            filter,
            column,
            dispatch,
            props.notify
            )
          }
        }
        ,[principal, props]
  )

  const field =useMemo(()=>{
    if(principal)
      return <FieldProductBrandPrincipalSearchForm  t={props.t} dispatch={props.dispatch} />
    else
    return <FieldProductBrandSearchForm t={props.t} dispatch={props.dispatch} />
  },[principal, props])


  const columnDefault =useMemo(()=>{
    if(principal){
      return searchProductBrandPrincipalColumn(props.t)
    }else{
      return searchProductBrandColumn(props.t)
    }
  },[principal, props.t])

  const listBreadcrumb=useMemo(()=>{
    if(principal){
      return productBrandPrinciaplSearchBreadCrumb(props.t)
    }else{
      return productBrandSearchBreadCrumb(props.t)
    }
  },[principal, props.t])
  
  return (
    <Div>
      <HeaderPage
        title={props.t("PRODUCT_BRAND:SEARCH.TITLE")}
        listBreadcrumb={listBreadcrumb}
        defaultBreadcrumb={props.t("PRODUCT_BRAND:SEARCH.TITLE")}
        toTitlePage={ROUTER_CONSTANT.PRODUCT_BRAND.MAPPING}
      />
      <SearchForm
        title="PRODUCT_BRAND:SEARCH.TITLE"
        field={field}
        t={props.t}
        dispatch={props.dispatch}
        mappingCode={MAPPING_CODE.PRODUCT_BRAND}
        navigate={props.navigate}
        advance={false}
        columnDefault={columnDefault}
        backNavigate={`${ROUTER_CONSTANT.PRODUCT_BRAND.PRINCIPAL.MAPPING}`}
        searchFunc={searchFunc}
      />
    </Div>
  );
}

export default ProductBrandSearchPage;
