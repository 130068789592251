import { useState } from "react";

import { useSelector } from "react-redux";

import FieldComparison from "../../../../../../Components/Molecules/Field/FieldComparison/FieldComparison";
import FieldGroupComparison from "../../../../../../Components/Molecules/Field/FieldGroupComparison/FieldGroupComparison";
import { defaultLimitSearch } from "../../../../../../Enum/Limit/SearchAdvance";
import { REGEX_CONSTANT } from "../../../../../../Enum/RegexConstant";
import { postPrincipal } from "../../../../../../Fetch/Api/SearchMappingAPI";
import { fetchPrincipal } from "../../../../../../Fetch/FetchData/SearchMappingData";
import { searchMappingActions } from "../../../../../../Store/Reducers/SearchMappingReducer";
import {
  DDSonChange,
  DDSOnClick,
  DDSOnScroll,
} from "../../../../../../Utils/Action/DropDownSearch/DropDownSearch";
import { fieldRequired } from "../../../../../../Utils/Action/Field/FieldRequired";
import { IgnorePrincipal } from "../../../../../../Utils/Action/Field/IgnorePrincipal";

const FieldProductBrandPrincipalMappingForm = (props) => {
  const { t } = props;
  const principal = useSelector((state) => state.searchMapping.principal);
  const [filter, setFilter] = useState();
  const [limitation, setLimitation] = useState(defaultLimitSearch);
  const clickSelect = (fetch) => {
    DDSOnClick(fetch, setLimitation, filter, props.validToken, props.dispatch, props.notify);
  };
  const onScroll = (fetch, prevList) => {
    DDSOnScroll(
      fetch,
      prevList,
      setLimitation,
      limitation,
      filter,
      props.validToken,
      props.dispatch,
      props.notify
    );
  };
  const onChange = async (e, type, fetch, setOption) => {
    DDSonChange(
      e,
      type,
      props.validToken,
      fetch,
      setLimitation,
      setFilter,
      setOption,
      filter,
      props.notify,
      props.dispatch
    );
  };
  
  const handleRequired = (key) =>
    fieldRequired(
      props.value(props.mappingForm, "principal_product_brand_id", "exist"),
      props.value(props.mappingForm, key, "isChecked")
    );

  return (
    <>
      <FieldComparison
        label={t("COMMON:FIELD.PRODUCT_BRAND_ID")}
        placeholder={t("COMMON:PLACEHOLDER", {
          field: t("COMMON:FIELD.principal_product_brand_id"),
        })}
        typeNew="number"
        typeExist="number"
        nameNew="principal_product_brand_id"
        valueNew={props.value(props.mappingForm, "principal_product_brand_id", "new")}
        nameExist="principal_product_brand_id_exist"
        valueExist={props.value(props.mappingForm, "principal_product_brand_id", "exist")}
        checked={
          props.value(props.mappingForm, "principal_product_brand_id", "exist") !== null
            ? false
            : props.value(props.mappingForm, "principal_product_brand_id", "isChecked")
        }
        disabledCheck={
          props.value(props.mappingForm, "principal_product_brand_id", "exist") !== null
            ? true
            : false
        }
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        disabled={true}
      />
      <FieldComparison
        label={t("COMMON:FIELD.PRODUCT_BRAND_CODE")}
        placeholder={t("COMMON:PLACEHOLDER", { field: t("COMMON:FIELD.PRODUCT_BRAND_CODE") })}
        typeNew="text"
        typeExist="text"
        nameNew="code"
        valueNew={props.value(props.mappingForm, "code", "new")}
        nameExist="code_id_exist"
        valueExist={props.value(props.mappingForm, "code", "exist")}
        checked={props.value(props.mappingForm, "code", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        pattern={REGEX_CONSTANT.NO_SPACE}
        message={t("COMMON:ERROR.NO_SPACE_SPECIAL", {
          field: t("COMMON:FIELD.PRODUCT_BRAND_CODE"),
        })}
        required={handleRequired("code")}
      />

      <FieldComparison
        label={t("COMMON:FIELD.PRODUCT_BRAND_NAME")}
        placeholder={t("COMMON:PLACEHOLDER", { field: t("COMMON:FIELD.PRODUCT_BRAND_NAME") })}
        typeNew="text"
        typeExist="text"
        nameNew="name"
        valueNew={props.value(props.mappingForm, "name", "new")}
        nameExist="name_id_exist"
        valueExist={props.value(props.mappingForm, "name", "exist")}
        checked={props.value(props.mappingForm, "name", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        required={handleRequired("name")}
      />
      <FieldGroupComparison
        label={t("PRODUCT_BRAND:FIELD.PRINCIPAL")}
        t={t}
        detailDefault={props.detail}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        submitMapping={props.submitMapping}
        option={principal}
        mainKey="principal_id"
        secondKey="principal_name"
        thirdKey="principal_code"
        typeFirst="number"
        typeSecond="text"
        typeThird="text"
        onChange={(e, type) => onChange(e, type, postPrincipal, searchMappingActions.setPrincipal)}
        onClick={() => clickSelect(fetchPrincipal)}
        onScroll={() => onScroll(fetchPrincipal, principal)}
        subLabelOne={"PRODUCT_BRAND:FIELD.PRINCIPAL_ID"}
        subLabelTwo={"PRODUCT_BRAND:FIELD.PRINCIPAL_NAME"}
        subLabelThree={"PRODUCT_BRAND:FIELD.PRINCIPAL_CODE"}
        placeholderThird={t("COMMON:SELECT", { field: t("COMMON:FIELD.PRINCIPAL_CODE") })}
        placeholderSecond={t("COMMON:SELECT", { field: t("COMMON:FIELD.PRINCIPAL_NAME") })}
        placeholderFirst={t("COMMON:SELECT", { field: t("COMMON:FIELD.PRINCIPAL_ID") })}
        valueNew={(key, item) => props.value(props.mappingForm, key, item)}
        onCheck={(e) => props.checkedPartial(e)}
        disabled={IgnorePrincipal(
          props.value(props.mappingForm, "principal_product_brand_id", "exist")
        )}
        disabledCheck={IgnorePrincipal(
          props.value(props.mappingForm, "principal_product_brand_id", "exist")
        )}
        dispatch={props.dispatch}
        required={props.value(props.mappingForm, "principal_id", "exist") !== null ? false : true}
      />
    </>
  );
};

export default FieldProductBrandPrincipalMappingForm;
