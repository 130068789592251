import { addArrFilter } from '../../Filter/AddArrFilter';
import { itemFilter } from '../../Filter/ItemFilter';

export const valueFilter=(form)=>{
  var multiValue =  [];
  addArrFilter(itemFilter(form.admin), multiValue);
  addArrFilter(itemFilter(form.mapping_type), multiValue);
  addArrFilter(itemFilter(form.resource), multiValue);
  addArrFilter(itemFilter(form.dateRange), multiValue);
  let stringvalue =  "";
  stringvalue = multiValue.join(",+");
  return stringvalue
}