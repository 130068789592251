export const tableMappingByTypeStateGroup = state => {
  return [
    state.dashboard.initiate,
    state.utils.checkedArray,
    state.utils.actionType,
    state.dashboard.totalRow,
    state.mapping.countData,
    state.mapping.list,
    state.utils.currentPage,
    state.utils.loading,
    state.utils.rowLimit,
    state.utils.sortDateAsc,
  ];
};
export const tableSearchMappingByTypeStateGroup = state => {
  return [
    state.dashboard.totalRow,
    state.mapping.countData,
    state.utils.currentPage,
    state.utils.rowLimit,
   
  ];
};