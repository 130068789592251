export const searchProductKeyAccountColumn =(t)=> [
  // {
  //   name: t("COMMON:FIELD.RESOURCE"),
  //   selector: "resources",
  //   width: "100px",
  // },
  {
    name: t("PRODUCT_KEY_ACCOUNT:FIELD.ID"),
    selector: "product_key_account_id",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.PRINCIPAL_CODE"),
    selector: "principal_code",
    width: "200px",
  },
  {
    name: t("COMMON:FIELD.PRINCIPAL_NAME"),
    selector: "principal_name",
    width: "200px",
  },
  {
    name: t("COMMON:FIELD.PRINCIPAL_ID"),
    selector: "principal_id",
    
    
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.CODE"),
    selector: "code",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.NAME"),
    selector: "name",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.LEVEL"),
    selector: "level",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.PARENT_CODE"),
    selector: "parent_code",
    width: "100px",
  },{
    name: t("COMMON:FIELD.PARENT_NAME"),
    selector: "parent_name",
    width: "100px",
  },{
    name: t("COMMON:FIELD.PARENT_ID"),
    selector: "parent_id",
    
    
    width: "100px",
  },
]