import React from 'react';

import { Col } from 'nexcomponent-ui';
import { useSelector } from 'react-redux';

import { searchFormData } from '../../../../../Utils/Form';
import style from '../FieldSearch/Field.module.css';
import FieldSearch from '../FieldSearch/FieldSearch';

function FieldPersonProfileSearchForm(props) {
  const {t} = props;
  const searchData = useSelector(state => state.utils.searchData);
  
  const formData = e => {
    searchFormData(e, searchData, props.dispatch);
  };

  return (
    <>
      <Col className={style.Col} size="lg" width={6}>
        <FieldSearch
          className={style.InputLeft}
          label={t("COMMON:FIELD.PERSON_PROFILE_ID")}
          type="number"
          name="person_profile_id"
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PERSON_PROFILE_ID")})}
          onChange={formData}
        />
        <FieldSearch
          className={style.InputLeft}
          label={t("COMMON:FIELD.CLIENT_ID")}
          type={"text"}
          name={"client_id"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.CLIENT_ID")})}
          onChange={formData}
        />
        <FieldSearch
          className={style.InputLeft}
          type={"text"}
          name={"first_name"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.FIRST_NAME")})}
          onChange={formData}
          label={t("COMMON:FIELD.FIRST_NAME")}
        />
        <FieldSearch
          className={style.InputLeft}
          type={"text"}
          name={"last_name"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.LAST_NAME")})}
          onChange={formData}
          label={t("COMMON:FIELD.LAST_NAME")}
        />
        <FieldSearch
          className={style.InputLeft}
          type={"text"}
          name={"nik"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.NIK")})}
          onChange={formData}
          label={t("COMMON:FIELD.NIK")}
        />
      </Col>
      <Col className={style.Col} size="lg" width={6}>
        <FieldSearch
          className={style.InputRight}
          type={"text"}
          name={"npwp"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.NPWP")})}
          onChange={formData}
          label={t("COMMON:FIELD.NPWP")}
        />
        <FieldSearch
          className={style.InputRight}
          type={"text"}
          name={"phone"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PHONE")})}
          onChange={formData}
          label={t("COMMON:FIELD.PHONE")}
        />
        <FieldSearch
          className={style.InputRight}
          type={"text"}
          name={"alternative_phone"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.ALTERNATIVE_PHONE")})}
          onChange={formData}
          label={t("PERSON_PROFILE:FIELD_ALTERNATIVE_PHONE")}
        />
        <FieldSearch
          className={style.InputRight}
          type={"text"}
          name={"address_1"}
          onChange={formData}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.ADDRESS1")})}
          label={t("COMMON:FIELD.ADDRESS1")}
        />
        <FieldSearch
          className={style.InputRight}
          type={"email"}
          name={"email"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.EMAIL")})}
          onChange={formData}
          label={t("COMMON:FIELD.EMAIL")}
        />
      </Col>
    </>
  );
}

export default FieldPersonProfileSearchForm;
