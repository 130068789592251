export const isContent =(res)=>{const {content}= res.data.nexsoft.payload.data
return content} 
export const isContentErr =(res)=>{const {content}= res.response.data.nexsoft.payload.data
return content} 
export const isAuthorize =(res)=>{const {authorization}= res.headers
return authorization}
export const isStatus =(res)=>{const {status}= res.data.nexsoft.payload
return status}
export const isStatusErr =(res)=>{const {status}= res.response.data.nexsoft.payload
return status}

export const isContentNotification = (res) => {
    const {status} = res.data.nexsoft.payload
    return status
}

export const isContentNotificationErr = (res) => {
    const {status} = res.response.data.nexsoft.payload
    return status
}
