export const notificationStateGroup = state => {
    return [
        state.notification.totalRow,
        state.notification.validLimit,
        state.notification.notificationList,
        state.utils.loading,
        state.utils.currentPage,
        state.utils.sortDateAsc,
        state.utils.rowLimit
    ]
}
  