import moment from 'moment';

export function dateFormater(date) {
  if (date === "" || date === null) {
    return "";
  } else {
    return moment(new Date(date)).format("YYYY-MM-DD");
  }
}
export function dateFormater2(date) {
  return moment(new Date(date)).format("DD/MMM/YYYY");
}
export function dateFormater3(date) {
  if (date === "") {
    return null;
  }
  if (date !== null) {
    return moment(new Date(date)).format("DD/MM/YYYY");
  }
  return "";
}
export function dateTimeFormat(date) {
  if (date === "") {
    return null;
  } else if (date !== null) {
    return moment(date).format("YYYY-MM-DDTHH:mm:ss[Z]");
  } else {
    return null;
  }
}
export function dateFormat(date) {
  if (date === "") {
    return "";
  } else if (date !== null) {
    return moment(date).format("DD/MM/yyyy");
  } else {
    return "";
  }
}
export function dateFormatPlus1(date) {
  if (date === "") {
    return "";
  } else if (date !== null) {
    return moment(date).subtract(1, "days").format("DD/MM/yyyy");
  } else {
    return "";
  }
}
export const dateFormatRange = date =>
  date.startDate === null && date.endDate === null
    ? ""
    : `${dateFormat(date.startDate)}-${dateFormatPlus1(date.endDate)}`;
