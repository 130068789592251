export const ROUTER_CONSTANT = Object.freeze({
  UNIVERSAL: `*`,
  INDEX: `/`,
  LOGIN: `/`,
  FORBIDDEN: `/forbidden`,
  DASHBOARD: `/dashboard`,
  BROADCAST: `/broadcast`,
  REJECT: `/reject`,
  FORGET_PASSWORD: `/forget/password`,
  RESET_PASSWORD: `/reset/password`,

  BANK: {
    LIST: `/bank`,
    DETAIL: `/bank/detail`,
    ADD: `/bank/add`,
    EDIT: `/bank/edit`,
  },

  BRAND: {
    LIST: `/brand`,
    DETAIL: `/brand/detail`,
    ADD: `/brand/add`,
    EDIT: `/brand/edit`,
  },

  BRAND_OWNER: {
    LIST: `/brandowner`,
    DETAIL: `/brandowner/detail`,
    ADD: `/brandowner/add`,
    EDIT: `/brandowner/edit`,
  },

  COMPANY_PROFILE: {
    LIST: `/companyprofile`,
    DETAIL: `/companyprofile/detail`,
    ADD: `/companyprofile/add`,
    EDIT: `/companyprofile/edit`,
    REJECT_REASON: `/companyprofile/rejectreason`,
    MAPPING: `/companyprofile/mapping`,
    SEARCH: `/companyprofile/search`,
  },

  COMPANY_TITLE: {
    LIST: `/companytitle`,
    DETAIL: `/companytitle/detail`,
    ADD: `/companytitle/add`,
    EDIT: `/companytitle/edit`,
  },

  CONTACT_PERSON: {
    LIST: `/contactperson`,
    DETAIL: `/contactperson/detail`,
    ADD: `/contactperson/add`,
    EDIT: `/contactperson/edit`,
  },

  COUNTRY: {
    LIST: `/country`,
    DETAIL: `/country/detail`,
    ADD: `/country/add`,
    EDIT: `/country/edit`,
  },

  DISTRICT: {
    LIST: `/district`,
    DETAIL: `/district/detail`,
    ADD: `/district/add`,
    EDIT: `/district/edit`,
  },
  DIVISION: {
    LIST: `/division`,
    DETAIL: `/division/detail`,
    ADD: `/division/add`,
    EDIT: `/division/edit`,
    REJECT_REASON: `/division/rejectreason`,
    MAPPING: `/division/mapping`,
    SEARCH: `/division/search`,
  },

  GROUP_OF_DISTRIBUTOR: {
    LIST: `/groupdistributor`,
    DETAIL: `/groupdistributor/detail`,
    ADD: `/groupdistributor/add`,
    EDIT: `/groupdistributor/edit`,
  },

  ISLAND: {
    LIST: `/island`,
    DETAIL: `/island/detail`,
    ADD: `/island/add`,
    EDIT: `/island/edit`,
  },

  PERSON_PROFILE: {
    LIST: `/personprofile`,
    DETAIL: `/personprofile/detail`,
    ADD: `/personprofile/add`,
    EDIT: `/personprofile/edit`,
    REJECT_REASON: `/personprofile/rejectreason`,
    MAPPING: `/personprofile/mapping`,
    SEARCH: `/personprofile/search`,
  },

  PERSON_TITLE: {
    LIST: `/persontitle`,
    DETAIL: `/persontitle/detail`,
    ADD: `/persontitle/add`,
    EDIT: `/persontitle/edit`,
  },

  POSITION: {
    LIST: `/position`,
    DETAIL: `/position/detail`,
    ADD: `/position/add`,
    EDIT: `/position/edit`,
  },

  POSTAL_CODE: {
    LIST: `/postalcode`,
    DETAIL: `/postalcode/detail`,
    ADD: `/postalcode/add`,
    EDIT: `/postalcode/edit`,
  },

  PRINCIPAL: {
    LIST: `/principal`,
    DETAIL: `/detail`,
    ADD: `/add`,
    EDIT: `/edit`,
  },

  PROVINCE: {
    LIST: `/province`,
    DETAIL: `/province/detail`,
    ADD: `/province/add`,
    EDIT: `/province/edit`,
  },

  SUB_DISTRICT: {
    LIST: `/subdistrict`,
    DETAIL: `/subdistrict/detail`,
    ADD: `/subdistrict/add`,
    EDIT: `/subdistrict/edit`,
  },

  URBAN_VILLAGE: {
    LIST: `/urbanvillage`,
    DETAIL: `/urbanvillage/detail`,
    ADD: `/urbanvillage/add`,
    EDIT: `/urbanvillage/edit`,
  },

  VENDOR_CHANNEL: {
    LIST: `/vendorchannel`,
    DETAIL: `/vendorchannel/detail`,
    ADD: `/vendorchannel/add`,
    EDIT: `/vendorchannel/edit`,
  },

  PRODUCT_GROUP_HIERARCHY: {
    LIST: `/productgroup`,
    DETAIL: `/productgroup/detail`,
    ADD: `/productgroup/add`,
    EDIT: `/productgroup/edit`,
    REJECT_REASON: `/productgroup/rejectreason`,
    MAPPING: `/productgroup/mapping`,
    SEARCH: `/productgroup/search`,
    PRINCIPAL: {
      LIST: `/principal/productgroup`,
      REJECT_REASON: `/principal/productgroup/rejectreason`,
      MAPPING: `/principal/productgroup/mapping`,
      SEARCH: `/principal/productgroup/search`,
    },
  },

  PRODUCT_CATEGORY: {
    LIST: `/productcategory`,
    DETAIL: `/productcategory/detail`,
    ADD: `/productcategory/add`,
    EDIT: `/productcategory/edit`,
    REJECT_REASON: `/productcategory/rejectreason`,
    MAPPING: `/productcategory/mapping`,
    SEARCH: `/productcategory/search`,
  },

  PRODUCT_BRAND: {
    LIST: `/productbrand`,
    DETAIL: `/productbrand/detail`,
    ADD: `/productbrand/add`,
    EDIT: `/productbrand/edit`,
    REJECT_REASON: `/productbrand/rejectreason`,
    MAPPING: `/productbrand/mapping`,
    SEARCH: `/productbrand/search`,
    PRINCIPAL: {
      LIST: `/principal/productbrand`,
      SEARCH: `/principal/productbrand/search`,
      REJECT_REASON: `/principal/productbrand/rejectreason`,
      MAPPING: `/principal/productbrand/mapping`,
    },
  },

  PRODUCT_KEY_ACCOUNT: {
    LIST: `/productkeyaccount`,
    DETAIL: `/productkeyaccount/detail`,
    ADD: `/productkeyaccount/add`,
    EDIT: `/productkeyaccount/edit`,
    REJECT_REASON: `/productkeyaccount/rejectreason`,
    MAPPING: `/productkeyaccount/mapping`,
    SEARCH: `/productkeyaccount/search`,
  },

  PRODUCT: {
    LIST: `/product`,
    DETAIL: `/product/detail`,
    ADD: `/product/add`,
    EDIT: `/product/edit`,
    REJECT_REASON: `/product/rejectreason`,
    MAPPING: `/product/mapping`,
    SEARCH: `/product/search`,
    PRINCIPAL: {
      LIST: `/principal/product`,
      SEARCH: `/principal/product/search`,
      REJECT_REASON: `/principal/product/rejectreason`,
      MAPPING: `/principal/product/mapping`,
    },
  },

  PRODUCT_HISTORY: {
    LIST: `/producthistory`,
    DETAIL: `/producthistory/detail`,
    ADD: `/producthistory/add`,
    EDIT: `/producthistory/edit`,
  },

  ROLE: {
    LIST: `/role`,
    DETAIL: `/role/detail`,
    ADD: `/role/add`,
    EDIT: `/role/edit`,
  },

  DATA_GROUP: {
    LIST: `/datagroup`,
    DETAIL: `/datagroup/detail`,
    ADD: `/datagroup/add`,
    EDIT: `/datagroup/edit`,
  },

  DATA_SCOPE: {
    LIST: `/datascope`,
    DETAIL: `/datascope/detail`,
    ADD: `/datascope/add`,
    EDIT: `/datascope/edit`,
  },

  USER: {
    LIST: `/user`,
    DETAIL: `/user/detail`,
    ADD: `/user/add`,
    EDIT: `/user/edit`,
    VERIFY: `/user/verify`,
    PASSWORD: `/user/password`,
    OWN_PASSWORD: `/user/own-password`,
    ADD_RESOURCE: `/user/add-resource`,
    CHECK_USER: `/user/check-user`,
    EDIT_RESOURCE: `/user/edit-resource`,
  },

  DATA_SYNCHRONIZATION: `/synchronize/elastic`,

  JOB_PROCESS: {
    LIST: `/monitoring/jobprocess`,
    DETAIL: `/monitoring/jobprocess/detail`,
  },

  AUDIT: {
    LIST: `/monitoring/audit`,
    DETAIL: `/monitoring/audit/detail`,
  },

  COMPANY_USER_DEFINED_CATEGORY: {
    LIST: `/companyuserdefinedcategory`,
    DETAIL: `/companyuserdefinedcategory/detail`,
    ADD: `/companyuserdefinedcategory/add`,
    EDIT: `/companyuserdefinedcategory/edit`,
  },

  PRODUCT_USER_DEFINED_CATEGORY: {
    LIST: `/productuserdefinedcategory`,
    DETAIL: `/productuserdefinedcategory/detail`,
    ADD: `/productuserdefinedcategory/add`,
    EDIT: `/productuserdefinedcategory/edit`,
  },

  PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY: {
    LIST: `/principalproductuserdefinedcategory`,
    DETAIL: `/principalproductuserdefinedcategory/detail`,
    ADD: `/principalproductuserdefinedcategory/add`,
    EDIT: `/principalproductuserdefinedcategory/edit`,
    REJECT_REASON: `/principalproductuserdefinedcategory/rejectreason`,
    MAPPING: `/principalproductuserdefinedcategory/mapping`,
    SEARCH: `/principalproductuserdefinedcategory/search`,
  },

  PARAMETER: {
    LIST: `/parameter`,
    DETAIL: `/parameter/detail`,
    ADD: `/parameter/add`,
    EDIT: `/parameter/edit`,
  },

  PARAMETER_VALUE: {
    LIST: `/parametervalue`,
    DETAIL: `/parametervalue/detail`,
    ADD: `/parametervalue/add`,
    EDIT: `/parametervalue/edit`,
  },

  MOCK_URL: {
    MAPPING_LIST: `/mapping/list`,
  },

  IMPORT: `/import`,

  NOTIFICATION: {
    LIST: `/notification`,
    CREATE: `/notification/create`,
    EDIT: `/notification/edit`,
  },
});
