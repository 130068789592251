import {
  productBrandPrincipalFieldMapping,
} from '../../Enum/FieldMapping/ProductBrandFieldMapping';
import { dashboardAction } from '../../Store/Reducers/DashboardReducer';
import { mappingActions } from '../../Store/Reducers/MappingReducer';
import { utilsActions } from '../../Store/Reducers/UtilsReducer';
import {
  isContent,
  isContentErr,
} from '../../Utils/Response/Response';
import {
  getDetailExistingProductBrandPrincipal,
  getDetailProductBrandPrincipal,
  postApproveMappingProductBrandPrincipal,
  postProductBrandCountPrincipalData,
  postProductBrandPrinciaplList,
  postProductBrandSearchCountPrincipalData,
  postSearchProductBrandPrincipalList,
} from '../Api/ProductBrandAPI';
import {
  reloadList,
  setDefaultPagination,
} from './DashboardData';
import {
  approvalAction,
  approvalActionErr,
  setMappingForm,
  setMappingSearch,
  setMappingSearchErr,
} from './MappingData';

export  const fetchDataProductBrandPrincipalList=(
  totalRow,
  countData,
  token,
  currentPage,
  rowLimit,
  sortDate,
  dispatch,
  notify
)=>postProductBrandCountPrincipalData(token,notify,dispatch,
  (response)=>listData(response,totalRow, countData, token, currentPage, rowLimit, sortDate, dispatch,notify))

const listData=async(res,totalData, countData, token, currentPage, rowLimit, sortDate, dispatch,notify) =>{
    await dispatch(mappingActions.setCountData(isContent(res)))
    await dispatch(dashboardAction.setTotalRow(isContent(res)));

    if (totalData === countData) {
      postProductBrandPrinciaplList(token, currentPage, rowLimit, sortDate, dispatch,notify,
        response =>dispatch(mappingActions.setList(isContent(response))))
    } else {
      setDefaultPagination(dispatch)
      await postProductBrandPrinciaplList(token, 1, 10, sortDate, dispatch,notify,
        response => reloadList(response,dispatch,countData))
    }
    dispatch(utilsActions.setLoading(false));
  }

export const fetchProductBrandPrincipalMappingForm = 
  async ( token, dispatch, uniqueKey, NewId, ExistId, list, notify, navigate)=> {
    const newData = await getDetailProductBrandPrincipal(token, NewId, notify,dispatch,
      res=>isContent(res),err=>isContentErr(err))
    const existData = await getDetailExistingProductBrandPrincipal(token, ExistId, notify,dispatch,
      res=>isContent(res),err=>isContentErr(err))
    await setMappingForm ( productBrandPrincipalFieldMapping, list, uniqueKey, newData, existData, dispatch)
    navigate()
}


export async function fetchDataApprovalProductBrandPrincipal(
  token,
  data,
  mappingList,
  mappingCount,
  notify,
  dispatch
) {
  return postApproveMappingProductBrandPrincipal(
    token,
    data,
    notify,
    dispatch,
    res => approvalAction(res, mappingList, mappingCount, dispatch),
    err => approvalActionErr(err, dispatch)
  );
}

export async function fetchSearchProductBrandPrincipalList(token, data, column, dispatch, notify) {
  await postSearchProductBrandPrincipalList(token, data, dispatch, notify,
    res=>setMappingSearch(res,column,dispatch),
    err=>setMappingSearchErr(err, dispatch))
}
export const fetchDataProductBrandPrincipalSearchList = async (
  token,
  currentPage,
  rowLimit,
  filter,
  column,
  dispatch,
  notify
) => {
  postProductBrandSearchCountPrincipalData(token, filter, notify, dispatch, response =>
    listSearchData(
      response,
      token,
      currentPage,
      rowLimit,
      filter,
      column,
      dispatch,
      notify
    )
  );
};
const listSearchData = async (
  res,
  token,
  currentPage,
  rowLimit,
  filter,
  column,
  dispatch,
  notify
) => {
  let data = await {...{page: currentPage, limit: Number(rowLimit)}, ...filter};
  await postSearchProductBrandPrincipalList(
    token,
    data,
    dispatch,
    notify,
    reponse => setMappingSearch(reponse, column, isContent(res), dispatch),
    err => setMappingSearchErr(err, isContent(res), dispatch)
  );
  dispatch(utilsActions.setLoading(false));
};