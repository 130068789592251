import React from "react";

import { Div } from "nexcomponent-ui";

import { mainBreadCrumb } from "../../../../Enum/Breadcrumb/CommonBreadcrumb";
import { productBrandColumn } from "../../../../Enum/Column/ProductBrandColumn";
import { PAGE } from "../../../../Enum/IdSet";
import { MAPPING_CODE } from "../../../../Enum/MappingCode/MappingCode";
import { ROUTER_CONSTANT } from "../../../../Enum/RouterConstant/RouterConstant";
import {
  fetchDataProductBrandList,
  fetchProductBrandMappingForm,
} from "../../../../Fetch/FetchData/ProductBrandData";
import MappingTabList from "../../../Organisms/TableList/MappingTabList/MappingTabList";
import MappingTabContent from "../../../Organisms/TableList/MappingTabContent/MappingTabContent";
import {
  fetchDataProductBrandPrincipalList,
  fetchProductBrandPrincipalMappingForm,
} from "../../../../Fetch/FetchData/ProductBrandPrincipalData";

const ProductBrandPage = (props) => {
  const tabList = [
    {
      title: props.t("COMMON:PRINCIPAL"),
      component:(load)=> (
        <MappingTabContent
          notify={props.notify}
          t={props.t}
          load={load}
          dispatch={props.dispatch}
          navigate={props.navigate}
          mappingCode={MAPPING_CODE.PRODUCT_BRAND}
          principal={true}
          uniqueKey="principal_product_brand_id"
          rejectNavigate={ROUTER_CONSTANT.PRODUCT_BRAND.REJECT_REASON}
          mappingNavigate={(NewId, ExistId, List) =>
            fetchProductBrandPrincipalMappingForm(
              props.validToken(),
              props.dispatch,
              "principal_product_brand_id",
              NewId,
              ExistId,
              List,
              props.notify,
              () => props.navigate(`${ROUTER_CONSTANT.PRODUCT_BRAND.PRINCIPAL.MAPPING}`)
            )
          }
          column={productBrandColumn(props.t)}
          validToken={props.validToken}
          listFunc={(
            totalRow,
            countData,
            token,
            currentPage,
            rowLimit,
            sortDate,
            dispatch,
            notify
          ) =>
            fetchDataProductBrandPrincipalList(
              totalRow,
              countData,
              token,
              currentPage,
              rowLimit,
              sortDate,
              dispatch,
              notify
            )
          }
        />
      ),
      isShow: true,
    },
    {
      title: props.t("COMMON:MAPAN"),
      component:(load)=> (
        <MappingTabContent
          notify={props.notify}
          t={props.t}
          load={load}
          dispatch={props.dispatch}
          navigate={props.navigate}
          mappingCode={MAPPING_CODE.PRODUCT_BRAND}
          uniqueKey="product_brand_id"
          rejectNavigate={ROUTER_CONSTANT.PRODUCT_BRAND.REJECT_REASON}
          mappingNavigate={(NewId, ExistId, List) =>
            fetchProductBrandMappingForm(
              props.validToken(),
              props.dispatch,
              "product_brand_id",
              NewId,
              ExistId,
              List,
              props.notify,
              () => props.navigate(ROUTER_CONSTANT.PRODUCT_BRAND.MAPPING)
            )
          }
          column={productBrandColumn(props.t)}
          validToken={props.validToken}
          listFunc={(
            totalRow,
            countData,
            token,
            currentPage,
            rowLimit,
            sortDate,
            dispatch,
            notify
          ) =>
            fetchDataProductBrandList(
              totalRow,
              countData,
              token,
              currentPage,
              rowLimit,
              sortDate,
              dispatch,
              notify
            )
          }
        />
      ),
      isShow: false,
    },
  ];
  return (
    <Div id={PAGE.LIST}>
      <MappingTabList
        dispatch={props.dispatch}
        listBreadcrumb={mainBreadCrumb(props.t)}
        toTitlePage={ROUTER_CONSTANT.INDEX}
        title={props.t("PRODUCT_BRAND:TITLE")}
        defaultBreadcrumb={props.t("PRODUCT_BRAND:TITLE")}
        list={tabList}
      />
    </Div>
  );
};

export default ProductBrandPage;
