import React from 'react';

import {
  Button,
  Div,
  nexIcon,
} from 'nexcomponent-ui';
import { useSelector } from 'react-redux';

import { SIZESET } from '../../../../Enum/Props';
import { BTN_STYLE_TYPE } from '../../../../Enum/Style';
import { utilsActions } from '../../../../Store/Reducers/UtilsReducer';
import style from './CancelSearch.module.css';

function CancelSearch(props) {
  const applyAdvanceSearch = useSelector(state => state.utils.applyAdvanceSearch);
  const searchColumn = useSelector(state => state.utils.searchColumn);
  const searchData = useSelector(state => state.utils.searchData);

  const cancelClick = () => {
    props.navigate(props.backNavigate);
  };
  const searchClick = async e => {
    await e.preventDefault();
    await props.dispatch(utilsActions.setLoading(true));
    let data = { ...applyAdvanceSearch, ...searchData};
    props.searchFunc(data, searchColumn);
  };
  return (
    <Div className={style.ButtonGroup}>
      <Button
        label={props.t("COMMON:CANCEL")}
        size={SIZESET.LG}
        typeStyle={BTN_STYLE_TYPE.SECONDARY}
        onClick={cancelClick}
      />
      <Button
        icon={<nexIcon.NcSearch color="#fff" />}
        className={style.ButtonRight}
        label={props.t("COMMON:SEARCH")}
        size={SIZESET.LG}
        onClick={searchClick}
      />
    </Div>
  );
}

export default CancelSearch;
