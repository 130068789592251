import moment from 'moment';

let date = new Date();
export const times = locale => {
  let result = moment(date).format("DD MMMM, h:mm ");
  if (locale === "id-ID") {
    result =
      moment(date).format("DD ") +
      date.toLocaleString("id-ID", {month: "long"}) +
      moment(date).format(", h:mm");
  }
  return result;
};
