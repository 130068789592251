import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  initiate: {},
  mappingList: [],
  totalRow: 0,
  rejectReason: [],
  menuLevel1: null,
  menuLevel2: null,
  menuLevel3: null,
  online:false
};
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    setInitiate(state, data) {
      state.initiate = data.payload;
    },
    setMappingList(state, data) {
      state.mappingList = data.payload;
    },
    setTotalRow(state, data) {
      state.totalRow = data.payload;
    },
    setRejectReason(state, data) {
      state.rejectReason = data.payload;
    },
    setMenuLevel1(state, data) {
      state.menuLevel1 = data.payload;
    },
    setMenuLevel2(state, data) {
      state.menuLevel2 = data.payload;
    },
    setMenuLevel3(state, data) {
      state.menuLevel3 = data.payload;
    }, setOnline(state, data) {
      state.online = data.payload;
    },
  },
});

export const dashboardAction = dashboardSlice.actions;
export default dashboardSlice.reducer;
