import _ from 'lodash';

import { MAPPING_CODE } from '../../Enum/MappingCode/MappingCode';
import {
  fetchCompanyProfileMappingForm,
} from '../../Fetch/FetchData/CompanyProfileData';
import { fetchDivisionMappingForm } from '../../Fetch/FetchData/DivisionData';
import {
  fetchPersonProfileMappingForm,
} from '../../Fetch/FetchData/PersonProfileData';
import {
  fetchPrincipalProductUserDefinedCategoryMappingForm,
} from '../../Fetch/FetchData/PrincipalProductUserDefinedCategoryData';
import {
  fetchProductBrandMappingForm,
} from '../../Fetch/FetchData/ProductBrandData';
import {
  fetchProductCategoryMappingForm,
} from '../../Fetch/FetchData/ProductCategoryData';
import { fetchProductMappingForm } from '../../Fetch/FetchData/ProductData';
import {
  fetchProductGroupMappingForm,
} from '../../Fetch/FetchData/ProductGroupData';
import {
  fetchProductKeyAccountMappingForm,
} from '../../Fetch/FetchData/ProductKeyAccountData';
import { utilsActions } from '../../Store/Reducers/UtilsReducer';
import { mappingNavigate } from '../../Utils/Other/MappingNavigate';
import { rejectNavigate } from '../../Utils/Other/RejectNavigate';
import { mappingActions } from '../Reducers/MappingReducer';
import {
  closeModal,
  openModal,
} from './ModalAction';

export const rejectClick = async dispatch => {
  await dispatch(mappingActions.setRejectList([]));
  await dispatch(utilsActions.setCheckedArray([]));
  await dispatch(utilsActions.setActionReject(true));
  await dispatch(utilsActions.setActionType("reject"));
  dispatch(utilsActions.setSelectableRow(true));
};
export const cancelRejectClick = async dispatch => {
  await dispatch(utilsActions.setActionReject(false));
  dispatch(utilsActions.setCheckedArray([]));
};
export const mappingClick = async (
  mappingCode,
  navigate,
  token,
  dispatch,
  List,
  actionType,
  notify
) => {
  await dispatch(utilsActions.setModalType(false));
  await dispatch(utilsActions.setOverwrite(true));
  await dispatch(utilsActions.setActionType("mapping"));

  dispatch(utilsActions.setSelectableRow(true));
  if (actionType === "mapping") {
    await mappingForm(mappingCode, List, dispatch, token, notify);
    navigate(mappingNavigate(mappingCode));
  }
};

export const cancelButtonClick = async (onReject, navigate, backPage, mappingCode, dispatch) => {
  if (onReject) {
    await dispatch(utilsActions.setCheckedArray([]));
    await dispatch(mappingActions.setRejectList([]));
    await dispatch(utilsActions.setActionType(null));
    navigate(backPage);
  } else {
    await dispatch(utilsActions.setCheckedArray([]));
    await dispatch(mappingActions.setRejectList([]));
    await dispatch(utilsActions.setActionType(null));
    cancelRejectClick(dispatch);
  }
};
export const rejectButtonClick = (
  onReject,
  mappingCode,
  rejectList,
  checkedArray,
  rejectReasonSelect,
  navigate,
  dispatch,
  principal
) => {
  if (onReject) {
    openModal(dispatch);
    let tempUser= _.filter(checkedArray, { isChecked: true });
     let list = tempUser.map(datas => {
     return {id: datas.id, updated_at: datas.updated_at}
    });
    dispatch(
      utilsActions.setRejectData({
        list_mapping_id: list,
        reject_reason_option: rejectReasonSelect.reject_reason_option,
        reject_reason_custom: rejectReasonSelect.reject_reason_custom,
      })
    );
  } else {
    closeModal(dispatch);
    navigate(`${principal ? "/principal" : ""}${rejectNavigate(mappingCode)}`);
  }
};

export const mappingForm = async (mappingCode, list, dispatch, token, notify) => {
  const first = await _.first(list);
  if (mappingCode === MAPPING_CODE.PERSON_PROFILE) {
    await fetchPersonProfileMappingForm(
      token,
      dispatch,
      first.id,
      first.person_profile_id,
      first,
      notify
    );
  } else if (mappingCode === MAPPING_CODE.DIVISION) {
    await fetchDivisionMappingForm(token, dispatch, first.id, first.division_id, first, notify);
  } else if (mappingCode === MAPPING_CODE.PRODUCT_BRAND) {
    await fetchProductBrandMappingForm(
      token,
      dispatch,
      first.id,
      first.product_brand_id,
      first,
      notify
    );
  } else if (mappingCode === MAPPING_CODE.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY) {
    await fetchPrincipalProductUserDefinedCategoryMappingForm(
      token,
      dispatch,
      first.id,
      first.principal_product_user_defined_category_id,
      first,
      notify
    );
  } else if (mappingCode === MAPPING_CODE.PRODUCT_GROUP) {
    await fetchProductGroupMappingForm(
      token,
      dispatch,
      first.id,
      first.product_group_hierarchy_id,
      first,
      notify
    );
  } else if (mappingCode === MAPPING_CODE.PRODUCT_CATEGORY) {
    await fetchProductCategoryMappingForm(
      token,
      dispatch,
      first.id,
      first.product_category_id,
      first,
      notify
    );
  } else if (mappingCode === MAPPING_CODE.COMPANY_PROFILE) {
    await fetchCompanyProfileMappingForm(
      token,
      dispatch,
      first.id,
      first.company_profile_id,
      first,
      notify
    );
  } else if (mappingCode === MAPPING_CODE.PRODUCT_KEY_ACCOUNT) {
    await fetchProductKeyAccountMappingForm(
      token,
      dispatch,
      first.id,
      first.product_key_account_id,
      first,
      notify
    );
  } else if (mappingCode === MAPPING_CODE.PRODUCT) {
    await fetchProductMappingForm(token, dispatch, first.id, first.product_id, first, notify);
  }
};
