import React from 'react';

import { Div } from 'nexcomponent-ui';

import {
  notificationAfterListBreadcrumb,
} from '../../../Enum/Breadcrumb/NotificationBreadcrumb';
import {
  ROUTER_CONSTANT,
} from '../../../Enum/RouterConstant/RouterConstant';
import HeaderPage from '../../Organisms/Header/HeaderPage/HeaderPage';
import NotificationCreateForm from '../../Organisms/Form/NotificationForm/NotificationCreateForm';

const CreateNotificationPage = props => {
  return (
    <Div>
      <HeaderPage
        title={props.t("COMMON:ADD_NOTIFICATION")}
        listBreadcrumb={notificationAfterListBreadcrumb(props.t)}
        defaultBreadcrumb={props.t("NOTIFICATION:TITLE_CREATE")}
        toTitlePageto={ROUTER_CONSTANT.NOTIFICATION.LIST}
      />

      <NotificationCreateForm
        t={props.t}
        validToken={props.validToken}
        dispatch={props.dispatch}
        notify={props.notify}
        navigate={props.navigate}
      />
    </Div>
  );
};

export default CreateNotificationPage;
