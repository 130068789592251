import { MAPPING_CODE } from '../../Enum/MappingCode/MappingCode';
import { ROUTER_CONSTANT } from '../../Enum/RouterConstant/RouterConstant';

export function rejectNavigate(mappingCode){
  if(mappingCode===MAPPING_CODE.PERSON_PROFILE){
    return(ROUTER_CONSTANT.PERSON_PROFILE.REJECT_REASON)
  }else if(mappingCode===MAPPING_CODE.COMPANY_PROFILE){
    return(ROUTER_CONSTANT.COMPANY_PROFILE.REJECT_REASON)
  }else if(mappingCode===MAPPING_CODE.DIVISION){
    return(ROUTER_CONSTANT.DIVISION.REJECT_REASON)
  }else if(mappingCode===MAPPING_CODE.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY){
    return(ROUTER_CONSTANT.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY.REJECT_REASON)
  }else if(mappingCode===MAPPING_CODE.PRODUCT_BRAND){
    return(ROUTER_CONSTANT.PRODUCT_BRAND.REJECT_REASON)
  }else if(mappingCode===MAPPING_CODE.PRODUCT_CATEGORY){
    return(ROUTER_CONSTANT.PRODUCT_CATEGORY.REJECT_REASON)
  }else if(mappingCode===MAPPING_CODE.PRODUCT_GROUP){
    return(ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.REJECT_REASON)
  }else if(mappingCode===MAPPING_CODE.PRODUCT_KEY_ACCOUNT){
    return(ROUTER_CONSTANT.PRODUCT_KEY_ACCOUNT.REJECT_REASON)
  }else if(mappingCode===MAPPING_CODE.PRODUCT){
    return(ROUTER_CONSTANT.PRODUCT.REJECT_REASON)
  }else{
    return("/")
  }
}