import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { ListEmpty } from 'nexcomponent-ui'

import { TABLE } from '../../../../Enum/IdSet'
import { notificationColumn } from '../../../../Enum/Column/NotificationColumn'

import SpinnerLoad from '../../../../Components/Molecules/Loader/SpinnerLoad/SpinnerLoad'
import TableList from '../TableList'

import { notificationStateGroup } from '../../../../Store/StateGroup'

import { fetchNotificationList } from '../../../../Fetch/FetchData/NotificationData'

import ModalDelete from '../../Modal/ModalDelete/ModalDelete'
import { deleteNotification } from '../../../../Fetch/Api/NotificationAPI'

import style from './NotificationList.module.css';

const NotificationList = (props)  => {
  const [ totalRow, validLimit, notificationList, loading, currentPage, sortDate, rowLimit ] = useSelector(notificationStateGroup)
  const selectedNotification = useSelector(state => state.notification.selectedNotification)
  const filter = useSelector(state => state.notification.filter)

  useEffect(() => {
      fetchNotificationList(
        currentPage,
        sortDate,
        props,
        rowLimit,
        filter?filter:""
      )
  }, [currentPage, loading, sortDate, props, rowLimit, filter])

  return (
    <>
        <ModalDelete
          onReject={true}
          t={props.t}
          dispatch={props.dispatch}
          navigate={props.navigate}
          deleteFunc={() =>
            deleteNotification(props.validToken(), selectedNotification, props.notify, props.dispatch)
          }
          uniqueKey={"notification_code"}
        />
        <TableList
          className={style.TableList}
          customHeader={props.customHeader}
          id={TABLE.NOTIFICATION_LIST}
          sort={true}
          columns={notificationColumn(props.t, props.dispatch)}
          data={notificationList}
          totalRow={totalRow}
          limitation={validLimit}
          empty={
            !loading && notificationList === null ? (
              <ListEmpty value={props.t("COMMON:REQUEST_TIME_OUT.TITLE")} />
            ) : !loading && notificationList.length === 0 ? (
              <ListEmpty value={props.t("COMMON:LIST_EMPTY")} />
            ) : (
              <ListEmpty value={<SpinnerLoad t={props.t} />} />
            )
          }
          t={props.t}
          footer={false}
          dispatch={props.dispatch}
          sortingNotification={true}
        />
    </>
  )
}

export default NotificationList
