import React, { useEffect } from 'react';

import _ from 'lodash';
import { Div } from 'nexcomponent-ui';
import { useSelector } from 'react-redux';

import {
  divisionBreadCrumb,
} from '../../../../Enum/Breadcrumb/DivisionBreadcrumb';
import { MAPPING_CODE } from '../../../../Enum/MappingCode/MappingCode';
import {
  ROUTER_CONSTANT,
} from '../../../../Enum/RouterConstant/RouterConstant';
import {
  fetchDataApprovalDivision,
  fetchDivisionMappingForm,
} from '../../../../Fetch/FetchData/DivisionData';
import { mappingFormPageStateGroup } from '../../../../Store/StateGroup';
import CancelOverwrite
  from '../../../Organisms/Button/CancelOverwrite/CancelOverwrite';
import MappingForm
  from '../../../Organisms/Form/MappingForm/MappingForm/MappingForm';
import HeaderPageMapping
  from '../../../Organisms/Header/HeaderPageMapping/HeaderPageMapping';
import ModalMapping from '../../../Organisms/Modal/ModalMapping/ModalMapping';

const DivisionMappingPage = props => {
  const [, list, , mappingForm] = useSelector(mappingFormPageStateGroup);
  useEffect(() => {
    if (_.has(props.location.state, "data")) {
      const first = _.first(list);
      fetchDivisionMappingForm(
        props.validToken(),
        props.dispatch,
        "division_id",
        first.id,
        props.location.state.data.division_id,
        list,
        props.notify,
        () => props.navigate(ROUTER_CONSTANT.DIVISION.MAPPING)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, props.location.state, props.dispatch, props.validToken]);
  return (
    <Div>
      <ModalMapping
        t={props.t}
        dispatch={props.dispatch}
        navigate={props.navigate}
        listNavigate={ROUTER_CONSTANT.DIVISION.LIST}
        approveFuc={(data, mappingList, mappingCount) =>
          fetchDataApprovalDivision(
            props.validToken(),
            data,
            mappingList,
            mappingCount,
            props.notify,
            props.dispatch
          )
        }
        uniqueKey="division_id"
        nextMapping={(uniqueKey, newId, existId, mappingList) =>
          fetchDivisionMappingForm(
            props.validToken(),
            props.dispatch,
            uniqueKey,
            newId,
            existId,
            mappingList,
            props.notify,
            () => props.navigate(ROUTER_CONSTANT.DIVISION.MAPPING)
          )
        }
      />

      <HeaderPageMapping
        title={props.t("DIVISION:TITLE")}
        listBreadcrumb={divisionBreadCrumb(props.t)}
        defaultBreadcrumb={props.t("DIVISION:MAPPING.TITLE")}
        to={ROUTER_CONSTANT.DIVISION.LIST}
      />
      <MappingForm
        t={props.t}
        validToken={props.validToken}
        dispatch={props.dispatch}
        navigate={props.navigate}
        idKey="division_id"
        mappingCode={MAPPING_CODE.DIVISION}
        mappingForm={mappingForm}
      />
      <CancelOverwrite
        t={props.t}
        dispatch={props.dispatch}
        navigate={props.navigate}
        mappingForm={mappingForm}
        idKey="division_id"
        mappingCode={14}
      />
    </Div>
  );
};

export default DivisionMappingPage;
