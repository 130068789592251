export const searchDivisionColumn = t => [
  // {
  //   name: t("COMMON:FIELD.RESOURCE"),
  //   selector: "resources",
  //   width: "100px",
  // },
  {
    name: t("DIVISION:FIELD.DIVISION_ID"),
    selector: "division_id",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.PRINCIPAL_CODE"),
    selector: "principal_code",
    width: "200px",
  },
  {
    name: t("COMMON:FIELD.PRINCIPAL_NAME"),
    selector: "principal_name",
    width: "200px",
  },
  {
    name: t("COMMON:FIELD.PRINCIPAL_ID"),
    selector: "principal_id",

    width: "100px",
  },
  {
    name: t("COMMON:FIELD.CODE"),
    selector: "code",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.NAME"),
    selector: "name",
    width: "100px",
  },
];
