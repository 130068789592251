import { useState } from 'react';

import { useSelector } from 'react-redux';

import FieldComparison
  from '../../../../../../Components/Molecules/Field/FieldComparison/FieldComparison';
import FieldGroupComparison
  from '../../../../../../Components/Molecules/Field/FieldGroupComparison/FieldGroupComparison';
import { Max } from '../../../../../../Enum/DefaultProps/MaxLength';
import { defaultLimitSearch } from '../../../../../../Enum/Limit/SearchAdvance';
import { REGEX_CONSTANT } from '../../../../../../Enum/RegexConstant';
import {
  postPrincipal,
  postProductBrand,
  postProductCategory,
  postProductGroup,
  postProductKeyAccount,
} from '../../../../../../Fetch/Api/SearchMappingAPI';
import {
  fetchPrincipal,
  fetchProductBrand,
  fetchProductCategory,
  fetchProductGroupProduct,
  fetchProductKeyAccount,
} from '../../../../../../Fetch/FetchData/SearchMappingData';
import {
  searchMappingActions,
} from '../../../../../../Store/Reducers/SearchMappingReducer';
import {
  DDSonChange,
  DDSonChangeProductGroup,
  DDSOnClick,
  DDSOnScroll,
} from '../../../../../../Utils/Action/DropDownSearch/DropDownSearch';
import {
  fieldRequired,
} from '../../../../../../Utils/Action/Field/FieldRequired';
import {
  IgnorePrincipal,
} from '../../../../../../Utils/Action/Field/IgnorePrincipal';
import { dateFormater } from '../../../../../../Utils/Date/formater';

const FieldProductMappingForm = props => {
  const {t} = props;
  const principal = useSelector(state => state.searchMapping.principal);
  const product_brand = useSelector(state => state.searchMapping.productBrand);
  const product_group = useSelector(state => state.searchMapping.productGroup);
  const product_category = useSelector(state => state.searchMapping.productCategory);
  const product_key_account = useSelector(state => state.searchMapping.productKeyAccount);
  const [filter, setFilter] = useState();

  const [limitation, setLimitation] = useState(defaultLimitSearch);
  const getPrincipal = () => {
    let checkId = props.value(props.mappingForm, "product_id", "exist");
    let principalId = Number(props.value(props.mappingForm, "principal_id", "new"));
    if (checkId !== null) {
      return (principalId = Number(props.value(props.mappingForm, "principal_id", "exist")));
    }
    return principalId;
  };
  const filterPrincipal = {principal_id: getPrincipal()};
  const clickSelect = (fetch, filter) => {
    DDSOnClick(fetch, setLimitation, filter, props.validToken, props.dispatch, props.notify);
  };
  const onScroll = (fetch, prevList, filter) => {
    DDSOnScroll(
      fetch,
      prevList,
      setLimitation,
      limitation,
      filter,
      props.validToken,
      props.dispatch,
      props.notify
    );
  };
  const onChange = async (e, type, fetch, setOption, filter) => {
    DDSonChange(
      e,
      type,
      props.validToken,
      fetch,
      setLimitation,
      setFilter,
      setOption,
      filter,
      props.notify,
      props.dispatch
    );
  };
  const onChangeTypeProductGroup = async (
    e,
    type,
    fetch,
    setOption,
    idKey,
    nameKey,
    codeKey,
    filter
  ) => {
    DDSonChangeProductGroup(
      e,
      type,
      props.validToken,
      fetch,
      setLimitation,
      setFilter,
      setOption,
      filter,
      props.notify,
      props.dispatch,
      idKey,
      nameKey,
      codeKey
    );
  };
  const handleRequired = key =>
    fieldRequired(
      props.value(props.mappingForm, "product_id", "exist"),
      props.value(props.mappingForm, key, "isChecked")
    );
  const embalaceStatus = () =>
    props.value(props.mappingForm, "embalace_status", "isChecked")
      ? props.value(props.mappingForm, "embalace_status", "new")
      : props.value(props.mappingForm, "embalace_status", "exist");
  const embalaceDisable = () => (embalaceStatus() === "Y" ? false : true);
  return (
    <>
      <FieldComparison
        label={t("COMMON:FIELD.PRODUCT_ID")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PRODUCT_ID")})}
        typeNew="text"
        typeExist="text"
        nameNew="product_id"
        valueNew={props.value(props.mappingForm, "product_id", "new")}
        nameExist="product_id_exist"
        valueExist={props.value(props.mappingForm, "product_id", "exist")}
        checked={
          props.value(props.mappingForm, "product_id", "exist") !== null
            ? false
            : props.value(props.mappingForm, "product_id", "isChecked")
        }
        disabledCheck={
          props.value(props.mappingForm, "product_id", "exist") !== null ? true : false
        }
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        disabled={true}
      />
      <FieldComparison
        label={t("COMMON:FIELD.PRODUCT_CODE")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PRODUCT_CODE")})}
        typeNew="text"
        typeExist="text"
        nameNew="product_code"
        valueNew={props.value(props.mappingForm, "product_code", "new")}
        nameExist="product_code_exist"
        valueExist={props.value(props.mappingForm, "product_code", "exist")}
        checked={props.value(props.mappingForm, "product_code", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        pattern={REGEX_CONSTANT.NO_SPACE}
        message={t("COMMON:ERROR.NO_SPACE_SPECIAL", {field: t("COMMON:FIELD.PRODUCT_CODE")})}
        maxLength={Max.length35}
        required={handleRequired("product_code")}
      />
      <FieldGroupComparison
        label={t("PRODUCT:FIELD.PRINCIPAL")}
        t={t}
        detailDefault={props.detail}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        submitMapping={props.submitMapping}
        option={principal}
        mainKey="principal_id"
        secondKey="principal_name"
        thirdKey="principal_code"
        typeFirst="number"
        typeSecond="text"
        typeThird="text"
        onChange={(e, type) =>
          onChange(e, type, postPrincipal, searchMappingActions.setPrincipal, filter)
        }
        onClick={() => clickSelect(fetchPrincipal, filter)}
        onScroll={() => onScroll(fetchPrincipal, principal, filter)}
        subLabelOne={"PRODUCT:FIELD.PRINCIPAL_ID"}
        subLabelTwo={"PRODUCT:FIELD.PRINCIPAL_NAME"}
        subLabelThree={"PRODUCT:FIELD.PRINCIPAL_CODE"}
        placeholderThird={t("COMMON:SELECT", {field: t("COMMON:FIELD.PRINCIPAL_CODE")})}
        placeholderSecond={t("COMMON:SELECT", {field: t("COMMON:FIELD.PRINCIPAL_NAME")})}
        placeholderFirst={t("COMMON:SELECT", {field: t("COMMON:FIELD.PRINCIPAL_ID")})}
        valueNew={(key, item) => props.value(props.mappingForm, key, item)}
        onCheck={e => props.checkedPartial(e)}
        dispatch={props.dispatch}
        disabled={IgnorePrincipal(props.value(props.mappingForm, "product_id", "exist"))}
        disabledCheck={IgnorePrincipal(props.value(props.mappingForm, "product_id", "exist"))}
        required={props.value(props.mappingForm, "principal_id", "exist") !== null ? false : true}
      />
      <FieldComparison
        label={t("COMMON:FIELD.PRINCIPAL_PRODUCT_CODE")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PRINCIPAL_PRODUCT_CODE")})}
        typeNew="text"
        typeExist="text"
        nameNew="principal_product_code"
        valueNew={props.value(props.mappingForm, "principal_product_code", "new")}
        nameExist="principal_product_code_exist"
        valueExist={props.value(props.mappingForm, "principal_product_code", "exist")}
        checked={props.value(props.mappingForm, "principal_product_code", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        pattern={REGEX_CONSTANT.NO_SPACE}
        message={t("COMMON:ERROR.NO_SPACE_SPECIAL", {
          field: t("COMMON:FIELD.PRINCIPAL_PRODUCT_CODE"),
        })}
        maxLength={Max.length35}
        required={handleRequired("principal_product_code")}
      />
      <FieldComparison
        label={t("PRODUCT:FIELD.PARENT_CODE")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PARENT_CODE")})}
        typeNew="text"
        typeExist="text"
        nameNew="parent_code"
        valueNew={props.value(props.mappingForm, "parent_code", "new")}
        nameExist="parent_code_exist"
        valueExist={props.value(props.mappingForm, "parent_code", "exist")}
        checked={props.value(props.mappingForm, "parent_code", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        pattern={REGEX_CONSTANT.NO_SPACE}
        message={t("COMMON:ERROR.NO_SPACE_SPECIAL", {field: t("COMMON:FIELD.PARENT_CODE")})}
        maxLength={Max.length35}
      />
      <FieldComparison
        label={t("COMMON:FIELD.SHORT_CODE")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.SHORT_CODE")})}
        typeNew="text"
        typeExist="text"
        nameNew="short_code"
        valueNew={props.value(props.mappingForm, "short_code", "new")}
        nameExist="short_code_exist"
        valueExist={props.value(props.mappingForm, "short_code", "exist")}
        checked={props.value(props.mappingForm, "short_code", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        pattern={REGEX_CONSTANT.NO_SPACE}
        message={t("COMMON:ERROR.NO_SPACE_SPECIAL", {field: t("COMMON:FIELD.SHORT_CODE")})}
        maxLength={Max.length35}
      />
      <FieldComparison
        label={t("COMMON:FIELD.NAME")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.NAME")})}
        typeNew="text"
        typeExist="text"
        nameNew="name"
        valueNew={props.value(props.mappingForm, "name", "new")}
        nameExist="name_exist"
        valueExist={props.value(props.mappingForm, "name", "exist")}
        checked={props.value(props.mappingForm, "name", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        maxLength={Max.length35}
        required={handleRequired("name")}
      />
      <FieldComparison
        label={t("COMMON:FIELD.NAME_2")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.NAME_2")})}
        typeNew="text"
        typeExist="text"
        nameNew="name_2"
        valueNew={props.value(props.mappingForm, "name_2", "new")}
        nameExist="name_2_exist"
        valueExist={props.value(props.mappingForm, "name_2", "exist")}
        checked={props.value(props.mappingForm, "name_2", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        maxLength={Max.length35}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.PACKAGING")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PACKAGING")})}
        typeNew="text"
        typeExist="text"
        nameNew="packaging"
        valueNew={props.value(props.mappingForm, "packaging", "new")}
        nameExist="packaging_exist"
        valueExist={props.value(props.mappingForm, "packaging", "exist")}
        checked={props.value(props.mappingForm, "packaging", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        pattern={REGEX_CONSTANT.LETTER}
        message={t("COMMON:ERROR.LETTER", {field: t("COMMON:FIELD.PACKAGING"), exp: "PCS"})}
        maxLength={Max.length50}
        required={handleRequired("packaging")}
      />
      <FieldComparison
        label={t("COMMON:FIELD.PACKAGING_2")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PACKAGING_2")})}
        typeNew="text"
        typeExist="text"
        nameNew="packaging_2"
        valueNew={props.value(props.mappingForm, "packaging_2", "new")}
        nameExist="packaging_2_exist"
        valueExist={props.value(props.mappingForm, "packaging_2", "exist")}
        checked={props.value(props.mappingForm, "packaging_2", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        pattern={REGEX_CONSTANT.LETTER}
        message={t("COMMON:ERROR.LETTER", {field: t("COMMON:FIELD.PACKAGING_2"), exp: "PCS"})}
        maxLength={Max.length50}
      />
      <FieldComparison
        label={t("COMMON:FIELD.DESCRIPTION")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.DESCRIPTION")})}
        typeNew="text"
        typeExist="text"
        nameNew="description"
        valueNew={props.value(props.mappingForm, "description", "new")}
        nameExist="description_exist"
        valueExist={props.value(props.mappingForm, "description", "exist")}
        checked={props.value(props.mappingForm, "description", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        maxLength={Max.length200}
      />
      <FieldGroupComparison
        label={t("COMMON:FIELD.PRODUCT_BRAND")}
        t={t}
        detailDefault={props.detail}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        submitMapping={props.submitMapping}
        option={product_brand}
        mainKey="product_brand_id"
        secondKey="product_brand_name"
        thirdKey="product_brand_code"
        typeFirst="number"
        typeSecond="text"
        typeThird="text"
        onChange={(e, type) =>
          onChange(e, type, postProductBrand, searchMappingActions.setProductBrand, filterPrincipal)
        }
        onClick={() => clickSelect(fetchProductBrand, filterPrincipal)}
        onScroll={() => onScroll(fetchProductBrand, product_brand, filterPrincipal)}
        subLabelOne={"PRODUCT:FIELD.PRODUCT_BRAND_ID"}
        subLabelTwo={"PRODUCT:FIELD.PRODUCT_BRAND_NAME"}
        subLabelThree={"PRODUCT:FIELD.PRODUCT_BRAND_CODE"}
        placeholderThird={t("COMMON:SELECT", {field: t("COMMON:FIELD.PRODUCT_BRAND_CODE")})}
        placeholderSecond={t("COMMON:SELECT", {field: t("COMMON:FIELD.PRODUCT_BRAND_NAME")})}
        placeholderFirst={t("COMMON:SELECT", {field: t("COMMON:FIELD.PRODUCT_BRAND_ID")})}
        valueNew={(key, item) => props.value(props.mappingForm, key, item)}
        resetSelect={true}
        onCheck={e => props.checkedPartial(e)}
        dispatch={props.dispatch}
        required={handleRequired("product_brand_id")}
      />
      <FieldGroupComparison
        label={t("COMMON:FIELD.PRODUCT_GROUP")}
        t={t}
        detailDefault={props.detail}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        submitMapping={props.submitMapping}
        option={product_group}
        mainKey="product_group_hierarchy_id"
        secondKey="product_group_hierarchy_name"
        thirdKey="product_group_hierarchy_code"
        typeFirst="number"
        typeSecond="text"
        typeThird="text"
        onChange={(e, type) =>
          onChangeTypeProductGroup(
            e,
            type,
            postProductGroup,
            searchMappingActions.setProductGroup,
            "product_group_id",
            "product_group_name",
            "product_group_code",
            filterPrincipal
          )
        }
        onClick={() => clickSelect(fetchProductGroupProduct, filterPrincipal)}
        onScroll={() => onScroll(fetchProductGroupProduct, product_group, filterPrincipal)}
        subLabelOne={"PRODUCT:FIELD.PRODUCT_GROUP_ID"}
        G
        subLabelTwo={"PRODUCT:FIELD.PRODUCT_GROUP_NAME"}
        subLabelThree={"PRODUCT:FIELD.PRODUCT_GROUP_CODE"}
        placeholderThird={t("COMMON:SELECT", {field: t("COMMON:FIELD.PRODUCT_GROUP_CODE")})}
        placeholderSecond={t("COMMON:SELECT", {field: t("COMMON:FIELD.PRODUCT_GROUP_NAME")})}
        placeholderFirst={t("COMMON:SELECT", {field: t("COMMON:FIELD.PRODUCT_GROUP_ID")})}
        valueNew={(key, item) => props.value(props.mappingForm, key, item)}
        onCheck={e => props.checkedPartial(e)}
        dispatch={props.dispatch}
        resetSelect={true}
        required={handleRequired("product_group_hierarchy_id")}
      />
      <FieldGroupComparison
        label={t("COMMON:FIELD.PRODUCT_CATEGORY")}
        t={t}
        detailDefault={props.detail}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        submitMapping={props.submitMapping}
        option={product_category}
        mainKey="product_category_id"
        secondKey="product_category_name"
        thirdKey="product_category_code"
        typeFirst="number"
        typeSecond="text"
        typeThird="text"
        onChange={(e, type) =>
          onChange(
            e,
            type,
            postProductCategory,
            searchMappingActions.setProductCategory,
            filterPrincipal
          )
        }
        onClick={() => clickSelect(fetchProductCategory, filterPrincipal)}
        onScroll={() => onScroll(fetchProductCategory, product_category, filterPrincipal)}
        subLabelOne={"PRODUCT:FIELD.PRODUCT_CATEGORY_ID"}
        subLabelTwo={"PRODUCT:FIELD.PRODUCT_CATEGORY_NAME"}
        subLabelThree={"PRODUCT:FIELD.PRODUCT_CATEGORY_CODE"}
        placeholderThird={t("COMMON:SELECT", {field: t("COMMON:FIELD.PRODUCT_CATEGORY_CODE")})}
        placeholderSecond={t("COMMON:SELECT", {field: t("COMMON:FIELD.PRODUCT_CATEGORY_NAME")})}
        placeholderFirst={t("COMMON:SELECT", {field: t("COMMON:FIELD.PRODUCT_CATEGORY_ID")})}
        resetSelect={true}
        valueNew={(key, item) => props.value(props.mappingForm, key, item)}
        onCheck={e => props.checkedPartial(e)}
        dispatch={props.dispatch}
        required={handleRequired("product_category_id")}
      />
      <FieldGroupComparison
        label={t("COMMON:FIELD.PRODUCT_KEY_ACCOUNT")}
        t={t}
        detailDefault={props.detail}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        submitMapping={props.submitMapping}
        option={product_key_account}
        mainKey="product_key_account_id"
        secondKey="product_key_account_name"
        thirdKey="product_key_account_code"
        typeFirst="number"
        typeSecond="text"
        typeThird="text"
        onChange={(e, type) =>
          onChange(
            e,
            type,
            postProductKeyAccount,
            searchMappingActions.setProductKeyAccount,
            filterPrincipal
          )
        }
        onClick={() => clickSelect(fetchProductKeyAccount, filterPrincipal)}
        onScroll={() => onScroll(fetchProductKeyAccount, product_key_account, filterPrincipal)}
        subLabelOne={"PRODUCT:FIELD.PRODUCT_KEY_ACCOUNT_ID"}
        subLabelTwo={"PRODUCT:FIELD.PRODUCT_KEY_ACCOUNT_NAME"}
        subLabelThree={"PRODUCT:FIELD.PRODUCT_KEY_ACCOUNT_CODE"}
        placeholderThird={t("COMMON:SELECT", {field: t("COMMON:FIELD.PRODUCT_KEY_ACCOUNT_CODE")})}
        placeholderSecond={t("COMMON:SELECT", {field: t("COMMON:FIELD.PRODUCT_KEY_ACCOUNT_NAME")})}
        placeholderFirst={t("COMMON:SELECT", {field: t("COMMON:FIELD.PRODUCT_KEY_ACCOUNT_ID")})}
        resetSelect={true}
        valueNew={(key, item) => props.value(props.mappingForm, key, item)}
        onCheck={e => props.checkedPartial(e)}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.IMAGE_FILE_NAME_1")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.IMAGE_FILE_NAME_1")})}
        typeNew="text"
        typeExist="text"
        nameNew="image_file_name_1"
        valueNew={props.value(props.mappingForm, "image_file_name_1", "new")}
        nameExist="image_file_name_1_exist"
        valueExist={props.value(props.mappingForm, "image_file_name_1", "exist")}
        checked={props.value(props.mappingForm, "image_file_name_1", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.IMAGE_FILE_NAME_2")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.IMAGE_FILE_NAME_2")})}
        typeNew="text"
        typeExist="text"
        nameNew="image_file_name_2"
        valueNew={props.value(props.mappingForm, "image_file_name_2", "new")}
        nameExist="image_file_name_2_exist"
        valueExist={props.value(props.mappingForm, "image_file_name_2", "exist")}
        checked={props.value(props.mappingForm, "image_file_name_2", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.IMAGE_FILE_NAME_3")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.IMAGE_FILE_NAME_3")})}
        typeNew="text"
        typeExist="text"
        nameNew="image_file_name_3"
        valueNew={props.value(props.mappingForm, "image_file_name_3", "new")}
        nameExist="image_file_name_3_exist"
        valueExist={props.value(props.mappingForm, "image_file_name_3", "exist")}
        checked={props.value(props.mappingForm, "image_file_name_3", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.IMAGE_FILE_NAME_4")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.IMAGE_FILE_NAME_4")})}
        typeNew="text"
        typeExist="text"
        nameNew="image_file_name_4"
        valueNew={props.value(props.mappingForm, "image_file_name_4", "new")}
        nameExist="image_file_name_4_exist"
        valueExist={props.value(props.mappingForm, "image_file_name_4", "exist")}
        checked={props.value(props.mappingForm, "image_file_name_4", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.IMAGE_FILE_NAME_5")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.IMAGE_FILE_NAME_5")})}
        typeNew="text"
        typeExist="text"
        nameNew="image_file_name_5"
        valueNew={props.value(props.mappingForm, "image_file_name_5", "new")}
        nameExist="image_file_name_5_exist"
        valueExist={props.value(props.mappingForm, "image_file_name_5", "exist")}
        checked={props.value(props.mappingForm, "image_file_name_5", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.UOM_1")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.UOM_1")})}
        typeNew="text"
        typeExist="text"
        nameNew="uom_1"
        valueNew={props.value(props.mappingForm, "uom_1", "new")}
        nameExist="uom_1_exist"
        valueExist={props.value(props.mappingForm, "uom_1", "exist")}
        checked={props.value(props.mappingForm, "uom_1", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        pattern={REGEX_CONSTANT.LETTER}
        message={t("COMMON:ERROR.LETTER", {field: t("COMMON:FIELD.UOM_1"), exp: "PCS"})}
        maxLength={Max.length5}
        required={handleRequired("uom_1")}
      />
      <FieldComparison
        label={t("COMMON:FIELD.UOM_2")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.UOM_2")})}
        typeNew="text"
        typeExist="text"
        nameNew="uom_2"
        valueNew={props.value(props.mappingForm, "uom_2", "new")}
        nameExist="uom_2_exist"
        valueExist={props.value(props.mappingForm, "uom_2", "exist")}
        checked={props.value(props.mappingForm, "uom_2", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        pattern={REGEX_CONSTANT.LETTER}
        message={t("COMMON:ERROR.LETTER", {field: t("COMMON:FIELD.UOM_2"), exp: "PCS"})}
        required={handleRequired("uom_2")}
      />
      <FieldComparison
        label={t("COMMON:FIELD.UOM_3")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.UOM_3")})}
        typeNew="text"
        typeExist="text"
        nameNew="uom_3"
        valueNew={props.value(props.mappingForm, "uom_3", "new")}
        nameExist="uom_3_exist"
        valueExist={props.value(props.mappingForm, "uom_3", "exist")}
        checked={props.value(props.mappingForm, "uom_3", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        pattern={REGEX_CONSTANT.LETTER}
        message={t("COMMON:ERROR.LETTER", {field: t("COMMON:FIELD.UOM_3"), exp: "PCS"})}
        required={handleRequired("uom_3")}
      />
      <FieldComparison
        label={t("COMMON:FIELD.UOM_4")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.UOM_4")})}
        typeNew="text"
        typeExist="text"
        nameNew="uom_4"
        valueNew={props.value(props.mappingForm, "uom_4", "new")}
        nameExist="uom_4_exist"
        valueExist={props.value(props.mappingForm, "uom_4", "exist")}
        checked={props.value(props.mappingForm, "uom_4", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        pattern={REGEX_CONSTANT.LETTER}
        message={t("COMMON:ERROR.LETTER", {field: t("COMMON:FIELD.UOM_4"), exp: "PCS"})}
        required={handleRequired("uom_4")}
      />
      <FieldComparison
        label={t("COMMON:FIELD.CONVERSION_1_TO_4")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.CONVERSION_1_TO_4")})}
        typeNew="number"
        typeExist="text"
        nameNew="conversion_1_to_4"
        valueNew={props.value(props.mappingForm, "conversion_1_to_4", "new")}
        nameExist="conversion_1_to_4_exist"
        valueExist={props.value(props.mappingForm, "conversion_1_to_4", "exist")}
        checked={props.value(props.mappingForm, "conversion_1_to_4", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        required={handleRequired("conversion_1_to_4")}
      />
      <FieldComparison
        label={t("COMMON:FIELD.CONVERSION_2_TO_4")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.CONVERSION_2_TO_4")})}
        typeNew="number"
        typeExist="number"
        nameNew="conversion_2_to_4"
        valueNew={props.value(props.mappingForm, "conversion_2_to_4", "new")}
        nameExist="conversion_2_to_4_exist"
        valueExist={props.value(props.mappingForm, "conversion_2_to_4", "exist")}
        checked={props.value(props.mappingForm, "conversion_2_to_4", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        required={handleRequired("conversion_2_to_4")}
      />
      <FieldComparison
        label={t("COMMON:FIELD.CONVERSION_3_TO_4")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.CONVERSION_3_TO_4")})}
        typeNew="number"
        typeExist="number"
        nameNew="conversion_3_to_4"
        valueNew={props.value(props.mappingForm, "conversion_3_to_4", "new")}
        nameExist="conversion_3_to_4_exist"
        valueExist={props.value(props.mappingForm, "conversion_3_to_4", "exist")}
        checked={props.value(props.mappingForm, "conversion_3_to_4", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        required={handleRequired("conversion_3_to_4")}
      />
      <FieldComparison
        label={t("COMMON:FIELD.CODE_BOTTLE")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.CODE_BOTTLE")})}
        typeNew="text"
        typeExist="text"
        nameNew="code_bottle"
        valueNew={props.value(props.mappingForm, "code_bottle", "new")}
        nameExist="code_bottle_exist"
        valueExist={props.value(props.mappingForm, "code_bottle", "exist")}
        checked={props.value(props.mappingForm, "code_bottle", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        disabled={embalaceDisable()}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.CODE_CRATE")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.CODE_CRATE")})}
        typeNew="text"
        typeExist="text"
        nameNew="code_crate"
        valueNew={props.value(props.mappingForm, "code_crate", "new")}
        nameExist="code_crate_exist"
        valueExist={props.value(props.mappingForm, "code_crate", "exist")}
        checked={props.value(props.mappingForm, "code_crate", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        disabled={embalaceDisable()}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.MARKET_LAUNCH_DATE")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.MARKET_LAUNCH_DATE")})}
        typeNew="date"
        typeExist="date"
        nameNew="market_launch_date"
        valueNew={props.value(props.mappingForm, "market_launch_date", "new")}
        nameExist="market_launch_date_exist"
        valueExist={dateFormater(props.value(props.mappingForm, "market_launch_date", "exist"))}
        checked={props.value(props.mappingForm, "market_launch_date", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.WEIGHT_PER_UNIT")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.WEIGHT_PER_UNIT")})}
        typeNew="text"
        typeExist="text"
        nameNew="weight_per_unit"
        valueNew={props.value(props.mappingForm, "weight_per_unit", "new")}
        nameExist="weight_per_unit_exist"
        valueExist={props.value(props.mappingForm, "weight_per_unit", "exist")}
        checked={props.value(props.mappingForm, "weight_per_unit", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        pattern={REGEX_CONSTANT.FLOATPLUS}
        message={t("COMMON:ERROR.NUMBER", {field: t("COMMON:FIELD.WEIGHT_PER_UNIT")})}
      />
      <FieldComparison
        label={t("COMMON:FIELD.DRY_WEIGHT_PER_UNIT")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.DRY_WEIGHT_PER_UNIT")})}
        typeNew="text"
        typeExist="text"
        nameNew="dry_weight_per_unit"
        valueNew={props.value(props.mappingForm, "dry_weight_per_unit", "new")}
        nameExist="dry_weight_per_unit_exist"
        valueExist={props.value(props.mappingForm, "dry_weight_per_unit", "exist")}
        checked={props.value(props.mappingForm, "dry_weight_per_unit", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        pattern={REGEX_CONSTANT.FLOATPLUS}
        message={t("COMMON:ERROR.NUMBER", {field: t("COMMON:FIELD.DRY_WEIGHT_PER_UNIT")})}
      />
      <FieldComparison
        label={t("COMMON:FIELD.NET_WEIGHT_PER_CASE")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.NET_WEIGHT_PER_CASE")})}
        typeNew="text"
        typeExist="text"
        nameNew="net_weight_per_case"
        valueNew={props.value(props.mappingForm, "net_weight_per_case", "new")}
        nameExist="net_weight_per_case_exist"
        valueExist={props.value(props.mappingForm, "net_weight_per_case", "exist")}
        checked={props.value(props.mappingForm, "net_weight_per_case", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        pattern={REGEX_CONSTANT.FLOATPLUS}
        message={t("COMMON:ERROR.NUMBER", {field: t("COMMON:FIELD.NET_WEIGHT_PER_CASE")})}
      />
      <FieldComparison
        label={t("COMMON:FIELD.VOLUME_PER_UNIT")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.VOLUME_PER_UNIT")})}
        typeNew="text"
        typeExist="text"
        nameNew="volume_per_unit"
        valueNew={props.value(props.mappingForm, "volume_per_unit", "new")}
        nameExist="volume_per_unit_exist"
        valueExist={props.value(props.mappingForm, "volume_per_unit", "exist")}
        checked={props.value(props.mappingForm, "volume_per_unit", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        pattern={REGEX_CONSTANT.FLOATPLUS}
        message={t("COMMON:ERROR.NUMBER", {field: t("COMMON:FIELD.VOLUME_PER_UNIT")})}
      />
      <FieldComparison
        label={t("COMMON:FIELD.CASE_WIDTH")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.CASE_WIDTH")})}
        typeNew="text"
        typeExist="text"
        nameNew="case_width"
        valueNew={props.value(props.mappingForm, "case_width", "new")}
        nameExist="case_width_exist"
        valueExist={props.value(props.mappingForm, "case_width", "exist")}
        checked={props.value(props.mappingForm, "case_width", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        pattern={REGEX_CONSTANT.FLOATPLUS}
        message={t("COMMON:ERROR.NUMBER", {field: t("COMMON:FIELD.CASE_WIDTH")})}
      />
      <FieldComparison
        label={t("COMMON:FIELD.CASE_HEIGHT")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.CASE_HEIGHT")})}
        typeNew="text"
        typeExist="text"
        nameNew="case_height"
        valueNew={props.value(props.mappingForm, "case_height", "new")}
        nameExist="case_height_exist"
        valueExist={props.value(props.mappingForm, "case_height", "exist")}
        checked={props.value(props.mappingForm, "case_height", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        pattern={REGEX_CONSTANT.FLOATPLUS}
        message={t("COMMON:ERROR.NUMBER", {field: t("COMMON:FIELD.CASE_HEIGHT")})}
      />
      <FieldComparison
        label={t("COMMON:FIELD.CASE_WEIGHT")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.CASE_WEIGHT")})}
        typeNew="text"
        typeExist="text"
        nameNew="case_weight"
        valueNew={props.value(props.mappingForm, "case_weight", "new")}
        nameExist="case_weight_exist"
        valueExist={props.value(props.mappingForm, "case_weight", "exist")}
        checked={props.value(props.mappingForm, "case_weight", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        pattern={REGEX_CONSTANT.FLOATPLUS}
        message={t("COMMON:ERROR.NUMBER", {field: t("COMMON:FIELD.CASE_WEIGHT")})}
      />
      <FieldComparison
        label={t("COMMON:FIELD.CASE_DEPTH")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.CASE_DEPTH")})}
        typeNew="text"
        typeExist="text"
        nameNew="case_depth"
        valueNew={props.value(props.mappingForm, "case_depth", "new")}
        nameExist="case_depth_exist"
        valueExist={props.value(props.mappingForm, "case_depth", "exist")}
        checked={props.value(props.mappingForm, "case_depth", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        pattern={REGEX_CONSTANT.FLOATPLUS}
        message={t("COMMON:ERROR.NUMBER", {field: t("COMMON:FIELD.CASE_DEPTH")})}
      />
      <FieldComparison
        label={t("COMMON:FIELD.FARMALKES_ID")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.FARMALKES_ID")})}
        typeNew="text"
        typeExist="text"
        nameNew="farmalkes_id"
        valueNew={props.value(props.mappingForm, "farmalkes_id", "new")}
        nameExist="farmalkes_id_exist"
        valueExist={props.value(props.mappingForm, "farmalkes_id", "exist")}
        checked={props.value(props.mappingForm, "farmalkes_id", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.FARMALKES_DESC")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.FARMALKES_DESC")})}
        typeNew="text"
        typeExist="text"
        nameNew="farmalkes_desc"
        valueNew={props.value(props.mappingForm, "farmalkes_desc", "new")}
        nameExist="farmalkes_desc_exist"
        valueExist={props.value(props.mappingForm, "farmalkes_desc", "exist")}
        checked={props.value(props.mappingForm, "farmalkes_desc", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.NIE_NUMBER")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.NIE_NUMBER")})}
        typeNew="text"
        typeExist="text"
        nameNew="nie_number"
        valueNew={props.value(props.mappingForm, "nie_number", "new")}
        nameExist="nie_number_exist"
        valueExist={props.value(props.mappingForm, "nie_number", "exist")}
        checked={props.value(props.mappingForm, "nie_number", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.NIE_EXPIRED_DATE")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.NIE_EXPIRED_DATE")})}
        typeNew="date"
        typeExist="date"
        nameNew="nie_expired_date"
        valueNew={props.value(props.mappingForm, "nie_expired_date", "new")}
        nameExist="nie_expired_date_exist"
        valueExist={dateFormater(props.value(props.mappingForm, "nie_expired_date", "exist"))}
        checked={props.value(props.mappingForm, "nie_expired_date", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.BARCODE_LIST_FOR_UOM_1")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.BARCODE_LIST_FOR_UOM_1")})}
        typeNew="text"
        typeExist="text"
        nameNew="barcode_list_for_uom_1"
        valueNew={props.value(props.mappingForm, "barcode_list_for_uom_1", "new")}
        nameExist="barcode_list_for_uom_1_exist"
        valueExist={props.value(props.mappingForm, "barcode_list_for_uom_1", "exist")}
        checked={props.value(props.mappingForm, "barcode_list_for_uom_1", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        maxLength={Max.length100}
      />
      <FieldComparison
        label={t("COMMON:FIELD.BARCODE_LIST_FOR_UOM_2")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.BARCODE_LIST_FOR_UOM_2")})}
        typeNew="text"
        typeExist="text"
        nameNew="barcode_list_for_uom_2"
        valueNew={props.value(props.mappingForm, "barcode_list_for_uom_2", "new")}
        nameExist="barcode_list_for_uom_2_exist"
        valueExist={props.value(props.mappingForm, "barcode_list_for_uom_2", "exist")}
        checked={props.value(props.mappingForm, "barcode_list_for_uom_2", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        maxLength={Max.length100}
      />
      <FieldComparison
        label={t("COMMON:FIELD.BARCODE_LIST_FOR_UOM_3")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.BARCODE_LIST_FOR_UOM_3")})}
        typeNew="text"
        typeExist="text"
        nameNew="barcode_list_for_uom_3"
        valueNew={props.value(props.mappingForm, "barcode_list_for_uom_3", "new")}
        nameExist="barcode_list_for_uom_3_exist"
        valueExist={props.value(props.mappingForm, "barcode_list_for_uom_3", "exist")}
        checked={props.value(props.mappingForm, "barcode_list_for_uom_3", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        maxLength={Max.length100}
      />
      <FieldComparison
        label={t("COMMON:FIELD.BARCODE_LIST_FOR_UOM_4")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.BARCODE_LIST_FOR_UOM_4")})}
        typeNew="text"
        typeExist="text"
        nameNew="barcode_list_for_uom_4"
        valueNew={props.value(props.mappingForm, "barcode_list_for_uom_4", "new")}
        nameExist="barcode_list_for_uom_4_exist"
        valueExist={props.value(props.mappingForm, "barcode_list_for_uom_4", "exist")}
        checked={props.value(props.mappingForm, "barcode_list_for_uom_4", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        maxLength={Max.length100}
      />
      <FieldComparison
        label={t("COMMON:FIELD.IS_FREE_GOOD_ITEM")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.IS_FREE_GOOD_ITEM")})}
        typeNew="switch"
        typeExist="text"
        nameNew="free_good_status"
        valueNew={props.value(props.mappingForm, "free_good_status", "new")}
        nameExist="free_good_status_exist"
        valueExist={props.value(props.mappingForm, "free_good_status", "exist")}
        checked={props.value(props.mappingForm, "free_good_status", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("PRODUCT:FIELD.IS_EMBALACE_ITEM")}
        typeNew="switch"
        typeExist="text"
        nameNew="embalace_status"
        valueNew={props.value(props.mappingForm, "embalace_status", "new")}
        nameExist="embalace_status_exist"
        valueExist={props.value(props.mappingForm, "embalace_status", "exist")}
        checked={props.value(props.mappingForm, "embalace_status", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("PRODUCT:FIELD.IS_TAX_1_APPLIED")}
        typeNew="switch"
        typeExist="text"
        nameNew="tax_1_applied_status"
        valueNew={props.value(props.mappingForm, "tax_1_applied_status", "new")}
        nameExist="tax_1_applied_status_exist"
        valueExist={props.value(props.mappingForm, "tax_1_applied_status", "exist")}
        checked={props.value(props.mappingForm, "tax_1_applied_status", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("PRODUCT:FIELD.IS_TAX_2_APPLIED")}
        typeNew="switch"
        typeExist="text"
        nameNew="tax_2_applied_status"
        valueNew={props.value(props.mappingForm, "tax_2_applied_status", "new")}
        nameExist="tax_2_applied_status_exist"
        valueExist={props.value(props.mappingForm, "tax_2_applied_status", "exist")}
        checked={props.value(props.mappingForm, "tax_2_applied_status", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("PRODUCT:FIELD.IS_TAX_3_APPLIED")}
        typeNew="switch"
        typeExist="text"
        nameNew="tax_3_applied_status"
        valueNew={props.value(props.mappingForm, "tax_3_applied_status", "new")}
        nameExist="tax_3_applied_status_exist"
        valueExist={props.value(props.mappingForm, "tax_3_applied_status", "exist")}
        checked={props.value(props.mappingForm, "tax_3_applied_status", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("PRODUCT:FIELD.IS_TAX_SUBSIDIZED")}
        typeNew="switch"
        typeExist="text"
        nameNew="tax_subsidized_status"
        valueNew={props.value(props.mappingForm, "tax_subsidized_status", "new")}
        nameExist="tax_subsidized_status_exist"
        valueExist={props.value(props.mappingForm, "tax_subsidized_status", "exist")}
        checked={props.value(props.mappingForm, "tax_subsidized_status", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.CLASS")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.CLASS")})}
        typeNew="text"
        typeExist="text"
        nameNew="class"
        valueNew={props.value(props.mappingForm, "class", "new")}
        nameExist="class_exist"
        valueExist={props.value(props.mappingForm, "class", "exist")}
        checked={props.value(props.mappingForm, "class", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
      />{" "}
      <FieldComparison
        label={t("COMMON:FIELD.REPORT_COLUMN_HEADER_1")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.REPORT_COLUMN_HEADER_1")})}
        typeNew="text"
        typeExist="text"
        nameNew="report_column_header_1"
        valueNew={props.value(props.mappingForm, "report_column_header_1", "new")}
        nameExist="report_column_header_1_exist"
        valueExist={props.value(props.mappingForm, "report_column_header_1", "exist")}
        checked={props.value(props.mappingForm, "report_column_header_1", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.REPORT_COLUMN_HEADER_2")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.REPORT_COLUMN_HEADER_2")})}
        typeNew="text"
        typeExist="text"
        nameNew="report_column_header_2"
        valueNew={props.value(props.mappingForm, "report_column_header_2", "new")}
        nameExist="report_column_header_2_exist"
        valueExist={props.value(props.mappingForm, "report_column_header_2", "exist")}
        checked={props.value(props.mappingForm, "report_column_header_2", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.SORT_SEQUENCE")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.SORT_SEQUENCE")})}
        typeNew="number"
        typeExist="number"
        nameNew="sort_sequence"
        valueNew={props.value(props.mappingForm, "sort_sequence", "new")}
        nameExist="sort_sequence_exist"
        valueExist={props.value(props.mappingForm, "sort_sequence", "exist")}
        checked={props.value(props.mappingForm, "sort_sequence", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        pattern={REGEX_CONSTANT.INTEGER}
        message={t("COMMON:ERROR.NUMBER", {field: t("COMMON:FIELD.SORT_SEQUENCE")})}
        maxLength={Max.length35}
      />
      {/* <FieldComparison
        label={t("COMMON:FIELD.ADDITIONAL_MARGIN")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.ADDITIONAL_MARGIN")})}
        typeNew="text"
        typeExist="text"
        nameNew="additional_margin"
        valueNew={props.value(props.mappingForm, "additional_margin", "new")}
        nameExist="additional_margin_exist"
        valueExist={props.value(props.mappingForm, "additional_margin", "exist")}
        checked={props.value(props.mappingForm, "additional_margin", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
      /> */}
      <FieldComparison
        label={t("PRODUCT:FIELD.IS_NO_REGULAR_DISCOUNT")}
        typeNew="switch"
        typeExist="text"
        nameNew="no_regular_discount_status"
        valueNew={props.value(props.mappingForm, "no_regular_discount_status", "new")}
        nameExist="no_regular_discount_status_exist"
        valueExist={props.value(props.mappingForm, "no_regular_discount_status", "exist")}
        checked={props.value(props.mappingForm, "no_regular_discount_status", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
      />
    </>
  );
};

export default FieldProductMappingForm;
