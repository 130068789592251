import { NOTIFICATION_URL } from '../EndPoint/NotificationEndpoint';
import * as BaseAPI from './BaseAPIs';
import {
  isContentNotification,
  isContentNotificationErr,
  isStatus,
  isStatusErr,
} from '../../Utils/Response/Response';

export const getNotificationList = (token, page, limit, order, filter, notify, dispatch, action, errAction) => {
  return BaseAPI.doGet(
        NOTIFICATION_URL.LIST(page, limit, order, filter), token, null, null, notify, dispatch,
        (res) => action(res),
        (err) => errAction(err)
    )
}

export const deleteNotification = (token, data, notify, dispatch) => 
  BaseAPI.doDelete(NOTIFICATION_URL.DELETE(data.id), token, data, notify, dispatch, res=>isStatus(res), err=>isStatusErr(err))

export const getInitiateNotification = (token, filter, notify, dispatch, action) => {
  return BaseAPI.doGet(NOTIFICATION_URL.INITIATE(filter), token, null, null, notify, dispatch, res =>
    action(res)
  ) 
}

export const addNotification = (token, data, notify, dispatch) => {
  return BaseAPI.doPost(NOTIFICATION_URL.CREATE, token, data, notify, dispatch, res => isContentNotification(res), err => isContentNotificationErr(err))
}

export const editNotification = (token, data, notify, dispatch) => {
  return BaseAPI.doPut(NOTIFICATION_URL.EDIT(data.id), token, data, notify, dispatch, res => isContentNotification(res), err => isContentNotificationErr(err))
}
