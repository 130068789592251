import { ListEmpty } from 'nexcomponent-ui';

import {
  detailBroadcastCompanyProfile,
  detailBroadcastDivision,
  detailBroadcastPersonProfile,
  detailBroadcastPPUDC,
  detailBroadcastProduct,
  detailBroadcastProductBrand,
  detailBroadcastProductCategory,
  detailBroadcastProductGroup,
  detailBroadcastProductKeyAccount,
} from '../../../../../Enum/DetailBroadcast';
import { mappingDataTypeId } from '../../../../../Enum/IdSet/MappingDataType';
import {
  MAPPING_DATA_TYPE,
} from '../../../../../Enum/MappingDataType/MappingDataType';
import { itemDetailValue } from '../../../../../Utils/Value';
import { detail } from './Detail';
import { mapDetailItem } from './MapDetailItem';

const rejectReason = (t, data) => [
  {
    id: "tslrejectreason",
    title: t("COMMON:REJECT_REASON"),
    value: itemDetailValue(data.reject_reason),
  },
];

const fullDetail = (t, data, details, additionalDetail, typeModal) => {
  let arr = details(t, data);
  if (typeModal === "reject") {
    arr = [...details(t, data), ...additionalDetail(t, data)];
  }else{
    let newArr=details(t, data)
    arr=  newArr.slice(0, newArr.length-1)
  }
  return arr;
};

export const detailByMappingTypeData = (modal, mappingDataType, data, t) => {
  let ID = mappingDataTypeId;
  let Detail = <ListEmpty value={t("COMMON:LIST_EMPTY")} />;
  switch (mappingDataType) {
    case MAPPING_DATA_TYPE.PERSON_PROFILE:
      Detail = detail(
        ID.PERSON_PROFILE,
        mapDetailItem(fullDetail(t, data, detailBroadcastPersonProfile, rejectReason, modal))
      );
      break;
    case MAPPING_DATA_TYPE.DIVISION:
      Detail = detail(
        ID.PERSON_PROFILE,
        mapDetailItem(fullDetail(t, data, detailBroadcastDivision, rejectReason, modal))
      );
      break;
    case MAPPING_DATA_TYPE.PRODUCT_BRAND:
      Detail = detail(
        ID.PERSON_PROFILE,
        mapDetailItem(fullDetail(t, data, detailBroadcastProductBrand, rejectReason, modal))
      );
      break;
    case MAPPING_DATA_TYPE.PRODUCT_GROUP:
      Detail = detail(
        ID.PERSON_PROFILE,
        mapDetailItem(fullDetail(t, data, detailBroadcastProductGroup, rejectReason, modal))
      );
      break;
    case MAPPING_DATA_TYPE.COMPANY_PROFILE:
      Detail = detail(
        ID.PERSON_PROFILE,
        mapDetailItem(fullDetail(t, data, detailBroadcastCompanyProfile, rejectReason, modal))
      );
      break;
    case MAPPING_DATA_TYPE.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY:
      Detail = detail(
        ID.PERSON_PROFILE,
        mapDetailItem(fullDetail(t, data, detailBroadcastPPUDC, rejectReason, modal))
      );
      break;
    case MAPPING_DATA_TYPE.PRODUCT_CATEGORY:
      Detail = detail(
        ID.PERSON_PROFILE,
        mapDetailItem(fullDetail(t, data, detailBroadcastProductCategory, rejectReason, modal))
      );
      break;
    case MAPPING_DATA_TYPE.PRODUCT_KEY_ACCOUNT:
      Detail = detail(
        ID.PERSON_PROFILE,
        mapDetailItem(fullDetail(t, data, detailBroadcastProductKeyAccount, rejectReason, modal))
      );
      break;
    case MAPPING_DATA_TYPE.PRODUCT:
      Detail = detail(
        ID.PRODUCT,
        mapDetailItem(fullDetail(t, data, detailBroadcastProduct, rejectReason, modal))
      );
      break;
    default:
      return Detail;
  }
  return Detail;
};
