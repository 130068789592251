import { DASHBOARD_URL } from '../EndPoint/DasboardEndpoint';
import * as BaseAPI from './BaseAPIs';

export const getInitiateDashboard = (token, notify, dispatch, action) => {
  return BaseAPI.doGet(DASHBOARD_URL.INITIATE, token, null, null, notify, dispatch, response =>
    action(response)
  );
};
export const getMappingList = (token, page, limit, dateAsc, notify, dispatch, action) => {
  const url = DASHBOARD_URL.MAPPING_LIST(page, limit, dateAsc);
  return BaseAPI.doGet(url, token, null, null, notify, dispatch, response => action(response));
};
