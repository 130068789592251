import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  Button,
  Col,
  Div,
  Form,
  Input,
  Row,
  nexIcon
} from 'nexcomponent-ui'

import SelectMulti from '../../../../../Components/UnstableComponent/SelectMulti/SelectMulti'
import { BOX, BUTTON } from '../../../../../Enum/IdSet'

import style from './NotificationFilterForm.module.css'

import { ROUTER_CONSTANT } from '../../../../../Enum/RouterConstant/RouterConstant'
import { Link } from 'react-router-dom'
import { notificationStateGroup } from '../../../../../Store/StateGroup'
import { utilsActions } from '../../../../../Store/Reducers/UtilsReducer'

const NotificationFilterForm = props => {
  const [reset, setReset] = useState(false)
  const [form, setForm] = useState(null)
  const [searchByOpt, setSearchByOpt] = useState([
    {
      id: "notification_code",
      value: "Notification Code",
      isChecked: false
    },
    {
      id: "notification_name",
      value: "Notification Name",
      isChecked: false
    }
  ])
  const [ totalRow, validLimit, notificationList, loading, currentPage, sortDate, rowLimit ] = useSelector(notificationStateGroup)

  const [search, setSearch] = useState({resource: null, admin: null, mappingTitle: null})
  const [value, setValue] = useState(props.searchValue)

  const buttonReset = e => {
    e.preventDefault()
    
    props.dispatch(utilsActions.setLoading(true))
    props.dispatch(props.action.setNotificationFilter(""))
    props.dispatch(props.action.setSearchValue(""))
    props.dispatch(props.action.setSearchKey(null))

    setForm(null)
    setValue("")
    setSearchByOpt([
      {
        id: "notification_code",
        value: "Notification Code",
        isChecked: false
      },
      {
        id: "notification_name",
        value: "Notification Name",
        isChecked: false
      }
    ])
    
    props.list(
      currentPage,
      sortDate,
      props,
      rowLimit,
      props.filter
    )
  }

  useEffect(() => {
    if (reset) {
      setReset(false);
    }

    if (props.searchKey !== null) {
      let arr = searchByOpt.map(datas =>
        datas['id'] === props.searchKey ? {...datas, isChecked: true} : datas
      )
      
      setSearchByOpt(arr);

      setForm(prevsValue => {
        return {
          ...prevsValue,
          "admin": props.searchKey,
        }
      })
    }
  }, [reset])

  const onChange = async e => {
    const {value} = e.target;
    setValue(value);
  };
  
  const apply = async e => {
    e.preventDefault()
    
    props.dispatch(utilsActions.setLoading(true))
    props.dispatch(props.action.setNotificationFilter(form.admin+"+like+"+value))
    props.dispatch(props.action.setSearchValue(value))

    if (form !== null) {
      props.list(
        currentPage,
        sortDate,
        props,
        rowLimit,
        props.filter
      )
    }
  }

  const handleOnchange = (name, value) => {
    if (value !== "") {
      setForm(prevsValue => {
        return {
          ...prevsValue,
          [name]: value,
        }
      })
    } else {
      setForm(prevsValue => {
        return {
          ...prevsValue,
          [name]: "",
        }
      })
    }
  }

  const controlValue = (tempArr, key) => {
    let val = []

    for (var j = 0; j < tempArr.length; j++) {
      if (tempArr[j].isChecked === true) {
        val.push(tempArr[j][key])
      }
    }

    let stringvalue = ""
    stringvalue += val.map(itm => itm)

    return stringvalue
  }

  const handleChecked = (e, name, list, valueKey, setArr) => {
    const { value, checked } = e.target

    let currentCheckedObj = list.find(val => val.isChecked === true);
    
    if (currentCheckedObj === undefined) {
      if (checked === true) {
        let arr = list.map(datas => datas[valueKey].toString() === value ? {...datas, isChecked: checked} : datas)

        setArr(arr)

        props.dispatch(props.action.setSearchKey(value))
        handleOnchange(name, controlValue(arr, valueKey))
        props.dispatch(utilsActions.setLoading(true))
      } else {
        props.dispatch(props.action.setSearchKey(null))
        props.dispatch(utilsActions.setLoading(true))
      }   
    } else {
      if (currentCheckedObj.id === value) {
        let currentUncheckedIndex = list.findIndex(val => val.id !== value)
        list[currentUncheckedIndex] = {
          id: list[currentUncheckedIndex].id,
          value: list[currentUncheckedIndex].value,
          isChecked: false
        }

        let currentCheckedIndex = list.findIndex(val => val.id === value)
        list[currentCheckedIndex] = {
          id: list[currentCheckedIndex].id,
          value: list[currentCheckedIndex].value,
          isChecked: false
        }

        setArr(list)
        props.dispatch(props.action.setSearchKey(null))
        handleOnchange(name, controlValue(list, valueKey))
        props.dispatch(utilsActions.setLoading(true))
      } else {
        let newCheckedIndex = list.findIndex(val => val.id === value)
        list[newCheckedIndex] = {
          id: list[newCheckedIndex].id,
          value: list[newCheckedIndex].value,
          isChecked: true
        }

        let otherIndex = list.findIndex(val => val.id !== value)
        list[otherIndex] = {
          id: list[otherIndex].id,
          value: list[otherIndex].value,
          isChecked: false
        }

        setArr(list)
        props.dispatch(props.action.setSearchKey(value))
        handleOnchange(name, controlValue(list, valueKey))
        props.dispatch(utilsActions.setLoading(true))
      }
    }
  }

  const onChangeSearch = async (e, name, set) => {
    const { value } = e.target
    
    set(prevsValue => {
      return {
        ...prevsValue,
        [name]: value,
      };
    })
  }

  return (
    <Form className={style.Form} id={BOX.FORM}>
      <Row className={style.FormRow} id={BOX.ROW}>
        <Div className={style.FormColLeft} id={BOX.COL_LEFT}>
          <Row className={style.FormRow} id={BOX.COL_LEFT + BOX.ROW}>
            <Col className={style.Col} width={2} id={BOX.COL_LEFT + BOX.ROW + "admin"}>
              <Div className={style.FormInput}>
                <SelectMulti
                  name="admin"
                  placeholder={props.t("COMMON:FIELD_SEARCH_BY")}
                  option={searchByOpt}
                  valueKey="id"
                  labelKey="value"
                  checkedKey="isChecked"
                  onChecked={(e, valueKey) =>
                    handleChecked(e, "admin", searchByOpt, valueKey, setSearchByOpt)
                  }
                  onChange={evt => handleOnchange(evt)}
                  search={true}
                  valueSearch={search.admin}
                  onInputSearch={evt => onChangeSearch(evt, "admin", setSearch)}
                  valueNotFound={props.t("COMMON:DATA_NOT_FOUND")}
                />
              </Div>
            </Col>
            <Col className={style.Col} width={4} id={BOX.COL_LEFT + BOX.ROW + "admin"}>
              <Div className={style.FormInput}>
                <Div className={style.BoxInputSearch}>
                  <Input
                    id={props.id}
                    size="xs"
                    className={style.InputSearch}
                    name={props.name}
                    value={value}
                    placeholder={props.t("COMMON:FIELD_SEARCH_KEY")}
                    type="text"
                    onChange={onChange}
                  />
                  <Div className={style.IconSearch}>
                    <nexIcon.NcSearch color="rgba(153, 153, 153, 1)" width={5} height={5} />
                  </Div>
                </Div>
              </Div>
            </Col>
            <Div className={style.ButtonContainer}>
              <Button id={BUTTON.APPLY} label={props.t("COMMON:APPLY")} onClick={apply} />
              <Button
                id={BUTTON.RESET}
                label={props.t("COMMON:RESET_FILTER")}
                onClick={buttonReset}
                typeStyle="tertiary"
              />
            </Div>
            <Div className={style.RightButtonContainer}>
              <Link to={ROUTER_CONSTANT.NOTIFICATION.CREATE}>
                <Button id={BUTTON.APPLY} label={props.t("COMMON:ADD_NOTIFICATION")} />
              </Link>
            </Div>
          </Row>
        </Div>
      </Row>
    </Form>
  )
}

export default NotificationFilterForm
  