import React, { useCallback } from "react";

import { Div } from "nexcomponent-ui";

import { productGroupBreadCrumb } from "../../../../Enum/Breadcrumb/ProductGroupBreadcrumb";
import { productGroupColumn } from "../../../../Enum/Column/ProductGroupColumn";
import { PAGE } from "../../../../Enum/IdSet";
import { MAPPING_CODE } from "../../../../Enum/MappingCode/MappingCode";
import { ROUTER_CONSTANT } from "../../../../Enum/RouterConstant/RouterConstant";
import {
  postRejectProductGroupList,
  postRejectProductGroupPrincipalList,
} from "../../../../Fetch/Api/ProductGroupAPI";
import { fetchProductGroupMappingForm } from "../../../../Fetch/FetchData/ProductGroupData";
import CancelRejectButton from "../../../Organisms/Button/CancelRejectButton/CancelRejectButton";
import HeaderPage from "../../../Organisms/Header/HeaderPage/HeaderPage";
import ModalReject from "../../../Organisms/Modal/ModalReject/ModalReject";
import RejectReasonList from "../../../Organisms/TableList/RejectReasonList/RejectReasonList";
import useQueryRoute from "../../../../Hooks/useQueryRoute";
import { fetchProductGroupPrincipalMappingForm } from "../../../../Fetch/FetchData/ProductGroupPrincipalData";

const ProductGroupRejectReasonPage = (props) => {
  const { principal } = useQueryRoute();

  const rejectFunc = useCallback(
    (data, notify, dispatch) => {
      if (principal) {
        return postRejectProductGroupPrincipalList(props.validToken(), data, notify, dispatch);
      } else {
        return postRejectProductGroupList(props.validToken(), data, notify, dispatch);
      }
    },
    [principal, props]
  );

  const nextMapping = useCallback(
    (newId, existId, mappingList, nav) => {
      if (principal) {
        return fetchProductGroupPrincipalMappingForm(
          props.validToken(),
          props.dispatch,
          "principal_product_brand_id",
          newId,
          existId,
          mappingList,
          props.notify,
          () => props.navigate(nav)
        );
      } else {
        return fetchProductGroupMappingForm(
          props.validToken(),
          props.dispatch,
          "product_brand_id",
          newId,
          existId,
          mappingList,
          props.notify,
          () => props.navigate(nav)
        );
      }
    },
    [principal, props]
  );
  return (
    <Div id={PAGE.REJECT_LIST}>
      <ModalReject
        onReject={true}
        t={props.t}
        dispatch={props.dispatch}
        mappingCode={MAPPING_CODE.PRODUCT_GROUP}
        navigate={props.navigate}
        rejectFunc={(data, notify, dispatch) => rejectFunc(data, notify, dispatch)}
        uniqueKey={
          principal ? "principal_product_group_id" : "product_group_hierarchy_id"
        }
        nextMapping={(newId, existId, mappingList, nav) =>
          nextMapping(newId, existId, mappingList, nav)
        }
      />
      <HeaderPage
        title={props.t("PRODUCT_GROUP:TITLE")}
        listBreadcrumb={productGroupBreadCrumb(props.t)}
        defaultBreadcrumb={props.t("COMMON:REJECT_REASON")}
        toTitlePageto={ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.LIST}
      />
      <RejectReasonList
        t={props.t}
        dispatch={props.dispatch}
        column={productGroupColumn(props.t)}
        mappingCode={MAPPING_CODE.PRODUCT_GROUP}
      />
      <CancelRejectButton
        backPage={ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.LIST}
        mappingCode={MAPPING_CODE.PRODUCT_GROUP}
        onReject={true}
        t={props.t}
        dispatch={props.dispatch}
        navigate={props.navigate}
      />
    </Div>
  );
};

export default ProductGroupRejectReasonPage;
