import _ from 'lodash';

import { mappingActions } from '../Reducers/MappingReducer';

export const dataMapping = async (
  checked,
  nameNew,
  mappingForm,
  submitMapping,
  existData,
  dispatch
) => {
  let find = await _.find(mappingForm, ["key", nameNew]);

  if (checked) {
    dispatch(mappingActions.setSubmitMapping({...submitMapping, [nameNew]: find.new}));
  } else if (!checked) {
    dispatch(mappingActions.setSubmitMapping({...submitMapping, [nameNew]: existData[nameNew]}));
  }
};

export const onChangeMapping = (
  checked,
  name,
  value,
  type,
  mappingForm,
  submitMapping,
  dispatch,
  negatif
) => {
  let valueType = (type, value) => {
    if (value === "" && type === "number") {
      return null;
    } else if (type === "number") {
      if (value < 0 && !negatif) {
        return Number(value) * -1;
      } else {
        return Number(value);
      }
    } else {
      return value;
    }
  };
  let tempForm = mappingForm.map(datas =>
    datas.key === name ? {...datas, new: valueType(type, value)} : datas
  );
if(name==="level"&& value<=0){
  let resetParentSubmit={...submitMapping,...{parent_id:null,parent_code:null,parent_name:null}}
  let resetParentForm=tempForm.map(datas =>
        datas.key === "parent_id"|| datas.key === "parent_code"|| datas.key === "parent_name"
                 ? {...datas, new: null}
                 : datas)
onChangeDataMapping(checked, name, valueType(type, value), resetParentSubmit, resetParentForm, dispatch);
}else{

  onChangeDataMapping(checked, name, valueType(type, value), submitMapping, tempForm, dispatch);
}
};
const onChangeDataMapping = (checked, name, value, submitMapping, tempForm, dispatch) => {
  dispatch(mappingActions.setMappingForm(tempForm));
  if (checked) {
    if (submitMapping.hasOwnProperty(name)) {
      dispatch(mappingActions.setSubmitMapping({...submitMapping, [name]: value}));
    }
  }
};
