import React, { useEffect } from 'react';

import { Div } from 'nexcomponent-ui';
import { useSelector } from 'react-redux';

import { CARD } from '../../../../Enum/IdSet';
import {
  ROUTER_CONSTANT,
} from '../../../../Enum/RouterConstant/RouterConstant';
import {
  fetchDataInitiateDashboard,
} from '../../../../Fetch/FetchData/DashboardData';
import { dashboardAction } from '../../../../Store/Reducers/DashboardReducer';
import { utilsActions } from '../../../../Store/Reducers/UtilsReducer';
import {
  tokenInitiateLoading,
} from '../../../../Store/StateGroup/DashboardStateGroup';
import { month } from '../../../../Utils/Date/month';
import { times } from '../../../../Utils/Date/times';
import { loadingData } from '../../../../Utils/Handler/LoadingData';
import CardMapping from '../CardMapping/CardMapping';
import style from './CardDashboard.module.css';

function CardDashboard(props) {
  const locale = useSelector((state)=>state.authentication.language)
  const [initiate, loading] = useSelector(tokenInitiateLoading);
  useEffect(() => {
    fetchDataInitiateDashboard(props.validToken(), props.dispatch,props.notify);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dispatch, props.notify, props.validToken]);
  loadingData(loading, props);
  const toBroadcast = () => {
    props.navigate(ROUTER_CONSTANT.BROADCAST);
  };
  const toMapping = () => {
    props.dispatch(dashboardAction.setMenuLevel1("data"));
    props.dispatch(utilsActions.setSideMenu("data"));
    props.dispatch(utilsActions.setSideBarCollapse(true));
  };
  return (
    <Div id={props.id} className={style.Container}>
      <CardMapping
        id={CARD.UNMAPPED}
        className={style.CardUnMapped}
        isData={props.t("COMMON:ISDATA", {field: " "})}
        statusMap={props.t("COMMON:UNMAPPED")}
        isMapping={props.t("COMMON:MAPPING")}
        date={times(locale)}
        count={initiate.count_request_mapping}
        mapped={false}
        onClick={toMapping}
      />

      <CardMapping
        id={CARD.MAPPED}
        className={style.CardMapped}
        isData={props.t("COMMON:ISDATA")}
        statusMap={props.t("COMMON:MAPPED")}
        isMapping={props.t("COMMON:MAPPING")}
        title={props.t("COMMON:MAPPED_DATA")}
        subtitle={month(locale)}
        onClick={toBroadcast}
        count={initiate.count_already_mapped}
        mapped={true}
      />
    </Div>
  );
}

export default CardDashboard;
