import {
  Col,
  Div,
  Span,
} from 'nexcomponent-ui';

import { ID } from '../../../../../Enum/IdSet';
import DetailItem from '../../DetailItem/DetailItem';
import style from './Detail.module.css';

export const renderItem = (detail, index) => {
  function isJson() {
    detail.value = typeof detail.value !== "string" ? JSON.stringify(detail.value) : detail.value;

    try {
      detail.value = JSON.parse(detail.value);
    } catch (e) {
      return false;
    }
    if (typeof detail.value === "object" && detail.value !== null) {
      return true;
    }
    return false;
  }
  return (
    <Col className={style.Col} width={4} size="lg" id={ID.BOX + detail.id} key={index}>
      {detail.obj ? (
        <Div>
           <Div id={ detail.id+"title"}className={style.Title}>{detail.title}</Div>
          {isJson() && detail.value.length>0 ? (
            detail.value.map(data => (
              <>
              <Div className={style.Box}>
                <Span  className={style.Properties}value="Key : " />
                <Span value={data.key} />
                <br/>
                <Span className={style.Properties} value="Value : " />
                <Span value={data.value} />
              </Div>
              </>
            ))
          ) : (
            <Span  className={style.Box} value={"-"} />
          )}
        </Div>
      ) : (
        <DetailItem id={detail.id} title={detail.title} value={detail.value} />
      )}
    </Col>
  );
};
