import _ from 'lodash';

import { defaultValues } from '../../DefaultValue/DefaultValue';

export const subMenuList = (menu, locale) => {
  let overideMenu = () => {
    let tranform = {};
    tranform = menu.map(item => {
      if (
        item.en_name === "Country" ||
        item.en_name === "Subdistrict" ||
        item.en_name === "Urban Village" ||
        item.en_name === "Island" ||
        item.en_name === "District"||
        item.en_name === "Province"
      ) {
        return {
          id: item.id,
          name: item.name,
          en_name: item.en_name,
          url: item.url,
          sequence: item.sequence,
          mapping: false,
        };
      } else {
        return {
          id: item.id,
          name: item.name,
          en_name: item.en_name,
          url: item.url,
          sequence: item.sequence,
          mapping: true,
        };
      }
    });

    return tranform;
  };
  const subMenu = _.orderBy(_.reject(overideMenu(), {mapping: false}), "sequence", "asc");
  let data = {};
  if (locale === defaultValues.lang.us || locale === defaultValues.lang.en) {
    data = subMenu.map(item => {
      return {id: item.id, name: item.en_name, url: item.url};
    });
  }
  if (locale === defaultValues.lang.id) {
    data = subMenu.map(item => {
      return {id: item.id, name: item.name, url: item.url};
    });
  }
  return data;
};
