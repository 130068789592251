export const keyFilter = name => {
  let key = "";
  if (name === "admin") {
    key = "created_by+list_id+";
  } else if (name === "mapping_type") {
    key = "mapping_data_type+list_string+";
  } else if (name === "resource") {
    key = "resource+list_string+";
  } else if (name === "dateRange") {
    key = "created_at+range+";
  }
  return key;
};
export const keyFilterReject = name => {
  let key = "";
  if (name === "admin") {
    key = "updated_by+list_id+";
  } else if (name === "mapping_type") {
    key = "mapping_data_type+list_string+";
  } else if (name === "resource") {
    key = "resource+list_string+";
  } else if (name === "dateRange") {
    key = "updated_at+range+";
  }
  return key;
};