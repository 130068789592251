export const Max = { 
  length1: 1,
  length3: 3,
  length4: 4,
  length5: 5,
  length10: 10,
  length16: 16,
  length20: 20,
  length25: 25,
  length30: 30,
  length35: 35,
  length50: 50,
  length100: 100,
  length200: 200,
  length256: 256,
  length500: 500,
};
