import React from 'react';

import {
  Button,
  Div,
  ModalDialog,
} from 'nexcomponent-ui';

import { BUTTON } from '../../../../Enum/IdSet';
import { SIZESET } from '../../../../Enum/Props/SizeSet';
import { BTN_STYLE_TYPE } from '../../../../Enum/Style/ButtonTypeStyle';
import style from './Modal.module.css';

const Modal = props => {
  return (
    <ModalDialog
      id={props.id}
      classHeader={style.Header}
      classBody={style.Body}
      classFooter={style.Footer}
      title={props.title}
      content={props.content}
      onClose={props.onClose}
      hideClose={true}
      show={props.show}
      footer={
        <Div id={props.idFooter}>
          <Button
            id={BUTTON.CANCEL + props.idFooter}
            className={style.FooterButton}
            size={SIZESET.SM}
            typeStyle={BTN_STYLE_TYPE.SECONDARY}
            label={props.t("COMMON:CANCEL")}
            onClick={props.onClose}
          />
          <Button
            id={BUTTON.REJECT + props.idFooter}
            className={style.FooterButton}
            size={SIZESET.SM}
            label={props.labelYes}
            onClick={props.yesAction}
          />
        </Div>
      }
    />
  );
};

export default Modal;
