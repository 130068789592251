import React, { useCallback } from "react";

import { Div } from "nexcomponent-ui";

import { productBreadCrumb } from "../../../../Enum/Breadcrumb/ProductBreadcrumb";
import { productColumn } from "../../../../Enum/Column/ProductColumn";
import { PAGE } from "../../../../Enum/IdSet";
import { MAPPING_CODE } from "../../../../Enum/MappingCode/MappingCode";
import { ROUTER_CONSTANT } from "../../../../Enum/RouterConstant/RouterConstant";
import {
  postRejectProductList,
  postRejectProductPrincipalList,
} from "../../../../Fetch/Api/ProductAPI";
import { fetchProductMappingForm } from "../../../../Fetch/FetchData/ProductData";
import CancelRejectButton from "../../../Organisms/Button/CancelRejectButton/CancelRejectButton";
import HeaderPage from "../../../Organisms/Header/HeaderPage/HeaderPage";
import ModalReject from "../../../Organisms/Modal/ModalReject/ModalReject";
import RejectReasonList from "../../../Organisms/TableList/RejectReasonList/RejectReasonList";
import useQueryRoute from "../../../../Hooks/useQueryRoute";
import { fetchProductPrincipalMappingForm } from "../../../../Fetch/FetchData/ProductPrincipalData";

const ProductRejectReasonPage = (props) => {
  const { principal } = useQueryRoute();

  const rejectFunc = useCallback(
    (data, notify, dispatch) => {
      if (principal) {
        return postRejectProductPrincipalList(props.validToken(), data, notify, dispatch);
      } else {
        return postRejectProductList(props.validToken(), data, notify, dispatch);
      }
    },
    [principal, props]
  );

  const nextMapping = useCallback(
    (newId, existId, mappingList, nav) => {
      if (principal) {
        return fetchProductPrincipalMappingForm(
          props.validToken(),
          props.dispatch,
          "principal_product_brand_id",
          newId,
          existId,
          mappingList,
          props.notify,
          () => props.navigate(nav)
        );
      } else {
        return fetchProductMappingForm(
          props.validToken(),
          props.dispatch,
          "product_brand_id",
          newId,
          existId,
          mappingList,
          props.notify,
          () => props.navigate(nav)
        );
      }
    },
    [principal, props]
  );

  return (
    <Div id={PAGE.REJECT_LIST}>
      <ModalReject
        onReject={true}
        t={props.t}
        dispatch={props.dispatch}
        mappingCode={MAPPING_CODE.PRODUCT}
        navigate={props.navigate}
        rejectFunc={(data, notify, dispatch) => rejectFunc(data, notify, dispatch)}
        uniqueKey={principal ? "principal_product_id" : "product_id"}
        nextMapping={(newId, existId, mappingList, nav) =>
          nextMapping(newId, existId, mappingList, nav)
        }
      />
      <HeaderPage
        title={props.t("PRODUCT:TITLE")}
        listBreadcrumb={productBreadCrumb(props.t)}
        defaultBreadcrumb={props.t("COMMON:REJECT_REASON")}
        toTitlePageto={ROUTER_CONSTANT.PRODUCT.LIST}
      />
      <RejectReasonList
        t={props.t}
        dispatch={props.dispatch}
        column={productColumn(props.t)}
        mappingCode={MAPPING_CODE.PRODUCT}
      />
      <CancelRejectButton
        backPage={ROUTER_CONSTANT.PRODUCT.LIST}
        mappingCode={MAPPING_CODE.PRODUCT}
        onReject={true}
        t={props.t}
        dispatch={props.dispatch}
        navigate={props.navigate}
      />
    </Div>
  );
};

export default ProductRejectReasonPage;
