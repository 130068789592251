import { MAPPING_CODE } from '../../Enum/MappingCode/MappingCode';
import { ROUTER_CONSTANT } from '../../Enum/RouterConstant/RouterConstant';
import { mappingActions } from '../../Store/Reducers/MappingReducer';
import { utilsActions } from '../../Store/Reducers/UtilsReducer';

export function searchNavigate(mappingCode, dispatch) {
  let navigate = "/";
  dispatch(mappingActions.setSearchList(null));
  if (mappingCode === MAPPING_CODE.PERSON_PROFILE) {
    navigate = ROUTER_CONSTANT.PERSON_PROFILE.SEARCH;
  } else if (mappingCode === MAPPING_CODE.COMPANY_PROFILE) {
    navigate = ROUTER_CONSTANT.COMPANY_PROFILE.SEARCH;
  } else if (mappingCode === MAPPING_CODE.DIVISION) {
    navigate = ROUTER_CONSTANT.DIVISION.SEARCH;
  } else if (mappingCode === MAPPING_CODE.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY) {
    navigate = ROUTER_CONSTANT.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY.SEARCH;
  } else if (mappingCode === MAPPING_CODE.PRODUCT_BRAND) {
    navigate = ROUTER_CONSTANT.PRODUCT_BRAND.SEARCH;
  } else if (mappingCode === MAPPING_CODE.PRODUCT_GROUP) {
    navigate = ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.SEARCH;
  } else if (mappingCode === MAPPING_CODE.PRODUCT_CATEGORY) {
    navigate = ROUTER_CONSTANT.PRODUCT_CATEGORY.SEARCH;
  } else if (mappingCode === MAPPING_CODE.PRODUCT_KEY_ACCOUNT) {
    navigate = ROUTER_CONSTANT.PRODUCT_KEY_ACCOUNT.SEARCH;
  } else if (mappingCode === MAPPING_CODE.PRODUCT) {
    navigate = ROUTER_CONSTANT.PRODUCT.SEARCH;
  }
  dispatch(utilsActions.setLoading(false))
  return navigate;
}
export function searchReload(mappingCode) {
  let navigate = "/";
  if (mappingCode === MAPPING_CODE.PERSON_PROFILE) {
    navigate = ROUTER_CONSTANT.PERSON_PROFILE.SEARCH;
  } else if (mappingCode === MAPPING_CODE.COMPANY_PROFILE) {
    navigate = ROUTER_CONSTANT.COMPANY_PROFILE.SEARCH;
  } else if (mappingCode === MAPPING_CODE.DIVISION) {
    navigate = ROUTER_CONSTANT.DIVISION.SEARCH;
  } else if (mappingCode === MAPPING_CODE.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY) {
    navigate = ROUTER_CONSTANT.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY.SEARCH;
  } else if (mappingCode === MAPPING_CODE.PRODUCT_BRAND) {
    navigate = ROUTER_CONSTANT.PRODUCT_BRAND.SEARCH;
  } else if (mappingCode === MAPPING_CODE.PRODUCT_GROUP) {
    navigate = ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.SEARCH;
  } else if (mappingCode === MAPPING_CODE.PRODUCT_CATEGORY) {
    navigate = ROUTER_CONSTANT.PRODUCT_CATEGORY.SEARCH;
  } else if (mappingCode === MAPPING_CODE.PRODUCT_KEY_ACCOUNT) {
    navigate = ROUTER_CONSTANT.PRODUCT_KEY_ACCOUNT.SEARCH;
  } else if (mappingCode === MAPPING_CODE.PRODUCT) {
    navigate = ROUTER_CONSTANT.PRODUCT.SEARCH;
  }
  return navigate;
}
