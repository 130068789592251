export const MAPPING_DATA_TYPE = Object.freeze({
  PERSON_PROFILE: "Person Profile",
  COMPANY_PROFILE: "Company Profile",
  DIVISION:"Division",
  PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY: "Principal Product User Defined Category",
  PRODUCT_BRAND:"Product Brand",
  PRODUCT_CATEGORY:"Product Category",
  PRODUCT_GROUP:"Product Group",
  PRODUCT_KEY_ACCOUNT:"Product Key Account",
  PRODUCT:"Product"
});