import React, { useState } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';

import { closeModal } from '../../../../Store/Action/ModalAction';
import { utilsActions } from '../../../../Store/Reducers/UtilsReducer';
import Modal from '../Modal/Modal';
import ResultModal from '../ResultModal/ResultModal';

function ModalSave(props) {
  const [msg, setMsg] = useState(null);
  const result = useSelector(state => state.utils.resultModal);
  const sureSave = async () => {
    await closeModal(props.dispatch);
    let message = await props.approveFuc();
    setMsg(message);
  };
  const modal = useSelector(state => state.utils.modal);
  const handleCancel = () => {
    closeModal(props.dispatch);
  };
  const nextMapping = async () => {
    await props.dispatch(utilsActions.setResultModal(false));
    const first = await _.first(props.mappingList);
    await props.dispatch(utilsActions.setLoading(true))
     props.nextMapping(first.id, first[props.uniqueKey], props.mappingList);
  };
  const handleResultCancel = async () => {
    if (msg.success === true && props.mappingList.length > 0) {
      await props.dispatch(utilsActions.setLoading(true))
       nextMapping();
    } else if (msg.success === true && props.mappingList.length === 0) {
      props.navigate(props.listNavigate);
    } else {
      props.dispatch(utilsActions.setResultModal(false));
    }
  };
  const navigate = () => {
    if (props.mappingList.length > 0) {
      nextMapping();
    } else {
      props.navigate(props.listNavigate);
    }
  };
  return (
    <>
      <ResultModal
        success={msg === null ? null : msg.success}
        content={msg === null ? null : msg.message}
        onClose={handleResultCancel}
        show={result}
        t={props.t}
        labelYes={props.t("COMMON:YES_SURE")}
        sucessBtnLabel={
          props.mappingList.length > 0
            ? props.t("COMMON:NEXT_DATA_MAPPING")
            : props.t("COMMON:BACK_TO_LIST_MAPPING")
        }
        navigate={navigate}
      />
      <Modal
        title={props.customSaveTitleCondition ? props.customSaveTitle : props.t("COMMON:SAVE_DATA")}
        content={props.customSaveMessageCondition ? props.customSaveMessage : props.t("COMMON:SAVE_MESSAGE")}
        onClose={handleCancel}
        show={modal}
        t={props.t}
        yesAction={sureSave}
        labelYes={props.customLabelYesCondition ? props.customLabelYes : props.t("COMMON:YES_SURE")}
      />
    </>
  );
}

export default ModalSave;
