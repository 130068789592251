import React from 'react';

import {
  Button,
  Div,
  Image,
  ModalDialog,
  Paragraph,
} from 'nexcomponent-ui';

import { httpStatusImg } from '../../../../Assets/Images';
import { BUTTON } from '../../../../Enum/IdSet';
import { SIZESET } from '../../../../Enum/Props/SizeSet';
import { BTN_STYLE_TYPE } from '../../../../Enum/Style/ButtonTypeStyle';
import style from './ModalTimeOut.module.css';

const ModalTimeOut = props => {
  const reload=()=>{
window.location.reload()
  }
  return (
    <ModalDialog
      id={props.id}
      classHeader={style.Header}
      classBody={style.Body}
      classFooter={style.Footer}
      className={style.Modal}
      title={
        <Div>
          <Image src={ httpStatusImg.Error404} width="50%"/>
          <Paragraph
            className={style.Title}
            value={props.t("COMMON:CONNECTION_ERROR.TITLE")}
          />
        </Div>
      }
      content={props.content}
      onClose={reload}
      hideClose={true}
      show={props.show}
      footer={
        <Div id={props.idFooter}>
          
            <Button
              id={BUTTON.CANCEL + props.idFooter}
              className={`${style.FooterButton} ${style.FailButton} `}
              size={SIZESET.SM}
              typeStyle={BTN_STYLE_TYPE.SECONDARY}
              label={props.t("COMMON:TRY_AGAIN")}
              onClick={reload}
            />
        </Div>
      }
    />
  );
};

export default ModalTimeOut;
