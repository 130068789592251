import React from 'react';

import { Col } from 'nexcomponent-ui';
import { useSelector } from 'react-redux';

import { searchFormData } from '../../../../../Utils/Form';
import style from '../FieldSearch/Field.module.css';
import FieldSearch from '../FieldSearch/FieldSearch';

function FieldDivisionSearchForm(props) {
  const {t} = props;
  const searchData = useSelector(state => state.utils.searchData);

  const formData = e => {
    searchFormData(e, searchData, props.dispatch);
  };
  return (
    <>
      <Col className={style.Col} size="lg" width={6}>
        <FieldSearch
          className={style.InputLeft}
          label={t("DIVISION:FIELD.DIVISION_ID")}
          type="number"
          name="division_id"
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.DIVISION_ID")})}
          onChange={formData}
        />
        <FieldSearch
          className={style.InputLeft}
          label={t("COMMON:FIELD.PRINCIPAL_CODE")}
          type={"text"}
          name={"principal_code"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PRINCIPAL_CODE")})}
          onChange={formData}
        />
        <FieldSearch
          className={style.InputLeft}
          type={"text"}
          name={"principal_name"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PRINCIPAL_NAME")})}
          onChange={formData}
          label={t("COMMON:FIELD.PRINCIPAL_NAME")}
        />
        <FieldSearch
          className={style.InputLeft}
          type={"number"}
          name={"principal_id"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PRINCIPAL_ID")})}
          onChange={formData}
          label={t("COMMON:FIELD.PRINCIPAL_ID")}
        />
        <FieldSearch
          className={style.InputLeft}
          type={"text"}
          name={"code"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.CODE")})}
          onChange={formData}
          label={t("COMMON:FIELD.CODE")}
        />
      </Col>
      <Col className={style.Col} size="lg" width={6}>
        <FieldSearch
          className={style.InputRight}
          type={"text"}
          name={"name"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.NAME")})}
          onChange={formData}
          label={t("COMMON:FIELD.NAME")}
        />
        {/* <FieldSearch
          className={style.InputRight}
          type={"text"}
          name={"resources"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.RESOURCE")})}
          onChange={formData}
          valid={formValidation.resources}
          pattern={REGEX_CONSTANT.NAME}
          maxLength={16}
          message={t("COMMON:ERROR.NAME", {
            field: t("DIVISION:FIELD.RESOURCE"),
            exp: "NexMile",
            maxChar: 16,
          })}
          label={t("COMMON:FIELD.RESOURCE")}
        /> */}
      </Col>
    </>
  );
}

export default FieldDivisionSearchForm;
