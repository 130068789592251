import _ from 'lodash';

export const fieldMapping = [
  "person_profile_id",
  "person_title_name",
  "person_title_id",
  "auth_user_id",
  "client_id",
  "nik",
  "npwp",
  "first_name",
  "last_name",
  "sex",
  "address_1",
  "address_2",
  "address_3",
  "hamlet",
  "neighbourhood",
  "country_name",
  "country_id",
  "province_name",
  "province_code",
  "province_id",
  "district_name",
  "district_code",
  "district_id",
  "sub_district_name",
  "sub_district_id",
  "sub_district_code",
  "urban_village_name",
  "urban_village_code",
  "urban_village_id",
  "island_name",
  "island_code",
  "island_id",
  "postal_code_id",
  "postal_code",
  "phone",
  "alternative_phone",
  "email",
  "alternative_email",
  "birth_place",
  "birth_date",
  "occupation",
  "spouse_birth_date",
  "spouse_firstname",
  "spouse_lastname",
  "anniversary_date",
  "education_history",
  "job_experience_history",
  "remark",
  "additional_info",
];

export const fieldSubmitMapping = {
  person_profile_id: null,
  person_title_id: null,
  auth_user_id: null,
  client_id: null,
  nik: null,
  npwp: null,
  first_name: null,
  last_name: null,
  sex: null,
  address_1: null,
  address_2: null,
  address_3: null,
  hamlet: null,
  neighbourhood: null,
  country_id: null,
  province_id: null,
  district_id: null,
  sub_district_id: null,
  urban_village_id: null,
  island_id: null,
  postal_code_id: null,
  phone: null,
  alternative_phone: null,
  email: null,
  alternative_email: null,
  birth_place: null,
  birth_date: null,
  occupation: null,
  spouse_firstname: null,
  spouse_lastname: null,
  anniversary_date: null,
  education_history: null,
  job_experience_history: null,
  remark: null,
  additional_info: null,
};

let findData = (mappingform, key) => {
  let find = _.find(mappingform, ["key", key]);
  return find.new;
};
export const submitByChecked = (mappingform,submitMapping) => {
 
  let data = {
    id:submitMapping.id,
    person_profile_id: findData(mappingform, "person_profile_id"),
    person_title_id: findData(mappingform, "person_title_id"),
    auth_user_id: findData(mappingform, "auth_user_id"),
    client_id: findData(mappingform, "client_id"),
    nik: findData(mappingform, "nik"),
    npwp: findData(mappingform, "npwp"),
    first_name: findData(mappingform, "first_name"),
    last_name: findData(mappingform, "last_name"),
    sex: findData(mappingform, "sex"),
    address_1: findData(mappingform, "address_1"),
    address_2: findData(mappingform, "address_2"),
    address_3: findData(mappingform, "address_3"),
    hamlet: findData(mappingform, "hamlet"),
    neighbourhood: findData(mappingform, "neighbourhood"),
    country_name: findData(mappingform, "country_name"),
    country_id: findData(mappingform, "country_id"),
    province_name: findData(mappingform, "province_name"),
    province_code: findData(mappingform, "province_code"),
    province_id: findData(mappingform, "province_id"),
    district_name: findData(mappingform, "district_name"),
    district_code: findData(mappingform, "district_code"),
    district_id: findData(mappingform, "district_id"),
    sub_district_id: findData(mappingform, "sub_district_id"),
    urban_village_id: findData(mappingform, "urban_village_id"),
    island_id: findData(mappingform, "island_id"),
    postal_code_id: findData(mappingform, "postal_code_id"),
    phone: findData(mappingform, "phone"),
    alternative_phone: findData(mappingform, "alternative_phone"),
    email: findData(mappingform, "email"),
    alternative_email: findData(mappingform, "alternative_email"),
    birth_place: findData(mappingform, "birth_place"),
    birth_date: findData(mappingform, "birth_date"),
    occupation: findData(mappingform, "occupation"),
    spouse_firstname: findData(mappingform, "spouse_firstname"),
    spouse_lastname: findData(mappingform, "spouse_lastname"),
    anniversary_date: findData(mappingform, "anniversary_date"),
    education_history: findData(mappingform, "education_history"),
    job_experience_history: findData(mappingform, "job_experience_history"),
    remark: findData(mappingform, "remark"),
    additional_info: findData(mappingform, "additional_info"),
    resource: submitMapping.resource
  };
  
    return data
  
};



