import { Breadcrumb, Div, Heading, TitlePage } from "nexcomponent-ui";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BREADCRUMB, TEXT_SINGLE } from "../../../../Enum/IdSet";
import style from "./MappingTabList.module.css";
import { mappingActions } from "../../../../Store/Reducers/MappingReducer";
import { utilsActions } from "../../../../Store/Reducers/UtilsReducer";
import { useSelector } from "react-redux";

const MappingTabList = (props) => {
  const [list, setList] = useState(props.list);
  const [load, setLoad] = useState(true);
  const loading=useSelector(state=> state.utils.loading)

  const handleClick = (index) => {
    if((!loading||!load)&&  !list[index]?.isShow ){

      setList((prevState) =>
        prevState.map((item, i) =>
          i === index ? { ...item, isShow: true } : { ...item, isShow: false }
        )
      );
        props.dispatch(mappingActions.setMappingList([]));
        props.dispatch(mappingActions.setRejectList([]));
        props.dispatch(mappingActions.setList([]));
        props.dispatch(utilsActions.setLoading(true));
        props.dispatch(utilsActions.setCurrentPage(1));
        setLoad(true)
    }
      
  };

  useEffect(()=>{
    if(load){
     setTimeout(() => {
      
       setLoad(false)
     }, 1500);
    }
  },[load]);

  const component = useMemo(() => list.find((item) => item.isShow)?.component, [list]);

  return (
    <div>
      <Breadcrumb
        id={BREADCRUMB.HEADER}
        list={props.listBreadcrumb}
        default={props.defaultBreadcrumb}
      />
      <TitlePage
        id={TEXT_SINGLE.TITLE_PAGE}
        className={style.Title}
        to={props.toTitlePage}
        title={props.title}
      />
      <Div className={style.Container}>
        <Div className={style.TabChoose}>
          {list.map((list, index) => (
            <Div
              className={list.isShow ? style.TabChooseItemActive : style.TabChooseItem}
              key={index}
              onClick={() => handleClick(index)}
            >
              <Heading size={7} value={list?.title} />
            </Div>
          ))}
        </Div>
        {component(load)}
      </Div>
    </div>
  );
};

export default MappingTabList;
