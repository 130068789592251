import React from 'react';

import { Div } from 'nexcomponent-ui';
import { useSelector } from 'react-redux';

import {
  broadcastBreadcrumb,
} from '../../../Enum/Breadcrumb/BroadcastBreadcrumb';
import { PAGE } from '../../../Enum/IdSet';
import { ROUTER_CONSTANT } from '../../../Enum/RouterConstant/RouterConstant';
import { getBroadcastAdminList } from '../../../Fetch/Api/BroadcastAPI';
import {
  fetchDataBroadcastList,
  fetchDataInitiateBroadcast,
} from '../../../Fetch/FetchData/BroadcastData';
import { broadcastsActions } from '../../../Store/Reducers/BroadcastReducer';
import { keyFilter } from '../../../Utils/Filter/KeyFilter';
import FilterForm from '../../Organisms/Form/SearchForm/FilterForm/FilterForm';
import HeaderPage from '../../Organisms/Header/HeaderPage/HeaderPage';
import BroadcastList
  from '../../Organisms/TableList/BroadcastList/BroadcastList';

const BroadcastPage = props => {
  const filter = useSelector(state => state.broadcast.filter);
  const admin = useSelector(state => state.broadcast.admin);
  const initiateReject = useSelector(state => state.broadcast.initiate);
  return (
    <Div id={PAGE.BROADCAST}>
      <HeaderPage
        title={props.t("BROADCAST:TITLE")}
        listBreadcrumb={broadcastBreadcrumb(props.t)}
        defaultBreadcrumb={props.t("BROADCAST:TITLE")}
        toTitlePageto={ROUTER_CONSTANT.BROADCAST}
      />
      <BroadcastList
        customHeader={
          <FilterForm
            validToken={props.validToken}
            dispatch={props.dispatch}
            keyFilter={filter => keyFilter(filter)}
            t={props.t}
            notify={props.notify}
            filter={filter}
            admin={admin}
            action={broadcastsActions}
            initiate={initiateReject}
            setInitiate={fetchDataInitiateBroadcast}
            list={fetchDataBroadcastList}
            getAdmin={getBroadcastAdminList}
          />
        }
        t={props.t}
        validToken={props.validToken}
        dispatch={props.dispatch}
        navigate={props.navigate}
        notify={props.notify}
      />
    </Div>
  );
};

export default BroadcastPage;
