import { defaultValues } from '../../DefaultValue/DefaultValue';

export const subMenuTitle = (menu, locale) => {
  let submenu = null;
  if (locale === defaultValues.lang.us || locale === defaultValues.lang.us) {
    submenu = menu.en_name;
  } else {
    submenu = menu.name;
  }
  return submenu;
};
