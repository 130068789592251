import React, { useEffect } from 'react';

import _ from 'lodash';
import { Div } from 'nexcomponent-ui';
import { useSelector } from 'react-redux';

import {
  companyProfileBreadCrumb,
} from '../../../../Enum/Breadcrumb/CompanyProfileBreadcrumb';
import { MAPPING_CODE } from '../../../../Enum/MappingCode/MappingCode';
import {
  ROUTER_CONSTANT,
} from '../../../../Enum/RouterConstant/RouterConstant';
import {
  fetchCompanyProfileMappingForm,
  fetchDataApprovalCompanyProfile,
} from '../../../../Fetch/FetchData/CompanyProfileData';
import { mappingFormPageStateGroup } from '../../../../Store/StateGroup';
import CancelOverwrite
  from '../../../Organisms/Button/CancelOverwrite/CancelOverwrite';
import MappingForm
  from '../../../Organisms/Form/MappingForm/MappingForm/MappingForm';
import HeaderPageMapping
  from '../../../Organisms/Header/HeaderPageMapping/HeaderPageMapping';
import ModalMapping from '../../../Organisms/Modal/ModalMapping/ModalMapping';

const CompanyProfileMappingPage = props => {
  const [, list, , mappingForm] = useSelector(mappingFormPageStateGroup);

  useEffect(() => {
    if (_.has(props.location.state, "data")) {
      const first = _.first(list);
      fetchCompanyProfileMappingForm(
        props.validToken(),
        props.dispatch,
        "company_profile_id",
        first.id,
        props.location.state.data.company_profile_id,
        list,
        props.notify,
        () => props.navigate(ROUTER_CONSTANT.COMPANY_PROFILE.MAPPING)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, props.location.state, props.dispatch, props.validToken]);
  return (
    <Div>
      <ModalMapping
        t={props.t}
        dispatch={props.dispatch}
        navigate={props.navigate}
        listNavigate={ROUTER_CONSTANT.COMPANY_PROFILE.LIST}
        approveFuc={(data, mappingList, mappingCount) =>
          fetchDataApprovalCompanyProfile(
            props.validToken(),
            data,
            mappingList,
            mappingCount,
            props.notify,
            props.dispatch,
            props.t("COMMON:ERROR.NUMBER", {field: props.t("COMMON:FIELD.LONGLAT")})
          )
        }
        uniqueKey="company_profile_id"
        nextMapping={(uniqueKey, newId, existId, mappingList) =>
          fetchCompanyProfileMappingForm(
            props.validToken(),
            props.dispatch,
            uniqueKey,
            newId,
            existId,
            mappingList,
            props.notify,
            () => props.navigate(ROUTER_CONSTANT.COMPANY_PROFILE.MAPPING)
          )
        }
      />

      <HeaderPageMapping
        title={props.t("COMPANY_PROFILE:TITLE")}
        listBreadcrumb={companyProfileBreadCrumb(props.t)}
        defaultBreadcrumb={props.t("COMPANY_PROFILE:MAPPING.TITLE")}
        to={ROUTER_CONSTANT.COMPANY_PROFILE.LIST}
      />
      <MappingForm
        t={props.t}
        validToken={props.validToken}
        dispatch={props.dispatch}
        navigate={props.navigate}
        idKey="company_profile_id"
        mappingCode={MAPPING_CODE.COMPANY_PROFILE}
        mappingForm={mappingForm}
      />
      <CancelOverwrite
        t={props.t}
        dispatch={props.dispatch}
        navigate={props.navigate}
        idKey="company_profile_id"
        mappingForm={mappingForm}
        mappingCode={MAPPING_CODE.COMPANY_PROFILE}
      />
    </Div>
  );
};

export default CompanyProfileMappingPage;
