import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import { DEFAULT_LANGUAGE } from '../Enum/PropertiesConstant';
import { resourceLanguage } from '../Utils/ResourcesLanguage';

i18next.use(initReactI18next).init({
  resources: resourceLanguage,
  fallbackLng: DEFAULT_LANGUAGE,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  react: {
    useSuspense: true,
  },
});

export default i18next;


