import { MAPPING_CODE } from "../../../../../Enum/MappingCode/MappingCode";
import FieldCompanyProfileMappingForm from "../FieldMappingFrom/FieldCompanyProfileMappingForm/FieldCompanyProfileMappingForm";
import FieldDivisionMappingForm from "../FieldMappingFrom/FieldDivisionMappingForm/FieldDivisionMappingForm";
import FieldPersonProfileMappingForm from "../FieldMappingFrom/FieldPersonProfileMappingForm/FieldPersonProfileMappingForm";
import FieldPPUDCMappingForm from "../FieldMappingFrom/FieldPPUDCMappingForm/FieldPPUDCMappingForm";
import FieldProductBrandMappingForm from "../FieldMappingFrom/FieldProductBrandMappingForm/FieldProductBrandMappingForm";
import FieldProductBrandPrincipalMappingForm from "../FieldMappingFrom/FieldProductBrandPrincipalMappingForm/FieldProductBrandPrincipalMappingForm";
import FieldProductCategoryMappingForm from "../FieldMappingFrom/FieldProductCategoryMappingForm/FieldProductCategoryMappingForm";
import FieldProductGroupMappingForm from "../FieldMappingFrom/FieldProductGroupMappingForm/FieldProductGroupMappingForm";
import FieldProductGroupPrincipalMappingForm from "../FieldMappingFrom/FieldProductGroupPrincipalMappingForm/FieldProductGroupPrincipalMappingForm";
import FieldProductKeyAccountMappingForm from "../FieldMappingFrom/FieldProductKeyAccountMappingForm/FieldProductKeyAccountMappingForm";
import FieldProductMappingForm from "../FieldMappingFrom/FieldProductMappingForm/FieldProductMappingForm";
import FieldProductPrincipalMappingForm from "../FieldMappingFrom/FieldProductPrincipalMappingForm/FieldProductPrincipalMappingForm";

export const selectFieldMappingForm = (
  t,
  validToken,
  dispatch,
  checkedPartial,
  value,
  mappingForm,
  submitMapping,
  mappingCode,
  idKey,
  detail
) => {
  let field = <></>;
  switch (mappingCode) {
    case MAPPING_CODE.PERSON_PROFILE:
      field = (
        <FieldPersonProfileMappingForm
          t={t}
          validToken={validToken}
          mappingForm={mappingForm}
          submitMapping={submitMapping}
          mappingCode={mappingCode}
          value={(form, key, item) => value(form, key, item)}
          checkedPartial={checkedPartial}
          dispatch={dispatch}
          idKey={idKey}
          detail={detail}
        />
      );
      break;
    case MAPPING_CODE.DIVISION:
      field = (
        <FieldDivisionMappingForm
          t={t}
          validToken={validToken}
          mappingForm={mappingForm}
          submitMapping={submitMapping}
          mappingCode={mappingCode}
          value={(form, key, item) => value(form, key, item)}
          checkedPartial={checkedPartial}
          dispatch={dispatch}
          idKey={idKey}
          detail={detail}
        />
      );
      break;
    case MAPPING_CODE.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY:
      field = (
        <FieldPPUDCMappingForm
          t={t}
          validToken={validToken}
          mappingForm={mappingForm}
          submitMapping={submitMapping}
          mappingCode={mappingCode}
          value={(form, key, item) => value(form, key, item)}
          checkedPartial={checkedPartial}
          dispatch={dispatch}
          idKey={idKey}
          detail={detail}
        />
      );
      break;
    case MAPPING_CODE.PRODUCT_CATEGORY:
      field = (
        <FieldProductCategoryMappingForm
          t={t}
          validToken={validToken}
          mappingForm={mappingForm}
          submitMapping={submitMapping}
          mappingCode={mappingCode}
          value={(form, key, item) => value(form, key, item)}
          checkedPartial={checkedPartial}
          dispatch={dispatch}
          idKey={idKey}
          detail={detail}
        />
      );
      break;
    case MAPPING_CODE.PRODUCT_GROUP:
      field = (
        <FieldProductGroupMappingForm
          t={t}
          validToken={validToken}
          mappingForm={mappingForm}
          submitMapping={submitMapping}
          mappingCode={mappingCode}
          value={(form, key, item) => value(form, key, item)}
          checkedPartial={checkedPartial}
          dispatch={dispatch}
          idKey={idKey}
          detail={detail}
        />
      );
      break;
    case MAPPING_CODE.PRODUCT_KEY_ACCOUNT:
      field = (
        <FieldProductKeyAccountMappingForm
          t={t}
          validToken={validToken}
          mappingForm={mappingForm}
          submitMapping={submitMapping}
          mappingCode={mappingCode}
          value={(form, key, item) => value(form, key, item)}
          checkedPartial={checkedPartial}
          dispatch={dispatch}
          idKey={idKey}
          detail={detail}
        />
      );
      break;
    case MAPPING_CODE.PRODUCT:
      field = (
        <FieldProductMappingForm
          t={t}
          validToken={validToken}
          mappingForm={mappingForm}
          submitMapping={submitMapping}
          mappingCode={mappingCode}
          value={(form, key, item) => value(form, key, item)}
          checkedPartial={checkedPartial}
          dispatch={dispatch}
          idKey={idKey}
          detail={detail}
        />
      );
      break;
    case MAPPING_CODE.COMPANY_PROFILE:
      field = (
        <FieldCompanyProfileMappingForm
          t={t}
          validToken={validToken}
          mappingForm={mappingForm}
          submitMapping={submitMapping}
          mappingCode={mappingCode}
          value={(form, key, item) => value(form, key, item)}
          checkedPartial={checkedPartial}
          dispatch={dispatch}
          idKey={idKey}
          detail={detail}
        />
      );
      break;
    case MAPPING_CODE.PRODUCT_BRAND:
      field = (
        <FieldProductBrandMappingForm
          t={t}
          validToken={validToken}
          mappingForm={mappingForm}
          submitMapping={submitMapping}
          mappingCode={mappingCode}
          value={(form, key, item) => value(form, key, item)}
          checkedPartial={checkedPartial}
          dispatch={dispatch}
          idKey={idKey}
          detail={detail}
        />
      );
      break;
    default:
      return field;
  }
  return field;
};

export const selectFieldPrincipalMappingForm = (
  t,
  validToken,
  dispatch,
  checkedPartial,
  value,
  mappingForm,
  submitMapping,
  mappingCode,
  idKey,
  detail
) => {
  let field = <></>;
  switch (mappingCode) {
    case MAPPING_CODE.PRODUCT_GROUP:
      field = (
        <FieldProductGroupPrincipalMappingForm
          t={t}
          validToken={validToken}
          mappingForm={mappingForm}
          submitMapping={submitMapping}
          mappingCode={mappingCode}
          value={(form, key, item) => value(form, key, item)}
          checkedPartial={checkedPartial}
          dispatch={dispatch}
          idKey={idKey}
          detail={detail}
        />
      );
      break;

    case MAPPING_CODE.PRODUCT:
      field = (
        <FieldProductPrincipalMappingForm
          t={t}
          validToken={validToken}
          mappingForm={mappingForm}
          submitMapping={submitMapping}
          mappingCode={mappingCode}
          value={(form, key, item) => value(form, key, item)}
          checkedPartial={checkedPartial}
          dispatch={dispatch}
          idKey={idKey}
          detail={detail}
        />
      );
      break;
    case MAPPING_CODE.PRODUCT_BRAND:
      field = (
        <FieldProductBrandPrincipalMappingForm
          t={t}
          validToken={validToken}
          mappingForm={mappingForm}
          submitMapping={submitMapping}
          mappingCode={mappingCode}
          value={(form, key, item) => value(form, key, item)}
          checkedPartial={checkedPartial}
          dispatch={dispatch}
          idKey={idKey}
          detail={detail}
        />
      );
      break;
    default:
      return field;
  }
  return field;
};
