import React, {
  useEffect,
  useState,
} from 'react';

import _ from 'lodash';
import {
  Button,
  Div,
  Image,
  Input,
  Paragraph,
  Span,
  useNotification,
} from 'nexcomponent-ui';
import { useSelector } from 'react-redux';

import { Icon } from '../../../../Assets/Icons';
import FieldAutoCompleteAdvanceSearch
  from '../../../../Components/Molecules/Field/FieldAutoCompleteAdvanceSearch/FieldAutoCompleteAdvanceSearch';
import DatePicker
  from '../../../../Components/UnstableComponent/DatePicker/DatePicker/DatePicker';
import { SIZESET } from '../../../../Enum/Props';
import { BTN_STYLE_TYPE } from '../../../../Enum/Style';
import { utilsActions } from '../../../../Store/Reducers/UtilsReducer';
import { resValue } from '../../../../Utils/Other/FindValues';
import style from './AdvanceSearch.module.css';

function AdvanceSearch(props) {
  const advanceSearch = useSelector(state => state.utils.advanceSearch);
  const searchOption = useSelector(state => state.utils.searchOption);

  const [open, setOpen] = useState(false);
  const toggleDropdown = () => setOpen(!open);
  const mouseLeave = () => setOpen(true);
  const notify = useNotification();
  useEffect(() => {
    props.dispatch(utilsActions.setSearchOption(props.option));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dispatch]);
  const reset = evt => {
    evt.preventDefault();
    props.dispatch(utilsActions.setSearchOption(props.option));
    props.dispatch(utilsActions.setSearchColumn([]));
    props.dispatch(utilsActions.setApplyAdvanceSearch({}));
    props.dispatch(
      utilsActions.setAdvanceSearch([
        {id: 1, searchTitle: "", searchName: "", searchValue: "", type: "text"},
      ])
    );
  };
  const addSearchParam = array => {
    if (advanceSearch.length <= 5) {
      props.dispatch(
        utilsActions.setAddAdvanceSearch({
          id: _.last(array).id + 1,
          searchTitle: "",
          searchName: "",
          searchValue: "",
          type: "text",
        })
      );
    } else {
      notify({type: "ERROR", message: props.t("COMMON:MAX_ITEM_ADVANCE_SEARCH")});
    }
  };

  const searchValue = (e, array) => {
    const {value, name, type} = e.target;
    let tempForm2 = array.map(datas =>
      datas.searchName === name ? {...datas, searchValue: resValue(type, value)} : datas
    );
    props.dispatch(utilsActions.setAdvanceSearch(tempForm2));
  };
  const searchTitle = async (value, array, index) => {
    const unSelected = await _.find(advanceSearch, ["id", index]);
    const existOption = searchOption.some(data => data === unSelected.searchTitle);
    let tempForm2 = array.map(datas =>
      datas.id === index ? {...datas, searchTitle: value} : datas
    );

    const filter = searchOption.filter(object => {
      return object.title !== value;
    });
    await props.dispatch(utilsActions.setAdvanceSearch(tempForm2));
    await props.dispatch(utilsActions.setSearchOption(filter));

    if (!existOption) {
      const defaultOption = props.option.some(data => data === unSelected.searchTitle);
      if (defaultOption) {
        const recoverOption = [...filter, unSelected.searchTitle];
        await props.dispatch(utilsActions.setSearchOption([...new Set(recoverOption.sort())]));
      }
    }
  };
  const handleSelect = async (value, array, index) => {
    const option = await _.find(searchOption, ["title", value]);
    const advance = _.find(advanceSearch, ["searchName", option.code]);

    if (advance === undefined) {
      let tempForm2 = array.map(datas =>
        datas.id === index
          ? {
              ...datas,
              searchTitle: value,
              searchName: option.code,
              maxLength: option.maxLength,
              type: option.type,
            }
          : datas
      );
      await props.dispatch(utilsActions.setAdvanceSearch(tempForm2));
    } else {
      notify({
        type: "ERROR",
        message: props.t("COMMON:CANNOT_DUPLICATE", {field: props.t("COMMON:SELECT_TITLE")}),
      });
    }
  };
  const deleteSearchParam = (index, length) => {
    if (length > 1) {
      const selected = _.find(advanceSearch, ["id", index]);

      const existOption = props.option.some(data => data.title === selected.searchTitle);
      if (existOption || selected.searchTitle === "") {
        const recoverOption = [...searchOption, selected.searchTitle];
        props.dispatch(utilsActions.setSearchOption(recoverOption.sort()));
        props.dispatch(utilsActions.setRemoveAdvanceSearch(index));
      }
    }
  };

  const cancelClick = e => {
    e.preventDefault();
    setOpen(false);
  };
  const applyClick = e => {
    e.preventDefault();
    const results = advanceSearch.filter(element => {
      return element?.searchValue !== "";
    });
    var applyObject = {};

    for (var i = 0; i < results.length; i++) {
      applyObject[results[i].searchName] = results[i].searchValue;
    }
    props.dispatch(utilsActions.setApplyAdvanceSearch(applyObject));
    setOpen(false);
    const advanceColumn = [];
    for (var j = 0; j < advanceSearch.length; j++) {
      const validColumn = _.find(props.option, ["code", advanceSearch[j].searchName]);
      if (validColumn !== undefined) {
        advanceColumn.push({
          name: advanceSearch[j].searchTitle,
          selector: advanceSearch[j].searchName,
          width: "100px",
        });
      }
    }
    props.dispatch(utilsActions.setSearchColumn(advanceColumn));
  };
  const applyDisable = advanceSearch.every(data => data?.searchTitle === "");
  const applyClass = applyDisable => {
    if (applyDisable) {
      return style.ButtonDisable;
    } else {
      return style.ButtonRight;
    }
  };

  return (
    <>
      <Div className={style.Toggle}>
        <Div className={style.ToggleButton} onClick={toggleDropdown}>
          <Paragraph className={style.Title} value={props.t("COMMON:ADVANCE_SEARCH")} />
          <Div style={{marginTop: "4px"}}>
            <Image src={!open ? Icon.arrowDown : Icon.arrowUp} width="20px" />
          </Div>
        </Div>
        {open ? (
          <Div className={style.DropDown} onMouseLeave={mouseLeave}>
            <Div className={style.Header}>
              <Span className={style.SelectTitle} value={props.t("COMMON:SELECT_TITLE")} />
              <Div className={style.ButtonHeader}>
                <Button
                  label={props.t("COMMON:BUTTON_RESET")}
                  size={SIZESET.XS}
                  typeStyle={BTN_STYLE_TYPE.TERTIARY}
                  onClick={reset}
                />
              </Div>
            </Div>

            {advanceSearch.map((item, index) => {
              return (
                <Div className={style.SelectItem} key={index}>
                  <Div className={style.Row}>
                    <Div className={style.ColLeft}>
                      <Div className={style.AutoComplete}>
                        <FieldAutoCompleteAdvanceSearch
                          itemId={item.id}
                          name={item.id}
                          value={item.searchTitle}
                          size="xs"
                          labelKey="title"
                          valueKey="code"
                          onChange={(value, id) => searchTitle(value, advanceSearch, id)}
                          onSelect={(value, id) => handleSelect(value, advanceSearch, id)}
                          suggestions={searchOption}
                          placeholder={props.t("COMMON:SELECT_TITLE")}
                        />
                      </Div>
                    </Div>
                    <Div className={style.ColRight}>
                      {item.type === "date" ? (
                        <DatePicker
                          onChange={e => searchValue(e, advanceSearch)}
                          placeholder={props.t("COMMON:SEARCH_HERE")}
                          size="xs"
                          name={item.searchName}
                          value={item.searchValue}
                        />
                      ) : (
                        <Input
                          size="xs"
                          name={item.searchName}
                          type={item.type}
                          onChange={e => searchValue(e, advanceSearch)}
                          value={item.searchValue}
                          placeholder={props.t("COMMON:SEARCH_HERE")}
                        />
                      )}
                    </Div>
                  </Div>
                  <Div className={style.ButtonGroupItem}>
                    <Div>
                      <Image src={Icon.Add} onClick={() => addSearchParam(advanceSearch)} />
                      <Image
                        src={advanceSearch.length > 1 ? Icon.Trash : Icon.TrashDesable}
                        onClick={() => deleteSearchParam(item.id, advanceSearch.length)}
                      />
                    </Div>
                  </Div>
                </Div>
              );
            })}
            <Div className={style.ButtonGroup}>
              <Button
                label={props.t("COMMON:CANCEL")}
                className={style.ButtonLeft}
                typeStyle={BTN_STYLE_TYPE.SECONDARY}
                size={SIZESET.XS}
                onClick={cancelClick}
              />
              <Button
                label={props.t("COMMON:APPLY")}
                size={SIZESET.XS}
                className={applyClass(applyDisable)}
                disabled={applyDisable}
                onClick={applyClick}
              />
            </Div>
          </Div>
        ) : (
          ""
        )}
      </Div>
    </>
  );
}

export default AdvanceSearch;
