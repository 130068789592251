import React, {
  useEffect,
  useState,
} from 'react';

import _ from 'lodash';
import {
  Div,
  Input,
  Li,
  nexIcon,
  Paragraph,
  Ul,
} from 'nexcomponent-ui';

import useComponentVisible
  from '../../../UnstableComponent/Multi/useComponentVisible';
import style from './DropDownSearch.module.css';

const DropDownSearch = props => {
  const {
    ref: componentRef,
    isComponentVisible: menuOpen,
    setIsComponentVisible: setMenuOpen,
  } = useComponentVisible({
    initialIsVisible: false,
    onClickOutside: props.onMenuClose,
  });
  const [open, setOpen] = useState(false);
  const [filtered, setFiltered] = useState([]);
  useEffect(() => {
    setFiltered(props.suggestions);
  }, [filtered, props.suggestions]);
  const nonClickableItem = target => {
    if (
      target.hasAttribute("clickable") ||
      target.parentNode.hasAttribute("clickable") ||
      target.parentNode.parentNode.hasAttribute("clickable") ||
      target.parentNode.parentNode.parentNode.hasAttribute("clickable")
    ) {
      return false;
    }
    return true;
  };

  const checkIsDropdownHandle = target => {
    if (
      target.hasAttribute("dropdown-handle") ||
      target.parentNode.hasAttribute("dropdown-handle") ||
      target.parentNode.parentNode.hasAttribute("dropdown-handle")
    ) {
      return true;
    }
  };
  const [value, setValue] = useState(null);
  const openMenu = ({target}) => {
    if (nonClickableItem(target)) {
      if (checkIsDropdownHandle(target)) {
        if (!menuOpen) {
          setMenuOpen(true);
        } else {
          setMenuOpen(false);
          props.onMenuClose();
        }
      } else if (target.id === "btndone" || target.id === "btncancel") {
        setMenuOpen(false);
      } else {
        setMenuOpen(true);
      }
    }
  };
  const onChange = async e => {
    const {value} = e.target;
    setValue(value);
    const newFilteredSuggestions = props.suggestions.filter(
      suggestion =>
        suggestion[props.labelKey].toString().toLowerCase().indexOf(value.toLowerCase()) > -1
    );
    setFiltered(newFilteredSuggestions);
    props.onChange(e);
  };

  const getWidth = () => {
    const el = componentRef.current;
    var rect = el.getBoundingClientRect();
    let position = rect.width;

    return position;
  };

  const onClick = e => {
    props.onClick(e);
    setOpen(true);
  };
  const handleScroll = async e => {
    const containerHeight = e.currentTarget.clientHeight;
    const scrollHeight = e.currentTarget.scrollHeight;
    const scrollTop = e.currentTarget.scrollTop;
    const progress = ((scrollTop + containerHeight) / scrollHeight) * 100;
    if (progress > 90) {
      props.onScroll(e);
    }
  };
  const onClickSelect = (e, value) => {
    setOpen(false);
    const newFilteredSuggestions = props.suggestions.filter(object => {
      return object[props.valueKey] !== value;
    });
    setFiltered(newFilteredSuggestions);
    setValue(e.currentTarget.innerText);
    props.onSelect({target: {name: props.name, value: e.currentTarget.innerText}});
  };
  const onClickReset = async() => {
     setOpen(false);
     setValue("");
    props.onSelect({target: {name: props.name, value: null, reset:true}});
  };
  const renderAutocomplete = () => {
    return (
      <Div id={props.id} className={style.Autocomplete} style={{width: getWidth() + "px"}}>
        <Div className={style.BoxInputSearch}>
          <Input
            id={props.id}
            size="xs"
            className={style.InputSearch}
            name={props.name}
            value={value}
            onChange={onChange}
            placeholder={props.placeholder}
            type="text"
          />
          <Div className={style.IconSearch}>
            <nexIcon.NcSearch color="rgba(153, 153, 153, 1)" width={5} height={5} />
          </Div>
        </Div>
        <Ul className={style.ListOption} onScroll={handleScroll}>
          {props.resetSelect ? (
            <Li onClick={onClickReset}>
              <Paragraph id={props.id} value="Reset Select" />
            </Li>
          ) : (
            <></>
          )}
          {filtered.length === 0 ? (
            <Li>
              <Paragraph id={props.id} value="Not found" />
            </Li>
          ) : (
            _.orderBy(filtered, ["value"], ["asc"]).map((item, index) => {
              return (
                <Li
                  id={props.id}
                  key={index}
                  onClick={e => onClickSelect(e, item[props.valueKey], item[props.labelKey])}
                >
                  {item[props.labelKey]}
                </Li>
              );
            })
          )}
        </Ul>
      </Div>
    );
  };
  const empty =""
  return (
    <Div ref={componentRef} onClick={openMenu}>
      <Div id={props.id} className={style.ContainerAutocomplete} tabIndex="0">
        <Input
          id={props.id}
          size={props.size}
          name={props.name}
          value={props.value===null?empty:props.value}
          onClick={onClick}
          className={style.Input}
          placeholder={props.placeholder}
          disabled={props.disabled}
          type="text"
        />
        <Div className={style.IconSelect}>
          <nexIcon.NcSelectOption color="#9b3789" width={10} height={10} />
        </Div>
      </Div>

      {menuOpen && open ? (
        <Div className={style.BoxOption} width={getWidth() + "px"}>
          {renderAutocomplete()}
        </Div>
      ) : (
        ""
      )}
    </Div>
  );
};

export default DropDownSearch;

DropDownSearch.defaultProps = {
  onMenuClose: () => {},
  onScroll: () => {},
  onClick: () => {},
  value: "",
  id: "",
  suggestions: [],
  size: "md",
  onSelect: () => {},
};
