import { itemDetailValue } from '../../Utils/Value';

export const detailBroadcastProductGroup = (t, data) => [
  {id: "tslcode", title: t("PRODUCT_GROUP:FIELD.CODE"), value: itemDetailValue(data.code)},
  {id: "tslname", title: t("PRODUCT_GROUP:FIELD.NAME"), value: itemDetailValue(data.name)},
  {id: "tslid", title: t("PRODUCT_GROUP:FIELD.ID"), value: itemDetailValue(data.id)},
  {
    id: "tslprincipalcode",
    title: t("PRODUCT_GROUP:FIELD.PRINCIPAL_CODE"),
    value: itemDetailValue(data.principal_code),
  },
  {
    id: "tslprincipalname",
    title: t("PRODUCT_GROUP:FIELD.PRINCIPAL_NAME"),
    value: itemDetailValue(data.principal_name),
  },
  {
    id: "tslprincipalid",
    title: t("PRODUCT_GROUP:FIELD.PRINCIPAL_ID"),
    value: itemDetailValue(data.principal_id),
  },
  {
    id: "tsllevel",
    title: t("COMMON:FIELD.LEVEL"),
    value: itemDetailValue(data.level),
  },
  {
    id: "tslcode",
    title: t("PRODUCT_GROUP:FIELD.PARENT_CODE"),
    value: itemDetailValue(data.parent_code),
  },
  {
    id: "tslparentname",
    title: t("PRODUCT_GROUP:FIELD.PARENT_NAME"),
    value: itemDetailValue(data.parent_name),
  },
  {
    id: "tslparentid",
    title: t("PRODUCT_GROUP:FIELD.PARENT_ID"),
    value: itemDetailValue(data.parent_id),
  },
  {
    id: "tslresource",
    title: t("PRODUCT_GROUP:FIELD.RESOURCE"),
    value: itemDetailValue(data.resource),
  },
];
