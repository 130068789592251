import { MAPPING_CODE } from '../../Enum/MappingCode/MappingCode';
import { ROUTER_CONSTANT } from '../../Enum/RouterConstant/RouterConstant';

export function mappingNavigate(mappingCode) {
  let navigate = "/";
  if (mappingCode === MAPPING_CODE.PERSON_PROFILE) {
    navigate = ROUTER_CONSTANT.PERSON_PROFILE.MAPPING;
  } else if (mappingCode === MAPPING_CODE.COMPANY_PROFILE) {
    navigate = ROUTER_CONSTANT.COMPANY_PROFILE.MAPPING;
  } else if (mappingCode === MAPPING_CODE.DIVISION) {
    navigate = ROUTER_CONSTANT.DIVISION.MAPPING;
  } else if (mappingCode === MAPPING_CODE.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY) {
    navigate = ROUTER_CONSTANT.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY.MAPPING;
  } else if (mappingCode === MAPPING_CODE.PRODUCT_BRAND) {
    navigate = ROUTER_CONSTANT.PRODUCT_BRAND.MAPPING;
  } else if (mappingCode === MAPPING_CODE.PRODUCT_GROUP) {
    navigate = ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.MAPPING;
  } else if (mappingCode === MAPPING_CODE.PRODUCT_CATEGORY) {
    navigate = ROUTER_CONSTANT.PRODUCT_CATEGORY.MAPPING;
  } else if (mappingCode === MAPPING_CODE.PRODUCT_KEY_ACCOUNT) {
    navigate = ROUTER_CONSTANT.PRODUCT_KEY_ACCOUNT.MAPPING;
  } else if (mappingCode === MAPPING_CODE.PRODUCT) {
    navigate = ROUTER_CONSTANT.PRODUCT.MAPPING;
  }
  return navigate;
}
