import React from 'react';

import { Div } from 'nexcomponent-ui';

import { mainBreadCrumb } from '../../../../Enum/Breadcrumb/CommonBreadcrumb';
import {
  productKeyAccountColumn,
} from '../../../../Enum/Column/ProductKeyAccountColumn';
import { PAGE } from '../../../../Enum/IdSet';
import { MAPPING_CODE } from '../../../../Enum/MappingCode/MappingCode';
import {
  ROUTER_CONSTANT,
} from '../../../../Enum/RouterConstant/RouterConstant';
import {
  fetchDataProductKeyAccountList,
  fetchProductKeyAccountMappingForm,
} from '../../../../Fetch/FetchData/ProductKeyAccountData';
import HeaderPageList
  from '../../../Organisms/Header/HeaderPageList/HeaderPageList';
import MappingList from '../../../Organisms/TableList/MappingList/MappingList';

const ProductKeyAccountPage = props => (
  <Div id={PAGE.LIST}>
    <HeaderPageList
      notify={props.notify}
      t={props.t}
      dispatch={props.dispatch}
      navigate={props.navigate}
      listBreadcrumb={mainBreadCrumb(props.t)}
      toTitlePage={ROUTER_CONSTANT.INDEX}
      title={props.t("PRODUCT_KEY_ACCOUNT:TITLE")}
      mappingCode={MAPPING_CODE.PRODUCT_KEY_ACCOUNT}
      defaultBreadcrumb={props.t("PRODUCT_KEY_ACCOUNT:TITLE")}
      rejectNavigate={ROUTER_CONSTANT.PRODUCT_KEY_ACCOUNT.REJECT_REASON}
      uniqueKey="product_key_account_id"
      mappingNavigate={(NewId, ExistId, List) =>
        fetchProductKeyAccountMappingForm(
          props.validToken(),
          props.dispatch,
          "product_key_account_id",
          NewId,
          ExistId,
          List,
          props.notify,
          () => props.navigate(ROUTER_CONSTANT.PRODUCT_KEY_ACCOUNT.MAPPING)
        )
      }
    />
    <MappingList
      t={props.t}
      column={productKeyAccountColumn(props.t)}
      mappingCode={MAPPING_CODE.PRODUCT_KEY_ACCOUNT}
      validToken={props.validToken}
      dispatch={props.dispatch}
      navigate={props.navigate}
      notify={props.notify}
      listFunc={(totalRow, countData, token, currentPage, rowLimit, sortDate, dispatch, notify) =>
        fetchDataProductKeyAccountList(
          totalRow,
          countData,
          token,
          currentPage,
          rowLimit,
          sortDate,
          dispatch,
          notify
        )
      }
    />
  </Div>
);

export default ProductKeyAccountPage;
