import React from 'react';

import {
  Breadcrumb,
  Div,
  Span,
  TitlePage,
} from 'nexcomponent-ui';
import { useSelector } from 'react-redux';

import {
  BOX,
  BREADCRUMB,
  TEXT_SINGLE,
} from '../../../../Enum/IdSet';
import style from './HeaderPageMapping.module.css';

const HeaderPageMapping = props => {
  const mappingCount = useSelector(state => state.mapping.mappingCount);
  const mappingSum = useSelector(state => state.mapping.mappingSum);
  return (
    <>
      <Breadcrumb
        id={BREADCRUMB.HEADER}
        list={props.listBreadcrumb}
        default={props.defaultBreadcrumb}
      />
      <Div id={BOX.ROW} className={style.PageTitle}>
        <TitlePage
          id={TEXT_SINGLE.TITLE_PAGE}
          className={style.Title}
          to={props.toTitlePage}
          title={props.title}
        />
        <Div id={BOX.COL_RIGHT} className={style.CountMappingData}>
          <Div id={BOX.MAPPING_COUNTER}>
            <Span
              id={TEXT_SINGLE.MAPPING_COUNT}
              className={style.PartOf}
              value={`Mapping Data ${mappingCount}`}
            />
            <Span id={TEXT_SINGLE.MAPPING_SUM} className={style.Sum} value={`of ${mappingSum}`} />
          </Div>
        </Div>
      </Div>
    </>
  );
};

export default HeaderPageMapping;
