import { COMMON } from '../../Enum/EndPoint/CommonEndpoint';

export const SEARCH_URL = Object.freeze({
  COUNTRY: COMMON.SEARCH + COMMON.REGION.COUNTRY,
  DISTRICT: COMMON.SEARCH + COMMON.REGION.DISTRICT,
  ISLAND: COMMON.SEARCH + COMMON.REGION.ISLAND,
  POSTAL_CODE: COMMON.SEARCH + COMMON.REGION.POSTAL_CODE,
  PROVINCE: COMMON.SEARCH + COMMON.REGION.PROVINCE,
  SUB_DISTRICT: COMMON.SEARCH + COMMON.REGION.SUBDISTRICT,
  URBAN_VILLAGE: COMMON.SEARCH + COMMON.REGION.URBAN_VILLAGE,
  PRINCIPAL: COMMON.SEARCH + COMMON.PRINCIPAL,
  PERSON_TITLE: COMMON.SEARCH + COMMON.PERSON_TITLE,
  COMPANY_TITLE: COMMON.SEARCH + COMMON.COMPANY_TITLE,
  COMPANY_PARENT: COMMON.SEARCH + COMMON.MAPPING_TYPE.COMPANY_PROFILE,
  PRODUCT_BRAND: COMMON.SEARCH + COMMON.MAPPING_TYPE.PRODUCT_BRAND,
  PRODUCT_BRAND_PRINCIPAL:COMMON.MAPPING + COMMON.MAPPING_TYPE.PRINCIPAL_PRODUCT_BRAND + COMMON.SEARCH,
  PRODUCT_GROUP_PRINCIPAL:COMMON.MAPPING + COMMON.MAPPING_TYPE.PRINCIPAL_PRODUCT_GROUP + COMMON.SEARCH,
  PRODUCT_GROUP: COMMON.SEARCH + COMMON.MAPPING_TYPE.PRODUCT_GROUP,
  PRODUCT_CATEGORY: COMMON.SEARCH + COMMON.MAPPING_TYPE.PRODUCT_CATEGORY,
  PRODUCT_KEY_ACCOUNT: COMMON.SEARCH + COMMON.MAPPING_TYPE.PRODUCT_KEY_ACCOUNT,
});
