import _ from 'lodash';

import { mappingActions } from '../Reducers/MappingReducer';
import { utilsActions } from '../Reducers/UtilsReducer';

export const checkedAllStatus = checkedArray => {
  if (checkedArray.length <= 0) {
    return false;
  } else {
    return !checkedArray.some(data => data?.isChecked !== true);
  }
};
export const checkedAllClick = async (e, data, dispatch, actionType) => {
  const {name, checked} = e.target;
  if (name === "checkboxAll") {
    let tempUser = await data.map(datas => {
      return {...datas, isChecked: checked};
    });
    await dispatch(utilsActions.setCheckedArray(tempUser));
    if (actionType !== "reject") {
      rejectList(checked, data, dispatch);
      mappingList(checked, data, dispatch);
    }
  }
};

export const rejectList = (checked, data, dispatch) => {
  if (checked) {
    dispatch(mappingActions.setRejectList(data));
  } else {
    dispatch(mappingActions.setRejectList([]));
  }
};
export const mappingList = (checked, data, dispatch) => {
  if (checked) {
    dispatch(mappingActions.setMappingList(data));
    dispatch(mappingActions.setMappingCount(data.length - (data.length - 1)));
    dispatch(mappingActions.setMappingSum(data.length));
  } else {
    dispatch(mappingActions.setMappingList([]));
    dispatch(mappingActions.setMappingCount(0));
    dispatch(mappingActions.setMappingSum(0));
  }
};
export const checkedByActionType = (actionType, checked, data, dispatch) => {
  if (actionType === "reject") {
    rejectList(checked, data, dispatch);
  } else if (actionType === "mapping") {
    mappingList(checked, data, dispatch);
  }
};

export const checkedPerItem = async (e, item, data, dispatch, actionType) => {
  const {name, checked} = e.target;
  let tempUser = await data.map(datas =>
    datas.id === Number(name) ? {...datas, isChecked: checked} : datas
  );
  await dispatch(utilsActions.setCheckedArray(tempUser));
  if (actionType !== "reject") {
    rejectListPartial(checked, item, dispatch);
    mappingListPartial(checked, item, dispatch);
  }
};

export const rejectListPartial = (checked, item, dispatch) => {
  if (checked) {
    dispatch(mappingActions.setAddRejectList(item));
  } else {
    dispatch(mappingActions.setRemoveRejectList(item.id));
  }
};
export const mappingListPartial = (checked, item, dispatch) => {
  if (checked) {
    dispatch(mappingActions.setAddMappingList(item));
    dispatch(mappingActions.setMappingCount(1));
    dispatch(mappingActions.setAddMappingSum(1));
  } else {
    dispatch(mappingActions.setRemoveMappingList(item.id));
    dispatch(mappingActions.setMappingCount(1));
    dispatch(mappingActions.setAddMappingSum(-1));
  }
};
export const checkedPartialByActionType = (actionType, checked, item, dispatch) => {
  if (actionType === "reject") {
    rejectListPartial(checked, item, dispatch);
  } else if (actionType === "mapping") {
    mappingListPartial(checked, item, dispatch);
  }
};

export const multiMappingSelect = (param, param2, param3, check, data, dispatch, submitMapping) => {
  let tempForm = data.map(datas => (datas.key === param ? {...datas, isChecked: check} : datas));
  let tempForm2 = tempForm.map(datas =>
    datas.key === param2 ? {...datas, isChecked: check} : datas
  );
  let tempForm3 = tempForm2.map(datas =>
    datas.key === param3 ? {...datas, isChecked: check} : datas
  );

  const value = (object, key) => {
    const value = _.find(object, ["key", key]);
    return value["new"];
  };
  const valueExist = (object, key) => {
    const value = _.find(object, ["key", key]);
    return value["exist"];
  };
  if (check) {
    if (param3 !== undefined) {
      const submited = {
        [param]: value(tempForm3, param),
        [param2]: Number(value(tempForm3, param2)),
        [param3]: value(tempForm3, param3),
      };
      setToMappingForm(tempForm3, submited, submitMapping, dispatch);
    } else {
      const submited = {
        [param]: value(tempForm2, param),
        [param2]: Number(value(tempForm2, param2)),
      };
      setToMappingForm(tempForm2, submited, submitMapping, dispatch);
    }
  } else {
    if (param3 !== undefined) {
      const submited = {
        [param]: valueExist(tempForm3, param),
        [param2]: Number(valueExist(tempForm3, param2)),
        [param3]: valueExist(tempForm3, param3),
      };

      setToMappingForm(tempForm3, submited, submitMapping, dispatch);
    } else {
      const submited = {
        [param]: valueExist(tempForm2, param),
        [param2]: Number(valueExist(tempForm2, param2)),
      };

      setToMappingForm(tempForm2, submited, submitMapping, dispatch);
    }
  }
};
const setToMappingForm = (data, submited, submitMapping, dispatch) => {
  dispatch(mappingActions.setMappingForm(data));
  dispatch(mappingActions.setSubmitMapping({...submitMapping, ...submited}));
};
// const setToMappingFormUnCheck = (data, submited, dispatch) => {
//   dispatch(mappingActions.setMappingForm(data));
//   dispatch(mappingActions.setSubmitMapping(submited));
// };
