import { styleColumn } from './StyleColumn/StyleColumn';

export const MappingColumn = t => [
  {
    name: t("COMMON:FIELD.DATE_TIME"),
    component: true,
    key: "created_at",
    selector: row => {
      return row.created_at;
    },
    sortable: true,
    width: "25vw",
    padding: styleColumn.paddingColumnStart,
  },
  {
    name: t("COMMON:FIELD.MAPPING_DATA_TYPE"),
    key: "mapping_data_type",
    selector: "mapping_data_type",
    width: "25vw",
  },
  {
    name: t("COMMON:FIELD.UPLOAD_BY"),
    key: "created_by",
    selector: "created_by",
    width: "25vw",
  },
  {
    name: t("COMMON:FIELD.RESOURCE"),
    key: "resource",
    selector: "resource",
    width: "25vw",
    padding: styleColumn.paddingColumnEnd,
  },
];
