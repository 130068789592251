import React, {
  useEffect,
  useState,
} from 'react';

import _ from 'lodash';
import {
  Button,
  Div,
  Image,
  Input,
  Li,
  Ul,
} from 'nexcomponent-ui';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Icon } from '../../../../Assets/Icons';
import { SIZESET } from '../../../../Enum/Props/SizeSet';
import { mappingActions } from '../../../../Store/Reducers/MappingReducer';
import style from './FieldMulti.module.css';

const FieldMulti = props => {
  const {t} = useTranslation();
  const listFormc = useSelector(state => state.mapping.additionalInfo);
  const mappingForm = useSelector(state => state.mapping.mappingForm);
  const [load, setload] = useState(true);
  const [listForm, setListForm] = useState([]);
  function isJson(item) {
    item = typeof item !== "string" ? JSON.stringify(item) : item;

    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }

    if (typeof item === "object" && item !== null) {
      return true;
    }
    return false;
  }
  useEffect(() => {
    if (load) {
      try {
        if (isJson(props.value) === true) {
          if (
            _.has(JSON.parse(props.value)[0], "key") &&
            _.has(JSON.parse(props.value)[0], "value")
          ) {
            setListForm(JSON.parse(props.value))
            props.dispatch(mappingActions.setAdditionalInfo(JSON.parse(props.value)));
          }else{
            props.dispatch(mappingActions.setAdditionalInfo([]));
          }
        } 
        
      } catch (error) {
       
      }
      setload(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dispatch, props.value]);
  const removeField = index => {
    if (listForm.length === 1) {
      props.dispatch(mappingActions.setAdditionalInfo([]));
      setListForm([])
      props.onChange({target: {name: props.name, value: []}});
    } else {
      const data = [];
      for (let i = 0; i < listForm.length; i++) {
        if (i !== index) {
          data.push(listForm[i]);
        }
      }
      props.dispatch(mappingActions.setAdditionalInfo(data));
      setListForm(data)
      props.onChange({target: {name: props.name, value: data}});
    }
  };
  const addField = evt => {
    evt.preventDefault();
    props.dispatch(mappingActions.setAddAdditionalInfo({key: "", value: ""}));
    setListForm(listForm.concat([{key: "", value: ""}]))
    props.onChange({target: {name: props.name, value: listForm.concat([{key: "", value: ""}])}});
  };
  const update = async (evt, index) => {
    let tempForm2 = listForm.map((datas, item) =>
      item === index ? {...datas, [evt.target.name]: evt.target.value} : datas
    );
    props.dispatch(mappingActions.setAdditionalInfo(tempForm2));
    let mappingDorm = mappingForm.map(datas =>
      datas.key === props.name ? {...datas, new: tempForm2} : datas
    );
    props.dispatch(mappingActions.setMappingForm(mappingDorm));
    setListForm(tempForm2)
    props.onChange({target: {name: props.name, value: tempForm2}});
  };

  return (
    <>
      {listFormc !== 0 && listFormc !== null && Array.isArray(listFormc) ? (
        <Div className={style.Box}>
          <Ul className={style.FormUl}>
            {listFormc.map((item, index) => {
              return item.key !== undefined ? (
                <Li className={style.FormLi} key={index}>
                  <Input
                    name={"key"}
                    className={style.InputKey}
                    value={item.key}
                    onChange={evt => update(evt, index)}
                    placeholder={props.placeholderKey}
                  />
                  <Input
                    name={"value"}
                    className={style.InputValue}
                    value={item.value}
                    onChange={evt => update(evt, index)}
                    placeholder={props.placeholderValue}
                  />
                  {/* {listForm.length <= 1 ? (
                  <Image className={style.Disable} src={Icon.TrashDesable} onClick={() => {}} />
                ) : ( */}
                  <Image src={Icon.Trash} onClick={() => removeField(index)} />
                  {/* )} */}
                </Li>
              ) : (
                ""
              );
            })}
          </Ul>
          <Button
            icon={<Image src={Icon.Add} className={style.ButtonAddIcon} />}
            className={style.Button}
            label={t("COMMON:FIELD.ADDITIONAL_INFO")}
            size={SIZESET.XS}
            onClick={addField}
          />
        </Div>
      ) : (
        <Div className={style.Box}>
          <Button
            icon={<Image src={Icon.Add} className={style.ButtonAddIcon} />}
            className={style.Button}
            label={t("COMMON:FIELD.ADDITIONAL_INFO")}
            size={SIZESET.XS}
            onClick={addField}
          />
        </Div>
      )}
    </>
  );
};

export default FieldMulti;
