import React, { useCallback, useEffect, useMemo } from "react";

import _ from "lodash";
import { Div } from "nexcomponent-ui";
import { useSelector } from "react-redux";

import { productGroupBreadCrumb } from "../../../../Enum/Breadcrumb/ProductGroupBreadcrumb";
import { MAPPING_CODE } from "../../../../Enum/MappingCode/MappingCode";
import { ROUTER_CONSTANT } from "../../../../Enum/RouterConstant/RouterConstant";
import {
  fetchDataApprovalProductGroup,
  fetchProductGroupMappingForm,
} from "../../../../Fetch/FetchData/ProductGroupData";
import { mappingFormPageStateGroup } from "../../../../Store/StateGroup";
import CancelOverwrite from "../../../Organisms/Button/CancelOverwrite/CancelOverwrite";
import MappingForm from "../../../Organisms/Form/MappingForm/MappingForm/MappingForm";
import HeaderPageMapping from "../../../Organisms/Header/HeaderPageMapping/HeaderPageMapping";
import ModalMapping from "../../../Organisms/Modal/ModalMapping/ModalMapping";
import useQueryRoute from "../../../../Hooks/useQueryRoute";
import {
  fetchDataApprovalProductGroupPrincipal,
  fetchProductGroupPrincipalMappingForm,
} from "../../../../Fetch/FetchData/ProductGroupPrincipalData";

const ProductGroupMappingPage = (props) => {
  const { principal } = useQueryRoute();

  const [, list, , mappingForm] = useSelector(mappingFormPageStateGroup);

  const key = useMemo(
    () => (principal ? "principal_product_group_id" : "product_group_hierarchy_id"),
    [principal]
  );

  const approveFuc = useCallback(
    (data, mappingList, mappingCount) => {
      if (principal) {
        return fetchDataApprovalProductGroupPrincipal(
          props.validToken(),
          data,
          mappingList,
          mappingCount,
          props.notify,
          props.dispatch
        );
      } else {
        return fetchDataApprovalProductGroup(
          props.validToken(),
          data,
          mappingList,
          mappingCount,
          props.notify,
          props.dispatch
        );
      }
    },
    [principal, props]
  );
  
  const nextMapping = useCallback(
    (uniqueKey, newId, existId, mappingList) => {
      if (principal) {
        return fetchProductGroupPrincipalMappingForm(
          props.validToken(),
          props.dispatch,
          uniqueKey,
          newId,
          existId,
          mappingList,
          props.notify,
          () => props.navigate(`${ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.PRINCIPAL.MAPPING}`)
        );
      } else {
        return fetchProductGroupMappingForm(
          props.validToken(),
          props.dispatch,
          uniqueKey,
          newId,
          existId,
          mappingList,
          props.notify,
          () => props.navigate(ROUTER_CONSTANT.PRODUCT_BRAND.MAPPING)
        );
      }
    },
    [principal, props]
  );

  useEffect(() => {
    if (_.has(props.location.state, "data")) {
      const first = _.first(list);
      if (principal) {
        fetchProductGroupPrincipalMappingForm(
          props.validToken(),
          props.dispatch,
          "principal_product_group_id",
          first?.id,
          props.location.state.data.principal_product_group_id,
          list,
          props.notify,
          () => props.navigate(`${ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.PRINCIPAL.PRINCIPAL.MAPPING}`)
        );
      } else {
        fetchProductGroupMappingForm(
          props.validToken(),
          props.dispatch,
          "product_group_hierarchy_id",
          first.id,
          props.location.state.data.product_group_hierarchy_id,
          list,
          props.notify,
          () => props.navigate(ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.MAPPING)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, props.location.state, props.dispatch, props.validToken]);
  return (
    <Div>
      <ModalMapping
        t={props.t}
        dispatch={props.dispatch}
        navigate={props.navigate}
        listNavigate={ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.LIST}
        approveFuc={approveFuc}
        uniqueKey={key}
        nextMapping={nextMapping}
      />

      <HeaderPageMapping
        title={props.t("PRODUCT_GROUP:TITLE")}
        listBreadcrumb={productGroupBreadCrumb(props.t)}
        defaultBreadcrumb={props.t("PRODUCT_GROUP:MAPPING.TITLE")}
        to={ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.LIST}
      />
      <MappingForm
        t={props.t}
        validToken={props.validToken}
        dispatch={props.dispatch}
        navigate={props.navigate}
        idKey={key}
        mappingCode={MAPPING_CODE.PRODUCT_GROUP}
        mappingForm={mappingForm}
      />
      <CancelOverwrite
        t={props.t}
        dispatch={props.dispatch}
        navigate={props.navigate}
        idKey={key}
        mappingForm={mappingForm}
        mappingCode={MAPPING_CODE.PRODUCT_GROUP}
      />
    </Div>
  );
};

export default ProductGroupMappingPage;
