import React, {
  useEffect,
  useState,
} from 'react';

import {
  ListEmpty,
  ModalDialog,
  nexIcon,
  useNotification,
} from 'nexcomponent-ui';
import { useSelector } from 'react-redux';

import SpinnerLoad
  from '../../../../Components/Molecules/Loader/SpinnerLoad/SpinnerLoad';
import { rejectColumn } from '../../../../Enum/Column/RejectColumn';
import { TABLE } from '../../../../Enum/IdSet';
import {
  fetchDataRejectDetail,
  fetchDataRejectList,
} from '../../../../Fetch/FetchData/RejectData';
import {
  closeModal,
  openModal,
} from '../../../../Store/Action/ModalAction';
import { utilsActions } from '../../../../Store/Reducers/UtilsReducer';
import {
  initiateTotalRowMappingList,
} from '../../../../Store/StateGroup/DashboardStateGroup';
import {
  currentPageLoadingRowLimitSortDate,
} from '../../../../Store/StateGroup/UtilsStateGroup';
import { loadingData } from '../../../../Utils/Handler/LoadingData';
import {
  detailByMappingTypeData,
} from '../../Box/BoxDetail/Detail/DetailByMappingTypeData';
import { TitleModalDetail } from '../../Box/BoxDetail/TitleModalDetail';
import TableList from '../TableList';
import style from './RejectList.module.css';

function RejectList(props) {
  const [currentPage, loading, rowLimit, sortDate] = useSelector(
    currentPageLoadingRowLimitSortDate
  );
  const [detail, setDetail] = useState(null);
  const [, totalRow] = useSelector(initiateTotalRowMappingList);
  const initiateReject = useSelector(state => state.reject.initiate);
  const rejectList = useSelector(state => state.reject.rejectList);
  const rejectDetail = useSelector(state => state.reject.rejectDetail);
  const modal = useSelector(state => state.utils.modal);
  const modalType = useSelector(state => state.utils.modalType);
  const filter = useSelector(state => state.reject.filter);

  const notify = useNotification();
  const handleCancel = () => {
    closeModal(props.dispatch);
  };
  const [sort] = sortDate.split(" ");
  useEffect(() => {
    if(loading){
      fetchDataRejectList(
        totalRow,
        initiateReject.count_data,
        props.validToken(),
        currentPage,
        rowLimit,
        sortDate,
        filter,
        props.dispatch,
        props.notify
      );
    }
    if (sort === "created_at") {
      props.dispatch(utilsActions.setSortDateAsc("updated_at asc"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loading
  ]);
  loadingData(loading, props);
  
  const openDetail = data => {
    props.dispatch(utilsActions.setModalType("reject"));
    openModal(props.dispatch);
    setDetail(data);
    fetchDataRejectDetail(
      props.validToken(),
      data.mapping_data_type,
      data.id,
      props.dispatch,
      notify
    );
  };
  return (
    <>
      <ModalDialog
        classBody={style.ModalBody}
        title={TitleModalDetail(detail === null ? "" : detail.mapping_data_type, props.t)}
        content={
          rejectDetail === null
            ? props.t("COMMON:DETAIL_DATA_UNAVAILABLE")
            : detailByMappingTypeData(
                modalType,
                detail === null ? "" : detail.mapping_data_type,
                rejectDetail,
                props.t
              )
        }
        onClose={handleCancel}
        show={modal}
        closeIcon={<nexIcon.NcClose color="#9b3789" />}
      />

      <TableList
        customHeader={props.customHeader}
        id={TABLE.BROADCAST_LIST}
        sort={true}
        columns={rejectColumn(props.t)}
        data={loading ? null : rejectList}
        rowLink={true}
        rowClick={data => openDetail(data)}
        limitation={initiateReject.valid_limit}
        totalRow={initiateReject.count_data}
        empty={
          !loading ? (
            <ListEmpty value={props.t("COMMON:LIST_EMPTY")} />
          ) : (
            <ListEmpty value={<SpinnerLoad t={props.t} />} />
          )
        }
        t={props.t}
        footer={false}
        dispatch={props.dispatch}
        typeList="reject"
        asc="updated_at asc"
        desc="updated_at desc"
      />
    </>
  );
}

export default RejectList;
