export const productFieldMapping = [
  "product_id",
  "product_code",
  "principal_product_code",
  "parent_code",
  "short_code",
  "name",
  "name_2",
  "packaging",
  "packaging_2",
  "description",
  "product_brand_id",
  "product_brand_name",
  "product_brand_code",
  "product_category_id",
  "product_category_name",
  "product_category_code",
  "product_group_hierarchy_id",
  "product_group_hierarchy_name",
  "product_group_hierarchy_code",
  "product_key_account_id",
  "product_key_account_name",
  "product_key_account_code",
  "uom_1",
  "uom_2",
  "uom_3",
  "uom_4",
  "conversion_1_to_4",
  "conversion_2_to_4",
  "conversion_3_to_4",
  "code_bottle",
  "code_crate",
  "market_launch_date",
  "weight_per_unit",
  "dry_weight_per_unit",
  "net_weight_per_case",
  "volume_per_unit",
  "case_width",
  "case_height",
  "case_depth",
  "case_weight",
  "farmalkes_id",
  "farmalkes_desc",
  "nie_number",
  "nie_expired_date",
  "product_key_account_id",
  "barcode_list_for_uom_1",
  "barcode_list_for_uom_2",
  "barcode_list_for_uom_3",
  "barcode_list_for_uom_4",
  "free_good_status",
  "embalace_status",
  "tax_1_applied_status",
  "tax_2_applied_status",
  "tax_3_applied_status",
  "tax_subsidized_status",
  "class",
  "report_column_header_1",
  "report_column_header_2",
  "sort_sequence",
  "additional_margin",
  "no_regular_discount_status",
  "principal_id",
  "principal_name",
  "principal_code",
  "image_file_name_1",
  "image_file_name_2",
  "image_file_name_3",
  "image_file_name_4",
  "image_file_name_5"
];
export const productFieldPrincipalMapping = [
  "principal_product_id",
  "product_code",
  "principal_product_code",
  "parent_code",
  "short_code",
  "product_name",
  "product_name_2",
  "packaging",
  "packaging_2",
  "description",
  "principal_product_brand_id",
  "principal_product_brand_name",
  "principal_product_brand_code",
  "principal_product_group_id",
  "principal_product_group_name",
  "principal_product_group_code",
  "uom_1",
  "uom_2",
  "uom_3",
  "uom_4",
  "conversion_1_to_4",
  "conversion_2_to_4",
  "conversion_3_to_4",
  "code_bottle",
  "code_crate",
  "market_launch_date",
  "weight_per_unit",
  "dry_weight_per_unit",
  "net_weight_per_case",
  "volume_per_unit",
  "case_width",
  "case_height",
  "case_depth",
  "case_weight",
  "farmalkes_id",
  "farmalkes_desc",
  "nie_number",
  "nie_expired_date",
  "product_key_account_id",
  "barcode_list_for_uom_1",
  "barcode_list_for_uom_2",
  "barcode_list_for_uom_3",
  "barcode_list_for_uom_4",
  "free_good_status",
  "embalace_status",
  "tax_1_applied_status",
  "tax_2_applied_status",
  "tax_3_applied_status",
  "tax_subsidized_status",
  "class",
  "report_column_header_1",
  "report_column_header_2",
  "sort_sequence",
  "additional_margin",
  "no_regular_discount_status",
  "principal_id",
  "principal_name",
  "principal_code",
  "image_file_name_1",
  "image_file_name_2",
  "image_file_name_3",
  "image_file_name_4",
  "image_file_name_5"
];