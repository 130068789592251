import { formatDD_MMMM_YYYY } from '../../Utils/Date/DateFormater';
import { itemDetailValue } from '../../Utils/Value';

export const detailBroadcastProductBrand = (t, data) => [
  {id: "tslcode", title: t("PRODUCT_BRAND:FIELD.CODE"), value: itemDetailValue(data.code)},
  {id: "tslname", title: t("PRODUCT_BRAND:FIELD.NAME"), value: itemDetailValue(data.name)},
  {id: "tslid", title: t("PRODUCT_BRAND:FIELD.ID"), value: itemDetailValue(data.id)},
  {
    id: "tslprincipalcode",
    title: t("PRODUCT_BRAND:FIELD.PRINCIPAL_CODE"),
    value: itemDetailValue(data.principal_code),
  },
  {
    id: "tslprincipalname",
    title: t("PRODUCT_BRAND:FIELD.PRINCIPAL_NAME"),
    value: itemDetailValue(data.principal_name),
  },
  {
    id: "tslprincipalid",
    title: t("PRODUCT_BRAND:FIELD.PRINCIPAL_ID"),
    value: itemDetailValue(data.principal_id),
  },
  {
    id: "tslownedstartdate",
    title: t("PRODUCT_BRAND:FIELD.OWNED_START_DATE"),
    value: formatDD_MMMM_YYYY(data.owned_start_date),
  },
  {
    id: "tslresource",
    title: t("PRODUCT_BRAND:FIELD.RESOURCE"),
    value: itemDetailValue(data.resource),
  },
];
