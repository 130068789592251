import {
  isStatus,
  isStatusErr,
} from '../../Utils/Response/Response';
import { PERSON_PROFILE_URL } from '../EndPoint';
import * as BaseAPI from './BaseAPIs';

export const postPersonProfileCountData = (token, notify, dispatch, action) =>
  BaseAPI.doPost(PERSON_PROFILE_URL.COUNT, token, null, notify, dispatch, res => action(res));

export const postPersonProfileList = (token, page, limit, sortDate,notify,dispatch,action) =>
  BaseAPI.doPost(PERSON_PROFILE_URL.LIST(page, limit, sortDate), token, null,notify,dispatch,(res)=>action(res));


export const postRejectPersonProfileList = (token, data,notify,dispatch) => 
  BaseAPI.doPost(PERSON_PROFILE_URL.REJECT, token, data,notify,dispatch,
    res=>isStatus(res),err=>isStatusErr(err))
    
export const getDetailPersonProfile =(token, id,notify,dispatch,action,errAction) => 
  BaseAPI.doGet(PERSON_PROFILE_URL.DETAIL + id, token, null,null,notify,dispatch,
  res=>action(res),err=>errAction(err));
export const getDetailExistingPersonProfile = (token, id,notify,dispatch,action,errAction) => 
  BaseAPI.doGet(PERSON_PROFILE_URL.DETAIL_EXISTING + id, token, null,null,notify,dispatch,
  res=>action(res),err=>errAction(err));

export const postSearchPersonProfileList = (token, data,dispatch, notify,action,errAction) => {
  return BaseAPI.doPost(PERSON_PROFILE_URL.SEARCH,  token, data ,notify,dispatch,
    (res)=>action(res),err=>errAction(err));
};
export const postApproveMappingPersonProfile = (token, data,notify,dispatch,action,errAction) => {
  return BaseAPI.doPost(PERSON_PROFILE_URL.MAPPING_APPROVE, token, data,notify,dispatch,
      (res)=>action(res),err=>errAction(err));
};
export const postPersonProfileSearchCountData = (token,data,notify,dispatch,action) =>  
  BaseAPI.doPost(PERSON_PROFILE_URL.COUNT_SEARCH, token, data,notify,dispatch,(res)=>action(res));