export const productGroupColumn= t => [
  {
    name: t("COMMON:FIELD.DATE_TIME"),
    selector: "created_at",
    key: "created_at",
    sortable: true,
    width: "20vw",
  },
  {
    name: t("COMMON:FIELD.PRINCIPAL_CODE"),
    selector: "principal_code",
    key: "principal_code",
    width: "10vw",
  },
  {
    name: t("COMMON:FIELD.PRINCIPAL_NAME"),
    selector: "principal_name",
    key: "principal_name",
    width: "20vw",
  },
  {
    name: t("COMMON:FIELD.PRINCIPAL_ID"),
    selector: "principal_id",
    key: "principal_id",
    
    
    width: "10vw",
  },
  {
    name: t("COMMON:FIELD.CODE"),
    selector: "code",
    key: "code",
    width: "10vw",
  },
  {
    name: t("COMMON:FIELD.NAME"),
    selector: "name",
    key: "name",
    width: "10vw",
  },
  {
    name: t("COMMON:FIELD.RESOURCE"),
    selector: "resource",
    key: "resource",
    width: "20vw",
  },
];
