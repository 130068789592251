import React, { useState } from 'react';

import _ from 'lodash';
import { useNotification } from 'nexcomponent-ui';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { mappingType } from '../../../../Enum/DataTypeContant';
import { BOX } from '../../../../Enum/IdSet';
import { closeModal } from '../../../../Store/Action/ModalAction';
import { mappingActions } from '../../../../Store/Reducers/MappingReducer';
import { utilsActions } from '../../../../Store/Reducers/UtilsReducer';
import Modal from '../Modal/Modal';
import ResultModal from '../ResultModal/ResultModal';

// import ModalDialog from '../ModalDialog/ModalDialog';

function ModalReject(props) {
  const location = useLocation();

  const rejectData = useSelector(state => state.utils.rejectData);
  const mappingList = useSelector(state => state.mapping.mappingList);
  const mappingCount = useSelector(state => state.mapping.mappingCount);
  const notify = useNotification();
  const modal = useSelector(state => state.utils.modal);
  const [msg, setMsg] = useState(null);
  const [count] = useState(mappingList.length);
  const result = useSelector(state => state.utils.resultModal);
  const handleCancel = () => {
    closeModal(props.dispatch);
  };
  const newMappingList = mappingList.slice(1, mappingList.length);
  const navigate = async () => {
    if (_.has(location.state, "previous")) {
      await props.dispatch(mappingActions.setMappingList(newMappingList));
      await props.dispatch(mappingActions.setMappingCount(mappingCount + 1));
      if (newMappingList.length > 0) {
        const first = await _.first(newMappingList);
        await props.nextMapping(
          first.id,
          first[props.uniqueKey],
          newMappingList,
          location.state.previous
        );
        await props.navigate(location.state.previous);
      } else {
        props.navigate(mappingType(props.mappingCode));
      }
    } else {
      props.navigate(mappingType(props.mappingCode));
    }
  };

  const sureReject = async () => {
    let message = await props.rejectFunc(rejectData, notify, props.dispatch);
    setMsg(message);
    await closeModal(props.dispatch);
    props.dispatch(utilsActions.setResultModal(true));
  };

  const handleResultCancel = () => {
    props.dispatch(utilsActions.setResultModal(false));
    if (msg.success) {
      if (_.has(location.state, "previous")) {
       navigate()
      } else {
        props.navigate(mappingType(props.mappingCode));
      }
    }
  };
  return (
    <>
      <ResultModal
        success={msg === null ? null : msg.success}
        content={msg === null ? null : msg.message}
        onClose={handleResultCancel}
        show={result}
        t={props.t}
        labelYes={props.t("COMMON:YES_SURE")}
        sucessBtnLabel={
          count > 1 && _.has(location.state, "previous")
            ? props.t("COMMON:BACK_TO_MAPPING")
            : props.t("COMMON:BACK_TO_MAPPING_LIST")
        }
        navigate={navigate}
      />
      <Modal
        id={BOX.REJECT_MODAL}
        idFooter={BOX.REJECT_MODAL_FOOTER}
        title={props.t("COMMON:REJECT_DATA")}
        content={props.t("COMMON:REJECT_MESSAGE")}
        onClose={handleCancel}
        show={modal}
        t={props.t}
        yesAction={sureReject}
        labelYes={props.t("COMMON:YES_REJECT")}
      />
    </>
  );
}

export default ModalReject;
