import React from 'react';

import _ from 'lodash';
import {
  Button,
  Div,
} from 'nexcomponent-ui';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  BOX,
  BUTTON,
} from '../../../../Enum/IdSet';
import { REGEX_CONSTANT } from '../../../../Enum/RegexConstant';
import { BTN_STYLE_TYPE } from '../../../../Enum/Style';
import {
  cancelButtonClick,
  rejectButtonClick,
} from '../../../../Store/Action/ButtonMappingAction';
import style from './CancelRejectButton.module.css';

const CancelRejectButton = props => {
  const location = useLocation();
  const rejectList = useSelector(state => state.utils.rejectList);
  const checkedArray = useSelector(state => state.utils.checkedArray);
  const rejectReasonSelect = useSelector(state => state.utils.rejectReasonSelect);
  const cancel = () =>
    _.has(location.state, "previous")
      ? props.navigate(location.state.previous)
      : cancelButtonClick(
          props.onReject,
          props.navigate,
          props.backPage,
          props.mappingCode,
          props.dispatch
        );
  const rejectButton = async () => {
    rejectButtonClick(
      props.onReject,
      props.mappingCode,
      rejectList,
      checkedArray,
      rejectReasonSelect,
      props.navigate,
      props.dispatch
    );
  };

  const rejectReasonCustomValid = new RegExp(REGEX_CONSTANT.ANY_NON_WHITESAPCE);
  const reasonValid = value => {
    if (!rejectReasonCustomValid.test(value)) {
      return false;
    } else if (rejectReasonCustomValid.test(value)) {
      return true;
    }
  };
  let checked = _.filter(checkedArray, {isChecked: true});

  return (
    <Div id={BOX.BUTTON_REJECT} className={style.Button}>
      <Button
        id={BUTTON.CANCEL}
        className={style.ButtonLeft}
        typeStyle={BTN_STYLE_TYPE.SECONDARY}
        onClick={cancel}
        label={props.t("COMMON:CANCEL")}
      />
      <Button
        id={BUTTON.REJECT}
        label={props.t("COMMON:REJECT")}
        onClick={rejectButton}
        disabled={
          reasonValid(rejectReasonSelect.reject_reason_custom) &&
          rejectReasonSelect.reject_reason_custom !== "" &&
          checked.length > 0
            ? false
            : true
        }
      />
    </Div>
  );
};

export default CancelRejectButton;
