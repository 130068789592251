export const productGroupFieldMapping = [
  "product_group_hierarchy_id",
  "code",
  "name",
  "level",
  "principal_id",
  "principal_code",
  "principal_id",
  "principal_name",
  "parent_id",
  "parent_name",
  "parent_code"
];
export const productGroupPrincipalFieldMapping = [
  "principal_product_group_id",
  "code",
  "name",
  "level",
  "principal_id",
  "principal_code",
  "principal_id",
  "principal_name",
  "parent_id",
  "parent_name",
  "parent_code"
];
