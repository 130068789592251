import React from 'react';

import { Div } from 'nexcomponent-ui';

import {
  divisionSearchBreadCrumb,
} from '../../../../Enum/Breadcrumb/DivisionBreadcrumb';
import {
  searchDivisionColumn,
} from '../../../../Enum/Column/SearchDivisionColumn';
import { MAPPING_CODE } from '../../../../Enum/MappingCode/MappingCode';
import {
  ROUTER_CONSTANT,
} from '../../../../Enum/RouterConstant/RouterConstant';
import {
  fetchDataDivisionSearchList,
} from '../../../../Fetch/FetchData/DivisionData';
import FieldDivisionSearchForm
  from '../../../Organisms/Form/SearchForm/FieldDivisionSearchForm/FieldDivisionSearchForm';
import SearchForm
  from '../../../Organisms/Form/SearchForm/SearchForm/SearchForm';
import HeaderPage from '../../../Organisms/Header/HeaderPage/HeaderPage';

function DivisionSearchPage(props) {
  return (
    <Div>
      <HeaderPage
        title={props.t("DIVISION:SEARCH.TITLE")}
        listBreadcrumb={divisionSearchBreadCrumb(props.t)}
        defaultBreadcrumb={props.t("DIVISION:SEARCH.TITLE")}
        toTitlePage={ROUTER_CONSTANT.DIVISION.MAPPING}
      />

      <SearchForm
        title="DIVISION:SEARCH.TITLE"
        field={<FieldDivisionSearchForm t={props.t} dispatch={props.dispatch} />}
        t={props.t}
        dispatch={props.dispatch}
        mappingCode={MAPPING_CODE.DIVISION}
        navigate={props.navigate}
        advance={false}
        columnDefault={searchDivisionColumn(props.t)}
        backNavigate={ROUTER_CONSTANT.DIVISION.MAPPING}
        searchFunc={(currentPage, rowLimit,filter, column,dispatch) =>
          fetchDataDivisionSearchList(
            props.validToken(),
            currentPage,
            rowLimit,
            filter,
            column,
            dispatch,
            props.notify
          )
        }
      />
    </Div>
  );
}

export default DivisionSearchPage;
