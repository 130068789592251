import React from 'react';

import {
  Col,
  Row,
} from 'nexcomponent-ui';
import { useSelector } from 'react-redux';

import { searchFormData } from '../../../../../Utils/Form';
import style from '../FieldSearch/Field.module.css';
import FieldSearch from '../FieldSearch/FieldSearch';

function FieldProductPrincipalSearchForm(props) {
  const {t} = props;
  const searchData = useSelector(state => state.utils.searchData);

  const formData = e => {
    searchFormData(e, searchData, props.dispatch);
  };
  return (
    <Row>
      <Col className={style.Col} size="lg" width={6}>
        <FieldSearch
          className={style.InputLeft}
          label={t("COMMON:FIELD.PRODUCT_ID")}
          type={"number"}
          name="principal_product_id"
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PRODUCT_ID")})}
          onChange={formData}
        />
        <FieldSearch
          className={style.InputLeft}
          label={t("COMMON:FIELD.PRINCIPAL_CODE")}
          type={"text"}
          name={"principal_code"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PRINCIPAL_CODE")})}
          onChange={formData}
        />
        <FieldSearch
          className={style.InputLeft}
          type={"text"}
          name={"principal_name"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PRINCIPAL_NAME")})}
          onChange={formData}
          label={t("COMMON:FIELD.PRINCIPAL_NAME")}
        />
        <FieldSearch
          className={style.InputLeft}
          type={"number"}
          name={"principal_id"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PRINCIPAL_ID")})}
          onChange={formData}
          label={t("COMMON:FIELD.PRINCIPAL_ID")}
        />
        <FieldSearch
          className={style.InputLeft}
          type={"text"}
          name={"product_code"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PRODUCT_CODE")})}
          onChange={formData}
          label={t("COMMON:FIELD.PRODUCT_CODE")}
        />
      </Col>
      <Col className={style.Col} size="lg" width={6}>
        <FieldSearch
          className={style.InputRight}
          type={"text"}
          name={"product_principal_code"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PRODUCT_PRINCIPAL_CODE")})}
          onChange={formData}
          label={t("COMMON:FIELD.PRODUCT_PRINCIPAL_CODE")}
        />
        <FieldSearch
          className={style.InputRight}
          label={t("COMMON:FIELD.PARENT_CODE")}
          type={"text"}
          name={"parent_code"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PARENT_CODE")})}
          onChange={formData}
        />
        <FieldSearch
          className={style.InputRight}
          type={"text"}
          name={"short_code"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.SHORT_CODE")})}
          onChange={formData}
          label={t("COMMON:FIELD.SHORT_CODE")}
        />
        <FieldSearch
          className={style.InputRight}
          type={"text"}
          name={"product_name"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PRODUCT_NAME")})}
          onChange={formData}
          label={t("COMMON:FIELD.PRODUCT_NAME")}
        />
      </Col>
    </Row>
  );
}

export default FieldProductPrincipalSearchForm;
