import { COMMON } from '../../Enum/EndPoint/CommonEndpoint';
import { MOCK_URL } from '../EndPoint/MockEndPoint';
import * as BaseAPI from './BaseAPIs';

export const mappingList = () => {
  return BaseAPI.mockGet(MOCK_URL.MAPPING_LIST);
};
export const mappingCount = () => {
  return BaseAPI.mockGet(MOCK_URL.MAPPING_COUNT);
};

export const mappingInitiate =()=>{
  return BaseAPI.mockGet(MOCK_URL.MAPPING_INITIATE)
}

export const getRejectReason = (token,notify,dispatch,action) => {
  return BaseAPI.doGet(COMMON.REJECT_REASON, token, null,null,notify,dispatch,res=>action(res));
};