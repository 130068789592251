import { broadcastsActions } from '../../Store/Reducers/BroadcastReducer';
import { dashboardAction } from '../../Store/Reducers/DashboardReducer';
import { utilsActions } from '../../Store/Reducers/UtilsReducer';
import {
  isContent,
  isContentErr,
} from '../../Utils/Response/Response';
import {
  getBroadcastAdminList,
  getBroadcastDetail,
  getBroadcastInitiate,
  getBroadcastList,
} from '../Api/BroadcastAPI';
import { setDefaultPagination } from './DashboardData';

const errContent = err => {
  const {content} = err.response.data.nexsoft.payload.data;
  return content;
};
export const setInitiateBroadcast = (res, dispatch) => {
  dispatch(broadcastsActions.setBroadcastInitiate(isContent(res)));
};
export const fetchDataInitiateBroadcast = (token, dispatch, filter, notify) => {
  getBroadcastInitiate(token, filter, notify, dispatch, res => setInitiateBroadcast(res, dispatch));
};
const setListBroadcast = (res, dispatch) => {
  dispatch(broadcastsActions.setBroadcastList(isContent(res)));
};
const setListBroadcastErr = (res, dispatch) => {
  dispatch(broadcastsActions.setBroadcastList(errContent(res)));
};
const setDetalBroadCast = (res, dispatch) => {
  dispatch(broadcastsActions.setBroadcastDetail(res));
};
export const fetchDataBroadcastDetail = async (token, id, dispatch, notify) => {
  await getBroadcastDetail(
    token,
    id,
    notify,
    dispatch,
    res => setDetalBroadCast(isContent(res), dispatch),
    err => setDetalBroadCast(isContentErr(err), dispatch)
  );
};

export const fetchDataBroadcastAdmin = async (token, page, limit, dispatch, notify) => {
  let arr = list =>
    list.map(datas => {
      return {...datas, isChecked: false};
    });
  const setAdminList = res => dispatch(broadcastsActions.setBroadcastAdmin(arr(isContent(res))));
  await getBroadcastAdminList(token, page, limit,null, notify, dispatch, response =>
    setAdminList(response)
  );
};
export async function fetchDataBroadcastList(
  totalRow,
  countData,
  token,
  currentPage,
  rowLimit,
  sortDate,
  filter,
  dispatch,
  notify
) {
  if (totalRow === countData) {
    getBroadcastInitiate(token, filter, notify, dispatch, res =>{
      setInitiateBroadcast(res, dispatch)
    }
    );
    listData(token, currentPage, rowLimit, sortDate, filter, dispatch, notify);
  } else {
    setDefaultPagination(dispatch);
    await dispatch(dashboardAction.setTotalRow(countData));
    getBroadcastInitiate(token, filter, notify, dispatch, res =>{
      setInitiateBroadcast(res, dispatch)
    }
    );
    listData(token, 1, 10, sortDate, filter, dispatch, notify);
  }
}
const listData = async (token, currentPage, rowLimit, sortDate, filter, dispatch, notify) => {
  await getBroadcastList(
    token,
    currentPage,
    rowLimit,
    sortDate,
    filter,
    notify,
    dispatch,
    res => setListBroadcast(res, dispatch),
    err => setListBroadcastErr(err, dispatch)
  );
  dispatch(utilsActions.setLoading(false));
};
