import { COMMON } from '../../Enum/EndPoint/CommonEndpoint';

export const PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY_URL = Object.freeze({
  COUNT:
    COMMON.MAPPING + COMMON.MAPPING_TYPE.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY + COMMON.COUNT,
  LIST: function list(page, limit, order) {
    return (
      COMMON.MAPPING +
      COMMON.MAPPING_TYPE.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY +
      COMMON.LIST(page, limit, order)
    );
  },
  REJECT:
    COMMON.MAPPING + COMMON.MAPPING_TYPE.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY + COMMON.REJECT,
  DETAIL:
    COMMON.MAPPING + COMMON.MAPPING_TYPE.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY + COMMON.INDEX,
  DETAIL_EXISTING:
    COMMON.MAPPING + COMMON.MAPPING_TYPE.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY + COMMON.EXISTING,
  SEARCH:
    COMMON.MAPPING + COMMON.MAPPING_TYPE.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY + COMMON.SEARCH,
  COUNT_SEARCH:
    COMMON.MAPPING +
    COMMON.MAPPING_TYPE.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY +
    COMMON.SEARCH +
    COMMON.COUNT,

  MAPPING_APPROVE:
    COMMON.MAPPING + COMMON.MAPPING_TYPE.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY + COMMON.APPROVE,
});
