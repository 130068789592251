import { formatDD_MMMM_YYYY } from '../../Utils/Date/DateFormater';
import { itemDetailValue } from '../../Utils/Value';

export const detailBroadcastProduct = (t, data) => [
  {
    id: "tslid",
    title: t("PRODUCT:FIELD.ID"),
    value: itemDetailValue(data.id),
  },
  {
    id: "tslprincipalcode",
    title: t("PRODUCT:FIELD.PRINCIPAL_CODE"),
    value: itemDetailValue(data.principal_code),
  },
  {
    id: "tslprincipalname",
    title: t("PRODUCT:FIELD.PRINCIPAL_NAME"),
    value: itemDetailValue(data.principal_name),
  },
  {
    id: "tslprincipalid",
    title: t("PRODUCT:FIELD.PRINCIPAL_ID"),
    value: itemDetailValue(data.principal_id),
  },
  {
    id: "tslprincipalproductcode",
    title: t("PRODUCT:FIELD.PRINCIPAL_PRODUCT_CODE"),
    value: itemDetailValue(data.principal_product_code),
  },
  {
    id: "tslparentcode",
    title: t("PRODUCT:FIELD.PARENT_CODE"),
    value: itemDetailValue(data.parent_code),
  },
  {
    id: "tslshortcode",
    title: t("PRODUCT:FIELD.SHORT_CODE"),
    value: itemDetailValue(data.short_code),
  },
  {
    id: "tslproductname",
    title: t("PRODUCT:FIELD.NAME"),
    value: itemDetailValue(data.name),
  },
  {
    id: "tslproductname2",
    title: t("PRODUCT:FIELD.NAME_2"),
    value: itemDetailValue(data.name_2),
  },
  {
    id: "tslpackaging",
    title: t("PRODUCT:FIELD.PACKAGING"),
    value: itemDetailValue(data.packaging),
  },
  {
    id: "tslpackaging2",
    title: t("PRODUCT:FIELD.PACKAGING_2"),
    value: itemDetailValue(data.packaging_2),
  },
  {
    id: "tsldescription",
    title: t("PRODUCT:FIELD.DESCRIPTION"),
    value: itemDetailValue(data.description),
  },
  {
    id: "tslproductbrandcode",
    title: t("PRODUCT:FIELD.PRODUCT_BRAND_CODE"),
    value: itemDetailValue(data.product_brand_code),
  },
  {
    id: "tslproductbrandname",
    title: t("PRODUCT:FIELD.PRODUCT_BRAND_NAME"),
    value: itemDetailValue(data.product_brand_name),
  },
  {
    id: "tslproductbrandid",
    title: t("PRODUCT:FIELD.PRODUCT_BRAND_ID"),
    value: itemDetailValue(data.product_brand_id),
  },
  {
    id: "tslproductcategorycode",
    title: t("PRODUCT:FIELD.PRODUCT_CATEGORY_CODE"),
    value: itemDetailValue(data.product_category_code),
  },
  {
    id: "tslproductcategoryname",
    title: t("PRODUCT:FIELD.PRODUCT_CATEGORY_NAME"),
    value: itemDetailValue(data.product_category_name),
  },
  {
    id: "tslproductgroupid",
    title: t("PRODUCT:FIELD.PRODUCT_CATEGORY_ID"),
    value: itemDetailValue(data.product_category_id),
  },
  {
    id: "tslproductgrouphierarchycode",
    title: t("PRODUCT:FIELD.PRODUCT_GROUP_CODE"),
    value: itemDetailValue(data.product_group_hierarchy_code),
  },
  {
    id: "tslproductgroupname",
    title: t("PRODUCT:FIELD.PRODUCT_GROUP_NAME"),
    value: itemDetailValue(data.product_group_hierarchy_name),
  },
  {
    id: "tslproductgroupid",
    title: t("PRODUCT:FIELD.PRODUCT_GROUP_ID"),
    value: itemDetailValue(data.product_group_hierarchy_id),
  },
  {
    id: "tslimagefilename1",
    title: t("PRODUCT:FIELD.IMAGE_FILE_NAME_1"),
    value: itemDetailValue(data.image_file_name_1),
  },
  {
    id: "tslimagefilename2",
    title: t("PRODUCT:FIELD.IMAGE_FILE_NAME_2"),
    value: itemDetailValue(data.image_file_name_2),
  },
  {
    id: "tslimagefilename3",
    title: t("PRODUCT:FIELD.IMAGE_FILE_NAME_3"),
    value: itemDetailValue(data.image_file_name_3),
  },
  {
    id: "tslimagefilename4",
    title: t("PRODUCT:FIELD.IMAGE_FILE_NAME_4"),
    value: itemDetailValue(data.image_file_name_4),
  },
  {
    id: "tslimagefilename5",
    title: t("PRODUCT:FIELD.IMAGE_FILE_NAME_5"),
    value: itemDetailValue(data.image_file_name_5),
  },
  {
    id: "tsluom1",
    title: t("PRODUCT:FIELD.UOM_1"),
    value: itemDetailValue(data.uom_1),
  },
  {
    id: "tsluom2",
    title: t("PRODUCT:FIELD.UOM_2"),
    value: itemDetailValue(data.uom_2),
  },
  {
    id: "tsluom3",
    title: t("PRODUCT:FIELD.UOM_3"),
    value: itemDetailValue(data.uom_3),
  },
  {
    id: "tsluom4",
    title: t("PRODUCT:FIELD.UOM_4"),
    value: itemDetailValue(data.uom_4),
  },
  {
    id: "tslconversion1to4",
    title: t("PRODUCT:FIELD.CONVERSION_1_TO_4"),
    value: itemDetailValue(data.conversion_1_to_4),
  },
  {
    id: "tslconversion2to4",
    title: t("PRODUCT:FIELD.CONVERSION_2_TO_4"),
    value: itemDetailValue(data.conversion_2_to_4),
  },
  {
    id: "tslconversion3to4",
    title: t("PRODUCT:FIELD.CONVERSION_3_TO_4"),
    value: itemDetailValue(data.conversion_3_to_4),
  },
  {
    id: "tslcodebottle",
    title: t("PRODUCT:FIELD.CODE_BOTTLE"),
    value: itemDetailValue(data.code_bottle),
  },
  {
    id: "tslcodecrate",
    title: t("PRODUCT:FIELD.CODE_CRATE"),
    value: itemDetailValue(data.code_crate),
  },
  {
    id: "tslmarketlaunchdate",
    title: t("PRODUCT:FIELD.MARKET_LAUNCH_DATE"),
    value: itemDetailValue(formatDD_MMMM_YYYY(data.market_launch_date)),
  },
  {
    id: "tslcasewidth",
    title: t("PRODUCT:FIELD.CASE_WIDTH"),
    value: itemDetailValue(data.case_width),
  },
  {
    id: "tslcaseheight",
    title: t("PRODUCT:FIELD.CASE_HEIGHT"),
    value: itemDetailValue(data.case_height),
  },
  {
    id: "tslcasedepth",
    title: t("PRODUCT:FIELD.CASE_DEPTH"),
    value: itemDetailValue(data.case_depth),
  },
  {
    id: "tslcaseweight",
    title: t("PRODUCT:FIELD.CASE_WEIGHT"),
    value: itemDetailValue(data.case_weight),
  },
  {
    id: "tslfarmalkes_id",
    title: t("PRODUCT:FIELD.FARMALKES_ID"),
    value: itemDetailValue(data.farmalkes_id),
  },
  {
    id: "tslfarmalkesdesc",
    title: t("PRODUCT:FIELD.FARMALKES_DESC"),
    value: itemDetailValue(data.farmalkes_desc),
  },
  {
    id: "tslnienumber",
    title: t("PRODUCT:FIELD.NIE_NUMBER"),
    value: itemDetailValue(data.nie_number),
  },
  {
    id: "tslnieexpireddate",
    title: t("PRODUCT:FIELD.NIE_EXPIRED_DATE"),
    value: itemDetailValue(formatDD_MMMM_YYYY(data.nie_expired_date)),
  },
  {
    id: "tslproductkeyaccountcode",
    title: t("PRODUCT:FIELD.PRODUCT_KEY_ACCOUNT_CODE"),
    value: itemDetailValue(data.product_key_account_code),
  },
  {
    id: "tslproductkeyaccountname",
    title: t("PRODUCT:FIELD.PRODUCT_KEY_ACCOUNT_NAME"),
    value: itemDetailValue(data.product_key_account_name),
  },
  {
    id: "tslproductkeyaccountid",
    title: t("PRODUCT:FIELD.PRODUCT_KEY_ACCOUNT_ID"),
    value: itemDetailValue(data.product_key_account_id),
  },
  {
    id: "tslbarcodelistforuom1",
    title: t("PRODUCT:FIELD.BARCODE_LIST_FOR_UOM_1"),
    value: itemDetailValue(data.barcode_list_for_uom_1),
  },
  {
    id: "tslbarcodelistforuom2",
    title: t("PRODUCT:FIELD.BARCODE_LIST_FOR_UOM_2"),
    value: itemDetailValue(data.barcode_list_for_uom_2),
  },
  {
    id: "tslbarcodelistforuom3",
    title: t("PRODUCT:FIELD.BARCODE_LIST_FOR_UOM_3"),
    value: itemDetailValue(data.barcode_list_for_uom_3),
  },
  {
    id: "tslbarcodelistforuom4",
    title: t("PRODUCT:FIELD.BARCODE_LIST_FOR_UOM_4"),
    value: itemDetailValue(data.barcode_list_for_uom_4),
  },
  {
    id: "tslisfreegooditem",
    title: t("PRODUCT:FIELD.IS_FREE_GOOD_ITEM"),
    value: itemDetailValue(data.free_good_status),
  },
  {
    id: "tslisembalaceitem",
    title: t("PRODUCT:FIELD.IS_EMBALACE_ITEM"),
    value: itemDetailValue(data.embalace_status),
  },
  {
    id: "tslistax1applied",
    title: t("PRODUCT:FIELD.IS_TAX_1_APPLIED"),
    value: itemDetailValue(data.tax_1_applied_status),
  },
  {
    id: "tslistax2applied",
    title: t("PRODUCT:FIELD.IS_TAX_2_APPLIED"),
    value: itemDetailValue(data.tax_2_applied_status),
  },
  {
    id: "tslistax3applied",
    title: t("PRODUCT:FIELD.IS_TAX_3_APPLIED"),
    value: itemDetailValue(data.tax_3_applied_status),
  },
  {
    id: "tslistaxsubsidized",
    title: t("PRODUCT:FIELD.IS_TAX_SUBSIDIZED"),
    value: itemDetailValue(data.tax_subsidized_status),
  },
  {
    id: "tslclass",
    title: t("PRODUCT:FIELD.CLASS"),
    value: itemDetailValue(data.class),
  },
  {
    id: "tslreportcolumnheader1",
    title: t("PRODUCT:FIELD.REPORT_COLUMN_HEADER_1"),
    value: itemDetailValue(data.report_column_header_1),
  },
  {
    id: "tslreportcolumnheader2",
    title: t("PRODUCT:FIELD.REPORT_COLUMN_HEADER_2"),
    value: itemDetailValue(data.report_column_header_2),
  },
  {
    id: "tslsortsequence",
    title: t("PRODUCT:FIELD.SORT_SEQUENCE"),
    value: itemDetailValue(data.sort_sequence),
  },
  {
    id: "tsladditional_margin",
    title: t("PRODUCT:FIELD.ADDITIONAL_MARGIN"),
    value: itemDetailValue(data.additional_margin),
  },
  {
    id: "tslisnoregulardiscount",
    title: t("PRODUCT:FIELD.IS_NO_REGULAR_DISCOUNT"),
    value: itemDetailValue(data.no_regular_discount_status),
  },
  {
    id: "tslresource",
    title: t("PRODUCT:FIELD.RESOURCE"),
    value: itemDetailValue(data.resource),
  },
];
