export const COMMON = Object.freeze({
  INDEX: `/`,
  BROADCASTSYSTEM: `/broadcastsystem`,
  REJECT: `/reject`,
  REJECT_LIST: `/reject/list`,
  INITIATE: `/initiate`,
  INFINITE: `/infinite`,
  USER: `/user`,
  MAPPING: `/mapping`,
  MAPPING_TYPE: {
    DIVISION: `/division`,
    PERSON_PROFILE: `/personprofile`,
    PRODUCT: `/product`,
    PRINCIPAL_PRODUCT: `/principalproduct`,
    PRODUCT_BRAND: `/productbrand`,
    PRINCIPAL_PRODUCT_BRAND: `/principalproductbrand`,
    PRODUCT_CATEGORY: `/productcategory`,
    PRODUCT_GROUP: `/productgrouphierarchy`,
    PRINCIPAL_PRODUCT_GROUP: `/principalproductgrouphierarchy`,
    PRODUCT_KEY_ACCOUNT: `/productkeyaccount`,
    COMPANY_PROFILE: `/companyprofile`,
    PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY: `/principalproductuserdefinedcategory`,
  },
  COUNT: `/count`,
  LIST: function list(page, limit, order) {
    return `/list?page=${page}&limit=${limit}&order=${order}`;
  },
  LISTS: function list(page, limit, name) {
    return `?page=${page}&limit=${limit}$filter=${name}`;
  },
  LIST_FILTER: function list(page, limit, order, filter) {
    return `?page=${page}&limit=${limit}&order=${order}&filter=${filter}`;
  },
  EXPORT_FILTER: function list(order, filter) {
    return `?order=${order}&filter=${filter}`;
  },
  FILTER: (filter) => `?filter=${filter}`,
  REJECT_REASON: `/rejectreason/initiate`,
  EXISTING: `/existing/`,
  SEARCH: `/search`,
  APPROVE: `/approve`,
  REGION: {
    COUNTRY: `/country`,
    DISTRICT: `/district`,
    ISLAND: `/island`,
    POSTAL_CODE: `/postalcode`,
    PROVINCE: `/province`,
    SUBDISTRICT: `/subdistrict`,
    URBAN_VILLAGE: `/urbanvillage`,
  },
  PRINCIPAL: `/principal`,
  PERSON_TITLE: `/persontitle`,
  COMPANY_TITLE: `/companytitle`,
  FORGET: `/forget`,
  EXPORT: `/export`,
  NOTIFICATION: `/notification`,
});
