import { encrypt } from '../../Encryption';

export const rememberAction = (checked, form) => {
  let data = {id: encrypt(form.username), key:encrypt(form.password)};
  if (checked) {
    localStorage.setItem("authorization", JSON.stringify(data));
  } else {
    localStorage.removeItem("authorization");
  }
};
