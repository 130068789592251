import { ID } from './StandartId';

export const PAGE = {
  AUTH_LOGIN: ID.PAGE + "authlogin",
  HOME: ID.PAGE + "home",
  LIST: ID.PAGE + "list",
  REJECT_LIST: ID.PAGE + "rejectlist",
  BROADCAST: ID.PAGE + "broadcast",
  REJECT: ID.PAGE + "reject",
  NOTIFICATION: ID.PAGE + "notification"
};
