import Add from './Add.svg';
import arrowUp from './ArrowDown.svg';
import arrowDown from './ArrowUp.svg';
import NcCloseSm from './NcCloseSm.svg';
import NcError from './NcError.svg';
import NcInfo from './NcInfo.svg';
import NcSuccess from './NcSuccess.svg';
import NcWarning from './NcWarning.svg';
import Trash from './Trash.svg';
import TrashDesable from './TrashDisable.svg';
import Edit from './Edit.svg'
import Delete from './Delete.svg'
import Setup from './Setup.svg'
import TrashRed from './TrashRed.svg'

const Icon = { NcCloseSm, NcError, NcInfo, NcSuccess, NcWarning, Add, arrowDown, arrowUp, Trash, TrashDesable, Edit, Delete, Setup, TrashRed };
export { Icon };