import React, {
  Fragment,
  useEffect,
} from 'react';

import {
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import LoginPage from '../Containers/Page/AuthPage/LoginPage/LoginPage';
import { ROUTER_CONSTANT } from '../Enum/RouterConstant/RouterConstant';
import { dashboardAction } from '../Store/Reducers/DashboardReducer';

const UnAuthorize = props => {
  useEffect(() => {
    props.dispatch(dashboardAction.setOnline(navigator.onLine));
  }, [props]);
 
  return (
    <Fragment>
      <Routes>
        {/* redirect unknown path */}
        <Route
          path={ROUTER_CONSTANT.UNIVERSAL}
          element={<Navigate to={ROUTER_CONSTANT.INDEX} />}
        />
        {/* main page unauthorize */}
        <Route path={ROUTER_CONSTANT.INDEX} element={<LoginPage {...props} />} />
      </Routes>
    </Fragment>
  );
};

export default UnAuthorize;
