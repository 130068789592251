import React from 'react';

import { httpStatusImg } from '../../../../Assets/Images';
import HttpStatusBox from '../../../Organisms/HttpStatus/HttpStatusBox';

const NotFoundPage = props => {
  return (
    <HttpStatusBox
      img={httpStatusImg.Error404}
      title={props.t("Something’s Missing")}
      MessageFirst={props.t("This page doesn’t \nexist or was remove")}
      MessageSecond={props.t("We suggest you back to home")}
    />
  );
};

export default NotFoundPage;
