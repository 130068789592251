import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { mappingFormPageStateGroup } from '../../../../Store/StateGroup';
import ModalClear from '../ModalClear/ModalClear';
import ModalOverwrite from '../ModalOverwrite/ModalOverwrite';
import ModalSave from '../ModalSave/ModalSave';
import moment from 'moment';

const ModalMapping = props => {
  const [, list, modalType, mappingForm] = useSelector(mappingFormPageStateGroup);
  const mappingCount = useSelector(state => state.mapping.mappingCount);
  const submitMapping = useSelector(state => state.mapping.submitMapping);

 
  function convertValues(obj) {
    const newObj = { ...obj }

    for (let key in newObj) {
        if (key!=="farmalkes_id"&&key.includes('_id') && typeof newObj[key] === 'string' && !isNaN(newObj[key])) {
            newObj[key] = Number(newObj[key]);
        }
        if (key.includes('_date') && (newObj[key] === '0001-01-01T00:00:00Z' || !newObj[key])) {
          newObj[key] = "";
        }
        if (key.includes('_date') && newObj[key]) {
          newObj[key] = moment(newObj[key]).format("YYYY-MM-DD");
        }
    }

    return newObj;
  }

const data = useMemo(() => {
  return submitMapping ? convertValues(submitMapping) : null;
}, [submitMapping]);

  return (
    <>
      {modalType === "Clear" ? (
        <ModalClear
          t={props.t}
          uniqueKey={props.uniqueKey}
          dispatch={props.dispatch}
          mappingList={list}
          navigate={props.navigate}
          mappingForm={mappingForm}
        />
      ) : modalType === "Overwrite" ? (
        <ModalOverwrite
          t={props.t}
          mappingList={list}
          dispatch={props.dispatch}
          navigate={props.navigate}
          approveFuc={() => props.approveFuc(data, list, mappingCount)}
          listNavigate={props.listNavigate}
          uniqueKey={props.uniqueKey}
          nextMapping={(newId, existId, mappingList) =>
            props.nextMapping(props.uniqueKey, newId, existId, mappingList)
          }
        />
      ) : modalType === "Save" ? (
        <ModalSave
          t={props.t}
          mappingList={list}
          dispatch={props.dispatch}
          navigate={props.navigate}
          approveFuc={() => props.approveFuc(data, list, mappingCount)}
          listNavigate={props.listNavigate}
          uniqueKey={props.uniqueKey}
          nextMapping={(newId, existId, mappingList) =>
            props.nextMapping(props.uniqueKey, newId, existId, mappingList)
          }
          customSaveMessageCondition={props.customSaveMessageCondition}
          customSaveMessage={props.customSaveMessage}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default ModalMapping;
