import React from 'react';

import { Div } from 'nexcomponent-ui';

import {
  personProfileAdvanceSearch,
} from '../../../../Enum/AdvanceSearch/PersonProfileAdvanceSearch';
import {
  personProfileSearchBreadCrumb,
} from '../../../../Enum/Breadcrumb/PersonProfileBreadcrumb';
import {
  searchPersonProfileColumn,
} from '../../../../Enum/Column/SearchPersonProfileColumn';
import { MAPPING_CODE } from '../../../../Enum/MappingCode/MappingCode';
import {
  ROUTER_CONSTANT,
} from '../../../../Enum/RouterConstant/RouterConstant';
import {
  fetchDataPersonProfileSearchList,
} from '../../../../Fetch/FetchData/PersonProfileData';
import FieldPersonProfileSearchForm
  from '../../../Organisms/Form/SearchForm/FieldPersonProfileSearchForm/FieldPersonProfileSearchForm';
import SearchForm
  from '../../../Organisms/Form/SearchForm/SearchForm/SearchForm';
import HeaderPage from '../../../Organisms/Header/HeaderPage/HeaderPage';

function PersonProfileSearchPage(props) {
  return (
    <Div>
      <HeaderPage
        title={props.t("PERSON_PROFILE:SEARCH.TITLE")}
        listBreadcrumb={personProfileSearchBreadCrumb(props.t)}
        defaultBreadcrumb={props.t("PERSON_PROFILE:SEARCH.TITLE")}
        toTitlePage={ROUTER_CONSTANT.PERSON_PROFILE.MAPPING}
      />
      <SearchForm
        title="PERSON_PROFILE:SEARCH.TITLE"
        field={<FieldPersonProfileSearchForm t={props.t} dispatch={props.dispatch} />}
        t={props.t}
        dispatch={props.dispatch}
        mappingCode={MAPPING_CODE.PERSON_PROFILE}
        navigate={props.navigate}
        option={personProfileAdvanceSearch(props.t)}
        advance={true}
        columnDefault={searchPersonProfileColumn(props.t)}
        backNavigate={ROUTER_CONSTANT.PERSON_PROFILE.MAPPING}
        searchFunc={(currentPage, rowLimit,filter, column,dispatch) =>
          fetchDataPersonProfileSearchList(
            props.validToken(),
            currentPage,
            rowLimit,
            filter,
            column,
            dispatch,
            props.notify
          )
        }
      />
    </Div>
  );
}

export default PersonProfileSearchPage;
