import React, { useEffect, useState } from "react";

import _ from "lodash";
import { Breadcrumb, TitlePage } from "nexcomponent-ui";
import { useLocation } from "react-router-dom";

import { BREADCRUMB, TEXT_SINGLE } from "../../../../Enum/IdSet";
import style from "./HeaderPage.module.css";

const HeaderPage = (props) => {
  const location = useLocation();
  const [breadcrumb, setBreadcrumb] = useState(props.listBreadcrumb);
  useEffect(() => {
    let breadcrumb = props.listBreadcrumb;
    if (_.has(location.state, "previous")) {
      breadcrumb[2].to = location.state.previous;
      setBreadcrumb(breadcrumb);
    }
  }, [location.state, props.listBreadcrumb]);
  return (
    <>
      <Breadcrumb id={BREADCRUMB.HEADER} list={breadcrumb} default={props.defaultBreadcrumb} />
      <div className={style.BoxTitle}>
        <TitlePage
          id={TEXT_SINGLE.TITLE_PAGE}
          className={style.Title}
          to={props.toTitlePage}
          title={props.title}
        />
        {props.customButton ? props.customButton : false}
      </div>
    </>
  );
};

export default HeaderPage;
