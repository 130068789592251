import React from 'react';

import { Div } from 'nexcomponent-ui';

import {
  personProfileBreadCrumb,
} from '../../../../Enum/Breadcrumb/PersonProfileBreadcrumb';
import {
  personProfileColumn,
} from '../../../../Enum/Column/PersonProfileColumn';
import { PAGE } from '../../../../Enum/IdSet';
import { MAPPING_CODE } from '../../../../Enum/MappingCode/MappingCode';
import {
  ROUTER_CONSTANT,
} from '../../../../Enum/RouterConstant/RouterConstant';
import {
  postRejectPersonProfileList,
} from '../../../../Fetch/Api/PersonProfileAPI';
import {
  fetchPersonProfileMappingForm,
} from '../../../../Fetch/FetchData/PersonProfileData';
import CancelRejectButton
  from '../../../Organisms/Button/CancelRejectButton/CancelRejectButton';
import HeaderPage from '../../../Organisms/Header/HeaderPage/HeaderPage';
import ModalReject from '../../../Organisms/Modal/ModalReject/ModalReject';
import RejectReasonList
  from '../../../Organisms/TableList/RejectReasonList/RejectReasonList';

const PersonProfileRejectReasonPage = props => (
  <Div id={PAGE.REJECT_LIST}>
    <ModalReject
      onReject={true}
      t={props.t}
      dispatch={props.dispatch}
      mappingCode={MAPPING_CODE.PERSON_PROFILE}
      navigate={props.navigate}
      rejectFunc={(data, notify, dispatch) =>
        postRejectPersonProfileList(props.validToken(), data, notify, dispatch)
      }
      uniqueKey={"person_profile_id"}
      nextMapping={(newId, existId, mappingList, nav) =>
        fetchPersonProfileMappingForm(
          props.validToken(),
          props.dispatch,
          "person_profile_id",
          newId,
          existId,
          mappingList,
          props.notify,
          () => props.navigate(nav)
        )
      }
    />
    <HeaderPage
      title={props.t("PERSON_PROFILE:TITLE")}
      listBreadcrumb={personProfileBreadCrumb(props.t)}
      defaultBreadcrumb={props.t("COMMON:REJECT_REASON")}
      toTitlePageto={ROUTER_CONSTANT.PERSON_PROFILE.LIST}
    />
    <RejectReasonList
      t={props.t}
      dispatch={props.dispatch}
      column={personProfileColumn(props.t)}
      mappingCode={MAPPING_CODE.PERSON_PROFILE}
    />
    <CancelRejectButton
      backPage={ROUTER_CONSTANT.PERSON_PROFILE.LIST}
      mappingCode={MAPPING_CODE.PERSON_PROFILE}
      onReject={true}
      t={props.t}
      dispatch={props.dispatch}
      navigate={props.navigate}
    />
  </Div>
);

export default PersonProfileRejectReasonPage;
