import { AUTH_URL } from '../EndPoint/AuthenticationEndpoint';
import * as BaseAPI from './BaseAPIs';

export const  postAuthorize=async(data, notify,dispatch,errAction)=> {  
  return BaseAPI.doPost(AUTH_URL.AUTHORIZE, null, data, notify,dispatch,undefined,err=>errAction(err));
}

export async function postVerify(authorization, data, notify,dispatch,action,errAction) {
  return BaseAPI.doPost(AUTH_URL.VERIFY, authorization, data, notify,dispatch,(res)=>action(res),err=>errAction(err));
}

export async function postGenerateToken(authorization_code, crypto_random, notify,dispatch,action,errAction) {
  const data = {authorization_code, code_verifier: crypto_random};
  return BaseAPI.doPost(AUTH_URL.GENERATOR_TOKEN, null, data, notify,dispatch,(res)=>action(res),err=>errAction(err));
}

export async function logOut(authorization, notify,dispatch,action) {
  return BaseAPI.doPost(AUTH_URL.LOGOUT, authorization, null, notify,dispatch,(res)=>action(res));
}

export const postUserVerify = (authorization, notify,dispatch,action) => {
  return BaseAPI.doGet(AUTH_URL.USER_VERIFY, authorization, null, null, notify,dispatch,(res)=>action(res));
};
export const postRefreshToken = (token, data, notify,dispatch,action) => {
  return BaseAPI.doPost(AUTH_URL.REFRESH_TOKEN, token, data, notify,dispatch,(res)=>action(res));
};
