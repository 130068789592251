import { BROADCAST_URL } from '../EndPoint/BroadcastEndpoint';
import * as BaseAPI from './BaseAPIs';

export const getBroadcastInitiate = (token, filter,notify,dispatch,action) => {
  return BaseAPI.doGet(BROADCAST_URL.INITIATE(filter), token,null,null,notify,dispatch,(res)=>action(res));
};
export const getBroadcastList = (token, page, limit, order, filter, notify,dispatch,action,errAction) => {
  return BaseAPI.doGet(BROADCAST_URL.LIST(page, limit, order, filter), token,null,null, notify,dispatch,(res)=>action(res),(err)=>errAction(err));
};
export const getBroadcastAdminList = (token, page, limit,name,notify,dispatch,action) => {
  let params=()=>name===""||name===undefined||name===null?{page:page,limit:limit}:{page:page,limit:limit,filter:`alias_name like ${name}`}
  
  return BaseAPI.doGet(
    BROADCAST_URL.ADMIN,
    token,
    params(),
    null,
    notify,
    dispatch,
    res => action(res)
  );
};
export const getBroadcastDetail = (token, id,notify,dispatch,action,errAction) => {
  return BaseAPI.doGet(BROADCAST_URL.DETAIL(id), token,null,null,notify,dispatch,(res)=>action(res),(err)=>errAction(err));
};
