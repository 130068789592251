import _ from 'lodash';

import { utilsActions } from '../../Store/Reducers/UtilsReducer';

export function limit(dispatch) {
  dispatch(utilsActions.setSelectLimit(false));
}
export function setPageArray(totalRow, rowLimit, dispatch) {
  const pageNumber = [];
  for (let i = 1; i <= Math.ceil(totalRow / rowLimit); i++) {
    pageNumber.push(i);
  }
  dispatch(utilsActions.setPageNumber(pageNumber));
}

export async function selectRowlimit(selectLimit, dispatch) {
   if (selectLimit === true) {
    await dispatch(utilsActions.setStarPage(0));
    await dispatch(utilsActions.setEndPage(4));
    await dispatch(utilsActions.setCurrentPage(1));
  }
}
export async function resetSelectRowlimit(dispatch) {
  await dispatch(utilsActions.setStarPage(0));
  await dispatch(utilsActions.setEndPage(4));
  await dispatch(utilsActions.setCurrentPage(1));
}

export const previousPage = async (startPage, endPage, currentPage, lastIndex, dispatch) => {

    if (currentPage <= 5) {
      await dispatch(utilsActions.setStarPage(0));
      await dispatch(utilsActions.setCurrentPage(currentPage - 1));
      await dispatch(utilsActions.setEndPage(4));
      limit(dispatch);
    } else if (endPage === lastIndex - 1) {
      await dispatch(utilsActions.setCurrentPage(currentPage - 1));
      await dispatch(utilsActions.setEndPage(endPage - 1));
      limit(dispatch);
    } else {
      await dispatch(utilsActions.setCurrentPage(currentPage - 1));
      await dispatch(utilsActions.setEndPage(endPage - 1));
      dispatch(utilsActions.setStarPage(startPage - 1));
      limit(dispatch);
    }
};

export const firstPage = async (dispatch) => {
  await dispatch(utilsActions.setStarPage(0));
  await dispatch(utilsActions.setCurrentPage(1));
  dispatch(utilsActions.setEndPage(4));
  limit(dispatch);
};

export const mid = (totalRow, rowLimit) => Math.ceil(Math.ceil(totalRow / rowLimit) / 2);

export const lastPage = async (lastIndex, dispatch) => {
  await dispatch(utilsActions.setStarPage(lastIndex - 6));
  await dispatch(utilsActions.setCurrentPage(lastIndex));
  dispatch(utilsActions.setEndPage(lastIndex - 1));
  limit(dispatch);
};

export const nextPage = async (startPage, endPage, lastIndex, currentPage, dispatch) => {
  if (currentPage === 1) {
    await dispatch(utilsActions.setCurrentPage(currentPage + 1));
    await dispatch(utilsActions.setStarPage(0));
    dispatch(utilsActions.setEndPage(4));
    limit(dispatch);
  } else if (endPage >= lastIndex-3) {
    await dispatch(utilsActions.setCurrentPage(currentPage + 1));
    await dispatch(utilsActions.setStarPage(lastIndex-6));
    dispatch(utilsActions.setEndPage(lastIndex-1));
  } else {
    await dispatch(utilsActions.setCurrentPage(currentPage + 1));
    await dispatch(utilsActions.setStarPage(startPage + 1));
    dispatch(utilsActions.setEndPage(endPage + 1));
    limit(dispatch);
  }
};

export const paginate = async (pageNumber, dispatch) => {
  await dispatch(utilsActions.setCurrentPage(pageNumber));
  limit(dispatch);
};
export const lastIndex = (array) => _.lastIndexOf(array);
export const firstIndex = (array) => _.first(array);
