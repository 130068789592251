import { ID } from './StandartId';

export const TEXT_SINGLE = {
  FOOTER: ID.TEXT_SINGLE + "footer",
  GREETING: ID.TEXT_SINGLE + "greeting",
  GUIDE: ID.TEXT_SINGLE + "guide",
  USERNAME: "username",
  PASSWORD: "password",
  TITLE_PAGE: ID.TEXT_SINGLE + "titlepage",
  TITLE_REJECT_REASON: ID.TEXT_SINGLE + "titlerejectreason",
  SUB_TITLEPAGE: ID.TEXT_SINGLE + "subtitlepage",
  CUSTOM_REASON: ID.TEXT_SINGLE + "customreason",
  MAPPING_SUM: ID.TEXT_SINGLE + "mappingsum",
  MAPPING_COUNT: ID.TEXT_SINGLE + "mappingcount",
};
