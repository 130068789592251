import React, { useCallback } from "react";

import { Div } from "nexcomponent-ui";

import { productBrandBreadCrumb } from "../../../../Enum/Breadcrumb/ProductBrandBreadcrumb";
import { productBrandColumn } from "../../../../Enum/Column/ProductBrandColumn";
import { PAGE } from "../../../../Enum/IdSet";
import { MAPPING_CODE } from "../../../../Enum/MappingCode/MappingCode";
import { ROUTER_CONSTANT } from "../../../../Enum/RouterConstant/RouterConstant";
import {
  postRejectProductBrandList,
  postRejectProductBrandPrincipalList,
} from "../../../../Fetch/Api/ProductBrandAPI";
import { fetchProductBrandMappingForm } from "../../../../Fetch/FetchData/ProductBrandData";
import CancelRejectButton from "../../../Organisms/Button/CancelRejectButton/CancelRejectButton";
import HeaderPage from "../../../Organisms/Header/HeaderPage/HeaderPage";
import ModalReject from "../../../Organisms/Modal/ModalReject/ModalReject";
import RejectReasonList from "../../../Organisms/TableList/RejectReasonList/RejectReasonList";
import useQueryRoute from "../../../../Hooks/useQueryRoute";
import { fetchProductBrandPrincipalMappingForm } from "../../../../Fetch/FetchData/ProductBrandPrincipalData";

const ProductBrandRejectReasonPage = (props) => {
  const { principal } = useQueryRoute();

  const rejectFunc = useCallback(
    (data, notify, dispatch) => {
      if (principal) {
        return postRejectProductBrandPrincipalList(props.validToken(), data, notify, dispatch);
      } else {
        return postRejectProductBrandList(props.validToken(), data, notify, dispatch);
      }
    },
    [principal, props]
  );

  const nextMapping = useCallback(
    (newId, existId, mappingList, nav) => {
      if (principal) {
        return fetchProductBrandPrincipalMappingForm(
          props.validToken(),
          props.dispatch,
          "principal_product_brand_id",
          newId,
          existId,
          mappingList,
          props.notify,
          () => props.navigate(nav)
        );
      } else {
        return fetchProductBrandMappingForm(
          props.validToken(),
          props.dispatch,
          "product_brand_id",
          newId,
          existId,
          mappingList,
          props.notify,
          () => props.navigate(nav)
        );
      }
    },
    [principal, props]
  );

  return (
    <Div id={PAGE.REJECT_LIST}>
      <ModalReject
        onReject={true}
        t={props.t}
        dispatch={props.dispatch}
        mappingCode={MAPPING_CODE.PRODUCT_BRAND}
        navigate={props.navigate}
        rejectFunc={rejectFunc}
        uniqueKey={principal ? "principal_product_brand_id" : "product_brand_id"}
        nextMapping={nextMapping}
      />
      <HeaderPage
        title={props.t("PRODUCT_BRAND:TITLE")}
        listBreadcrumb={productBrandBreadCrumb(props.t)}
        defaultBreadcrumb={props.t("COMMON:REJECT_REASON")}
        toTitlePageto={ROUTER_CONSTANT.PRODUCT_BRAND.LIST}
      />
      <RejectReasonList
        t={props.t}
        dispatch={props.dispatch}
        column={productBrandColumn(props.t)}
        mappingCode={MAPPING_CODE.PRODUCT_BRAND}
      />
      <CancelRejectButton
        backPage={ROUTER_CONSTANT.PRODUCT_BRAND.LIST}
        mappingCode={MAPPING_CODE.PRODUCT_BRAND}
        onReject={true}
        t={props.t}
        dispatch={props.dispatch}
        navigate={props.navigate}
      />
    </Div>
  );
};

export default ProductBrandRejectReasonPage;
