import React, { useState } from 'react';

import _ from 'lodash';
import {
  Div,
  Input,
  Li,
  nexIcon,
  Paragraph,
  Ul,
} from 'nexcomponent-ui';

import useComponentVisible
  from '../../../UnstableComponent/Multi/useComponentVisible';
import style from './FieldAutoCompleteAdvanceSearch.module.css';

const FieldAutoCompleteAdvanceSearch = props => {
  const {
    ref: componentRef,
    isComponentVisible: menuOpen,
    setIsComponentVisible: setMenuOpen,
  } = useComponentVisible({
    initialIsVisible: false,
    onClickOutside: props.onMenuClose,
  });
  const [open, setOpen] = useState(false);
  const [filtered, setFiltered] = useState(props.suggestions);
  const nonClickableItem = target => {
    if (
      target.hasAttribute("clickable") ||
      target.parentNode.hasAttribute("clickable") ||
      target.parentNode.parentNode.hasAttribute("clickable") ||
      target.parentNode.parentNode.parentNode.hasAttribute("clickable")
    ) {
      return false;
    }
    return true;
  };

  const checkIsDropdownHandle = target => {
    if (
      target.hasAttribute("dropdown-handle") ||
      target.parentNode.hasAttribute("dropdown-handle") ||
      target.parentNode.parentNode.hasAttribute("dropdown-handle")
    ) {
      return true;
    }
  };
  const openMenu = ({target}) => {
    if (nonClickableItem(target)) {
      if (checkIsDropdownHandle(target)) {
        if (!menuOpen) {
          setMenuOpen(true);
        } else {
          setMenuOpen(false);
          props.onMenuClose();
        }
      } else if (target.id === "btndone" || target.id === "btncancel") {
        setMenuOpen(false);
      } else {
        setMenuOpen(true);
      }
    }
  };
  const [value, setValue] = useState(null);
  const onChange = async e => {
    const {value} = e.target;
    // await props.onChange(value, props.itemId);
    setValue(value);
    if (value === "" || value === null) {
      setFiltered(props.suggestions);
    } else {
      const newFilteredSuggestions = props.suggestions.filter(
        suggestion => suggestion[props.labelKey].toLowerCase().indexOf(value.toLowerCase()) > -1
      );
      setFiltered(newFilteredSuggestions);
    }
  };

  const getWidth = () => {
    const el = componentRef.current;
    var rect = el.getBoundingClientRect();
    let position = rect.width;

    return position;
  };

  const onClick = () => {
    setOpen(true);
  };
  const handleScroll = async e => {
    const containerHeight = e.currentTarget.clientHeight;
    const scrollHeight = e.currentTarget.scrollHeight;
    const scrollTop = e.currentTarget.scrollTop;
    const progress = (scrollTop + containerHeight) / scrollHeight;
    if (progress === 1) {
      props.onScroll();
    }
  };
  const onClickSelect = (e, value) => {
    setOpen(false);
    const newFilteredSuggestions = props.suggestions.filter(object => {
      return object[props.valueKey] !== value;
    });
    setFiltered(newFilteredSuggestions);
    setValue(e.currentTarget.innerText);
    props.onSelect(e.currentTarget.innerText, props.itemId);
  };
  const renderAutocomplete = () => {
    return (
      <Div id={props.id} className={style.Autocomplete} style={{width: getWidth() + "px"}}>
        <Div className={style.BoxInputSearch}>
          <Input
            id={props.id}
            size="xs"
            className={style.InputSearch}
            name={props.name}
            value={value}
            onChange={onChange}
            placeholder={props.placeholder}
            type="text"
          />
          <Div className={style.IconSearch}>
            <nexIcon.NcSearch color="rgba(153, 153, 153, 1)" width={5} height={5} />
          </Div>
        </Div>
        <Ul className={style.ListOption} onScroll={handleScroll}>
          {filtered.length === 0 ? (
            <Li>
              <Paragraph id={props.id} value="Not found" />
            </Li>
          ) : (
            _.orderBy(filtered, ["value"], ["asc"]).map((item, index) => {
              return (
                <Li
                  id={props.id}
                  key={index}
                  onClick={e => onClickSelect(e, item[props.valueKey], item[props.labelKey])}
                >
                  {item[props.labelKey]}
                </Li>
              );
            })
          )}
        </Ul>
      </Div>
    );
  };
  return (
    <Div ref={componentRef} onClick={openMenu}>
      <Div id={props.id} className={style.ContainerAutocomplete} tabIndex="0">
        <Input
          id={props.id}
          size={props.size}
          name={props.name}
          value={props.value}
          onClick={onClick}
          className={style.Input}
          placeholder={props.placeholder}
          type="text"
        />
        <Div className={style.IconSelect} onClick={onClick}>
          <nexIcon.NcSelectOption color="#9b3789" width={10} height={10} />
        </Div>
      </Div>

      {menuOpen && open ? (
        <Div className={style.BoxOption} width={getWidth() + "px"}>
          {renderAutocomplete()}
        </Div>
      ) : (
        ""
      )}
    </Div>
  );
};

export default FieldAutoCompleteAdvanceSearch;
FieldAutoCompleteAdvanceSearch.defaultProps = {
  onMenuClose: () => {},
  onScroll: () => {},
  id: "",
};
