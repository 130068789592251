export const productKeyAccountFieldMapping = [
  "product_key_account_id" ,
    "code" ,
    "name" ,
    "level",
    "principal_id",
    "principal_name" ,
    "principal_code" ,
    "parent_id",
    "parent_name",
    "parent_code",
];
