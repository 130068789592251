import './index.css';
import './nexThemes.css';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import { NotificationProvider } from 'nexcomponent-ui';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import i18next from './Config/I18nConfig';
import App from './Containers/App/App';
import reportWebVitals from './reportWebVitals';
import store from './Store';

export let persistor = persistStore(store);
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Suspense fallback={"Loading"}>
          <I18nextProvider i18n={i18next}>
            <NotificationProvider>
              <App />
            </NotificationProvider>
          </I18nextProvider>
        </Suspense>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
