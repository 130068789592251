import React, { useEffect } from 'react';

import _ from 'lodash';
import { ListEmpty } from 'nexcomponent-ui';
import { useSelector } from 'react-redux';

import CheckBoxAll
  from '../../../../Components/Molecules/CheckBox/CheckBoxAll/CheckBoxAll';
import CheckBoxPartial
  from '../../../../Components/Molecules/CheckBox/CheckBoxPartial/CheckBoxPartial';
import { TABLE } from '../../../../Enum/IdSet';
import { utilsActions } from '../../../../Store/Reducers/UtilsReducer';
import RejectSection from '../../Section/RejectSection/RejectSection';
import TableList from '../TableList';
import style from './RejectReasonList.module.css';

const RejectReasonList = props => {
  const list = useSelector(state => state.mapping.rejectList);
  const checkedArray = useSelector(state => state.utils.checkedArray);
  const sortDateAsc = useSelector(state => state.utils.sortDateAsc);
  const dataList = _.orderBy(list, ["updated_at"], [sortDateAsc.replace("created_at ", "")]);
  const [sort] = sortDateAsc.split(" ");
  useEffect(() => {
    if (sort === "updated_at") {
      props.dispatch(utilsActions.setSortDateAsc("created_at asc"));
    }
  });
  return (
    <>
      <TableList
        id={TABLE.REJECT_LIST}
        sort={true}
        columns={props.column}
        data={dataList}
        selectable={true}
        t={props.t}
        dispatch={props.dispatch}
        actionType="reject"
        footer={false}
        checkBoxAll={
          <CheckBoxAll
            className={style.CheckBox}
            data={list}
            dispatch={props.dispatch}
            actionType="reject"
          />
        }
        checkBoxPartial={item => (
          <CheckBoxPartial
            className={style.CheckBox}
            mappingCode={props.mappingCode}
            item={item}
            data={checkedArray}
            value={item.id}
            t={props.t}
            dispatch={props.dispatch}
            actionType="reject"
          />
        )}
        customFooter={
          <RejectSection mappingCode={props.mappingCode} t={props.t} dispatch={props.dispatch} />
        }
        empty={<ListEmpty value={props.t("COMMON:LIST_EMPTY")} />}
      />
    </>
  );
};

export default RejectReasonList;
