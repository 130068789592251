import React from 'react';

import { Div } from 'nexcomponent-ui';

import {
  productKeyAccountSearchBreadCrumb,
} from '../../../../Enum/Breadcrumb/ProductKeyAccountBreadcrumb';
import {
  searchProductKeyAccountColumn,
} from '../../../../Enum/Column/SearchProductKeyAccountColumn';
import { MAPPING_CODE } from '../../../../Enum/MappingCode/MappingCode';
import {
  ROUTER_CONSTANT,
} from '../../../../Enum/RouterConstant/RouterConstant';
import {
  fetchDataProductKeyAccountSearchList,
} from '../../../../Fetch/FetchData/ProductKeyAccountData';
import FieldProductKeyAccountSearchForm
  from '../../../Organisms/Form/SearchForm/FieldProductKeyAccountSearchForm/FieldProductKeyAccountSearchForm';
import SearchForm
  from '../../../Organisms/Form/SearchForm/SearchForm/SearchForm';
import HeaderPage from '../../../Organisms/Header/HeaderPage/HeaderPage';

function ProductKeyAccountSearchPage(props) {
  return (
    <Div>
      <HeaderPage
        title={props.t("PRODUCT_KEY_ACCOUNT:SEARCH.TITLE")}
        listBreadcrumb={productKeyAccountSearchBreadCrumb(props.t)}
        defaultBreadcrumb={props.t("PRODUCT_KEY_ACCOUNT:SEARCH.TITLE")}
        toTitlePage={ROUTER_CONSTANT.PRODUCT_KEY_ACCOUNT.MAPPING}
      />
      <SearchForm
        title="PRODUCT_KEY_ACCOUNT:SEARCH.TITLE"
        field={<FieldProductKeyAccountSearchForm t={props.t} dispatch={props.dispatch} />}
        t={props.t}
        dispatch={props.dispatch}
        mappingCode={MAPPING_CODE.PRODUCT_KEY_ACCOUNT}
        navigate={props.navigate}
        advance={false}
        columnDefault={searchProductKeyAccountColumn(props.t)}
        backNavigate={ROUTER_CONSTANT.PRODUCT_KEY_ACCOUNT.MAPPING}
        searchFunc={(currentPage, rowLimit, filter, column, dispatch) =>
          fetchDataProductKeyAccountSearchList(
            props.validToken(),
            currentPage,
            rowLimit,
            filter,
            column,
            dispatch,
            props.notify
          )
        }
      />
    </Div>
  );
}

export default ProductKeyAccountSearchPage;
