import { REJECT_URL } from "../EndPoint/RejectEndpoint";
import * as BaseAPI from "./BaseAPIs";

export const getRejectInitiate = (token, filter, notify, dispatch, action) => {
  return BaseAPI.doGet(REJECT_URL.INITIATE(filter), token, null, null, notify, dispatch, (res) =>
    action(res)
  );
};
export const getRejectList = (
  token,
  page,
  limit,
  order,
  filter,
  notify,
  dispatch,
  action,
  errAction
) => {
  return BaseAPI.doGet(
    REJECT_URL.LIST(page, limit, order, filter),
    token,
    null,
    null,
    notify,
    dispatch,
    (res) => action(res),
    (err) => errAction(err)
  );
};
export const getRejectAdminList = (token, page, limit, name, notify, dispatch, action) => {
  let params = () =>
    name === "" || name === undefined || name === null
      ? { page: page, limit: limit }
      : { page: page, limit: limit, filter: `alias_name like ${name}` };
  return BaseAPI.doGet(REJECT_URL.ADMIN, token, params(), null, notify, dispatch, (res) =>
    action(res)
  );
};
export const getRejectDetail = (token, type, id, notify, dispatch, action, errAction) => {
  return BaseAPI.doGet(
    REJECT_URL.DETAIL(type, id),
    token,
    null,
    null,
    notify,
    dispatch,
    (res) => action(res),
    (err) => errAction(err)
  );
};

export const getRejectExport = (token, order, filter, notify, dispatch, action, errAction) => {
  return BaseAPI.doGet(
    REJECT_URL.EXPORT(order, filter),
    token,
    null,
    null,
    notify,
    dispatch,
    (res) => action(res),
    (err) => errAction(err)
  );
};
