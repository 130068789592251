export const tempMappingTitle = [
  {id: "Person Profile", value: "Person Profile", isChecked: false},
  {id: "Division", value: "Division", isChecked: false},
  {id: "Product Brand", value: "Product Brand", isChecked: false},
  {id: "Product Group", value: "Product Group", isChecked: false},
  {id: "Company Profile", value: "Company Profile", isChecked: false},
  {
    id: "Principal Product User Defined Category",
    value: "Principal Product User Defined Category",
    isChecked: false,
  },
  {id: "Product Category", value: "Product Category", isChecked: false},
  {id: "Product Key Account", value: "Product Key Account", isChecked: false},
  {id: "Product", value: "Product", isChecked: false},
];
export const Addddd = [
  {id: "1", value: "Person Profile", isChecked: false},
  {id: "2", value: "Division", isChecked: false},
];

export const tempMappingSource = [
  {id: 1, value: "NexChief"},
  {id: 2, value: "NexMile"},
  {id: 3, value: "NexDrive"},
  {id: 4, value: "NexHub"},
  {id: 5, value: "CHIEF"},
  {id: 6, value: "auth"},
];
