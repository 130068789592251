import { broadcastsActions } from '../Reducers/BroadcastReducer';
import { dashboardAction } from '../Reducers/DashboardReducer';
import { mappingActions } from '../Reducers/MappingReducer';
import { notificationActions } from '../Reducers/NotificationReducer';
import { rejectsActions } from '../Reducers/RejectReducer';
import { utilsActions } from '../Reducers/UtilsReducer';

export const activePageAction = async (activePage, pathname, menuLevel3, dispatch) => {
  const [, key, key2] = pathname.split("/");
  dispatch(utilsActions.setLoading(true));
  dispatch(utilsActions.setCurrentPage(1));
  dispatch(utilsActions.setRowLimit(10));
  if (menuLevel3 !== key && key2 === undefined) {
    dispatch(mappingActions.setList([]));
    await dispatch(mappingActions.setMappingList([]));
    await dispatch(mappingActions.setRejectList([]));

  }
  if (key2 === undefined) {
    await dispatch(mappingActions.setMappingList([]));
    await dispatch(mappingActions.setRejectList([]));
    
    dispatch(utilsActions.setLoading(true));
  }
  if (activePage !== pathname) {
    await dispatch(utilsActions.setActivePage(pathname));
    await dispatch(utilsActions.setForm(null));
    await dispatch(
      utilsActions.setAdvanceSearch([{id: 1, searchTitle: "", searchName: "", searchValue: ""}])
    );
    await dispatch(
      utilsActions.setRejectReasonSelect({
        reject_reason_custom: "Data It's Not Complete",
        reject_reason_option: 1,
      })
    );

    await dispatch(mappingActions.setSearchColumn([]));
    await dispatch(broadcastsActions.setBroadcastList([]));
    await dispatch(broadcastsActions.setFilter(""));
    await dispatch(rejectsActions.setFilter(""));
    await dispatch(dashboardAction.setMappingList([]));
    await dispatch(utilsActions.setSearchData({}));
    await dispatch(utilsActions.setApplyAdvanceSearch({}));
    await dispatch(utilsActions.setFormValidation({}));
    await dispatch(utilsActions.setSearchColumn([]));
    await dispatch(utilsActions.setResultModal(false));
    await dispatch(notificationActions.setNotificationList([]))
    
    dispatch(mappingActions.setList([]));
    dispatch(utilsActions.setTimeOut(false));
    dispatch(utilsActions.setCurrentPage(1));
    dispatch(utilsActions.setRowLimit(10));
  }
};
