import { ID } from './StandartId';

export const BUTTON = {
  CANCEL: ID.BUTTON + "cancel",
  SAVE: ID.BUTTON + "save",
  REJECT: ID.BUTTON + "reject",
  MAPPING: ID.BUTTON + "mapping",
  CLEAR: ID.BUTTON + "clear",
  DONE: ID.BUTTON + "done",
  LOGIN: ID.BUTTON + "login",
  APPLY: ID.BUTTON + "apply",
  RESET: ID.BUTTON + "reset",
  OVERWRITE: ID.BUTTON + "overwrite",
  EDIT: ID.BUTTON + "edit"
};
