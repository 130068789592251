import { authActions } from '../../../Store/Reducers/AuthenticationReducer';
import { userExistAction } from './UserExistAction';

export const defaultUserExist = dispatch => {
  if (userExistAction("id") !== null) {
  return  dispatch(
      authActions.setForm({username: userExistAction("id"), password: JSON.parse(localStorage.getItem("authorization")).key})
    );
  }
};
