
export const searchCompanyProfileColumn =(t)=>[
  // { 
  //   name: t("COMMON:FIELD.RESOURCE"),
  //   selector: "resource",
  //   width: "100px",
  // },
  {
    name: t("COMPANY_PROFILE:FIELD.ID"),
    selector:"company_profile_id",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.COMPANY_TITLE_NAME"),
    selector:"company_title_name",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.COMPANY_TITLE_ID"),
    selector:"company_title_id",
    width: "100px",
  }, {
    name: t("COMMON:FIELD.NPWP"),
    selector:"npwp",
    width: "100px",
  },  {
    name: t("COMMON:FIELD.PHONE"),
    selector:"phone",
    width: "100px",
  }, {
    name: t("COMMON:FIELD.NAME"),
    selector: "name",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.ADDRESS1"),
    selector: "address_1",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.ADDRESS2"),
    selector: "address_2",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.HAMLET"),
    selector: "hamlet",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.NEIGHBOURHOOD"),
    selector: "neighbourhood",
    width: "100px",
  }
]