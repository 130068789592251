import _ from 'lodash';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  searchColumn: [],
  advanceSearchColumn: [],
  searchForm: [],
  searchData: [],
  searchList: null,
  detail: {},
  existing: {},
  rejectList: [],
  mappingList: [],
  countData: 0,
  rejectReason: [],
  mappingForm: [],
  lastUpdate: null,
  submitMapping: {},
  mappingCount: 0,
  mappingSum: 0,
  clearCounter: true,
  additionalInfo:null,
  formValidation:{}
};
const mappingSlice = createSlice({
  name: "mapping",
  initialState: initialState,
  reducers: {
    setList(state, data) {
      state.list = data.payload;
    },
    setSearchColumn(state, data) {
      state.searchColumn = data.payload;
    },
    setAdvanceSearchColumn(state, data) {
      state.advanceSearchColumn = data.payload;
    },
    setSearchForm(state, data) {
      state.searchForm = data.payload;
    },
    setSearchList(state, data) {
      state.searchList = data.payload;
    },
    setCountData(state, data) {
      state.countData = data.payload;
    },
    setRejectList(state, data) {
      state.rejectList = data.payload;
    },
    setMappingList(state, data) {
      state.mappingList = data.payload;
    },
    setAddMappingList(state, data) {
      state.mappingList.push(data.payload);
    },
    setRemoveMappingList(state, data) {
      state.mappingList.splice(
        _.findIndex(state.mappingList, function (item) {
          return item.id === data.payload;
        }),
        1
      );
    },
    setAddRejectList(state, data) {
      state.rejectList.push(data.payload);
    },
    setRemoveRejectList(state, data) {
      state.rejectList.splice(
        _.findIndex(state.rejectList, function (item) {
          return item.id === data.payload;
        }),
        1
      );
    },
    setrejectReason(state, data) {
      state.rejectReason = data.payload;
    },
    setDetail(state, data) {
      state.detail = data.payload;
    },
    setExisting(state, data) {
      state.existing = data.payload;
    },
    setMappingForm(state, data) {
      state.mappingForm = data.payload;
    },
    setLastUpdate(state, data) {
      state.lastUpdate = data.payload;
    },
    setSubmitMapping(state, data) {
      state.submitMapping = data.payload;
    },
    setMappingCount(state, data) {
      state.mappingCount = data.payload;
    },
    setMappingSum(state, data) {
      state.mappingSum = data.payload;
    },
    setAddMappingSum(state, data) {
      state.mappingSum = state.mappingSum + data.payload;
    },
    setClearCounter(state, data) {
      state.clearCounter = data.payload;
    },
    setAdditionalInfo(state, data) {
      state.additionalInfo = data.payload;
    },
    setAddAdditionalInfo(state, data) {
      state.additionalInfo.push(data.payload);
    },
    setRemoveAdditionalInfo(state, data) {
      _.pullAt(state.additionalInfo, [data.payload]);
    },
    setFormValidation(state, data) {
      state.formValidation = data.payload;
    },
  },
});

export const mappingActions = mappingSlice.actions;
export default mappingSlice.reducer;
