export const profileMenu = t => {
  let menu = [
    // {
    //   to: "#",
    //   icon: <nexIcon.NcPerson width={SIZE.SIZE_20} height={SIZE.SIZE_20} color={COLOR.PRIMER} />,
    //   menuTitle: t("COMMON:PROFILE_SETTING"),
    // },
    // {
    //   to: "#",
    //   icon: <nexIcon.NcPerson width={SIZE.SIZE_20} height={SIZE.SIZE_20} color={COLOR.PRIMER} />,
    //   menuTitle: t("COMMON:BUTTON_CHANGE_PASSWORD"),
    // },
    // {
    //   to: "#",
    //   icon: <nexIcon.NcPerson width={SIZE.SIZE_20} height={SIZE.SIZE_20} color={COLOR.PRIMER} />,
    //   menuTitle: t("COMMON:OTHER_SETTING"),
    // },
  ];
  return menu;
};
