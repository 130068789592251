import {
  productFieldMapping,
  productFieldPrincipalMapping,
} from '../../Enum/FieldMapping/ProductFieldMapping';
import { dashboardAction } from '../../Store/Reducers/DashboardReducer';
import { mappingActions } from '../../Store/Reducers/MappingReducer';
import { utilsActions } from '../../Store/Reducers/UtilsReducer';
import {
  isContent,
  isContentErr,
} from '../../Utils/Response/Response';
import { getDetailExistingProductPrincipal, getDetailProductPrincipal, postApproveMappingProductPrincipal, postProductPrincipalCountData, postProductPrincipalList, postProductPrincipalSearchCountData, postSearchProductPrincipalList } from '../Api/ProductAPI';

import {
  reloadList,
  setDefaultPagination,
} from './DashboardData';
import {
  approvalAction,
  approvalActionErr,
  setMappingForm,
  setMappingSearch,
  setMappingSearchErr,
} from './MappingData';

export  const fetchDataProductPrincipalList=(
  totalRow,
  countData,
  token,
  currentPage,
  rowLimit,
  sortDate,
  dispatch,
  notify
)=>postProductPrincipalCountData(token,notify,dispatch,
  (response)=>listData(response,totalRow, countData, token, currentPage, rowLimit, sortDate, dispatch,notify))

const listData=async(res,totalData, countData, token, currentPage, rowLimit, sortDate, dispatch,notify) =>{
    await dispatch(mappingActions.setCountData(isContent(res)))
    await dispatch(dashboardAction.setTotalRow(isContent(res)));
    if (totalData === countData) {
      await postProductPrincipalList(token, currentPage, rowLimit, sortDate, dispatch,notify,
        response =>dispatch(mappingActions.setList(isContent(response))))
    } else {
      setDefaultPagination(dispatch)
      await postProductPrincipalList(token, 1, 10, sortDate, dispatch,notify,
        response => reloadList(response,dispatch,countData))
    }
    dispatch(utilsActions.setLoading(false));
  }
export const fetchProductPrincipalMappingForm = 
  async ( token, dispatch, uniqueKey, NewId, ExistId, list, notify, navigate)=> {
    const newData = await getDetailProductPrincipal(token, NewId, notify,dispatch,
      res=>isContent(res),err=>isContentErr(err))
    const existData = await getDetailExistingProductPrincipal(token, ExistId, notify,dispatch,
      res=>isContent(res),err=>isContentErr(err))

      function transformKeys(obj) {
        const transformedObj = {};
        
        for (const [key, value] of Object.entries(obj)) {
          const newKey = key.startsWith("name") ? key.replace("name", "product_name") : key;
          transformedObj[newKey] = value;
        }
        
        return transformedObj;
      }
    await setMappingForm ( productFieldPrincipalMapping, list, uniqueKey, transformKeys(newData), transformKeys(existData), dispatch)
    navigate()
}

export async function fetchDataApprovalProductPrincipal(
  token,
  data,
  mappingList,
  mappingCount,
  notify,
  dispatch
) {
  
  let submit=()=>{
    let submitData=data
    if(data.embalace_status==="N" ||data.embalace_status===""||data.embalace_status===null){
     let  relatedEffect={code_crate:null, code_bottle:null}
      submitData={...data,...relatedEffect}

    }
    return submitData
    
  }
  
  return postApproveMappingProductPrincipal(
    token,
    submit(),
    notify,
    dispatch,
    res => approvalAction(res, mappingList, mappingCount, dispatch),
    err => approvalActionErr(err, dispatch)
  );
}
export async function fetchSearchProductPrincipalList(token, data, column, dispatch, notify) {
  await postSearchProductPrincipalList(token, data, dispatch, notify,
    res=>setMappingSearch(res,column,dispatch),
    err=>setMappingSearchErr(err, dispatch))
}
export const fetchDataProductPrincipalSearchList = async (
  token,
  currentPage,
  rowLimit,
  filter,
  column,
  dispatch,
  notify
) => {
  postProductPrincipalSearchCountData(token, filter, notify, dispatch, response =>
    listSearchData(
      response,
      token,
      currentPage,
      rowLimit,
      filter,
      column,
      dispatch,
      notify
    )
  );
};
const listSearchData = async (
  res,
  token,
  currentPage,
  rowLimit,
  filter,
  column,
  dispatch,
  notify
) => {
  let data = await {...{page: currentPage, limit: Number(rowLimit)}, ...filter};
  await postSearchProductPrincipalList(
    token,
    data,
    dispatch,
    notify,
    reponse => setMappingSearch(reponse, column, isContent(res), dispatch),
    err => setMappingSearchErr(err, isContent(res), dispatch)
  );
  dispatch(utilsActions.setLoading(false));
};