import React, { useState } from 'react';

import moment from 'moment';
import {
  Div,
  Input,
  Paragraph,
  Switch,
} from 'nexcomponent-ui';
import { useTranslation } from 'react-i18next';

import { SIZESET } from '../../../../Enum/Props/SizeSet';
import { onChangeMapping } from '../../../../Store/Action/MappingAction';
import { fieldValid } from '../../../../Utils/Action/Field/FieldValid';
import DatePicker
  from '../../../UnstableComponent/DatePicker/DatePicker/DatePicker';
import CheckBoxMapping from '../../CheckBox/CheckBoxMapping/CheckBoxMapping';
import FieldMulti from '../FieldMulti/FieldMulti';
import FieldMultiExist from '../FieldMultiExist/FieldMultiExist';
import style from './FieldComparison.module.css';

function FieldComparison(props) {
  const {t} = useTranslation();
  const statusToggle = value => {
    let status = false;
    if (value === "Y") {
      status = true;
    }
    return status;
  };
  const statusData = value => {
    let status = "N";
    if (value) {
      status = "Y";
    }
    return status;
  };
  const [isChecked, setIsChecked] = useState(statusToggle(props.valueNew));
  const change = async e => {
    await setIsChecked(!isChecked);
    const {name, checked, type} = e.target;

    onChangeMapping(
      props.checked,
      name,
      statusData(checked),
      type,
      props.mapping,
      props.submitMapping,
      props.dispatch
    );
  };
  const validateSwitch = (type, value) => {
    let res = value;
    if (type === "switch" && value !== "Y") {
      res = "N";
    }
    if (type !== "switch" && (value === "" || value === null || value === 0)) {
      res = null;
    }
    if (value === undefined) {
      res = "";
    }
    return res;
  };
  const validation = (type, valueNew, valueExist) => {
    let newData = validateSwitch(type, valueNew);
    let existData = validateSwitch(type, valueExist);
    if (newData === existData) {
      return style.InputComparison;
    } else {
      return style.InputComparisonDiff;
    }
  };

  const handleInput = e => {
    const {name, value, type} = e.target;
    const valueInput = () => {
      if (props.typeNew === "float") {
        if (value.length > 2) {
          return Number(value);
        } else {
          return value;
        }
      } else {
        return value;
      }
    };
    props.onChange(e);
    onChangeMapping(
      props.checked,
      name,
      valueInput(),
      type,
      props.mapping,
      props.submitMapping,
      props.dispatch,
      props.negatifValue
    );
  };
  const multiChange = e => {
    onChangeMapping(
      props.checked,
      e.target.name,
      e.target.value,
      "text",
      props.mapping,
      props.submitMapping,
      props.dispatch
    );
  };

  const ignoreKey=()=>props.negatifValue===true?["e", "E", "+"]:["e", "E", "+", "-"]
  const onlyNumber = evt => ignoreKey().includes(evt.key) && evt.preventDefault();
  const formatValid = () =>
    props.pattern !== undefined ? fieldValid(props.valueNew, props.pattern) : null;
  const messageValid = customStyle => {
    if (props.required && (props.valueNew === "" || props.valueNew === null)) {
      return (
        <Paragraph
          className={`${style.Message} ${customStyle}`}
          value={t("COMMON:FIELD.CANNOT_BE_EMPTY")}
        />
      );
    } else if (formatValid() === false) {
      return <Paragraph className={style.Message} value={props.message} />;
    } else {
      return <></>;
    }
  };

  return (
    <Div className={validation(props.typeNew, props.valueNew, props.valueExist)}>
      <Div className={style.CheckBox}>
        <CheckBoxMapping
          size={SIZESET.SM}
          label={props.label}
          name={props.nameNew}
          checked={props.checked}
          onCheck={props.onCheck}
          disabled={props.disabledCheck}
          required={props.required}
        />
      </Div>
      <Div className={style.InputArea}>
        <Div className={style.NewData}>
          {props.typeNew === "date" ? (
            <>
              <DatePicker
                value={moment(props.valueNew).format("yyyy-MM-DD")}
                format={"DD MMMM YYYY"}
                classInput={style.InputComparisonDate}
                name={props.nameNew}
                onChange={e => handleInput(e)}
                placeholder={props.placeholder}
                message={messageValid()}
                disabled={props.disabled}
              />
            </>
          ) : props.typeNew === "switch" ? (
            <Switch
              id={"tgl" + props.nameNew}
              name={props.nameNew}
              deactive=""
              active=""
              className={style.Switch}
              checked={isChecked}
              onChange={change}
            />
          ) : props.typeNew === "multiField" ? (
            <FieldMulti
              placeholderKey={props.placeholderKey}
              placeholderValue={props.placeholderValue}
              name={props.nameNew}
              value={props.valueNew}
              dispatch={props.dispatch}
              onChange={value => multiChange(value)}
            />
          ) : (
            <Div className={style.Input}>
              <Input
                type={props.typeNew}
                name={props.nameNew}
                placeholder={props.placeholder}
                className={style.InputComparisonInput}
                valid={formatValid()}
                value={props.valueNew}
                onChange={handleInput}
                onPaste={handleInput}
                onKeyDown={props.typeNew === "number" ? onlyNumber : () => {}}
                disabled={props.disabled}
                maxLength={props.maxLength}
                required={props.required &&props.checked ?props.checked:props.required}
              />
              {messageValid()}
            </Div>
          )}
        </Div>
        <Div className={style.Existing}>
          {props.typeNew === "multiField" ? (
            <FieldMultiExist
              value={props.valueExist}
              valueNew={props.valueNew}
              dispatch={props.dispatch}
            />
          ) : (
            <Input
              type={props.typeExist}
              name={props.nameExist}
              className={style.InputComparisonInput}
              disabled={true}
              value={props.valueExist}
            />
          )}
        </Div>
      </Div>
    </Div>
  );
}

export default FieldComparison;
FieldComparison.defaultProps = {
  onChange: () => {},
};
