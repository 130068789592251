import React from "react";
import MappingTypeActionButton from "../../Button/MappingTypeActionButton/MappingTypeActionButton";

import MappingList from "../MappingList/MappingList";
const MappingTabContent = (props) => {
  return (
    <>
      <MappingTypeActionButton
        notify={props.notify}
        t={props.t}
        dispatch={props.dispatch}
        navigate={props.navigate}
        mappingCode={props.mappingCode}
        uniqueKey={props.uniqueKey}
        principal={props.principal}
        rejectNavigate={props.rejectNavigate}
        mappingNavigate={props.mappingNavigate}
      />
      <MappingList
        t={props.t}
        load={props.load}
        column={props.column}
        mappingCode={props.mappingCode}
        validToken={props.validToken}
        dispatch={props.dispatch}
        navigate={props.navigate}
        notify={props.notify}
        listFunc={props.listFunc}
      />
    </>
  );
};

export default MappingTabContent;
