import { ROUTER_CONSTANT } from '../RouterConstant/RouterConstant';

export const productCategoryBreadCrumb = t => [
  {value: t("COMMON:DATA_PROCESS"), to: ROUTER_CONSTANT.PRODUCT_CATEGORY.LIST},
  {value: t("COMMON:MAPPINGS"), to: ROUTER_CONSTANT.PRODUCT_CATEGORY.LIST},
  {value: t("COMMON:FIELD.PRODUCT_CATEGORY"), to: ROUTER_CONSTANT.PRODUCT_CATEGORY.LIST},
];
export const productCategorySearchBreadCrumb =t =>[
  {value: t("COMMON:DATA_PROCESS"), to: "/"},
  {value: t("COMMON:MAPPINGS"), to: "/"},
  {value: t("COMMON:FIELD.PRODUCT_CATEGORY"), to: ROUTER_CONSTANT.PRODUCT_CATEGORY.LIST},
  {value: `${t("COMMON:MAPPINGS")} ${t("COMMON:FIELD.PRODUCT_CATEGORY")}`, to: ROUTER_CONSTANT.PRODUCT_CATEGORY.MAPPING},
];
