import React from 'react';

import {
  Div,
  Paragraph,
  Spinner,
} from 'nexcomponent-ui';

import style from './SpinnerLoad.module.css';

function SpinnerLoad(props) {
  return (
    <Div className={style.Spinner}>
      <Spinner borderColor="#c96cb8" spinColor="#9b3789" />
      <Paragraph value={props.t("COMMON:LOADING")} />
    </Div>
  )
}

export default SpinnerLoad
