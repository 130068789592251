import { ROUTER_CONSTANT } from '../RouterConstant/RouterConstant';

export const personProfileBreadCrumb = t => [
  {value: t("COMMON:DATA_PROCESS"), to: ROUTER_CONSTANT.PERSON_PROFILE.LIST},
  {value: t("COMMON:MAPPINGS"), to: ROUTER_CONSTANT.PERSON_PROFILE.LIST},
  {value: t("COMMON:FIELD.PERSON_PROFILE"), to: ROUTER_CONSTANT.PERSON_PROFILE.LIST},
]
export const personProfileSearchBreadCrumb = t => [
  { value: t("COMMON:DATA_PROCESS"), to: "/" },
  { value: t("COMMON:MAPPINGS"), to: "/" },
  { value: t("COMMON:FIELD.PERSON_PROFILE"), to: ROUTER_CONSTANT.PERSON_PROFILE.LIST },
  { value: `${t("COMMON:MAPPINGS")} ${t("COMMON:FIELD.PERSON_PROFILE")}`, to: ROUTER_CONSTANT.PERSON_PROFILE.MAPPING },
];
