export const productAdvanceSearch = t => [
  {title: t("COMMON:FIELD.NAME_2"), code: "product_name_2", type: "text"},
  {title: t("COMMON:FIELD.PACKAGING"), code: "packaging", type: "text"},
  {title: t("COMMON:FIELD.PACKAGING_2"), code: "packaging_2", type: "text"},
  {title: t("COMMON:FIELD.DESCRIPTION"), code: "description", type: "text"},
  {title: t("COMMON:FIELD.PRODUCT_BRAND_CODE"), code: "product_brand_code", type: "text"},
  {title: t("COMMON:FIELD.PRODUCT_BRAND_NAME"), code: "product_brand_name", type: "text"},
  {title: t("COMMON:FIELD.PRODUCT_BRAND_ID"), code: "product_brand_id", type: "number"},
  {title: t("COMMON:FIELD.PRODUCT_CATEGORY_CODE"), code: "product_category_code", type: "text"},
  {title: t("COMMON:FIELD.PRODUCT_CATEGORY_NAME"), code: "product_category_name", type: "text"},
  {title: t("COMMON:FIELD.PRODUCT_CATEGORY_ID"), code: "product_category_id", type: "number"},
  {title: t("COMMON:FIELD.PRODUCT_GROUP_CODE"), code: "product_group_hierarchy_code", type: "text"},
  {title: t("COMMON:FIELD.PRODUCT_GROUP_NAME"), code: "product_group_hierarchy_name", type: "text"},
  {title: t("COMMON:FIELD.PRODUCT_GROUP_ID"), code: "product_group_hierarchy_id", type: "number"},
  {title: t("COMMON:FIELD.UOM_1"), code: "uom_1", type: "text"},
  {title: t("COMMON:FIELD.UOM_2"), code: "uom_2", type: "text"},
  {title: t("COMMON:FIELD.UOM_3"), code: "uom_3", type: "text"},
  {title: t("COMMON:FIELD.UOM_4"), code: "uom_4", type: "text"},
  {title: t("COMMON:FIELD.CONVERSION_1_TO_4"), code: "conversion_1_to_4", type: "number"},
  {title: t("COMMON:FIELD.CONVERSION_2_TO_4"), code: "conversion_2_to_4", type: "number"},
  {title: t("COMMON:FIELD.CONVERSION_3_TO_4"), code: "conversion_3_to_4", type: "number"},
  {title: t("COMMON:FIELD.CODE_BOTTLE"), code: "code_bottle", type: "text"},
  {title: t("COMMON:FIELD.CODE_CRATE"), code: "code_crate", type: "text"},
  {title: t("COMMON:FIELD.MARKET_LAUNCH_DATE"), code: "market_launch_date", type: "date"},
  {title: t("COMMON:FIELD.WEIGHT_PER_UNIT"), code: "weight_per_unit", type: "text"},
  {title: t("COMMON:FIELD.DRY_WEIGHT_PER_UNIT"), code: "dry_weight_per_unit", type: "text"},
  {title: t("COMMON:FIELD.NET_WEIGHT_PER_CASE"), code: "net_weight_per_cases", type: "text"},
  {title: t("COMMON:FIELD.VOLUME_PER_UNIT"), code: "volume_per_unit", type: "text"},
  {title: t("COMMON:FIELD.CASE_WIDTH"), code: "case_width", type: "text"},
  {title: t("COMMON:FIELD.CASE_HEIGHT"), code: "case_height", type: "text"},
  {title: t("COMMON:FIELD.CASE_DEPTH"), code: "case_depth", type: "text"},
  {title: t("COMMON:FIELD.CASE_WEIGHT"), code: "case_weight", type: "text"},
  {title: t("COMMON:FIELD.ID_FARMALKES"), code: "farmalkes_id", type: "text"},
  {title: t("COMMON:FIELD.FARMALKES_DESC"), code: "farmalkes_desc", type: "text"},
  {title: t("COMMON:FIELD.NIE_NUMBER"), code: "nie_number", type: "text"},
  {title: t("COMMON:FIELD.NIE_EXPIRED_DATE"), code: "nie_expired_date", type: "date"},
  {
    title: t("COMMON:FIELD.PRODUCT_KEY_ACCOUNT_CODE"),
    code: "product_key_account_code",
    type: "text",
  },
  {
    title: t("COMMON:FIELD.PRODUCT_KEY_ACCOUNT_NAME"),
    code: "product_key_account_name",
    type: "text",
  },
  {title: t("COMMON:FIELD.PRODUCT_KEY_ACCOUNT_ID"), code: "product_key_account_id", type: "number"},
  {title: t("COMMON:FIELD.BARCODE_LIST_FOR_UOM_1"), code: "barcode_list_for_uom_1", type: "text"},
  {title: t("COMMON:FIELD.BARCODE_LIST_FOR_UOM_2"), code: "barcode_list_for_uom_2", type: "text"},
  {title: t("COMMON:FIELD.BARCODE_LIST_FOR_UOM_3"), code: "barcode_list_for_uom_3", type: "text"},
  {title: t("COMMON:FIELD.BARCODE_LIST_FOR_UOM_4"), code: "barcode_list_for_uom_4", type: "text"},
  {title: t("COMMON:FIELD.FREE_GOOD_STATUS"), code: "free_good_status", type: "text"},
  {title: t("COMMON:FIELD.EMBALACE_STATUS"), code: "embalace_status", type: "text"},
  {title: t("COMMON:FIELD.TAX_1_APPLIED_STATUS"), code: "tax_1_applied_status", type: "text"},
  {title: t("COMMON:FIELD.TAX_2_APPLIED_STATUS"), code: "tax_2_applied_status", type: "text"},
  {title: t("COMMON:FIELD.TAX_3_APPLIED_STATUS"), code: "tax_3_applied_status", type: "text"},
  {title: t("COMMON:FIELD.TAX_SUBSIDIZED_STATUS"), code: "tax_subsidized_status", type: "text"},
  {title: t("COMMON:FIELD.CLASS"), code: "class", type: "text"},
  {title: t("COMMON:FIELD.REPORT_COLUMN_HEADER_1"), code: "report_column_header_1", type: "text"},
  {title: t("COMMON:FIELD.REPORT_COLUMN_HEADER_2"), code: "report_column_header_2", type: "text"},
  {title: t("COMMON:FIELD.SORT_SEQUENCE"), code: "sort_sequence", type: "number"},
  {title: t("COMMON:FIELD.ADDITIONAL_MARGIN"), code: "additional_margin", type: "text"},
  {
    title: t("COMMON:FIELD.NO_REGULAR_DISCOUNT_STATUS"),
    code: "no_regular_discount_status",
    type: "text",
  },
];
