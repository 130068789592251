import React from 'react';

import {
  Col,
  Row,
} from 'nexcomponent-ui';
import { useSelector } from 'react-redux';

import { searchFormData } from '../../../../../Utils/Form';
import style from '../FieldSearch/Field.module.css';
import FieldSearch from '../FieldSearch/FieldSearch';

function FieldCompanyProfileSearchForm(props) {
  const {t} = props;
  const searchData = useSelector(state => state.utils.searchData);
  const formData = e => {
    searchFormData(e, searchData, props.dispatch);
  };
  
  return (
    <Row>
      <Col className={style.Col} size="lg" width={6}>
        <FieldSearch
          className={style.InputLeft}
          label={t("COMMON:FIELD.COMPANY_PROFILE_ID")}
          type="number"
          name="company_profile_id"
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.COMPANY_PROFILE_ID")})}
          onChange={formData}
        />
        <FieldSearch
          className={style.InputLeft}
          label={t("COMMON:FIELD.COMPANY_TITLE_NAME")}
          type={"text"}
          name={"company_title_name"}
          onChange={formData}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.COMPANY_TITLE_NAME")})}
        />
        <FieldSearch
          className={style.InputLeft}
          type="number"
          name={"company_title_id"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.COMPANY_TITLE_ID")})}
          onChange={formData}
          label={t("COMMON:FIELD.COMPANY_TITLE_ID")}
        />
        <FieldSearch
          className={style.InputLeft}
          type={"text"}
          name={"npwp"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.NPWP")})}
          onChange={formData}
          label={t("COMPANY_PROFILE:FIELD.NPWP")}
        />
        <FieldSearch
          className={style.InputLeft}
          type={"text"}
          name={"phone"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PHONE")})}
          onChange={formData}
          label={t("COMMON:FIELD.PHONE")}
        />
      </Col>
      <Col className={style.Col} size="lg" width={6}>
        <FieldSearch
          className={style.InputRight}
          type={"text"}
          name={"name"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.NAME")})}
          onChange={formData}
          label={t("COMMON:FIELD.NAME")}
        />
        <FieldSearch
          className={style.InputRight}
          type={"text"}
          name={"address_1"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.ADDRESS1")})}
          onChange={formData}
          label={t("COMMON:FIELD.ADDRESS1")}
        />
        <FieldSearch
          className={style.InputRight}
          type={"text"}
          name={"address_2"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.ADDRESS2")})}
          onChange={formData}
          label={t("COMMON:FIELD.ADDRESS2")}
        />
        <FieldSearch
          className={style.InputRight}
          type="textNumber"
          name={"hamlet"}
          onChange={formData}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.HAMLET")})}
          label={t("COMMON:FIELD.HAMLET")}
        />
        <FieldSearch
          className={style.InputRight}
          type="textNumber"
          name={"neighbourhood"}
          onChange={formData}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.NEIGHBOURHOOD")})}
          label={t("COMMON:FIELD.NEIGHBOURHOOD")}
        />
      </Col>
    </Row>
  );
}

export default FieldCompanyProfileSearchForm;
