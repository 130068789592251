import {
  principalProductUserDefinedCategoryFieldMapping,
} from '../../Enum/FieldMapping/PrincipalProductUserDefinedCategoryFieldMapping';
import { dashboardAction } from '../../Store/Reducers/DashboardReducer';
import { mappingActions } from '../../Store/Reducers/MappingReducer';
import { utilsActions } from '../../Store/Reducers/UtilsReducer';
import {
  isContent,
  isContentErr,
} from '../../Utils/Response/Response';
import {
  getDetailExistingPrincipalProductUserDefinedCategory,
  getDetailPrincipalProductUserDefinedCategory,
  postApproveMappingPrincipalProductUserDefinedCategory,
  postPrincipalProductUserDefinedCategoryCountData,
  postPrincipalProductUserDefinedCategoryList,
  postPrincipalProductUserDefinedCategorySearchCountData,
  postSearchPrincipalProductUserDefinedCategoryList,
} from '../Api/PrincipalProductUserDefinedCategoryAPI';
import {
  reloadList,
  setDefaultPagination,
} from './DashboardData';
import {
  approvalAction,
  approvalActionErr,
  setMappingForm,
  setMappingSearch,
  setMappingSearchErr,
} from './MappingData';

export const fetchDataPrincipalProductUserDefinedCategoryList = (
  totalRow,
  countData,
  token,
  currentPage,
  rowLimit,
  sortDate,
  dispatch,
  notify
) =>
  postPrincipalProductUserDefinedCategoryCountData(token, notify, dispatch, response =>
    listData(
      response,
      totalRow,
      countData,
      token,
      currentPage,
      rowLimit,
      sortDate,
      dispatch,
      notify
    )
  );

const listData = async (
  res,
  totalData,
  countData,
  token,
  currentPage,
  rowLimit,
  sortDate,
  dispatch,
  notify
) => {
  await dispatch(mappingActions.setCountData(isContent(res)));
  await dispatch(dashboardAction.setTotalRow(isContent(res)));

  if (totalData === countData) {
    postPrincipalProductUserDefinedCategoryList(
      token,
      currentPage,
      rowLimit,
      sortDate,
      dispatch,
      notify,
      response => dispatch(mappingActions.setList(isContent(response)))
    );
  } else {
    setDefaultPagination(dispatch);
    await postPrincipalProductUserDefinedCategoryList(
      token,
      1,
      10,
      sortDate,
      dispatch,
      notify,
      response => reloadList(response, dispatch, countData)
    );
  }
  dispatch(utilsActions.setLoading(false));
};

export const fetchPrincipalProductUserDefinedCategoryMappingForm = async (
  token,
  dispatch,
  uniqueKey,
  NewId,
  ExistId,
  list,
  notify,
  navigate
) => {
  const newData = await getDetailPrincipalProductUserDefinedCategory(
    token,
    NewId,
    notify,
    dispatch,
    res => isContent(res),
    err => isContentErr(err)
  );
  const existData = await getDetailExistingPrincipalProductUserDefinedCategory(
    token,
    ExistId,
    notify,
    dispatch,
    res => isContent(res),
    err => isContentErr(err)
  );
  await setMappingForm(
    principalProductUserDefinedCategoryFieldMapping,
    list,
    uniqueKey,
    newData,
    existData,
    dispatch
  );
  navigate();
};

export async function fetchDataApprovalPrincipalProductUserDefinedCategory(
  token,
  data,
  mappingList,
  mappingCount,
  notify,
  dispatch
) {
  return postApproveMappingPrincipalProductUserDefinedCategory(
    token,
    data,
    notify,
    dispatch,
    res => approvalAction(res, mappingList, mappingCount, dispatch),
    err => approvalActionErr(err, dispatch)
  );
}
export async function fetchSearchPrincipalProductUserDefinedCategoryList(token, data, column, dispatch, notify) {
  await postSearchPrincipalProductUserDefinedCategoryList(token, data, dispatch, notify,
    res=>setMappingSearch(res,column,dispatch),
    err=>setMappingSearchErr(err, dispatch))
}
export const fetchDataPrincipalProductUserDefinedCategorySearchList= async (

  token,
  currentPage,
  rowLimit,
  filter,
  column,
  dispatch,
  notify
) => {
  postPrincipalProductUserDefinedCategorySearchCountData(token, filter, notify, dispatch, response =>
    listSearchData(
      response,
      token,
      currentPage,
      rowLimit,
      filter,
      column,
      dispatch,
      notify
    )
  );
};
const listSearchData = async (
  res,
  token,
  currentPage,
  rowLimit,
  filter,
  column,
  dispatch,
  notify
) => {
  let data = await {...{page: currentPage, limit: Number(rowLimit)}, ...filter};
   postSearchPrincipalProductUserDefinedCategoryList(
    token,
    data,
    dispatch,
    notify,
    reponse => setMappingSearch(reponse, column, isContent(res), dispatch),
    err => setMappingSearchErr(err, isContent(res), dispatch)
  );
  dispatch(utilsActions.setLoading(false));
};