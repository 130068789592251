import React from 'react';

import { Div } from 'nexcomponent-ui';

import { mainBreadCrumb } from '../../../../Enum/Breadcrumb/CommonBreadcrumb';
import {
  companyProfileColumn,
} from '../../../../Enum/Column/CompanyProfileColumn';
import { PAGE } from '../../../../Enum/IdSet';
import { MAPPING_CODE } from '../../../../Enum/MappingCode/MappingCode';
import {
  ROUTER_CONSTANT,
} from '../../../../Enum/RouterConstant/RouterConstant';
import {
  fetchCompanyProfileMappingForm,
  fetchDataCompanyProfileList,
} from '../../../../Fetch/FetchData/CompanyProfileData';
import HeaderPageList
  from '../../../Organisms/Header/HeaderPageList/HeaderPageList';
import MappingList from '../../../Organisms/TableList/MappingList/MappingList';

const CompanyProfilePage = props => (
  <Div id={PAGE.LIST}>
    <HeaderPageList
      notify={props.notify}
      t={props.t}
      dispatch={props.dispatch}
      navigate={props.navigate}
      toTitlePage={ROUTER_CONSTANT.INDEX}
      listBreadcrumb={mainBreadCrumb(props.t)}
      title={props.t("COMPANY_PROFILE:TITLE")}
      mappingCode={MAPPING_CODE.COMPANY_PROFILE}
      defaultBreadcrumb={props.t("COMPANY_PROFILE:TITLE")}
      rejectNavigate={ROUTER_CONSTANT.COMPANY_PROFILE.REJECT_REASON}
      uniqueKey="company_profile_id"
      mappingNavigate={(NewId, ExistId, List) =>
        fetchCompanyProfileMappingForm(
          props.validToken(),
          props.dispatch,
          "company_profile_id",
          NewId,
          ExistId,
          List,
          props.notify,
          () => props.navigate(ROUTER_CONSTANT.COMPANY_PROFILE.MAPPING)
        )
      }
    />
    <MappingList
      t={props.t}
      column={companyProfileColumn(props.t)}
      mappingCode={MAPPING_CODE.COMPANY_PROFILE}
      validToken={props.validToken}
      dispatch={props.dispatch}
      navigate={props.navigate}
      notify={props.notify}
      listFunc={(totalRow, countData, token, currentPage, rowLimit, sortDate, dispatch, notify) =>
        fetchDataCompanyProfileList(
          totalRow,
          countData,
          token,
          currentPage,
          rowLimit,
          sortDate,
          dispatch,
          notify
        )
      }
    />
  </Div>
);

export default CompanyProfilePage;
