import {
  isStatus,
  isStatusErr,
} from '../../Utils/Response/Response';
import { PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY_URL } from '../EndPoint';
import * as BaseAPI from './BaseAPIs';

export const postPrincipalProductUserDefinedCategoryCountData = (token, notify, dispatch, action) =>
  BaseAPI.doPost(
    PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY_URL.COUNT,
    token,
    null,
    notify,
    dispatch,
    res => action(res)
  );

export const postPrincipalProductUserDefinedCategoryList = (
  token,
  page,
  limit,
  sortDate,
  notify,
  dispatch,
  action
) =>
  BaseAPI.doPost(
    PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY_URL.LIST(page, limit, sortDate),
    token,
    null,
    notify,
    dispatch,
    res => action(res)
  );

export const postRejectPrincipalProductUserDefinedCategoryList = (token, data, notify, dispatch) =>
  BaseAPI.doPost(
    PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY_URL.REJECT,
    token,
    data,
    notify,
    dispatch,
    res => isStatus(res),
    err => isStatusErr(err)
  );

export const getDetailPrincipalProductUserDefinedCategory = (
  token,
  id,
  notify,
  dispatch,
  action,
  errAction
) =>
  BaseAPI.doGet(
    PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY_URL.DETAIL + id,
    token,
    null,
    null,
    notify,
    dispatch,
    res => action(res),
    err => errAction(err)
  );

export const getDetailExistingPrincipalProductUserDefinedCategory = (
  token,
  id,
  notify,
  dispatch,
  action,
  errAction
) =>
  BaseAPI.doGet(
    PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY_URL.DETAIL_EXISTING + id,
    token,
    null,
    null,
    notify,
    dispatch,
    res => action(res),
    err => errAction(err)
  );

export const postSearchPrincipalProductUserDefinedCategoryList = (
  token,
  data,
  dispatch,
  notify,
  action,
  errAction
) => {
  return BaseAPI.doPost(
    PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY_URL.SEARCH,
    token,
    data,
    notify,
    dispatch,
    res => action(res),
    err => errAction(err)
  );
};
export const postApproveMappingPrincipalProductUserDefinedCategory = (
  token,
  data,
  notify,
  dispatch,
  action,
  errAction
) => {
  return BaseAPI.doPost(
    PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY_URL.MAPPING_APPROVE,
    token,
    data,
    notify,
    dispatch,
    res => action(res),
    err => errAction(err)
  );
};
export const postPrincipalProductUserDefinedCategorySearchCountData = (
  token,
  data,
  notify,
  dispatch,
  action
) =>
  BaseAPI.doPost(
    PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY_URL.COUNT_SEARCH,
    token,
    data,
    notify,
    dispatch,
    res => action(res)
  );
