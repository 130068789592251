import { itemDetailValue } from '../../Utils/Value';

export const detailBroadcastProductCategory = (t, data) => [
  {id: "tslcode", title: t("PRODUCT_CATEGORY:FIELD.CODE"), value: itemDetailValue(data.code)},
  {id: "tslname", title: t("PRODUCT_CATEGORY:FIELD.NAME"), value: itemDetailValue(data.name)},
  {id: "tslid", title: t("PRODUCT_CATEGORY:FIELD.ID"), value: itemDetailValue(data.id)},
  {
    id: "tslprincipalcode",
    title: t("PRODUCT_CATEGORY:FIELD.PRINCIPAL_CODE"),
    value: itemDetailValue(data.principal_code),
  },
  {
    id: "tslprincipalname",
    title: t("PRODUCT_CATEGORY:FIELD.PRINCIPAL_NAME"),
    value: itemDetailValue(data.principal_name),
  },
  {
    id: "tslprincipalid",
    title: t("PRODUCT_CATEGORY:FIELD.PRINCIPAL_ID"),
    value: itemDetailValue(data.principal_id),
  },
  {
    id: "tslresource",
    title: t("PRODUCT_CATEGORY:FIELD.RESOURCE"),
    value: itemDetailValue(data.resource),
  },
];
