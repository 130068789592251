import React, { useState } from 'react';

import _ from 'lodash';
import { useNotification } from 'nexcomponent-ui';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { mappingType } from '../../../../Enum/DataTypeContant';
import { BOX } from '../../../../Enum/IdSet';
import { closeModal } from '../../../../Store/Action/ModalAction';
import { utilsActions } from '../../../../Store/Reducers/UtilsReducer';
import Modal from '../Modal/Modal';
import ResultDeleteModal from '../ResultDeleteModal/ResultDeleteModal';

function ModalDelete(props) {
  const location = useLocation();

  const deleteData = useSelector(state => state.utils.deleteData);
  const notify = useNotification();
  const modal = useSelector(state => state.utils.modal);
  const [msg, setMsg] = useState(null);
  const result = useSelector(state => state.utils.resultModal);
  
  const handleCancel = () => {
    closeModal(props.dispatch);
  }

  const navigate = () => {
    window.location.reload()
  };

  const doDelete = async () => {
    let message = await props.deleteFunc(deleteData, notify, props.dispatch);
    setMsg(message);
    await closeModal(props.dispatch);
    props.dispatch(utilsActions.setResultModal(true));
  };

  const handleResultCancel = () => {
    props.dispatch(utilsActions.setResultModal(false));
    if (msg.success) {
      if (_.has(location.state, "previous")) {
       navigate()
      } else {
        props.navigate(mappingType(props.mappingCode));
      }
    }
  };
  return (
    <>
      <ResultDeleteModal
        success={msg === null ? null : msg.success}
        onClose={handleResultCancel}
        show={result}
        t={props.t}
        labelYes={props.t("COMMON:YES_SURE")}
        sucessBtnLabel={
          props.t("COMMON:BACK")
        }
        navigate={navigate}
      />
      <Modal
        id={BOX.REJECT_MODAL}
        idFooter={BOX.REJECT_MODAL_FOOTER}
        title={props.t("COMMON:DELETE_DATA")}
        content={props.t("COMMON:DELETE_NOTIFICATION_MESSAGE")}
        onClose={handleCancel}
        show={modal}
        t={props.t}
        yesAction={doDelete}
        labelYes={props.t("COMMON:YES_DELETE")}
      />
    </>
  );
}

export default ModalDelete;
