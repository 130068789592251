export const TYPESET = {
  TXT: "text",
  NUM: "number",
  DATE: "date",
  SUBMIT: "submit",
  PASS: "password",
  BTN: "button",
  CKB: "checkbox",
  CLR: "color",
  DATE_TIME: "datetime-local",
  EMAL: "email",
  FILE: "file",
  HIDDEN: "hidden",
  IMG: "image",
  MONTH: "month",
  RDO: "radio",
  RANGE: "range",
  RST: "reset",
  SEARCH: "search",
  TEL: "tel",
  TIME: "time",
  URL: "url",
  WEEK: "week",
};
