import {
  isStatus,
  isStatusErr,
} from '../../Utils/Response/Response';
import { PRODUCT_BRAND_URL } from '../EndPoint';
import * as BaseAPI from './BaseAPIs';

export const postProductBrandCountData = (token, notify, dispatch, action) =>
  BaseAPI.doPost(PRODUCT_BRAND_URL.COUNT, token, null, notify, dispatch, res => action(res));


export const postProductBrandList =  (token, page, limit, sortDate,notify,dispatch,action) =>
  BaseAPI.doPost(PRODUCT_BRAND_URL.LIST(page, limit, sortDate), token, null,notify,dispatch,(res)=>action(res));

export const postRejectProductBrandList =(token, data,notify,dispatch) => 
  BaseAPI.doPost(PRODUCT_BRAND_URL.REJECT, token, data,notify,dispatch,
    res=>isStatus(res),err=>isStatusErr(err))

export const getDetailProductBrand = (token, id,notify,dispatch,action,errAction) => 
  BaseAPI.doGet(PRODUCT_BRAND_URL.DETAIL + id, token, null,null,notify,dispatch,
  res=>action(res),err=>errAction(err));

export const getDetailExistingProductBrand = (token, id,notify,dispatch,action,errAction) => 
  BaseAPI.doGet(PRODUCT_BRAND_URL.DETAIL_EXISTING + id, token, null,null,notify,dispatch,
  res=>action(res),err=>errAction(err));

export const postSearchProductBrandList = (token, data,dispatch, notify,action,errAction) => {
  return BaseAPI.doPost(PRODUCT_BRAND_URL.SEARCH,   token, data ,notify,dispatch,
    (res)=>action(res),err=>errAction(err));
};
export const postApproveMappingProductBrand = (token, data,notify,dispatch,action,errAction) => {
  return BaseAPI.doPost(PRODUCT_BRAND_URL.MAPPING_APPROVE, token, data,notify,dispatch,
      (res)=>action(res),err=>errAction(err));
};
export const postProductBrandSearchCountData = (token,data,notify,dispatch,action) =>  
  BaseAPI.doPost(PRODUCT_BRAND_URL.COUNT_SEARCH, token, data,notify,dispatch,(res)=>action(res));

export const postProductBrandCountPrincipalData = (token, notify, dispatch, action) =>
  BaseAPI.doPost(PRODUCT_BRAND_URL.PRINCIPAL.COUNT, token, null, notify, dispatch, res => action(res));


export const postProductBrandPrinciaplList =  (token, page, limit, sortDate,notify,dispatch,action) =>
  BaseAPI.doPost(PRODUCT_BRAND_URL.PRINCIPAL.LIST(page, limit, sortDate), token, null,notify,dispatch,(res)=>action(res));

export const postRejectProductBrandPrincipalList =(token, data,notify,dispatch) => 
  BaseAPI.doPost(PRODUCT_BRAND_URL.PRINCIPAL.REJECT, token, data,notify,dispatch,
    res=>isStatus(res),err=>isStatusErr(err))

export const getDetailProductBrandPrincipal = (token, id,notify,dispatch,action,errAction) => 
  BaseAPI.doGet(PRODUCT_BRAND_URL.PRINCIPAL.DETAIL + id, token, null,null,notify,dispatch,
  res=>action(res),err=>errAction(err));

export const getDetailExistingProductBrandPrincipal = (token, id,notify,dispatch,action,errAction) => 
  BaseAPI.doGet(PRODUCT_BRAND_URL.PRINCIPAL.DETAIL_EXISTING + id, token, null,null,notify,dispatch,
  res=>action(res),err=>errAction(err));

export const postSearchProductBrandPrincipalList = (token, data,dispatch, notify,action,errAction) => {
  return BaseAPI.doPost(PRODUCT_BRAND_URL.PRINCIPAL.SEARCH,   token, data ,notify,dispatch,
    (res)=>action(res),err=>errAction(err));
};
export const postApproveMappingProductBrandPrincipal = (token, data,notify,dispatch,action,errAction) => {
  return BaseAPI.doPost(PRODUCT_BRAND_URL.PRINCIPAL.MAPPING_APPROVE, token, data,notify,dispatch,
      (res)=>action(res),err=>errAction(err));
};
export const postProductBrandSearchCountPrincipalData = (token,data,notify,dispatch,action) =>  
  BaseAPI.doPost(PRODUCT_BRAND_URL.PRINCIPAL.COUNT_SEARCH, token, data,notify,dispatch,(res)=>action(res));