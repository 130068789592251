import { divisionFieldMapping } from '../../Enum/FieldMapping/DivisionMapping';
import {
  fieldValidationDivision,
} from '../../Enum/FieldValidation/FieldValidationDivision';
import { dashboardAction } from '../../Store/Reducers/DashboardReducer';
import { mappingActions } from '../../Store/Reducers/MappingReducer';
import { utilsActions } from '../../Store/Reducers/UtilsReducer';
import {
  isContent,
  isContentErr,
} from '../../Utils/Response/Response';
import {
  getDetailDivision,
  getDetailExistingDivision,
  postApproveMappingDivision,
  postDivisionCountData,
  postDivisionList,
  postDivisionSearchCountData,
  postSearchDivisionList,
} from '../Api/DivisionAPI';
import {
  reloadList,
  setDefaultPagination,
} from './DashboardData';
import {
  approvalAction,
  approvalActionErr,
  setMappingForm,
  setMappingSearch,
  setMappingSearchErr,
} from './MappingData';

export const fetchDataDivisionList = (
  totalRow,
  countData,
  token,
  currentPage,
  rowLimit,
  sortDate,
  dispatch,
  notify
) =>
  postDivisionCountData(token, notify, dispatch, response =>
    listData(
      response,
      totalRow,
      countData,
      token,
      currentPage,
      rowLimit,
      sortDate,
      dispatch,
      notify
    )
  );

const listData = async (
  res,
  totalData,
  countData,
  token,
  currentPage,
  rowLimit,
  sortDate,
  dispatch,
  notify
) => {
  await dispatch(mappingActions.setCountData(isContent(res)));
  await dispatch(dashboardAction.setTotalRow(isContent(res)));

  if (totalData === countData) {
    postDivisionList(token, currentPage, rowLimit, sortDate, dispatch, notify, response =>
      dispatch(mappingActions.setList(isContent(response)))
    );
  } else {
    setDefaultPagination(dispatch);
    await postDivisionList(token, 1, 10, sortDate, dispatch, notify, response =>
      reloadList(response, dispatch, countData)
    );
  }
  dispatch(utilsActions.setLoading(false));
};
export const fetchDivisionMappingForm = async (
  token,
  dispatch,
  uniqueKey,
  NewId,
  ExistId,
  list,
  notify,
  navigate
) => {
  const newData = await getDetailDivision(
    token,
    NewId,
    notify,
    dispatch,
    res => isContent(res),
    err => isContentErr(err)
  );
  const existData = await getDetailExistingDivision(
    token,
    ExistId,
    notify,
    dispatch,
    res => isContent(res),
    err => isContentErr(err)
  );

  await setMappingForm(divisionFieldMapping, list, uniqueKey, newData, existData, dispatch,fieldValidationDivision);
  navigate();
  
};


export async function fetchDataApprovalDivision(
  token,
  data,
  mappingList,
  mappingCount,
  notify,
  dispatch
) {
  return postApproveMappingDivision(
    token,
    data,
    notify,
    dispatch,
    res => approvalAction(res, mappingList, mappingCount, dispatch),
    err => approvalActionErr(err, dispatch)
  );
}

export async function fetchSearchDivisionList(token, data, column, dispatch, notify) {
  await postSearchDivisionList(token, data, dispatch, notify,
    res=>setMappingSearch(res,column,dispatch),
    err=>setMappingSearchErr(err, dispatch))
}

export const fetchDataDivisionSearchList = async (
  token,
  currentPage,
  rowLimit,
  filter,
  column,
  dispatch,
  notify
) => {
  postDivisionSearchCountData(token, filter, notify, dispatch, response =>
    listSearchData(
      response,
      token,
      currentPage,
      rowLimit,
      filter,
      column,
      dispatch,
      notify
    )
  );
};
const listSearchData = async (
  res,
  token,
  currentPage,
  rowLimit,
  filter,
  column,
  dispatch,
  notify
) => {
  let data = await {...{page: currentPage, limit: Number(rowLimit)}, ...filter};
   postSearchDivisionList(
    token,
    data,
    dispatch,
    notify,
    reponse => setMappingSearch(reponse, column, isContent(res), dispatch),
    err => setMappingSearchErr(err, isContent(res), dispatch)
  );
  dispatch(utilsActions.setLoading(false));
};