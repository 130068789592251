export const searchProductGroupPrincipalColumn = (t) => [
  {
    name: t("PRODUCT_GROUP:FIELD.ID"),
    component:true,
    selector: row=>row.principal_product_group_id?row.principal_product_group_id:"-",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.PRINCIPAL_CODE"),
    component:true,
    selector: row=>row.principal_code?row.principal_code:"-",
    width: "200px",
  },
  {
    name: t("COMMON:FIELD.PRINCIPAL_NAME"),
    component:true,
    selector: row=>row.principal_name?row.principal_name:"-",
    width: "200px",
  },
  {
    name: t("COMMON:FIELD.PRINCIPAL_ID"),
    component:true,
    selector: row=>row.principal_id?row.principal_id:"-",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.CODE"),
    component:true,
    selector: row=>row.code?row.code:"-",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.NAME"),
    component:true,
    selector: row=>row.name?row.name:"-",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.LEVEL"),
    component:true,
    selector: row=>row.level?row.level:"-",
    width: "100px",
  }, {
    name: t("COMMON:FIELD.PARENT_CODE"),
    component:true,
    selector: row=>row.parent_code?row.parent_code:"-",
    width: "100px",
  }, {
    name: t("COMMON:FIELD.PARENT_NAME"),
    component:true,
    selector: row=>row.parent_name?row.parent_name:"-",
    width: "100px",
  }, {
    name: t("COMMON:FIELD.PARENT_ID"),
    component:true,
    selector: row=>row.parent_id?row.parent_id:"-",
    width: "100px",
  },
];
