import {
  isStatus,
  isStatusErr,
} from '../../Utils/Response/Response';
import { DIVISION_URL } from '../EndPoint';
import * as BaseAPI from './BaseAPIs';

export const postDivisionCountData = (token, notify, dispatch, action) =>
  BaseAPI.doPost(DIVISION_URL.COUNT, token, null, notify, dispatch, res => action(res));

export const postDivisionList = (token, page, limit, sortDate,notify,dispatch,action) =>
  BaseAPI.doPost(DIVISION_URL.LIST(page, limit, sortDate), token, null,notify,dispatch,(res)=>action(res));

export const postRejectDivisionList =(token, data,notify,dispatch) => 
  BaseAPI.doPost(DIVISION_URL.REJECT, token, data,notify,dispatch,
    res=>isStatus(res),err=>isStatusErr(err))

export const getRejectReason = token => BaseAPI.doGet(DIVISION_URL.REJECT_REASON, token);

export const getDetailDivision = (token, id,notify,dispatch,action,errAction) => 
  BaseAPI.doGet(DIVISION_URL.DETAIL + id, token, null,null,notify,dispatch,
    res=>action(res),err=>errAction(err));

export const getDetailExistingDivision = (token, id,notify,dispatch,action,errAction) => 
  BaseAPI.doGet(DIVISION_URL.DETAIL_EXISTING + id, token, null,null,notify,dispatch,
    res=>action(res),err=>errAction(err));

export const postSearchDivisionList = (token, data,dispatch, notify,action,errAction) => {
  BaseAPI.doPost(DIVISION_URL.SEARCH, token, data ,notify,dispatch,
    (res)=>action(res),err=>errAction(err));
};

export const postApproveMappingDivision = (token, data,notify,dispatch,action,errAction) => {
     return BaseAPI.doPost(DIVISION_URL.MAPPING_APPROVE, token, data,notify,dispatch,
      (res)=>action(res),err=>errAction(err));
}
export const postDivisionSearchCountData = (token,data,notify,dispatch,action) =>  
  BaseAPI.doPost(DIVISION_URL.COUNT_SEARCH, token, data,notify,dispatch,(res)=>action(res));