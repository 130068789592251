import React from 'react';

import {
  Div,
  Logo,
  nexIcon,
} from 'nexcomponent-ui';

import logo from '../../../../Assets/Logos/logoNav.png';
import { BACKGROUND } from '../../../../Enum/Color/Background';
import { BOX } from '../../../../Enum/IdSet';
import { ICO } from '../../../../Enum/IdSet/IconId';
import style from './LogoLeft.module.css';

const LogoLeft = props => {
  return (
    <Div id={BOX.LOGO} className={style.Logo} >
      <Div className={style.Toggle} onClick={props.onClick}>
        <nexIcon.NcList size={25} color={BACKGROUND.PRIMARY} />
      </Div>
      <Logo id={ICO.Logo} width={"200px"} alt={ICO.Logo} title={ICO.Logo} src={logo} />
    </Div>
  );
};
export default LogoLeft;
