import _ from 'lodash';

import {
  companyProfileFieldMapping,
} from '../../Enum/FieldMapping/CompanyProfileMapping';
import { mappingActions } from '../../Store/Reducers/MappingReducer';
import { utilsActions } from '../../Store/Reducers/UtilsReducer';
import {
  isContent,
  isContentErr,
} from '../../Utils/Response/Response';
import {
  getDetailCompanyProfile,
  getDetailExistingCompanyProfile,
  postApproveMappingCompanyProfile,
  postCompanyProfileCountData,
  postCompanyProfileList,
  postCompanyProfileSearchCountData,
  postSearchCompanyProfileList,
} from '../Api/CompanyProfileAPI';
import {
  reloadList,
  setDefaultPagination,
} from './DashboardData';
import {
  approvalAction,
  approvalActionErr,
  setMappingForm,
  setMappingSearch,
  setMappingSearchErr,
} from './MappingData';
import { dashboardAction } from '../../Store/Reducers/DashboardReducer';

export const fetchDataCompanyProfileList = (
  totalRow,
  countData,
  token,
  currentPage,
  rowLimit,
  sortDate,
  dispatch,
  notify
) =>
  postCompanyProfileCountData(token, notify, dispatch, response =>
    listData(
      response,
      totalRow,
      countData,
      token,
      currentPage,
      rowLimit,
      sortDate,
      dispatch,
      notify
    )
  );

const listData = async (
  res,
  totalData,
  countData,
  token,
  currentPage,
  rowLimit,
  sortDate,
  dispatch,
  notify
) => {
  await dispatch(mappingActions.setCountData(isContent(res)));
  await dispatch(dashboardAction.setTotalRow(isContent(res)));
  if (totalData === countData) {
    postCompanyProfileList(token, currentPage, rowLimit, sortDate, dispatch, notify, response =>
      dispatch(mappingActions.setList(isContent(response)))
    );
  } else {
    setDefaultPagination(dispatch);
    await postCompanyProfileList(token, 1, 10, sortDate, dispatch, notify, response =>
      reloadList(response, dispatch, countData)
    );
  }
  dispatch(utilsActions.setLoading(false));
};

export const fetchCompanyProfileMappingForm = async (
  token,
  dispatch,
  uniqueKey,
  NewId,
  ExistId,
  list,
  notify,
  navigate
) => {
  const newData = await getDetailCompanyProfile(
    token,
    NewId,
    notify,
    dispatch,
    res => isContent(res),
    err => isContentErr(err)
  );
  const existData = await getDetailExistingCompanyProfile(
    token,
    ExistId,
    notify,
    dispatch,
    res => isContent(res),
    err => isContentErr(err)
  );
  await setMappingForm(companyProfileFieldMapping, list, uniqueKey, newData, existData, dispatch);
  navigate();
};

export async function fetchDataApprovalCompanyProfile(
  token,
  data,
  mappingList,
  mappingCount,
  notify,
  dispatch,
  message
) {
  const nameKeys = Object.keys(data);
  let submit = {};
  _.map(nameKeys, a =>
    a === "latitude" || a === "longitude" ? (submit[a] = Number(data[a])) : (submit[a] = data[a])
  );
  if (isNaN(data.latitude) || isNaN(data.longitude)) {
    dispatch(utilsActions.setResultModal(true));
    return {success: false, message: message};
  } else {
    let dataSubmit = submit;
    let checkAdditionalFormat =
      _.has(data.additional_info[0], "key") && _.has(data.additional_info[0], "value");
    if (!checkAdditionalFormat) {
      dataSubmit = {...data, ...{additional_info: null}};
    }
    return postApproveMappingCompanyProfile(
      token,
      dataSubmit,
      notify,
      dispatch,
      res => approvalAction(res, mappingList, mappingCount, dispatch),
      err => approvalActionErr(err, dispatch)
    );
  }
}

export async function fetchSearchCompanyProfileList(token, data, column, dispatch, notify) {
  await postSearchCompanyProfileList(
    token,
    data,
    dispatch,
    notify,
    res => setMappingSearch(res, column, dispatch),
    err => setMappingSearchErr(err, dispatch)
  );
}
export const fetchDataCompanyProfileSearchList = async (
  token,
  currentPage,
  rowLimit,
  filter,
  column,
  dispatch,
  notify
) => {
  postCompanyProfileSearchCountData(token, filter, notify, dispatch, response =>
    listSearchData(response, token, currentPage, rowLimit, filter, column, dispatch, notify)
  );
};
const listSearchData = async (
  res,
  token,
  currentPage,
  rowLimit,
  filter,
  column,
  dispatch,
  notify
) => {
  let data = await {...{page: currentPage, limit: Number(rowLimit)}, ...filter};

  await postSearchCompanyProfileList(
    token,
    data,
    dispatch,
    notify,
    reponse => setMappingSearch(reponse, column, isContent(res), dispatch),
    err => setMappingSearchErr(err, isContent(res), dispatch)
  );
  dispatch(utilsActions.setLoading(false));
};
