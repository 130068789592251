export const MAPPING_CODE = Object.freeze({
  PERSON_PROFILE: 12,
  COMPANY_PROFILE: 13,
  DIVISION:14,
  PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY: 15,
  PRODUCT_BRAND:16,
  PRODUCT_CATEGORY:17,
  PRODUCT_GROUP:18,
  PRODUCT_KEY_ACCOUNT:19,
  PRODUCT:20
});