import { useLocation } from "react-router-dom";

const useQueryRoute = () => {
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location?.search).entries());
  const convertedParams = {
    ...params,
    ...(location.pathname.split("/")[1] ==="principal" ? { principal: true } : {}),
  };

  return {...convertedParams,...location};
};

export default useQueryRoute;
