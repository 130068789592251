import { nexIcon } from 'nexcomponent-ui';

import { defaultProps } from '../../DefaultProps/DefaultProps';
import { subMenuTitle } from './SubMenuTitle';
import { Icon } from '../../../Assets/Icons'

export const sidebarMenuList = (submenu, subSubMenu, t, sideIconClick, language, dispatch,handleActiveLevel2) => {
  let menu = [
    {
      title: t("COMMON:HOME"),
      icon: <nexIcon.NcHome color={defaultProps.color.white} />,
      type: "home",
      tooltip: t("COMMON:HOME"),
      onClick: ()=>sideIconClick("home"),
      to: "/",
      submenu: [],
    },
    {
      title: t("COMMON:DATA_PROCESS"),
      icon: <nexIcon.NcDataProcess color={defaultProps.color.white} />,
      type: "data",
      tooltip: t("COMMON:DATA_PROCESS"),
      onClick:  ()=>sideIconClick("data"),
      to: "#",
      submenu: [
        {
          title: t("BROADCAST:TITLE"),
          icon: <nexIcon.NcBroadcast />,
          submenu: [],
          // type:"broadcast",
          handleActiveLevel2:()=>handleActiveLevel2("broadcast"),
          to: "/broadcast",
        },
        {
          title: t("COMMON:REJECT"),
          icon: <nexIcon.NcReject size={18} />,
          submenu: [],
          type:"reject",
          handleActiveLevel2:()=>handleActiveLevel2("reject"),
          to: "/reject",
        },
        {
          title: subMenuTitle(submenu, language),
          icon: <nexIcon.NcMapping />,
          submenu: subSubMenu,
          type:"mapping",
          handleActiveLevel2:()=>handleActiveLevel2("mapping"),
          to: "#",
          accordion:true
        },
      ],
    },
    {
      title: t("COMMON:SYSTEM_SETTING"),
      icon: <img src={Icon.Setup} alt="setup" style={{ color: '#ffffff' }} />,
      type: "system",
      tooltip: t("COMMON:SYSTEM_SETTING"),
      onClick: () => sideIconClick("system"),
      to: "/notification",
      submenu: [
        {
          title: t("NOTIFICATION:TITLE"),
          icon: <img src={Icon.Setup} alt="setup" />,
          submenu: [
            {
              id: 1,
              name: 'Notification List',
              url : '/notification'
            }
          ],
          type:"notification",
          handleActiveLevel2: () => handleActiveLevel2("notification"),
          to: "/notification",
          accordion:true
        },
      ],
    },
  ];
  return menu;
};
