import { ID } from './StandartId';

export const BOX = {
  LEFT: ID.BOX + "left",
  RIGHT: ID.BOX + "right",
  FORM: ID.BOX + "form",
  ROW: ID.BOX + "row",
  USERNAME: ID.BOX + "username",
  PASSWORD: ID.BOX + "password",
  CHECKBOX: ID.BOX + "checkbox",
  LOGO: ID.BOX + "logo",
  TOPBAR: ID.BOX + "top",
  SIDE: ID.BOX + "side",
  SIDEBAR: "sidebar",
  CONTENT: ID.BOX + "content",
  CONTENTBOTTOM: ID.BOX + "contentbottom",
  CARD_MAPPING: ID.BOX + "cardmapping",
  BUTTON_REJECT: ID.BOX + "boxbuttonreject",
  CUSTOM_HEADER_TABLE: ID.BOX + "customheadertable",
  COL_LEFT: ID.BOX + "columnleft",
  COL_RIGHT: ID.BOX + "columnright",
  REJECT_SECTION: ID.BOX + "rejectsection",
  REJECT_MODAL: ID.BOX + "rejectmodal",
  REJECT_MODAL_FOOTER: ID.BOX + "rejectmodalfooter",
  MAPPING_COUNTER: ID.BOX + "mappingcounter",
  BUTTON_GROUP: ID.BOX + "buttongroup",
};
