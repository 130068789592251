import { ROUTER_CONSTANT } from '../RouterConstant/RouterConstant';

export const productKeyAccountBreadCrumb = t => [
  {value: t("COMMON:DATA_PROCESS"), to: ROUTER_CONSTANT.PRODUCT_KEY_ACCOUNT.LIST},
  {value: t("COMMON:MAPPINGS"), to: ROUTER_CONSTANT.PRODUCT_KEY_ACCOUNT.LIST},
  {value: t("COMMON:FIELD.PRODUCT_KEY_ACCOUNT"), to: ROUTER_CONSTANT.PRODUCT_KEY_ACCOUNT.LIST},
];
export const productKeyAccountSearchBreadCrumb =t=> [
  {value: t("COMMON:DATA_PROCESS"), to: "/"},
  {value: t("COMMON:MAPPINGS"), to: "/"},
  {value: t("COMMON:FIELD.PRODUCT_KEY_ACCOUNT"), to: ROUTER_CONSTANT.PRODUCT_KEY_ACCOUNT.LIST},
  {value: `${t("COMMON:MAPPINGS")} ${t("COMMON:FIELD.PRODUCT_KEY_ACCOUNT")}`, to: ROUTER_CONSTANT.PRODUCT_KEY_ACCOUNT.MAPPING},
];
