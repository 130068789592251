import React from 'react';

import { Div } from 'nexcomponent-ui';

import { mainBreadCrumb } from '../../../../Enum/Breadcrumb/CommonBreadcrumb';
import {
  personProfileColumn,
} from '../../../../Enum/Column/PersonProfileColumn';
import { PAGE } from '../../../../Enum/IdSet';
import { MAPPING_CODE } from '../../../../Enum/MappingCode/MappingCode';
import {
  ROUTER_CONSTANT,
} from '../../../../Enum/RouterConstant/RouterConstant';
import {
  fetchDataPersonProfileList,
  fetchPersonProfileMappingForm,
} from '../../../../Fetch/FetchData/PersonProfileData';
import HeaderPageList
  from '../../../Organisms/Header/HeaderPageList/HeaderPageList';
import MappingList from '../../../Organisms/TableList/MappingList/MappingList';

const PersonProfilePage = props => (
  <Div id={PAGE.LIST}>
    <HeaderPageList
      notify={props.notify}
      t={props.t}
      dispatch={props.dispatch}
      navigate={props.navigate}
      listBreadcrumb={mainBreadCrumb(props.t)}
      toTitlePage={ROUTER_CONSTANT.INDEX}
      title={props.t("PERSON_PROFILE:TITLE")}
      mappingCode={MAPPING_CODE.PERSON_PROFILE}
      defaultBreadcrumb={props.t("PERSON_PROFILE:TITLE")}
      rejectNavigate={ROUTER_CONSTANT.PERSON_PROFILE.REJECT_REASON}
      uniqueKey="person_profile_id"
      mappingNavigate={(NewId, ExistId, List) =>
        fetchPersonProfileMappingForm(
          props.validToken(),
          props.dispatch,
          "person_profile_id",
          NewId,
          ExistId,
          List,
          props.notify,
          () => props.navigate(ROUTER_CONSTANT.PERSON_PROFILE.MAPPING)
        )
      }
    />
    <MappingList
      t={props.t}
      column={personProfileColumn(props.t)}
      mappingCode={MAPPING_CODE.PERSON_PROFILE}
      validToken={props.validToken}
      dispatch={props.dispatch}
      navigate={props.navigate}
      notify={props.notify}
      listFunc={(totalRow, countData, token, currentPage, rowLimit, sortDate, dispatch, notify) =>
        fetchDataPersonProfileList(
          totalRow,
          countData,
          token,
          currentPage,
          rowLimit,
          sortDate,
          dispatch,
          notify
        )
      }
    />
  </Div>
);

export default PersonProfilePage;
