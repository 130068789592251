import React, {
  useEffect,
  useState,
} from 'react';

import _ from 'lodash';
import {
  Button,
  Col,
  Div,
  Form,
  Row,
} from 'nexcomponent-ui';
import { useSelector } from 'react-redux';

import DatePickerRange
  from '../../../../../Components/UnstableComponent/DatePicker/DatePickerRange/DatePickerRange';
import SelectMulti
  from '../../../../../Components/UnstableComponent/SelectMulti/SelectMulti';
import {
  BOX,
  BUTTON,
} from '../../../../../Enum/IdSet';
import { tempMappingTitle } from '../../../../../Enum/TempEnum/Temp';
import { utilsActions } from '../../../../../Store/Reducers/UtilsReducer';
import {
  initiateTotalRowMappingList,
} from '../../../../../Store/StateGroup/DashboardStateGroup';
import {
  currentPageLoadingRowLimitSortDate,
} from '../../../../../Store/StateGroup/UtilsStateGroup';
import { valueFilter } from '../../../../../Utils/Action/Broadcast/ValueFilter';
import {
  dateFormatRange,
  dateTimeFormat,
} from '../../../../../Utils/Date/formater';
import { isContent } from '../../../../../Utils/Response/Response';
import style from './FilterForm.module.css';

const FilterForm = props => {
  const [date, setDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [reset, setReset] = useState(false);
  const [form, setForm] = useState(null);
  const [pageSearch, setPageSearch] = useState(1);
  const [adminOpt, setAdminOpt] = useState(props.admin);
  const [, totalRow] = useSelector(initiateTotalRowMappingList);
  const [mappingTitle, setMappingTitle] = useState(tempMappingTitle);
  const [search, setSearch] = useState({resource: null, admin: null, mappingTitle: null});
  const [currentPage, , rowLimit, sortDate] = useSelector(currentPageLoadingRowLimitSortDate);
  var resource = [];
  for (var i = 0; i < props.initiate.enum_data.length; i++) {
    resource.push({id: props.initiate.enum_data[i], value: props.initiate.enum_data[i]});
  }
  const resourceList = _.map(resource, data => {
    return {...data, ...{isChecked: false}};
  });
  const [resourceOpt, setResourceOpt] = useState(resourceList);

  let arr = async (list, set) => {
    let newList = await list.map(datas => {
      return {...datas, isChecked: false};
    });
    set(newList);
  };
  let arrNew = list =>
    list.map(datas => {
      return {...datas, isChecked: false};
    });
  const handleRangeDate = e => {
    if (e.target.value[0] === null && e.target.value[1] === null) {
      setDate({
        startDate: null,
        endDate: null,
      });

      setForm(prevsValue => {
        return {
          ...prevsValue,
          [e.target.name]: null,
        };
      });
    } else {
      setDate({
        startDate: dateTimeFormat(e.target.value[0]),
        endDate: dateTimeFormat(e.target.value[1]),
      });

      setForm(prevsValue => {
        return {
          ...prevsValue,
          [e.target.name]:
            props.keyFilter(e.target.name) +
            dateTimeFormat(e.target.value[0]) +
            ">>" +
            dateTimeFormat(e.target.value[1]),
        };
      });
    }
  };
  const buttonReset = async e => {
    e.preventDefault();
    setForm(null);
    setDate({
      startDate: null,
      endDate: null,
    });

    setReset(true);
    await props.dispatch(utilsActions.setLoading(true));
    await props.dispatch(props.action.setFilter(""));
    await props.dispatch(utilsActions.setRowLimit(10));
    await props.setInitiate(props.validToken(), props.dispatch, "", props.notify);
    props.dispatch(props.action.setRangeDate(""));
    props.list(
      totalRow,
      props.initiate.count_data,
      props.validToken(),
      1,
      10,
      sortDate,
      "",
      props.dispatch,
      props.notify
    );
    arr(adminOpt, setAdminOpt);
    arr(mappingTitle, setMappingTitle);
    arr(resourceOpt, setResourceOpt);
  };
  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);
  const apply = async e => {
    e.preventDefault();
    if (form !== null) {
      let stringvalue = valueFilter(form);
      await props.dispatch(utilsActions.setLoading(true));
      await props.dispatch(props.action.setFilter(stringvalue));
      await props.setInitiate(props.validToken(), props.dispatch, stringvalue, props.notify);
      props.list(
        totalRow,
        props.initiate.count_data,
        props.validToken(),
        currentPage,
        rowLimit,
        sortDate,
        props.filter,
        props.dispatch,
        props.notify
      );
    }
  };

  const handleOnchange = (name, value) => {
    if (value !== "") {
      setForm(prevsValue => {
        return {
          ...prevsValue,
          [name]: props.keyFilter(name) + value.replaceAll(",", "-"),
        };
      });
    } else {
      setForm(prevsValue => {
        return {
          ...prevsValue,
          [name]: "",
        };
      });
    }
  };
  const handleScroll = async e => {
    const newOptionSearch = await props.getAdmin(
      props.validToken(),
      pageSearch + 1,
      10,
      search.admin,
      props.notify,
      props.dispatch,
      response => arrNew(isContent(response))
    );
    setPageSearch(pageSearch + 1);
    var duplicateCheckAll = new Set(adminOpt.map(d => d.id));
    if (newOptionSearch !== null) {
      var mergedScroll = [
        ...adminOpt,
        ...newOptionSearch.filter(d => !duplicateCheckAll.has(d.id)),
      ];
      setAdminOpt(mergedScroll);
    }
  };
  const controlValue = (tempArr, key) => {
    let val = [];
    for (var j = 0; j < tempArr.length; j++) {
      if (tempArr[j].isChecked === true) {
        val.push(tempArr[j][key]);
      }
    }
    let stringvalue = "";
    stringvalue += val.map(itm => itm);
    return stringvalue;
  };
  const handleChecked = (e, name, list, valueKey, setArr) => {
    const {value, checked} = e.target;
    let arr = list.map(datas =>
      datas[valueKey].toString() === value.toString() ? {...datas, isChecked: checked} : datas
    );
    setArr(arr);
    handleOnchange(name, controlValue(arr, valueKey));
  };

  const onChangeSearh = (e, name, set) => {
    const {value} = e.target;
    set(prevsValue => {
      return {
        ...prevsValue,
        [name]: value,
      };
    });
  };
  const onChangeSearhAdmin = async (e, name, set) => {
    const {value} = e.target;
    let arrNew = list =>
      list.map(datas => {
        return {...datas, isChecked: false};
      });
    set(prevsValue => {
      return {
        ...prevsValue,
        [name]: value,
      };
    });
    const newOption = await props.getAdmin(
      props.validToken(),
      1,
      10,
      value,
      props.notify,
      props.dispatch,
      response => arrNew(isContent(response))
    );
    setPageSearch(1);
    var duplicateCheck = new Set(adminOpt.map(d => d.id));
    var merged = [...adminOpt, ...newOption.filter(d => !duplicateCheck.has(d.id))];
    setAdminOpt(merged);
  };
  const onClickAdmin = () => {
    setPageSearch(1);
  };
  return (
    <Div className={style.Container} id={BOX.CUSTOM_HEADER_TABLE}>
      <Form className={style.Form} id={BOX.FORM}>
        <Row className={style.FormRow} id={BOX.ROW}>
          <Div className={style.FormColLeft} id={BOX.COL_LEFT}>
            <Row className={style.FormRow} id={BOX.COL_LEFT + BOX.ROW}>
              <Col className={style.Col} size="lg" width={3} id={BOX.COL_LEFT + BOX.ROW + "date"}>
                <DatePickerRange
                  name="dateRange"
                  value={
                    dateFormatRange(date)
                    // date === ""
                    //   ? ""
                    //   : `${moment(date.startDate).format("DD/MM/yyyy")}-${moment(date.endDate)
                    //       .subtract(1, "days")
                    //       .format("DD/MM/yyyy")}`
                  }
                  valueHeader={date}
                  onChange={e => handleRangeDate(e)}
                  showDoubleView={false}
                  reset={reset}
                  placeholder={props.t("COMMON:SELECT_DATE")}
                  classInput={style.FormInput}
                  size="xs"
                />
              </Col>
              <Col className={style.Col} size="lg" width={3} id={BOX.COL_LEFT + BOX.ROW + "admin"}>
                <Div className={style.FormInput}>
                  <SelectMulti
                    name="admin"
                    placeholder={props.t("COMMON:SELECT_ADMIN")}
                    option={adminOpt}
                    valueKey="id"
                    labelKey="value"
                    checkedKey="isChecked"
                    onClick={onClickAdmin}
                    onScroll={e => handleScroll(e)}
                    onChecked={(e, valueKey) =>
                      handleChecked(e, "admin", adminOpt, valueKey, setAdminOpt)
                    }
                    onChange={evt => handleOnchange(evt)}
                    search={true}
                    valueSearch={search.admin}
                    onInputSearch={evt => onChangeSearhAdmin(evt, "admin", setSearch)}
                    valueNotFound={props.t("COMMON:DATA_NOT_FOUND")}
                  />
                </Div>
              </Col>
              <Col className={style.Col} size="lg" width={3} id={BOX.COL_LEFT + BOX.ROW + "admin"}>
                <Div className={style.FormInput}>
                  <SelectMulti
                    name="mapping_type"
                    placeholder={props.t("COMMON:SELECT_MAPPING_TITLE")}
                    option={mappingTitle}
                    valueKey="id"
                    labelKey="value"
                    checkedKey="isChecked"
                    onChecked={(e, valueKey) =>
                      handleChecked(e, "mapping_type", mappingTitle, valueKey, setMappingTitle)
                    }
                    onChange={evt => handleOnchange(evt)}
                    search={true}
                    valueSearch={search.mappingTitle}
                    onInputSearch={evt => onChangeSearh(evt, "mappingTitle", setSearch)}
                    valueNotFound={props.t("COMMON:DATA_NOT_FOUND")}
                  />
                </Div>
              </Col>
              <Col className={style.Col} size="lg" width={3} id={BOX.COL_LEFT + BOX.ROW + "admin"}>
                <Div className={style.FormInput}>
                  <SelectMulti
                    name="resource"
                    placeholder={props.t("COMMON:SELECT_SOURCE")}
                    option={resourceOpt}
                    valueKey="id"
                    labelKey="value"
                    checkedKey="isChecked"
                    onChecked={(e, valueKey) =>
                      handleChecked(e, "resource", resourceOpt, valueKey, setResourceOpt)
                    }
                    search={true}
                    valueSearch={search.resource}
                    onChange={evt => handleOnchange(evt)}
                    onInputSearch={evt => onChangeSearh(evt, "resource", setSearch)}
                    valueNotFound={props.t("COMMON:DATA_NOT_FOUND")}
                  />
                </Div>
              </Col>
            </Row>
          </Div>

          <Div className={style.FormColRight} id={BOX.COL_RIGHT}>
            <Button id={BUTTON.APPLY} label={props.t("COMMON:APPLY")} onClick={apply} />
            <Button
              id={BUTTON.RESET}
              label={props.t("COMMON:RESET_FILTER")}
              onClick={buttonReset}
              typeStyle="tertiary"
            />
          </Div>
        </Row>
      </Form>
    </Div>
  );
};

export default FilterForm;
