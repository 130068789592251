import _ from 'lodash';

import {
  searchCompanyProfileColumn,
} from '../../Enum/Column/SearchCompanyProfileColumn';
import { searchDivisionColumn } from '../../Enum/Column/SearchDivisionColumn';
import {
  searchPersonProfileColumn,
} from '../../Enum/Column/SearchPersonProfileColumn';
import {
  searchPrincipalProductUserDefinedCategoryColumn,
} from '../../Enum/Column/SearchPrincipalProductUserDefinedCategoryColumn';
import {
  searchProductBrandColumn,
} from '../../Enum/Column/SearchProductBrandColumn';
import {
  searchProductCategoryColumn,
} from '../../Enum/Column/SearchProductCategoryColumn';
import { searchProductColumn } from '../../Enum/Column/SearchProductColumn';
import {
  searchProductGroupColumn,
} from '../../Enum/Column/SearchProductGroupColumn';
import {
  searchProductKeyAccountColumn,
} from '../../Enum/Column/SearchProductKeyAccountColumn';
import { MAPPING_CODE } from '../../Enum/MappingCode/MappingCode';

export const searchMappingColumnState = (state,columnDefault) =>  _.concat(columnDefault, state.mapping.searchColumn);
export const searchMappingColumnGroup = (state, mappingCode,t) => {
 
  let col = [];
  let concatCol = (defaultCol) => _.concat(defaultCol, state.mapping.searchColumn);
  if (mappingCode === MAPPING_CODE.PERSON_PROFILE) {
    col = concatCol(searchPersonProfileColumn(t));
  } else if (mappingCode === MAPPING_CODE.DIVISION) {
    col = searchDivisionColumn(t);
  } else if (mappingCode === MAPPING_CODE.PRODUCT_BRAND) {
    col = searchProductBrandColumn(t);
  } else if (mappingCode === MAPPING_CODE.PRODUCT_GROUP) {
    col = searchProductGroupColumn(t);
  } else if (mappingCode === MAPPING_CODE.COMPANY_PROFILE) {
    col = concatCol(searchCompanyProfileColumn(t));
  } else if (mappingCode === MAPPING_CODE.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY) {
    col = searchPrincipalProductUserDefinedCategoryColumn(t);
  } else if (mappingCode === MAPPING_CODE.PRODUCT_CATEGORY) {
    col = searchProductCategoryColumn(t);
  } else if (mappingCode === MAPPING_CODE.PRODUCT_KEY_ACCOUNT) {
    col = searchProductKeyAccountColumn(t);
  } else if (mappingCode === MAPPING_CODE.PRODUCT) {
    col = concatCol(searchProductColumn(t));
  }
  return col;
};
