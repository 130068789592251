import React, { useCallback, useMemo } from 'react';

import { Div } from 'nexcomponent-ui';

import {
  productAdvanceSearch,
} from '../../../../Enum/AdvanceSearch/ProductAdvanceSearch';
import {
  productPrincipalSearchBreadCrumb,
  productSearchBreadCrumb,
} from '../../../../Enum/Breadcrumb/ProductBreadcrumb';
import {
  searchProductColumn,
} from '../../../../Enum/Column/SearchProductColumn';
import { MAPPING_CODE } from '../../../../Enum/MappingCode/MappingCode';
import {
  ROUTER_CONSTANT,
} from '../../../../Enum/RouterConstant/RouterConstant';
import {
  fetchDataProductSearchList,
} from '../../../../Fetch/FetchData/ProductData';
import FieldProductSearchForm
  from '../../../Organisms/Form/SearchForm/FieldProductSearchForm/FieldProductSearchForm';
import SearchForm
  from '../../../Organisms/Form/SearchForm/SearchForm/SearchForm';
import HeaderPage from '../../../Organisms/Header/HeaderPage/HeaderPage';
import useQueryRoute from '../../../../Hooks/useQueryRoute';
import { fetchDataProductPrincipalSearchList } from '../../../../Fetch/FetchData/ProductPrincipalData';
import FieldProductPrincipalSearchForm from '../../../Organisms/Form/SearchForm/FieldProductPrincipalSearchForm/FieldProductPrincipalSearchForm';
import { searchProductPrincipalColumn } from '../../../../Enum/Column/SearchProductPrincipalColumn';

function ProductSearchPage(props) {
  const { principal } = useQueryRoute();

  const searchFunc=useCallback(
    (currentPage, rowLimit,filter, column,dispatch) =>{
      if(principal){
        return fetchDataProductPrincipalSearchList(
          props.validToken(),
          currentPage,
          rowLimit,
          filter,
          column,
          dispatch,
          props.notify
        )}else{
          return fetchDataProductSearchList(
            props.validToken(),
            currentPage,
            rowLimit,
            filter,
            column,
            dispatch,
            props.notify
            )
          }
        }
        ,[principal, props]
  )
  const field =useMemo(()=>{
    if(principal)
      return <FieldProductPrincipalSearchForm t={props.t} dispatch={props.dispatch} />
    else
    return <FieldProductSearchForm t={props.t} dispatch={props.dispatch} />
  },[principal, props])

  const columnDefault =useMemo(()=>{
    if(principal){
      return searchProductPrincipalColumn(props.t)
    }else{
      return searchProductColumn(props.t)
    }
  },[principal, props.t])
    const listBreadcrumb=useMemo(()=>{
    if(principal){
      return productPrincipalSearchBreadCrumb(props.t)
    }else{
      return productSearchBreadCrumb(props.t)
    }
  },[principal, props.t])


  return (
    <Div>
      <HeaderPage
        title={props.t("PRODUCT:SEARCH.TITLE")}
        listBreadcrumb={listBreadcrumb}
        defaultBreadcrumb={props.t("PRODUCT:SEARCH.TITLE")}
        toTitlePage={ROUTER_CONSTANT.PRODUCT.MAPPING}
      />
      <SearchForm
        title="PRODUCT:SEARCH.TITLE"
        field={field}
        t={props.t}
        dispatch={props.dispatch}
        mappingCode={MAPPING_CODE.PRODUCT}
        navigate={props.navigate}
        option={productAdvanceSearch(props.t)}
        advance={true}
        columnDefault={columnDefault}
        backNavigate={`${ROUTER_CONSTANT.PRODUCT.PRINCIPAL.MAPPING}`}
        searchFunc={searchFunc}
      />
    </Div>
  );
}

export default ProductSearchPage;
