export const mappingDataTypeId = {
  PERSON_PROFILE: "personprofile",
  DIVISION: "division",
  PRODUCT_BRAND: "productbrand",
  PRODUCT_GROUP: "productgroup",
  COMPANY_PROFILE: "companyprofile",
  PRODUCT_CATEGORY: "productcategory",
  PRODUCT_KEY_ACCOUNT: "productkeyaccount",
  PRODUCT: "product",
};
