import { sha256 } from 'js-sha256';

import { createSlice } from '@reduxjs/toolkit';

import { CRYPTO_RANDOM } from '../../Enum/PropertiesConstant';

const initialState = {
  crypto_random: null,
  code_pkce: null,
  token: null,
  refreshToken: null,
  user: null,

  content: null,
  first_name: null,
  last_name: null,
  role: null,

  language: null,
  scope: null,
  list_menu: null,
  list_permission: null,

  dataAuthorize: {
    code_challenger: sha256(CRYPTO_RANDOM),
    redirect_uri: "",
    response_type: "code_pkce",
    state: "",
    wanted_scope: "read write",
  },
  form: null,
};
const authSlice = createSlice({
  name: "authentication",
  initialState: initialState,
  reducers: {
    setToken(state, data) {
      state.token = data.payload;
    },
    setRefreshToken(state, data) {
      state.refreshToken = data.payload;
    },
    clearToken(state) {
      state.token = null;
    },
    setContent(state, data) {
      state.content = data.payload;
    },
    setLanguage(state, data) {
      state.language = data.payload;
    },
    setForm(state, data) {
      state.form = data.payload;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
