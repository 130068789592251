import { productFieldMapping } from '../../Enum/FieldMapping/ProductFieldMapping';
import { dashboardAction } from '../../Store/Reducers/DashboardReducer';
import { mappingActions } from '../../Store/Reducers/MappingReducer';
import { utilsActions } from '../../Store/Reducers/UtilsReducer';
import {
  isContent,
  isContentErr,
} from '../../Utils/Response/Response';
import {
  getDetailExistingProduct,
  getDetailProduct,
  postApproveMappingProduct,
  postProductCountData,
  postProductList,
  postProductSearchCountData,
  postSearchProductList,
} from '../Api/ProductAPI';
import {
  reloadList,
  setDefaultPagination,
} from './DashboardData';
import {
  approvalAction,
  approvalActionErr,
  setMappingForm,
  setMappingSearch,
  setMappingSearchErr,
} from './MappingData';

export  const fetchDataProductList=(
  totalRow,
  countData,
  token,
  currentPage,
  rowLimit,
  sortDate,
  dispatch,
  notify
)=>postProductCountData(token,notify,dispatch,
  (response)=>listData(response,totalRow, countData, token, currentPage, rowLimit, sortDate, dispatch,notify))

const listData=async(res,totalData, countData, token, currentPage, rowLimit, sortDate, dispatch,notify) =>{
    await dispatch(mappingActions.setCountData(isContent(res)))
    await dispatch(dashboardAction.setTotalRow(isContent(res)));

    if (totalData === countData) {
      await postProductList(token, currentPage, rowLimit, sortDate, dispatch,notify,
        response =>dispatch(mappingActions.setList(isContent(response))))
    } else {
      setDefaultPagination(dispatch)
      await postProductList(token, 1, 10, sortDate, dispatch,notify,
        response => reloadList(response,dispatch,countData))
    }
    dispatch(utilsActions.setLoading(false));
  }
export const fetchProductMappingForm = 
  async ( token, dispatch, uniqueKey, NewId, ExistId, list, notify, navigate)=> {
    const newData = await getDetailProduct(token, NewId, notify,dispatch,
      res=>isContent(res),err=>isContentErr(err))
    const existData = await getDetailExistingProduct(token, ExistId, notify,dispatch,
      res=>isContent(res),err=>isContentErr(err))
    await setMappingForm ( productFieldMapping, list, uniqueKey, newData, existData, dispatch)
    navigate()
}

export async function fetchDataApprovalProduct(
  token,
  data,
  mappingList,
  mappingCount,
  notify,
  dispatch
) {
  let submit=()=>{
    let submitData=data
    if(data.embalace_status==="N" ||data.embalace_status===""||data.embalace_status===null){
     let  relatedEffect={code_crate:null, code_bottle:null}
      submitData={...data,...relatedEffect}

    }
    return submitData
    
  }
  
  return postApproveMappingProduct(
    token,
    submit(),
    notify,
    dispatch,
    res => approvalAction(res, mappingList, mappingCount, dispatch),
    err => approvalActionErr(err, dispatch)
  );
}
export async function fetchSearchProductList(token, data, column, dispatch, notify) {
  await postSearchProductList(token, data, dispatch, notify,
    res=>setMappingSearch(res,column,dispatch),
    err=>setMappingSearchErr(err, dispatch))
}
export const fetchDataProductSearchList = async (
  token,
  currentPage,
  rowLimit,
  filter,
  column,
  dispatch,
  notify
) => {
  postProductSearchCountData(token, filter, notify, dispatch, response =>
    listSearchData(
      response,
      token,
      currentPage,
      rowLimit,
      filter,
      column,
      dispatch,
      notify
    )
  );
};
const listSearchData = async (
  res,
  token,
  currentPage,
  rowLimit,
  filter,
  column,
  dispatch,
  notify
) => {
  let data = await {...{page: currentPage, limit: Number(rowLimit)}, ...filter};
   await postSearchProductList(
    token,
    data,
    dispatch,
    notify,
    reponse => setMappingSearch(reponse, column, isContent(res), dispatch),
    err => setMappingSearchErr(err, isContent(res), dispatch)
  );
  dispatch(utilsActions.setLoading(false));
};