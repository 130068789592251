import moment from 'moment';

export function formatDD_MMMM_YYYY(value) {
  let date = "-";
  if (value !== undefined || value !== "" || value !== null) {
    date = moment(value).format("DD MMMM YYYY");
  }
  if (value === "" || value === null) {
    date = "-";
  }

  return date;
}
