import React, {
  useEffect,
  useState,
} from 'react';

import {
  Button,
  CheckBox,
  Div,
  Field,
  Form,
  Heading,
  Logo,
  Span,
} from 'nexcomponent-ui';
import { useSelector } from 'react-redux';

import webLogo from '../../../../Assets/Logos/logo.svg';
import {
  BOX,
  BUTTON,
  CHECKBOX,
  FORM,
  ID,
  LABEL,
  TEXT_SINGLE,
} from '../../../../Enum/IdSet';
import {
  NAMESET,
  TYPESET,
} from '../../../../Enum/Input';
import {
  CRYPTO_RANDOM,
  logoSize,
} from '../../../../Enum/PropertiesConstant';
import { SIZESET } from '../../../../Enum/Props';
import { fetchDataLogin } from '../../../../Fetch/FetchData/AutheticationData';
import { utilsActions } from '../../../../Store/Reducers/UtilsReducer';
import { userExistAction } from '../../../../Utils/Action';
import { dataAuthorize } from '../../../../Utils/Form/AuthenticationForm';
import style from './LoginForm.module.css';

const LoginForm = props => {
  let remember = useSelector(state => state.utils.remember);
  let [form, setForm] = useState({username: "", password: ""});
  let formHandler = evt => {
    let {name, value} = evt.target;
    setForm(prevsValue => {
      return {
        ...prevsValue,
        [name]: value,
      };
    });
  };
  useEffect(() => {
    if (userExistAction("key") != null && userExistAction("id") != null) {
      setForm({username: userExistAction("id"), password: userExistAction("key")});
    }
  }, []);
  let checkedHandler = evt => {
    props.dispatch(utilsActions.setRemember(evt.target.checked));
  };
  let loginHandler = evt => {
    evt.preventDefault();
    fetchDataLogin(
      dataAuthorize,
      form,
      CRYPTO_RANDOM,
      props.dispatch,
      props.notify,
      props.t,
      remember
    );
  };
  return (
    <Form id={FORM.LOGIN} className={style.FormLogin} onSubmit={loginHandler}>
      <Logo id={ID.LOGO} width={logoSize} src={webLogo} />
      <Heading
        id={TEXT_SINGLE.GREETING}
        size={SIZESET.SIX}
        className={style.Title}
        value={props.t("COMMON:HI_WELCOME")}
      />
      <Span
        id={TEXT_SINGLE.GUIDE}
        className={style.SubTitle}
        value={props.t("COMMON:LOGIN_SUBTITLE")}
      />
      <Div id={BOX.FORM} className={style.Field}>
        <Field
          id={BOX.USERNAME}
          labelId={LABEL.USERNAME}
          inputId={TEXT_SINGLE.USERNAME}
          className={style.Field}
          defaultValue={userExistAction("id")}
          size={SIZESET.LG}
          type={TYPESET.TXT}
          name={NAMESET.USERNAME}
          label={props.t("USER:FIELD_USERNAME")}
          placeholder={props.t("USER:PLACEHOLDER.USERNAME")}
          onChange={formHandler}
        />
        <Field
          id={BOX.PASSWORD}
          labelId={LABEL.PASSWORD}
          inputId={TEXT_SINGLE.PASSWORD}
          defaultValue={userExistAction("key")}
          className={style.Field}
          size={SIZESET.LG}
          type={TYPESET.PASS}
          name={NAMESET.PASSWORD}
          label={props.t("USER:FIELD_PASSWORD")}
          placeholder={props.t("USER:PLACEHOLDER.PASSWORD")}
          onChange={formHandler}
        />
        {/* <Link id={HYPERLINK.FORGET_PASSWORD} to={COMMON.FORGET} className={style.ForgetLink}>
          {props.t("COMMON:FORGET_PASSWORD")}
        </Link> */}
        <CheckBox
          onChange={checkedHandler}
          name={NAMESET.REMEMBER}
          id={ID.BOX + CHECKBOX.REMEMBER}
          checkboxId={CHECKBOX.REMEMBER}
          labelId={LABEL.REMEMBER}
          className={style.CheckBox}
          label={props.t("COMMON:REMEMBER_ME")}
          size={SIZESET.SM}
        />
        <Button
          id={BUTTON.LOGIN}
          className={style.Button}
          type={TYPESET.SUBMIT}
          label={props.t("COMMON:LOGIN")}
        />
      </Div>
    </Form>
  );
};

export default LoginForm;
