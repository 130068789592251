import { COMMON } from '../../Enum/EndPoint/CommonEndpoint';

export const PRODUCT_KEY_ACCOUNT_URL = Object.freeze({
  COUNT: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRODUCT_KEY_ACCOUNT + COMMON.COUNT,
  LIST: function list(page, limit, order) {
    return (
      COMMON.MAPPING + COMMON.MAPPING_TYPE.PRODUCT_KEY_ACCOUNT + COMMON.LIST(page, limit, order)
    );
  },
  REJECT: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRODUCT_KEY_ACCOUNT + COMMON.REJECT,
  DETAIL: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRODUCT_KEY_ACCOUNT + COMMON.INDEX,
  DETAIL_EXISTING: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRODUCT_KEY_ACCOUNT + COMMON.EXISTING,
  SEARCH: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRODUCT_KEY_ACCOUNT + COMMON.SEARCH,
  COUNT_SEARCH:
    COMMON.MAPPING + COMMON.MAPPING_TYPE.PRODUCT_KEY_ACCOUNT + COMMON.SEARCH + COMMON.COUNT,
  MAPPING_APPROVE: COMMON.MAPPING + COMMON.MAPPING_TYPE.PRODUCT_KEY_ACCOUNT + COMMON.APPROVE,
});
