import React, { useEffect } from 'react';

import _ from 'lodash';
import {
  LayoutPage,
  nexIcon,
  SideBar,
  TopBar,
} from 'nexcomponent-ui';
import { useSelector } from 'react-redux';

import { httpStatusImg } from '../../../Assets/Images';
import LogoLeft from '../../../Components/Molecules/Image/LogoLeft/LogoLeft';
import {
  BOX,
  DROPDOWNLIST,
} from '../../../Enum/IdSet';
import { profileMenu } from '../../../Enum/Menu/ProfileMenu/ProfileMenu';
import { sidebarMenuList } from '../../../Enum/Menu/SideBar/SidebarMenuList';
import { subMenuList } from '../../../Enum/Menu/SideBar/SubMenuList';
import { SIZE } from '../../../Enum/Size/Size';
import { logOut } from '../../../Fetch/Api/AuthenticationAPI';
import { dashboardAction } from '../../../Store/Reducers/DashboardReducer';
import { utilsActions } from '../../../Store/Reducers/UtilsReducer';
import { layoutStateGroup } from '../../../Store/StateGroup/LayoutStateGroup';
import { defaultUserExist } from '../../../Utils/Action';
import { COLOR } from '../../../Utils/Style';
import ModalTimeOut from '../Modal/ModalTimeOut/ModalTimeOut';
import DropDownProfile from './DropDownProfile/DropDownProfile';

const Layout = props => {
  const [, language, menuLevel1, menuLevel2, , content, collapse, sideMenu] = useSelector(
    state => layoutStateGroup(state)
  );
  const timeOut = useSelector(state => state.utils.timeOut);
  
  const resLogout = async () => {
    window.localStorage.removeItem("persist:drive");
    await props.dispatch({type: "DESTROY_SESSION"});
    defaultUserExist(props.dispatch);
    props.navigate("/");
  };
  const handleCollapse = () => props.dispatch(utilsActions.setSideBarCollapse(!collapse));
  const logOutAction = async () => {
    await logOut(props.validToken(), props.notify, props.dispatch, () => resLogout());
  };

  const sidebaIconClick = type => {
    if (sideMenu !== type) {
      props.dispatch(utilsActions.setSideMenu(type));
      props.dispatch(utilsActions.setSideBarCollapse(true));
      props.dispatch(dashboardAction.setMenuLevel1(type));
      props.dispatch(dashboardAction.setMenuLevel2(null));
    } else {
      props.dispatch(utilsActions.setSideMenu(null));
      props.dispatch(utilsActions.setSideBarCollapse(false));
      props.dispatch(dashboardAction.setMenuLevel1(null));
    }
  };
  const menu = _.find(content.menu, {name: "Proses Data"});
  const serviceMenu = _.find(menu.service_menu, {name: "Mapping"});
  const subMenu = subMenuList(serviceMenu.menu_item, language);
  const handleActiveLevel2 = type => {
    props.dispatch(dashboardAction.setMenuLevel2(type));
  };
  const sidebarMenu = sidebarMenuList(
    menu.service_menu[1],
    subMenu,
    props.t,
    type => sidebaIconClick(type),
    language,
    props.dispatch,
    type => handleActiveLevel2(type), 
  );
  
  const sidebarByType = type => {
    return _.find(sidebarMenu, {type: type});
  };
  const location = () => {
    let loc = "";
    if (props.location.pathname === "/broadcast") {
      props.dispatch(dashboardAction.setMenuLevel2("broadcast"));
      loc = "";
    } else if (props.location.pathname === "/reject") {
      props.dispatch(dashboardAction.setMenuLevel2("reject"));
      loc = "";
    } else {
      props.dispatch(dashboardAction.setMenuLevel2("mapping"));
      loc = props.location.pathname
        .replace("/mapping", "")
        .replace("/rejectreason", "")
        .replace("/search", "");
      props.dispatch(dashboardAction.setMenuLevel3(loc.replace("/", "")));
    }
    return loc;
  };
  const userAlias = () => {
    const val = content.first_name + " " + content.last_name;
    let alias = val;
    if (val.length > 11) {
      alias = val.substr(0, 8) + "...";
    }
    return alias;
  };
  return (
    <>
      <ModalTimeOut t={props.t} show={timeOut} />
      <LayoutPage
        topBar={
          <TopBar
            id={BOX.TOPBAR}
            Logo={<LogoLeft onClick={handleCollapse} dispatch={props.dispatch} />}
            DropDownProfile={
              <DropDownProfile
                id={DROPDOWNLIST.MENU_PROFILE}
                titleIcon={
                  <nexIcon.NcPerson width={SIZE.SIZE} height={SIZE.SIZE_20} color={COLOR.PRIMER} />
                }
                title={content.first_name + " " + content.last_name }
                menu={profileMenu(props.t)}
                logoutFunc={logOutAction}
                logoutIcon={
                  <nexIcon.NcLogout width={SIZE.SIZE} height={SIZE.SIZE_17} color={COLOR.PRIMER} />
                }
                logoutTitle={props.t("COMMON:BUTTON_LOGOUT")}
              />
            }
          />
        }
        idContentBotton={BOX.CONTENTBOTTOM}
        idSide={BOX.SIDE}
        collapse={collapse}
        sideBarType={sidebarByType(sideMenu)}
        sideBar={
          <SideBar
            id={BOX.SIDEBAR}
            sidebarMenu={sidebarMenu}
            sidebarByType={sidebarByType(sideMenu)}
            sideMenu={sideMenu}
            activeLevel1={menuLevel1}
            activeLevel2={menuLevel2}
            activeLevel3={location()}
          />
        }
        idContent={BOX.CONTENT}
        content={
          props.content
        }
      />
    </>
  );
};

export default Layout;
