import React from 'react';

import { Div } from 'nexcomponent-ui';

import {
  productKeyAccountBreadCrumb,
} from '../../../../Enum/Breadcrumb/ProductKeyAccountBreadcrumb';
import {
  productKeyAccountColumn,
} from '../../../../Enum/Column/ProductKeyAccountColumn';
import { PAGE } from '../../../../Enum/IdSet';
import { MAPPING_CODE } from '../../../../Enum/MappingCode/MappingCode';
import {
  ROUTER_CONSTANT,
} from '../../../../Enum/RouterConstant/RouterConstant';
import {
  postRejectProductKeyAccountList,
} from '../../../../Fetch/Api/ProductKeyAccountAPI';
import {
  fetchProductKeyAccountMappingForm,
} from '../../../../Fetch/FetchData/ProductKeyAccountData';
import CancelRejectButton
  from '../../../Organisms/Button/CancelRejectButton/CancelRejectButton';
import HeaderPage from '../../../Organisms/Header/HeaderPage/HeaderPage';
import ModalReject from '../../../Organisms/Modal/ModalReject/ModalReject';
import RejectReasonList
  from '../../../Organisms/TableList/RejectReasonList/RejectReasonList';

const ProductKeyAccountRejectReasonPage = props => (
  <Div id={PAGE.REJECT_LIST}>
    <ModalReject
      onReject={true}
      t={props.t}
      dispatch={props.dispatch}
      mappingCode={MAPPING_CODE.PRODUCT_KEY_ACCOUNT}
      navigate={props.navigate}
      rejectFunc={(data, notify, dispatch) =>
        postRejectProductKeyAccountList(props.validToken(), data, notify, dispatch)
      }
      uniqueKey={"product_key_account_id"}
      nextMapping={(newId, existId, mappingList,nav) =>
        fetchProductKeyAccountMappingForm(
          props.validToken(),
          props.dispatch,
          "product_key_account_id",
          newId,
          existId,
          mappingList,
          props.notify,
          () => props.navigate(nav)
        )
      }
    />
    <HeaderPage
      title={props.t("PRODUCT_KEY_ACCOUNT:TITLE")}
      listBreadcrumb={productKeyAccountBreadCrumb(props.t)}
      defaultBreadcrumb={props.t("COMMON:REJECT_REASON")}
      toTitlePageto={ROUTER_CONSTANT.PRODUCT_KEY_ACCOUNT.LIST}
    />
    <RejectReasonList
      t={props.t}
      dispatch={props.dispatch}
      column={productKeyAccountColumn(props.t)}
      mappingCode={MAPPING_CODE.PRODUCT_KEY_ACCOUNT}
    />
    <CancelRejectButton
      backPage={ROUTER_CONSTANT.PRODUCT_KEY_ACCOUNT.LIST}
      mappingCode={MAPPING_CODE.PRODUCT_KEY_ACCOUNT}
      onReject={true}
      t={props.t}
      dispatch={props.dispatch}
      navigate={props.navigate}
    />
  </Div>
);

export default ProductKeyAccountRejectReasonPage;
