import jwt_decode from 'jwt-decode';

import { postRefreshToken } from '../../../Fetch/Api/AuthenticationAPI';
import { setAuthorization } from '../../../Fetch/FetchData/AutheticationData';

export const validateToken = (prevtoken, refreshToken, notify, dispatch) => {
  const dateNow = new Date();
  const data = {refresh_token: refreshToken};
  var exp = new Date(jwt_decode(prevtoken).exp * 1000);
  let token = prevtoken;
  const expToken = prevtoken !== null && refreshToken !== null && dateNow > exp;
  if (expToken)
    token = postRefreshToken(prevtoken, data, notify, dispatch, response =>
      setAuthorization(response, dispatch)
    );

  return token;
};
