import { Div, Form } from "nexcomponent-ui";
import { useSelector } from "react-redux";

import SpinnerLoad from "../../../../../Components/Molecules/Loader/SpinnerLoad/SpinnerLoad";
import { mappingCheckedAll, mappingCheckedPartial } from "../../../../../Store/Action";
import { statusCheckedMappingAll, valueMappingField } from "../../../../../Utils/Mapping";
import MappingFormHeader from "../MappingFormHeader/MappingFormHeader";
import {
  selectFieldMappingForm,
  selectFieldPrincipalMappingForm,
} from "../selectFielMappingForm/selectFielMappingForm";
import style from "./MappingForm.module.css";
import { useCallback, useEffect, useMemo } from "react";
import useQueryRoute from "../../../../../Hooks/useQueryRoute";
import { utilsActions } from "../../../../../Store/Reducers/UtilsReducer";

const MappingForm = (props) => {
  const { principal } = useQueryRoute();

  const lastUpdate = useSelector((state) => state.mapping.lastUpdate);
  const detail = useSelector((state) => state.mapping.detail);
  const load = useSelector((state) => state.utils.loading);
  const submitMapping = useSelector((state) => state.mapping.submitMapping);

  const existData = useSelector((state) => state.mapping.existing);
  const checkedPartial = useCallback(
    (e) => {
      mappingCheckedPartial(
        e,
        props.mappingForm,
        submitMapping,
        existData,
        props.dispatch,
        props.idKey
      );
    },
    [existData, props.dispatch, props.idKey, props.mappingForm, submitMapping]
  );

  const checkedAll = async (e) => {
    mappingCheckedAll(e, props.mappingForm, submitMapping, existData, props.dispatch, props.idKey);
  };

  const renderComponent = useMemo(() => {
    if (principal) {
      return selectFieldPrincipalMappingForm(
        props.t,
        props.validToken,
        props.dispatch,
        checkedPartial,
        (form, key, item) => valueMappingField(form, key, item),
        props.mappingForm,
        submitMapping,
        props.mappingCode,
        props.idKey,
        detail
      );
    } else {
      return selectFieldMappingForm(
        props.t,
        props.validToken,
        props.dispatch,
        checkedPartial,
        (form, key, item) => valueMappingField(form, key, item),
        props.mappingForm,
        submitMapping,
        props.mappingCode,
        props.idKey,
        detail
      );
    }
  }, [
    checkedPartial,
    detail,
    principal,
    props.dispatch,
    props.idKey,
    props.mappingCode,
    props.mappingForm,
    props.t,
    props.validToken,
    submitMapping,
  ]);

  useEffect(() => {
    if (load) {
      props.dispatch(utilsActions.setLoading(false));
    }
  }, [load, props, props.dispatch]);

  return (
    <>
      <Div className={style.MappingFrom}>
        {load ? (
          <Div className={style.Load}>
            <SpinnerLoad t={props.t} />
          </Div>
        ) : (
          <>
            <MappingFormHeader
              name="checkboxAll"
              onCheck={checkedAll}
              t={props.t}
              dispatch={props.dispatch}
              checked={statusCheckedMappingAll(props.mappingForm)}
              mappingCode={props.mappingCode}
              mapping={props.mappingForm}
              navigate={props.navigate}
              lastUpdate={lastUpdate}
              lastUpdateBy={existData === undefined ? "-" : existData.created_by}
            />
            <Form className={style.Form}>
              <Div className={style.MappingBody}>{renderComponent}</Div>
            </Form>
          </>
        )}
      </Div>
    </>
  );
};

export default MappingForm;
