import _ from 'lodash';

import { mappingActions } from '../../../Reducers/MappingReducer';

export const mappingCheckedPartial = async (
  e,
  mappingForm,
  submitMapping,
  existData,
  dispatch,
  idKey
) => {
  const {name, checked} = e.target;
  const value = _.find(mappingForm, ["key", name]);
  if (checked) {
    if (name === idKey) {
      dispatch(mappingActions.setSubmitMapping({...submitMapping, [name]: value.exist}));
    } else {
      dispatch(mappingActions.setSubmitMapping({...submitMapping, [name]: value.new}));
    }
  } else {
    let update = {[name]: value.key === name ? value.exist : value.new};
    dispatch(mappingActions.setSubmitMapping({...submitMapping, ...update}));
  }
  let tempForm = mappingForm.map(datas =>
    datas.key === name ? {...datas, isChecked: checked} : datas
  );
  dispatch(mappingActions.setMappingForm(tempForm));
};
