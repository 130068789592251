import React from 'react';

import { Row } from 'nexcomponent-ui';

import style from './Detail.module.css';

export const detail = (id,data) => {
  return (
    <Row className={style.Row} id={"box" + id} >
      {data}
    </Row>
  );
};

