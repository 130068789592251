import { itemDetailValue } from '../../Utils/Value';

export const detailBroadcastDivision = (t, data) => [
  { id: "tslcode", title: t("DIVISION:FIELD.DIVISION_CODE"), value: itemDetailValue(data.code) },
  { id: "tslname", title: t("DIVISION:FIELD.DIVISION_NAME"), value: itemDetailValue(data.name) },
  { id: "tslid", title: t("DIVISION:FIELD.DIVISION_ID"), value: itemDetailValue(data.id) },
  {
    id: "tslprincipalcode",
    title: t("DIVISION:FIELD.PRINCIPAL_CODE"),
    value: itemDetailValue(data.principal_code),
  },
  {
    id: "tslprincipalname",
    title: t("DIVISION:FIELD.PRINCIPAL_NAME"),
    value: itemDetailValue(data.principal_name),
  },
  {
    id: "tslprincipalid",
    title: t("DIVISION:FIELD.PRINCIPAL_ID"),
    value: itemDetailValue(data.principal_id),
  },
  {
    id: "tslresource",
    title: t("DIVISION:FIELD.RESOURCE"),
    value: itemDetailValue(data.resource),
  },
];
