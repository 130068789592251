import { COMMON } from '../../Enum/EndPoint/CommonEndpoint';

export const COMPANY_PROFILE_URL = Object.freeze({
  COUNT: COMMON.MAPPING + COMMON.MAPPING_TYPE.COMPANY_PROFILE + COMMON.COUNT,
  LIST: (page, limit, order) =>
    COMMON.MAPPING + COMMON.MAPPING_TYPE.COMPANY_PROFILE + COMMON.LIST(page, limit, order),
  REJECT: COMMON.MAPPING + COMMON.MAPPING_TYPE.COMPANY_PROFILE + COMMON.REJECT,
  DETAIL: COMMON.MAPPING + COMMON.MAPPING_TYPE.COMPANY_PROFILE + COMMON.INDEX,
  DETAIL_EXISTING: COMMON.MAPPING + COMMON.MAPPING_TYPE.COMPANY_PROFILE + COMMON.EXISTING,
  SEARCH: COMMON.MAPPING + COMMON.MAPPING_TYPE.COMPANY_PROFILE + COMMON.SEARCH,
  COUNT_SEARCH: COMMON.MAPPING + COMMON.MAPPING_TYPE.COMPANY_PROFILE + COMMON.SEARCH+COMMON.COUNT,
  MAPPING_APPROVE: COMMON.MAPPING + COMMON.MAPPING_TYPE.COMPANY_PROFILE + COMMON.APPROVE,
});
