import React from 'react';

import {
  Card,
  Heading,
  Image,
  Paragraph,
  Row,
  Span,
} from 'nexcomponent-ui';

import docImage from '../../../../Assets/Icons/Doc.svg';
import { month } from '../../../../Utils/Date/month';
import style from './CardMapping.module.css';

function CardMapping(props) {
  const mapped = props.mapped;
  function mappedHandler(mapped) {
    if (mapped === true) {
      return style.CardTitleMapping;
    } else {
      return style.CardTitleUnMapping;
    }
  }
  function totaldHandler(mapped) {
    if (mapped === true) {
      return "primer";
    } else {
      return "danger";
    }
  }

  return (
    <>
      <Card id={props.id} className={`${style.Card} ${props.className}`} onClick={props.onClick}>
        <Row id={props.id + "titlerow"} className={style.RowTitle}>
          <Span id={props.id + "titlecol1"} className={style.CardTitle} value={props.isData} />
          <Span
            id={props.id + "titlecol2"}
            className={mappedHandler(mapped)}
            value={props.statusMap}
          />
          <Span id={props.id + "titlecol3"} className={style.CardTitle} value={props.isMapping} />
        </Row>
        {props.subtitle === undefined ? (
          ""
        ) : (
          <Paragraph
            id={props.id + "month"}
            className={style.CardSubtitle}
            value={props.subtitle !== null ? props.subtitle : month}
          />
        )}

        <Row id={props.id + "countrow"} className={style.RowCount}>
          <Image id={props.id + "imagecount"} className={style.CardIcon} src={docImage} />
          <Heading
            id={props.id + "counter"}
            size={6}
            value={props.count !== null ? props.count : 0}
            color={totaldHandler(mapped)}
          />
        </Row>
        <Paragraph id={props.id + "date"} className={style.CardFooter} value={props.date} />
      </Card>
    </>
  );
}

export default CardMapping;
