import React from 'react';

import {
  Div,
  Li,
  PaginationTable,
  TableLimit,
  Ul,
} from 'nexcomponent-ui';

import style from './CustomPagination.module.css';

const CustomPagination = props => {
  const selectableRow = props.selectable;
  return (
    <Div id="customfooter" className={style.CustomFooter}>
      <Div
        id={props.id + "footer"}
        className={`${style.TableFooter} table-footer`}
        fixHeader={props.fixHeader}
        fixHeaderBg={props.fixHeaderBg}
      >
        <Div id={props.id + "footerrow"}>
          <Div
            id={props.id + "footerdata"}
            colSpan={selectableRow ? props.length + 1 : props.length}
          >
            <Ul id={props.id + "footerul"} className={style.List}>
              <Li id={props.id + "footerlabel"} className={`${style.Label} label-footer`}>
                {props.footerLabel}
              </Li>
              <Li id={props.id + "footerlimit"}>
                <TableLimit
                  id="ddllimit"
                  option={props.limitation}
                  defaultOption={props.defaultOption}
                  selectClick={props.selectClick}
                />
              </Li>
              <Li id={props.id + "footerpagination"} className={`${style.Pagination} pagination`}>
                <PaginationTable
                  currentPage={props.currentPage}
                  nextClick={props.nextClick}
                  startPage={props.startPage}
                  endPage={props.endPage}
                  rowLimit={props.rowLimit}
                  previousClick={props.previousClick}
                  firstClick={props.firstClick}
                  lastClick={props.lastClick}
                  pageClick={props.pageClick}
                  totalRow={props.totalRow}
                  pageNumber={props.pageNumber}
                />
              </Li>
            </Ul>
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

export default CustomPagination;
