export const tableAllMappingStateGroup = state => {
  return [
    state.dashboard.initiate,
    state.dashboard.totalRow,
    state.dashboard.mappingList,
    state.utils.currentPage,
    state.utils.loading,
    state.utils.rowLimit,
    state.utils.sortDateAsc,
  ];
};
