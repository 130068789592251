import { defaultLimitSearch } from '../../Enum/Limit/SearchAdvance';
import {
  searchMappingActions,
} from '../../Store/Reducers/SearchMappingReducer';
import { isContent } from '../../Utils/Response/Response';
import {
  postCompanyProfileParent,
  postCompanyTitle,
  postCountry,
  postDistrict,
  postIsland,
  postPersonTitle,
  postPostalCode,
  postPrincipal,
  postProductBrand,
  postProductBrandPrincipal,
  postProductCategory,
  postProductGroup,
  postProductGroupPrincipal,
  postProductKeyAccount,
  postProvince,
  postSubDistrict,
  postUrbanVillage,
} from '../Api/SearchMappingAPI';

export async function fetchCountryList(authorization, prevList, dispatch, data, notify) {
  await postCountry(
    authorization,
    data,
    notify,
    dispatch,
    res => {
      if (isContent(res) !== null)
        dispatch(searchMappingActions.setCountry(prevList.concat(isContent(res))));
    },
    () => dispatch(searchMappingActions.setCountry([]))
  );
}

export async function fetchDistrictList(authorization, prevList, dispatch, data, notify) {
  await postDistrict(
    authorization,
    data,
    notify,
    dispatch,
    res => {
      if (isContent(res) !== null)
        dispatch(searchMappingActions.setDistrict(prevList.concat(isContent(res))));
    },
    () => dispatch(searchMappingActions.setDistrict([]))
  );
}
export async function fetchIslandList(authorization, prevList, dispatch, data, notify) {
  await postIsland(
    authorization,
    data,
    notify,
    dispatch,
    res => {
      if (isContent(res) !== null)
        dispatch(searchMappingActions.setIsland(prevList.concat(isContent(res))));
    },
    () => dispatch(searchMappingActions.setIsland([]))
  );
}
export async function fetchProvinceList(authorization, prevList, dispatch, data, notify) {
  await postProvince(
    authorization,
    data,
    notify,
    dispatch,
    res => {
      if (isContent(res) !== null)
        dispatch(searchMappingActions.setProvince(prevList.concat(isContent(res))));
    },
    () => dispatch(searchMappingActions.setProvince([]))
  );
}
export async function fetchSubDistrictList(authorization, prevList, dispatch, data, notify) {
  await postSubDistrict(
    authorization,
    data,
    notify,
    dispatch,
    res => {
      if (isContent(res) !== null)
        dispatch(searchMappingActions.setSubDistrict(prevList.concat(isContent(res))));
    },
    () => dispatch(searchMappingActions.setSubDistrict([]))
  );
}
export async function fetchPostalCodeList(authorization, prevList, dispatch, data, notify) {
  await postPostalCode(
    authorization,
    data,
    notify,
    dispatch,
    res => {
      if (isContent(res) !== null)
        dispatch(searchMappingActions.setPostalCode(prevList.concat(isContent(res))));
    },
    () => dispatch(searchMappingActions.setPostalCode([]))
  );
}
export async function fetchUrbanVillageList(authorization, prevList, dispatch, data, notify) {
  await postUrbanVillage(
    authorization,
    data,
    notify,
    dispatch,
    res => {
      if (isContent(res) !== null)
        dispatch(searchMappingActions.setUrbanVillage(prevList.concat(isContent(res))));
    },
    () => dispatch(searchMappingActions.setUrbanVillage([]))
  );
}
export async function fetchPrincipal(authorization, prevList, dispatch, data, notify) {
  await postPrincipal(
    authorization,
    data,
    notify,
    dispatch,
    res => {
      if (isContent(res) !== null)
        dispatch(searchMappingActions.setPrincipal(prevList.concat(isContent(res))));
    },
    () => dispatch(searchMappingActions.setPrincipal([]))
  );
}
export async function fetchPersonTitle(authorization, prevList, dispatch, data, notify) {
  await postPersonTitle(
    authorization,
    data,
    notify,
    dispatch,
    res => {
      if (isContent(res) !== null)
        dispatch(searchMappingActions.setPersonTitle(prevList.concat(isContent(res))));
    },
    () => dispatch(searchMappingActions.setPersonTitle([]))
  );
}
export async function fetchCompanyTitle(authorization, prevList, dispatch, data, notify) {
  await postCompanyTitle(
    authorization,
    data,
    notify,
    dispatch,
    res => {
      if (isContent(res) !== null)
        dispatch(searchMappingActions.setCompanyTitle(prevList.concat(isContent(res))));
    },
    () => dispatch(searchMappingActions.setCompanyTitle([]))
  );
}
export async function fetchProductBrand(authorization, prevList, dispatch, data, notify) {
  await postProductBrand(
    authorization,
    data,
    notify,
    dispatch,
    res => {
      if (isContent(res) !== null)
        dispatch(searchMappingActions.setProductBrand(prevList.concat(isContent(res))));
    },
    () => dispatch(searchMappingActions.setProductBrand([]))
  );
}
export async function fetchProductBrandPrincipal(authorization, prevList, dispatch, data, notify) {
  await postProductBrandPrincipal(
    authorization,
    data,
    notify,
    dispatch,
    res => {
      if (isContent(res) !== null)
      {  const result=isContent(res).length>0?isContent(res).map(data=>{
          return {principal_product_brand_id: data.principal_product_brand_id,
            principal_product_brand_code: data.code,
            principal_product_brand_name: data.name}
        }):[]
        dispatch(searchMappingActions.setProductBrand(prevList.concat(result)));
     ;}
      
    },
    () => dispatch(searchMappingActions.setProductBrand([]))
  );
}
export async function fetchProductGroupPrincipal(authorization, prevList, dispatch, data, notify) {
  await postProductGroupPrincipal(
    authorization,
    data,
    notify,
    dispatch,
    res => {
      if (isContent(res) !== null)
      {  const result=isContent(res).length>0?isContent(res).map(data=>{
          return {principal_product_group_id: data.principal_product_group_id,
            principal_product_group_code: data.code,
            principal_product_group_name: data.name}
        }):[]
        dispatch(searchMappingActions.setProductGroup(prevList.concat(result)));
     ;}
      
    },
    () => dispatch(searchMappingActions.setProductGroup([]))
  );
}
export async function fetchProductGroupPrincipalParent(authorization, prevList, dispatch, data, notify) {
  await postProductGroupPrincipal(
    authorization,
    data,
    notify,
    dispatch,
    res => {
      if (isContent(res) !== null)
      {  const result=isContent(res).length>0?isContent(res).map(data=>{
          return {
            parent_id: Number(data.principal_product_group_id),
            parent_code: data.code,
            parent_name: data.name}
        }):[]
        dispatch(searchMappingActions.setProductGroup(prevList.concat(result)));
     ;}
      
    },
    () => dispatch(searchMappingActions.setProductGroup([]))
  );
}
export async function fetchProductGroup(authorization, prevList, dispatch, data, notify) {
  await postProductGroup(
    authorization,
    data,
    notify,
    dispatch,
    res => {
      if (isContent(res) !== null)
        dispatch(searchMappingActions.setProductGroup(prevList.concat(isContent(res))));
    },
    () => dispatch(searchMappingActions.setProductGroup([]))
  );
}
export async function fetchProductKeyAccount(authorization, prevList, dispatch, data, notify) {
  await postProductKeyAccount(
    authorization,
    data,
    notify,
    dispatch,
    res => {
      if (isContent(res) !== null)
        dispatch(searchMappingActions.setProductKeyAccount(prevList.concat(isContent(res))));
    },
    () => dispatch(searchMappingActions.setProductKeyAccount([]))
  );
}
export async function fetchProductCategory(authorization, prevList, dispatch, data, notify) {
  await postProductCategory(
    authorization,
    data,
    notify,
    dispatch,
    res => {
      if (isContent(res) !== null)
        dispatch(searchMappingActions.setProductCategory(prevList.concat(isContent(res))));
    },
    () => dispatch(searchMappingActions.setCompanyTitle([]))
  );
}
export const recreateList = (res, idKey,nameKey,codeKey) => {
  let data = [];
  let content = isContent(res);
  for (var i = 0; i < content.length; i++) {
    data.push({
      parent_id: Number(content[i][idKey]),
      parent_name: content[i][nameKey],
      parent_code: content[i][codeKey],
    });
  }
  return data;
}; 
export async function fetchProductKeyAccountParent(
  authorization,
  prevList,
  dispatch,
  data,
  notify
) {
  postProductKeyAccount(
    authorization,
    data,
    notify,
    dispatch,
    res => {
      if (isContent(res) !== null){
        dispatch(
          searchMappingActions.setProductKeyAccount(
            prevList.concat(recreateList(res, "product_key_account_id", "product_key_account_name", "product_key_account_code"))
          )
        )
      }
    },
    () => dispatch(searchMappingActions.setProductKeyAccount([]))
  );
}
export async function fetchProductGroupParent(authorization, prevList, dispatch, data, notify) {
  postProductGroup(
    authorization,
    data,
    notify,
    dispatch,
    res => {
      if (isContent(res) !== null)
        dispatch(
          searchMappingActions.setProductGroup(
            prevList.concat(recreateList(res, "product_group_id", "product_group_name", "product_group_code"))
          )
        );
    },
    () => dispatch(searchMappingActions.setProductGroup([]))
  );
}
export async function fetchCompanyProfileParent(authorization, prevList, dispatch, data, notify) {
  postCompanyProfileParent(
    authorization,
    data,
    notify,
    dispatch,
    res => {
      if (isContent(res) !== null)
        dispatch(
          searchMappingActions.setCompanyParent(
            prevList.concat(recreateListCompanyProfileParent(res, "company_profile_id", "company_profile_name", "company_profile_npwp"))
          )
        );
    },
    () => dispatch(searchMappingActions.setProductGroup([]))
  );
}

export const recreateListCompanyProfileParent = (res, idKey,nameKey,codeKey) => {
  let data = [];
  let content = isContent(res);
  for (var i = 0; i < content.length; i++) {
    data.push({
      parent_company_id: content[i][idKey],
      parent_company_name: content[i][nameKey],
      parent_company_npwp: content[i][codeKey],
    });
  }
  return data;
};
export const recreateListProductGroupProduct = (res, idKey,nameKey,codeKey) => {
  let data = [];
  let content = isContent(res);
  for (var i = 0; i < content.length; i++) {
    data.push({
      product_group_hierarchy_id: content[i][idKey],
      product_group_hierarchy_name: content[i][nameKey],
      product_group_hierarchy_code: content[i][codeKey],
    });
  }
  return data;
};
export async function fetchProductGroupProduct(authorization, prevList, dispatch, data, notify) {
  postProductGroup(
    authorization,
    data,
    notify,
    dispatch,
    res => {
      if (isContent(res) !== null)
        dispatch(
          searchMappingActions.setProductGroup(
            prevList.concat(recreateListProductGroupProduct(res, "product_group_id", "product_group_name", "product_group_code"))
          )
        );
    },
    () => dispatch(searchMappingActions.setProductGroup([]))
  );
}
export const personProfileInitiateDDS = (
  personTitle,
  country,
  postalCode,
  province,
  district,
  subDistrict,
  urbanVillage,
  island,
  validToken,
  dispatch,
  notify
) => {
  if (personTitle.length === 0)
    fetchPersonTitle(validToken(), [], dispatch, defaultLimitSearch, notify);
  if (country.length === 0)
    fetchCountryList(validToken(), [], dispatch, defaultLimitSearch, notify);
  if (postalCode.length === 0)
    fetchPostalCodeList(validToken(), [], dispatch, defaultLimitSearch, notify);
  if (province.length === 0)
    fetchProvinceList(validToken(), [], dispatch, defaultLimitSearch, notify);
  if (district.length === 0)
    fetchDistrictList(validToken(), [], dispatch, defaultLimitSearch, notify);
  if (subDistrict.length === 0)
    fetchSubDistrictList(validToken(), [], dispatch, defaultLimitSearch, notify);
  if (urbanVillage.length === 0)
    fetchUrbanVillageList(validToken(), [], dispatch, defaultLimitSearch, notify);
  if (island.length === 0) fetchIslandList(validToken(), [], dispatch, defaultLimitSearch, notify);
};

export const companyProfileInitiateDDS = (
  companyTitle,
  country,
  postalCode,
  province,
  district,
  subDistrict,
  urbanVillage,
  island,
  validToken,
  dispatch,
  notify
) => {
  if (companyTitle.length === 0)
    fetchCompanyTitle(validToken(), [], dispatch, defaultLimitSearch, notify);
  if (country.length === 0)
    fetchCountryList(validToken(), [], dispatch, defaultLimitSearch, notify);
  if (postalCode.length === 0)
    fetchPostalCodeList(validToken(), [], dispatch, defaultLimitSearch, notify);
  if (province.length === 0)
    fetchProvinceList(validToken(), [], dispatch, defaultLimitSearch, notify);
  if (district.length === 0)
    fetchDistrictList(validToken(), [], dispatch, defaultLimitSearch, notify);
  if (subDistrict.length === 0)
    fetchSubDistrictList(validToken(), [], dispatch, defaultLimitSearch, notify);
  if (urbanVillage.length === 0)
    fetchUrbanVillageList(validToken(), [], dispatch, defaultLimitSearch, notify);
  if (island.length === 0) fetchIslandList(validToken(), [], dispatch, defaultLimitSearch, notify);
};
export const principalInitiateDDS = (
  principal,
  validToken,
  dispatch,
  notify,
  customList,
  customGetList
) => {
  if (principal.length === 0)
    fetchPrincipal(validToken(), [], dispatch, defaultLimitSearch, notify);
  if (customList !== undefined && customGetList !== undefined) {
    if (customList.length === 0)
      customGetList(validToken(), [], dispatch, defaultLimitSearch, notify);
  }
};
