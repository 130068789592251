import React from "react";

import { Button, Div, Heading, Row, Span } from "nexcomponent-ui";
import { useLocation } from "react-router-dom";

import CheckBoxMapping from "../../../../../Components/Molecules/CheckBox/CheckBoxMapping/CheckBoxMapping";
import { SIZESET } from "../../../../../Enum/Props/SizeSet";
import { BTN_STYLE_TYPE } from "../../../../../Enum/Style";
import { utilsActions } from "../../../../../Store/Reducers/UtilsReducer";
import { rejectNavigate } from "../../../../../Utils/Other/RejectNavigate";
import { searchNavigate } from "../../../../../Utils/Other/SearchNavigate";
import style from "./MappingFormHeader.module.css";
import useQueryRoute from "../../../../../Hooks/useQueryRoute";

function MappingFormHeader(props) {
  const location = useLocation();

  const { principal } = useQueryRoute();

  const rejectHandle = (evt) => {
    evt.preventDefault();
    props.navigate(`${principal ? "/principal" : ""}${rejectNavigate(props.mappingCode)}`, {
      state: { previous: location.pathname },
    });
  };

  const clear = async (e) => {
    e.preventDefault();
    props.dispatch(utilsActions.setModalType("Clear"));
    props.dispatch(utilsActions.setModal(true));
  };
  const search = async (e) => {
    e.preventDefault();
    await props.navigate(
      `${principal ? "/principal" : ""}${searchNavigate(props.mappingCode, props.dispatch)}`
    );
    props.dispatch(utilsActions.setLoading(true));
  };
  return (
    <Div className={style.MappingHeader}>
      <Div className={style.CheckBox}>
        <Div className={style.HeaderItem}>
          <CheckBoxMapping
            size={SIZESET.SM}
            label={props.t("COMMON:SELECT_ALL")}
            name={props.name}
            onCheck={props.onCheck}
            checked={props.checked}
          />
        </Div>
      </Div>
      <Div className={style.HeaderRight}>
        <Div className={style.NewData}>
          <Div className={style.HeaderItem}>
            <Heading size={6} value={props.t("COMMON:NEW")} />
            <Button
              size={SIZESET.XS}
              className={style.ButtonReject}
              label={props.t("COMMON:REJECTDATA")}
              onClick={rejectHandle}
            />
          </Div>
        </Div>
        <Div className={style.Existing}>
          <Div className={style.HeaderItem}>
            <Heading size={6} value={props.t("COMMON:EXISTING")} />
            <Div className={style.ButtonGroup}>
              <Button
                typeStyle={BTN_STYLE_TYPE.SECONDARY}
                size={SIZESET.XS}
                label={props.t("COMMON:CLEAR")}
                onClick={clear}
              />
              <Button
                className={style.ButtonSearch}
                size={SIZESET.XS}
                label={props.t("COMMON:ADVANCE_SEARCH")}
                onClick={search}
              />
            </Div>
          </Div>
          <Row>
            {props.lastUpdateBy === undefined || props.lastUpdateBy === null ? (
              ""
            ) : (
              <>
                <Span value={props.t("COMMON:LAST_UPDATE")} className={style.Info} />
                <Span
                  value={`${props.t("COMMON:BY")} ${
                    props.lastUpdateBy !== null &&
                    props.lastUpdateBy !== undefined &&
                    props.lastUpdateBy !== ""
                      ? props.lastUpdateBy
                      : "-"
                  } `}
                  className={style.InfoAdmin}
                />
                <Span value={`${props.t("COMMON:ON")} `} className={style.InfoOn} />
                <Span value={"."} className={style.InfoNone} />
                <Span value={props.lastUpdate} className={style.InfoDate} />
              </>
            )}
          </Row>
        </Div>
      </Div>
    </Div>
  );
}

export default MappingFormHeader;
