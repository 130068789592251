import React from "react";

import { Button, Div } from "nexcomponent-ui";
import { useSelector } from "react-redux";

import { broadcastBreadcrumb } from "../../../Enum/Breadcrumb/BroadcastBreadcrumb";
import { BUTTON, PAGE } from "../../../Enum/IdSet";
import { ROUTER_CONSTANT } from "../../../Enum/RouterConstant/RouterConstant";
import { getRejectAdminList } from "../../../Fetch/Api/RejectAPI";
import {
  fetchDataInitiateReject,
  fetchDataRejectList,
  fetchRejectExport,
} from "../../../Fetch/FetchData/RejectData";
import { rejectsActions } from "../../../Store/Reducers/RejectReducer";
import { keyFilterReject } from "../../../Utils/Filter/KeyFilter";
import FilterForm from "../../Organisms/Form/SearchForm/FilterForm/FilterForm";
import HeaderPage from "../../Organisms/Header/HeaderPage/HeaderPage";
import RejectList from "../../Organisms/TableList/RejectList/RejectList";

const RejectPage = (props) => {
  const filter = useSelector((state) => state.reject.filter);
  const admin = useSelector((state) => state.reject.admin);
  const initiateReject = useSelector((state) => state.reject.initiate);
  const order = useSelector((state) => state.utils.sortDateAsc);

  const onExport = async () => {
    const data = await fetchRejectExport(
      props.validToken(),
      order,
      filter,
      props.notify,
      props.dispatch,
      props.t
    );
    return data;
  };

  return (
    <Div id={PAGE.REJECT}>
      <HeaderPage
        title={props.t("COMMON:REJECT")}
        listBreadcrumb={broadcastBreadcrumb(props.t)}
        defaultBreadcrumb={props.t("COMMON:REJECT")}
        toTitlePageto={ROUTER_CONSTANT.REJECT}
        customButton={
          <Button id={BUTTON.APPLY} label={props.t("COMMON:EXPORT")} onClick={() => onExport()} />
        }
      />

      <RejectList
        customHeader={
          <FilterForm
            validToken={props.validToken}
            dispatch={props.dispatch}
            keyFilter={(filter) => keyFilterReject(filter)}
            t={props.t}
            notify={props.notify}
            filter={filter}
            admin={admin}
            action={rejectsActions}
            initiate={initiateReject}
            setInitiate={fetchDataInitiateReject}
            list={fetchDataRejectList}
            getAdmin={getRejectAdminList}
          />
        }
        t={props.t}
        validToken={props.validToken}
        dispatch={props.dispatch}
        navigate={props.navigate}
        notify={props.notify}
      />
    </Div>
  );
};

export default RejectPage;
