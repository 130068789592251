export const searchProductBrandPrincipalColumn = (t) => [
  {
    name: t("PRODUCT_BRAND:FIELD.ID"),
    component: true,
    selector: row=>row.principal_product_brand_id?row.principal_product_brand_id:"-",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.PRINCIPAL_CODE"),
    component: true,
    selector: row=>row.principal_code?row.principal_code:"-",
    width: "200px",
  },
  {
    name: t("COMMON:FIELD.PRINCIPAL_NAME"),
    component: true,
    selector: row=>row.principal_name?row.principal_name:"-",
    width: "200px",
  },
  {
    name: t("COMMON:FIELD.PRINCIPAL_ID"),
    component: true,
    selector: row=>row.principal_id?row.principal_id:"-",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.CODE"),
    component: true,
    selector: row=>row.code?row.code:"-",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.NAME"),
    component: true,
    selector: row=>row.name?row.name:"-",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.OWNED_START_DATE"),
    component: true,
    key: "created_at",
    selector: row => {
      if(row.owned_start_date===""||row.owned_start_date===null||row.owned_start_date===undefined||
      row.owned_start_date==="0001-01-01"){
      return "-";

      }
      return row.owned_start_date;
    },
    width: "100px",
  },
];
