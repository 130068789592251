import { dashboardAction } from '../../Store/Reducers/DashboardReducer';
import { mappingActions } from '../../Store/Reducers/MappingReducer';
import { utilsActions } from '../../Store/Reducers/UtilsReducer';
import { isContent } from '../../Utils/Response/Response';
import {
  getInitiateDashboard,
  getMappingList,
} from '../Api/DashBoardAPI';

const initiateDasboardList = (res, dispatch) => {
  dispatch(dashboardAction.setInitiate(isContent(res)));
  dispatch(dashboardAction.setTotalRow(isContent(res).count_data));
  dispatch(utilsActions.setLoading(false));
};
export const setDefaultPagination = dispatch => {
  dispatch(utilsActions.setCurrentPage(1));
  dispatch(utilsActions.setRowLimit(10));
};
export const reloadList=(res,dispatch,countData)=>{
    dispatch(mappingActions.setList(isContent(res)));
    dispatch(dashboardAction.setTotalRow(countData));
    dispatch(dashboardAction.setTotalRow(countData))
}
export const reloadSearchList=(res,dispatch,countData)=>{
  dispatch(mappingActions.setSearchList(isContent(res)));
        dispatch(dashboardAction.setTotalRow(countData));
}
const setListDasboard = (res, dispatch) => {
  if (res.code !== "ECONNABORTED") {
    dispatch(dashboardAction.setMappingList(res.data.nexsoft.payload.data.content));
  } else {
    dispatch(dashboardAction.setMappingList(null));
  }
};
export const fetchDataInitiateDashboard = (token, dispatch, notify) => {
  getInitiateDashboard(token, notify, dispatch, response =>
    initiateDasboardList(response, dispatch)
  );
};

export const fetchDataMappingList = async (
  totalRow,
  countData,
  token,
  currentPage,
  rowLimit,
  sortDate,
  dispatch,
  notify
) => {
  if (totalRow === countData) {
    await getMappingList(token, currentPage, rowLimit, sortDate, notify, dispatch, response =>
      setListDasboard(response, dispatch)
    );
  } else {
    await getInitiateDashboard(token, notify, dispatch, response =>
      initiateDasboardList(response, dispatch)
    );
    setDefaultPagination(dispatch);
  }
};
