import _ from 'lodash';

import { mappingActions } from '../Reducers/MappingReducer';
import { dataMapping } from './MappingAction';

export const selectWithTwoLevel = async (
  name,
  value,
  param,
  paramType,
  option,
  data,
  key,
  checked,
  submitMapping,
  dispatch
) => {
  let tempForms = await data.map(datas => (datas.key === name ? {...datas, new: value} : datas));
  const resultMatch = values => {
    if (paramType === "Number") {
      return Number(values);
    } else {
      return values;
    }
  };
  const realated = await _.find(option, [name, resultMatch(value)]);
  let tempForms2 = await tempForms.map(datas =>
    datas.key === param ? {...datas, new: realated[param]} : datas
  );
  dispatch(mappingActions.setMappingForm(tempForms2));
  dataMapping(checked, key, tempForms2, submitMapping, "", dispatch);
};
export const selectWithThreeLevel = (
  name,
  value,
  param,
  param2,
  paramType,
  option,
  data,
  key,
  checked,
  submitMapping,
  dispatch
) => {
  let tempForms = data.map(datas => (datas.key === name ? {...datas, new: value} : datas));
  const resultMatch = values => {
    if (paramType === "number") {
      return Number(values);
    } else {
      return values;
    }
  };
  const realated = _.find(option, [name, resultMatch(value)]);
  let tempForms2 = tempForms.map(datas =>
    datas.key === param ? {...datas, new: realated[param]} : datas
  );
  let tempForms3 = tempForms2.map(datas => {
    if (param2 === null || param2 === undefined) {
      return tempForms2;
    } else {
      return datas.key === param2 ? {...datas, new: realated[param2]} : datas;
    }
  });
  dispatch(mappingActions.setMappingForm(tempForms3));
  dataMapping(checked, key, tempForms3, submitMapping, dispatch);
};
export const selectLevel = async (
  name,
  value,
  param,
  param2,
  paramType,
  option,
  data,
  checked,
  submitMapping,
  dispatch,
  reset
) => {
  let tempForms = await data.map(datas => (datas.key === name ? {...datas, new: value} : datas));
  const resultMatch = values => {
    if (paramType === "number") {
      return Number(values);
    } else {
      return values;
    }
  };
  const realated = await _.find(option, [name, resultMatch(value)]);
  let tempForms2 = () =>
    reset
      ? tempForms.map(datas => (datas.key === param ? {...datas, new: null} : datas))
      : tempForms.map(datas => (datas.key === param ? {...datas, new: realated[param]} : datas));
  let tempForms3 = await tempForms2().map(datas => {
    if (param2 === null || param2 === undefined) {
      return tempForms2;
    } else {
      return reset
        ? datas.key === param2
          ? {...datas, new: null}
          : datas
        : datas.key === param2
        ? {...datas, new: realated[param2]}
        : datas;
    }
  });
  let submited = reset
    ? {[name]: null, [param]: null, [param2]: null}
    : {[name]: resultMatch(value), [param]: realated[param], [param2]: realated[param2]};
  if (param2 !== undefined) {
    setMappingFormSelect(checked, submited, tempForms3, submitMapping, dispatch);
  } else {
    setMappingFormSelect(checked, submited, tempForms2(), submitMapping, dispatch);
  }
};
const setMappingFormSelect = async (checked, submited, tempForms, submitMapping, dispatch) => {
  dispatch(mappingActions.setMappingForm(tempForms));
  if (checked) {
    dispatch(mappingActions.setSubmitMapping({...submitMapping, ...submited}));
  }
};
