import React, {
  useEffect,
  useState,
} from 'react';

import {
  ListEmpty,
  ModalDialog,
  nexIcon,
  useNotification,
} from 'nexcomponent-ui';
import { useSelector } from 'react-redux';

import SpinnerLoad
  from '../../../../Components/Molecules/Loader/SpinnerLoad/SpinnerLoad';
import { broadcastColumn } from '../../../../Enum/Column/BroadcastColumn';
import { TABLE } from '../../../../Enum/IdSet';
import {
  fetchDataBroadcastDetail,
  fetchDataBroadcastList,
} from '../../../../Fetch/FetchData/BroadcastData';
import {
  closeModal,
  openModal,
} from '../../../../Store/Action/ModalAction';
import { utilsActions } from '../../../../Store/Reducers/UtilsReducer';
import {
  initiateTotalRowMappingList,
} from '../../../../Store/StateGroup/DashboardStateGroup';
import {
  currentPageLoadingRowLimitSortDate,
} from '../../../../Store/StateGroup/UtilsStateGroup';
import { loadingData } from '../../../../Utils/Handler/LoadingData';
import {
  detailByMappingTypeData,
} from '../../Box/BoxDetail/Detail/DetailByMappingTypeData';
import { TitleModalDetail } from '../../Box/BoxDetail/TitleModalDetail';
import TableList from '../TableList';
import style from './BroadcastList.module.css';

function BroadcastList(props) {
  const [currentPage, loading, rowLimit, sortDate] = useSelector(
    currentPageLoadingRowLimitSortDate
  );
  const [detail, setDetail] = useState(null);

  const [, totalRow] = useSelector(initiateTotalRowMappingList);
  const initiateBroadCast = useSelector(state => state.broadcast.initiate);
  const broadcastList = useSelector(state => state.broadcast.broadcastList);
  const broadcastDetail = useSelector(state => state.broadcast.broadcastDetail);
  const modalType = useSelector(state => state.utils.modalType);
  const modal = useSelector(state => state.utils.modal);
  const filter = useSelector(state => state.broadcast.filter);

  const notify = useNotification();
  const handleCancel = () => {
    closeModal(props.dispatch);
  };
  const [sort] = sortDate.split(" ");
  useEffect(() => {
    if (loading) {
    fetchDataBroadcastList(
      totalRow,
      initiateBroadCast.count_data,
      props.validToken(),
      currentPage,
      rowLimit,
      sortDate,
      filter,
      props.dispatch,
      props.notify
    );
    }
    if (sort === "updated_at") {
      props.dispatch(utilsActions.setSortDateAsc("created_at asc"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading
  ]);
  loadingData(loading, props);
  const openDetail = data => {
    props.dispatch(utilsActions.setModalType("broadcast"));
    openModal(props.dispatch);
    setDetail(data);
    fetchDataBroadcastDetail(props.validToken(), data.id, props.dispatch, notify);
  };
  return (
    <>
      <ModalDialog
        classBody={style.ModalBody}
        title={TitleModalDetail(detail === null ? "" : detail.mapping_data_type, props.t)}
        content={
          broadcastDetail === null
            ? props.t("COMMON:DETAIL_DATA_UNAVAILABLE")
            : detailByMappingTypeData(
                modalType,
                detail === null ? "" : detail.mapping_data_type,
                broadcastDetail,
                props.t
              )
        }
        onClose={handleCancel}
        show={modal}
        closeIcon={<nexIcon.NcClose color="#9b3789" />}
      />

      <TableList
        customHeader={props.customHeader}
        id={TABLE.BROADCAST_LIST}
        sort={true}
        columns={broadcastColumn(props.t)}
        data={loading ? null : broadcastList}
        rowLink={true}
        rowClick={data => openDetail(data)}
        limitation={initiateBroadCast.valid_limit}
        totalRow={initiateBroadCast.count_data}
        empty={
          !loading ? (
            <ListEmpty value={props.t("COMMON:LIST_EMPTY")} />
          ) : (
            <ListEmpty value={<SpinnerLoad t={props.t} />} />
          )
        }
        t={props.t}
        footer={false}
        dispatch={props.dispatch}
      />
    </>
  );
}

export default BroadcastList;
