import React, {
  useEffect,
  useState,
} from 'react';

import {
  Div,
  Input,
  Li,
  Ul,
} from 'nexcomponent-ui';

import style from './FieldMultiExist.module.css';

const FieldMultiExist = props => {
  const twice_json = JSON.stringify(props.value);
  const json = JSON.parse(twice_json);
  const [obj, setObj] = useState(undefined);
  useEffect(() => {
    if (obj === undefined) {
      try {
        const res = JSON.parse(json.replaceAll(/\\/g, ""));
        setObj(res);
        return res;
      } catch (error) {
        setObj(null);
      }
    }
  }, [json, obj]);

  return (
    <Div className={style.Box}>
      <Ul className={style.FormUl}>
        {obj !== undefined && obj !== null ? (
          <>
            {obj.map((item, index) => {
              return item.key !== undefined ? (
                <Li className={style.FormLi} key={index}>
                  <Input
                    name={"key"}
                    className={style.InputKey}
                    value={item.key}
                    onChange={() => {}}
                    disabled={true}
                  />
                  <Input
                    name={"value"}
                    className={style.InputValue}
                    value={item.value}
                    onChange={() => {}}
                    disabled={true}
                  />
                </Li>
              ) : (
                ""
              );
            })}
          </>
        ) : (
          <Li className={style.FormLi}>
            <Input
              name={"key"}
              className={style.InputKey}
              value={undefined}
              onChange={() => {}}
              disabled={true}
            />
            <Input
              name={"value"}
              className={style.InputValue}
              value={undefined}
              onChange={() => {}}
              disabled={true}
            />
          </Li>
        )}
      </Ul>
      <Ul className={style.FormUl}></Ul>
    </Div>
  );
};

export default FieldMultiExist;
