import AuditEnglish from '../Locale/Admin/Audit/en.json';
import AuditIndonesian from '../Locale/Admin/Audit/id.json';
import BankEnglish from '../Locale/Admin/Bank/en.json';
import BankIndonesian from '../Locale/Admin/Bank/id.json';
import BrandEnglish from '../Locale/Admin/Brand/en.json';
import BrandIndonesian from '../Locale/Admin/Brand/id.json';
import BrandOwnerEnglish from '../Locale/Admin/BrandOwner/en.json';
import BrandOwnerIndonesian from '../Locale/Admin/BrandOwner/id.json';
import BroadcastEnglish from '../Locale/Admin/Broadcast/en.json';
import BroadcastIndonesian from '../Locale/Admin/Broadcast/id.json';
import CompanyProfileEnglish from '../Locale/Admin/CompanyProfile/en.json';
import CompanyProfileIndonesian from '../Locale/Admin/CompanyProfile/id.json';
import CompanyTitleEnglish from '../Locale/Admin/CompanyTitle/en.json';
import CompanyTitleIndonesian from '../Locale/Admin/CompanyTitle/id.json';
import CompanyUserDefinedEnglish
  from '../Locale/Admin/CompanyUserDefined/en.json';
import CompanyUserDefinedIndonesian
  from '../Locale/Admin/CompanyUserDefined/id.json';
import ContactPersonEnglish from '../Locale/Admin/ContactPerson/en.json';
import ContactPersonIndonesian from '../Locale/Admin/ContactPerson/id.json';
import CountryEnglish from '../Locale/Admin/Country/en.json';
import CountryIndonesian from '../Locale/Admin/Country/id.json';
import DataGroupEnglish from '../Locale/Admin/DataGroup/en.json';
import DataGroupIndonesian from '../Locale/Admin/DataGroup/id.json';
import DataScopeEnglish from '../Locale/Admin/DataScope/en.json';
import DataScopeIndonesian from '../Locale/Admin/DataScope/id.json';
import DistrictEnglish from '../Locale/Admin/District/en.json';
import DistrictIndonesian from '../Locale/Admin/District/id.json';
import DivisionEnglish from '../Locale/Admin/Division/en.json';
import DivisionIndonesian from '../Locale/Admin/Division/id.json';
import GroupOfDistributorEnglish
  from '../Locale/Admin/GroupOfDistributor/en.json';
import GroupOfDistributorIndonesian
  from '../Locale/Admin/GroupOfDistributor/id.json';
import ImportEnglish from '../Locale/Admin/Import/en.json';
import ImportIndonesian from '../Locale/Admin/Import/id.json';
import IslandEnglish from '../Locale/Admin/Island/en.json';
import IslandIndonesian from '../Locale/Admin/Island/id.json';
import JobProcessEnglish from '../Locale/Admin/JobProcess/en.json';
import JobProcessIndonesian from '../Locale/Admin/JobProcess/id.json';
import ParameterEnglish from '../Locale/Admin/Parameter/en.json';
import ParameterIndonesian from '../Locale/Admin/Parameter/id.json';
import ParameterValueEnglish from '../Locale/Admin/ParameterValue/en.json';
import ParameterValueIndonesian from '../Locale/Admin/ParameterValue/id.json';
import PersonProfileEnglish from '../Locale/Admin/PersonProfile/en.json';
import PersonProfileIndonesian from '../Locale/Admin/PersonProfile/id.json';
import PersonTitleEnglish from '../Locale/Admin/PersonTitle/en.json';
import PersonTitleIndonesian from '../Locale/Admin/PersonTitle/id.json';
import PositionEnglish from '../Locale/Admin/Position/en.json';
import PositionIndonesian from '../Locale/Admin/Position/id.json';
import PostalCodeEnglish from '../Locale/Admin/PostalCode/en.json';
import PostalCodeIndonesian from '../Locale/Admin/PostalCode/id.json';
import PrincipalEnglish from '../Locale/Admin/Principal/en.json';
import PrincipalIndonesian from '../Locale/Admin/Principal/id.json';
import PrincipalProductUserDefinedEnglish
  from '../Locale/Admin/PrincipalProductUserDefined/en.json';
import PrincipalProductUserDefinedIndonesian
  from '../Locale/Admin/PrincipalProductUserDefined/id.json';
import ProductEnglish from '../Locale/Admin/Product/en.json';
import ProductIndonesian from '../Locale/Admin/Product/id.json';
import ProductBrandEnglish from '../Locale/Admin/ProductBrand/en.json';
import ProductBrandIndonesian from '../Locale/Admin/ProductBrand/id.json';
import ProductCategoryEnglish from '../Locale/Admin/ProductCategory/en.json';
import ProductCategoryIndonesian from '../Locale/Admin/ProductCategory/id.json';
import ProductGroupEnglish from '../Locale/Admin/ProductGroup/en.json';
import ProductGroupIndonesian from '../Locale/Admin/ProductGroup/id.json';
import ProductGroupHierarchyEnglish
  from '../Locale/Admin/ProductGroupHierarchy/en.json';
import ProductGroupHierarchyIndonesian
  from '../Locale/Admin/ProductGroupHierarchy/id.json';
import ProductHistoryEnglish from '../Locale/Admin/ProductHistory/en.json';
import ProductHistoryIndonesian from '../Locale/Admin/ProductHistory/id.json';
import ProductKeyAccountEnglish
  from '../Locale/Admin/ProductKeyAccount/en.json';
import ProductKeyAccountIndonesian
  from '../Locale/Admin/ProductKeyAccount/id.json';
import ProductUserDefinedEnglish
  from '../Locale/Admin/ProductUserDefined/en.json';
import ProductUserDefinedIndonesian
  from '../Locale/Admin/ProductUserDefined/id.json';
import ProvinceEnglish from '../Locale/Admin/Province/en.json';
import ProvinceIndonesian from '../Locale/Admin/Province/id.json';
import RoleEnglish from '../Locale/Admin/Role/en.json';
import RoleIndonesian from '../Locale/Admin/Role/id.json';
import SubDistrictEnglish from '../Locale/Admin/SubDistrict/en.json';
import SubDistrictIndonesian from '../Locale/Admin/SubDistrict/id.json';
import SyncEnglish from '../Locale/Admin/Sync/en.json';
import SyncIndonesian from '../Locale/Admin/Sync/id.json';
import UrbanVillageEnglish from '../Locale/Admin/UrbanVillage/en.json';
import UrbanVillageIndonesian from '../Locale/Admin/UrbanVillage/id.json';
import UserEnglish from '../Locale/Admin/User/en.json';
import UserIndonesian from '../Locale/Admin/User/id.json';
import VendorChannelEnglish from '../Locale/Admin/VendorChannel/en.json';
import VendorChannelIndonesian from '../Locale/Admin/VendorChannel/id.json';
import CommonEnglish from '../Locale/Common/en.json';
import CommonIndonesian from '../Locale/Common/id.json';
import NotificationEnglish from '../Locale/Admin/Notification/en.json';
import NotificationIndonesian from '../Locale/Admin/Notification/id.json';

export const resourceLanguage = {
  en: {
    COMMON: CommonEnglish,
    BROADCAST: BroadcastIndonesian,
    BANK: BankEnglish,
    BRAND: BrandEnglish,
    BRAND_OWNER: BrandOwnerEnglish,
    COMPANY_PROFILE: CompanyProfileEnglish,
    COMPANY_TITLE: CompanyTitleEnglish,
    CONTACT_PERSON: ContactPersonEnglish,
    COUNTRY: CountryEnglish,
    DISTRICT: DistrictEnglish,
    DIVISION: DivisionEnglish,
    GROUP_OF_DISTRIBUTOR: GroupOfDistributorEnglish,
    ISLAND: IslandEnglish,
    PERSON_PROFILE: PersonProfileEnglish,
    PERSON_TITLE: PersonTitleEnglish,
    POSITION: PositionEnglish,
    POSTAL_CODE: PostalCodeEnglish,
    PRINCIPAL: PrincipalEnglish,
    PROVINCE: ProvinceEnglish,
    SUB_DISTRICT: SubDistrictEnglish,
    URBAN_VILLAGE: UrbanVillageEnglish,
    VENDOR_CHANNEL: VendorChannelEnglish,
    PRODUCT: ProductEnglish,
    PRODUCT_BRAND: ProductBrandEnglish,
    PRODUCT_CATEGORY: ProductCategoryEnglish,
    PRODUCT_GROUP_HIERARCHY: ProductGroupHierarchyEnglish,
    PRODUCT_GROUP: ProductGroupEnglish,
    PRODUCT_KEY_ACCOUNT: ProductKeyAccountEnglish,
    PRODUCT_HISTORY: ProductHistoryEnglish,
    ROLE: RoleEnglish,
    DATA_GROUP: DataGroupEnglish,
    DATA_SCOPE: DataScopeEnglish,
    USER: UserEnglish,
    SYNCHRONIZE: SyncEnglish,
    JOB_PROCESS: JobProcessEnglish,
    AUDIT: AuditEnglish,
    COMPANY_USER_DEFINED_CATEGORY: CompanyUserDefinedEnglish,
    PRODUCT_USER_DEFINED_CATEGORY: ProductUserDefinedEnglish,
    PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY: PrincipalProductUserDefinedEnglish,
    PARAMETER: ParameterEnglish,
    PARAMETER_VALUE: ParameterValueEnglish,
    IMPORT: ImportEnglish,
    NOTIFICATION: NotificationEnglish
  },
  id: {
    COMMON: CommonIndonesian,
    BROADCAST: BroadcastEnglish,
    BANK: BankIndonesian,
    BRAND: BrandIndonesian,
    BRAND_OWNER: BrandOwnerIndonesian,
    COMPANY_PROFILE: CompanyProfileIndonesian,
    COMPANY_TITLE: CompanyTitleIndonesian,
    CONTACT_PERSON: ContactPersonIndonesian,
    COUNTRY: CountryIndonesian,
    DISTRICT: DistrictIndonesian,
    DIVISION: DivisionIndonesian,
    GROUP_OF_DISTRIBUTOR: GroupOfDistributorIndonesian,
    ISLAND: IslandIndonesian,
    PERSON_PROFILE: PersonProfileIndonesian,
    PERSON_TITLE: PersonTitleIndonesian,
    POSITION: PositionIndonesian,
    POSTAL_CODE: PostalCodeIndonesian,
    PRINCIPAL: PrincipalIndonesian,
    PROVINCE: ProvinceIndonesian,
    SUB_DISTRICT: SubDistrictIndonesian,
    URBAN_VILLAGE: UrbanVillageIndonesian,
    VENDOR_CHANNEL: VendorChannelIndonesian,
    PRODUCT: ProductIndonesian,
    PRODUCT_BRAND: ProductBrandIndonesian,
    PRODUCT_CATEGORY: ProductCategoryIndonesian,
    PRODUCT_GROUP_HIERARCHY: ProductGroupHierarchyIndonesian,
    PRODUCT_GROUP: ProductGroupIndonesian,
    PRODUCT_KEY_ACCOUNT: ProductKeyAccountIndonesian,
    PRODUCT_HISTORY: ProductHistoryIndonesian,
    ROLE: RoleIndonesian,
    DATA_GROUP: DataGroupIndonesian,
    DATA_SCOPE: DataScopeIndonesian,
    USER: UserIndonesian,
    SYNCHRONIZE: SyncIndonesian,
    JOB_PROCESS: JobProcessIndonesian,
    AUDIT: AuditIndonesian,
    COMPANY_USER_DEFINED_CATEGORY: CompanyUserDefinedIndonesian,
    PRODUCT_USER_DEFINED_CATEGORY: ProductUserDefinedIndonesian,
    PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY: PrincipalProductUserDefinedIndonesian,
    PARAMETER: ParameterIndonesian,
    PARAMETER_VALUE: ParameterValueIndonesian,
    IMPORT: ImportIndonesian,
    NOTIFICATION: NotificationIndonesian
  },
};
