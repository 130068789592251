import React from 'react';

import { Div } from 'nexcomponent-ui';

import {
  principalProductUserDefinedCategoryBreadCrumb,
} from '../../../../Enum/Breadcrumb/PrincipalProductUserDefinedCategoryBreadcrumb';
import {
  principalProductUserDefinedCategoryColumn,
} from '../../../../Enum/Column/PrincipalProductUserDefinedCategoryColumn';
import { PAGE } from '../../../../Enum/IdSet';
import { MAPPING_CODE } from '../../../../Enum/MappingCode/MappingCode';
import {
  ROUTER_CONSTANT,
} from '../../../../Enum/RouterConstant/RouterConstant';
import {
  postRejectPrincipalProductUserDefinedCategoryList,
} from '../../../../Fetch/Api/PrincipalProductUserDefinedCategoryAPI';
import {
  fetchPrincipalProductUserDefinedCategoryMappingForm,
} from '../../../../Fetch/FetchData/PrincipalProductUserDefinedCategoryData';
import CancelRejectButton
  from '../../../Organisms/Button/CancelRejectButton/CancelRejectButton';
import HeaderPage from '../../../Organisms/Header/HeaderPage/HeaderPage';
import ModalReject from '../../../Organisms/Modal/ModalReject/ModalReject';
import RejectReasonList
  from '../../../Organisms/TableList/RejectReasonList/RejectReasonList';

const PrincipalProductUserDefinedCategoryRejectReasonPage = props => (
  <Div id={PAGE.REJECT_LIST}>
    <ModalReject
      onReject={true}
      t={props.t}
      dispatch={props.dispatch}
      mappingCode={MAPPING_CODE.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY}
      navigate={props.navigate}
      rejectFunc={(data, notify, dispatch) =>
        postRejectPrincipalProductUserDefinedCategoryList(
          props.validToken(),
          data,
          notify,
          dispatch
        )
      }
      uniqueKey={"principal_product_user_defined_category_id"}
      nextMapping={(newId, existId, mappingList, nav) =>
        fetchPrincipalProductUserDefinedCategoryMappingForm(
          props.validToken(),
          props.dispatch,
          "principal_product_user_defined_category_id",
          newId,
          existId,
          mappingList,
          props.notify,
          () => props.navigate(nav)
        )
      }
    />
    <HeaderPage
      defaultBreadcrumb={props.t("COMMON:REJECT_REASON")}
      listBreadcrumb={principalProductUserDefinedCategoryBreadCrumb(props.t)}
      title={props.t("PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY:TITLE")}
      toTitlePageto={ROUTER_CONSTANT.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY.LIST}
    />
    <RejectReasonList
      t={props.t}
      dispatch={props.dispatch}
      column={principalProductUserDefinedCategoryColumn(props.t)}
      mappingCode={MAPPING_CODE.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY}
    />
    <CancelRejectButton
      backPage={ROUTER_CONSTANT.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY.LIST}
      mappingCode={MAPPING_CODE.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY}
      onReject={true}
      t={props.t}
      dispatch={props.dispatch}
      navigate={props.navigate}
    />
  </Div>
);

export default PrincipalProductUserDefinedCategoryRejectReasonPage;
