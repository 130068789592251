import React from "react";

import _ from "lodash";
import { Button, Div } from "nexcomponent-ui";
import { useSelector } from "react-redux";

import { BUTTON } from "../../../../Enum/IdSet";
import { SIZESET } from "../../../../Enum/Props/SizeSet";
import { BTN_STYLE_TYPE } from "../../../../Enum/Style";
import { rejectButtonClick } from "../../../../Store/Action/ButtonMappingAction";
import { utilsActions } from "../../../../Store/Reducers/UtilsReducer";
import style from "./RejectMappingButton.module.css";

function RejectMappingButton(props) {
  const checkedArray = useSelector((state) => state.utils.checkedArray);
  const rejectList = useSelector((state) => state.mapping.rejectList);
  const mappingList = useSelector((state) => state.mapping.mappingList);
  const reject = () =>
    rejectButtonClick(
      props.onReject,
      props.mappingCode,
      rejectList,
      checkedArray,
      {},
      props.navigate,
      props.dispatch,
      props.principal
    );
  const mapping = async () => {
    const first = await _.first(mappingList);
    props.mappingNavigate(first.id, first[props.uniqueKey], mappingList);
  };
  return (
    <Div className={style.ButtonGroup}>
      <Button
        id={BUTTON.REJECT}
        className={style.ButtonReject}
        size={SIZESET.LG}
        typeStyle={BTN_STYLE_TYPE.SECONDARY}
        onClick={reject}
        label={props.t("COMMON:REJECT")}
        disabled={rejectList.length <= 0 && mappingList.length <= 0 ? true : false}
      />
      <Button
        id={BUTTON.MAPPING}
        size={SIZESET.LG}
        onClick={mapping}
        label={props.t("COMMON:MAPPINGS")}
        disabled={mappingList.length <= 0 && mappingList.length <= 0 ? true : false}
      />
    </Div>
  );
}

export default RejectMappingButton;
