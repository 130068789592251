import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  country: [],
  province: [],
  district: [],
  subDistrict: [],
  postalCode: [],
  island: [],
  urbanVillage: [],
  personTitle: [],
  principal: [],
  companyTitle: [],
  companyParent: [],
  product: [],
  productBrand: [],
  productCategory: [],
  productGroup: [],
  productKeyAccount: [],
  regional: {country_id: 0, province_id: 0, district_id: 0, sub_district: 0, uraban_village: 0},
};
const searchMappingSlice = createSlice({
  name: "searchMapping",
  initialState: initialState,
  reducers: {
    reset() {
      return {
        ...initialState,
      };
    },
    setCountry(state, data) {
      state.country = data.payload;
    },
    setProvince(state, data) {
      state.province = data.payload;
    },
    setDistrict(state, data) {
      state.district = data.payload;
    },
    setSubDistrict(state, data) {
      state.subDistrict = data.payload;
    },
    setPostalCode(state, data) {
      state.postalCode = data.payload;
    },
    setIsland(state, data) {
      state.island = data.payload;
    },
    setUrbanVillage(state, data) {
      state.urbanVillage = data.payload;
    },
    setPrincipal(state, data) {
      state.principal = data.payload;
    },
    setPersonTitle(state, data) {
      state.personTitle = data.payload;
    },
    setCompanyTitle(state, data) {
      state.companyTitle = data.payload;
    },
    setCompanyParent(state, data) {
      state.companyParent = data.payload;
    },
    setProduct(state, data) {
      state.product = data.payload;
    },
    setProductBrand(state, data) {
      state.productBrand = data.payload;
    },
    setProductCategory(state, data) {
      state.productCategory = data.payload;
    },
    setProductGroup(state, data) {
      state.productGroup = data.payload;
    },
    setProductKeyAccount(state, data) {
      state.productKeyAccount = data.payload;
    },
    setRegional(state, data) {
      state.regional = data.payload;
    },
  },
});

export const searchMappingActions = searchMappingSlice.actions;
export default searchMappingSlice.reducer;
