import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  rejectList: [],
  rejectDetail: {},
  initiate: null,
  rangeDate: "",
  admin: [],
  pageAdmin: 1,
  resource: [],
  mappingTitle: [
    "Person Profile",
    "Division",
    "Product Brand",
    "Product Group",
    "Company Profile",
    "Principal Product User Defined Category",
    "Product Category",
    "Product key Account",
    "Product",
  ],
  filter: "",
};
const rejectSlice = createSlice({
  name: "reject",
  initialState: initialState,
  reducers: {
    setRejectList(state, data) {
      state.rejectList = data.payload;
    },
    setRejectDetail(state, data) {
      state.rejectDetail = data.payload;
    },
    setRejectInitiate(state, data) {
      state.initiate = data.payload;
    },
    setRangeDate(state, data) {
      state.rangeDate = data.payload;
    },
    setRejectAdmin(state, data) {
      state.admin = data.payload;
    },
    setRejectPageAdmin(state, data) {
      state.pageAdmin = data.payload;
    },
    setAddAdmin(state, data) {
      state.admin.push(data.payload);
    },
    setFilter(state, data) {
      state.filter = data.payload;
    },
  },
});

export const rejectsActions = rejectSlice.actions;
export default rejectSlice.reducer;
