import React from 'react';

import {
  ContainerFluid,
  Flex,
  Footer,
  ModalDialog,
} from 'nexcomponent-ui';
import { useSelector } from 'react-redux';

import SpinnerLoad
  from '../../../../Components/Molecules/Loader/SpinnerLoad/SpinnerLoad';
import { BACKGROUND } from '../../../../Enum/Color';
import {
  BOX,
  PAGE,
  TEXT_SINGLE,
} from '../../../../Enum/IdSet';
import { yearNow } from '../../../../Utils/Date';
import LoginForm from '../../../Organisms/Form/LoginForm/LoginForm';
import style from './LoginPage.module.css';

const LoginPage = props => {
  const load = useSelector(state => state.utils.modal);
  return (
    <Flex id={PAGE.AUTH_LOGIN}>
      <ModalDialog
        content={<SpinnerLoad t={props.t} />}
        show={load}
        t={props.t}
        hideClose={true}
        className={style.Modal}
        classContent={style.Body}
        classHeader={style.Header}
        classBody={style.Body}
        classFooter={style.Footer}
      />
      <ContainerFluid id={BOX.LEFT} bg={BACKGROUND.PRIMARY} className={style.ContentLeft}>
        <Footer
          id={TEXT_SINGLE.FOOTER}
          className={style.Footer}
          value={props.t("COMMON:COPYRIGHT", {year: yearNow})}
        />
      </ContainerFluid>
      <ContainerFluid id={BOX.RIGHT} className={style.ContentRight}>
        <LoginForm t={props.t} dispatch={props.dispatch} notify={props.notify} />
      </ContainerFluid>
    </Flex>
  );
};

export default LoginPage;
