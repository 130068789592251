import { sha256 } from 'js-sha256';

import { CRYPTO_RANDOM } from '../../Enum/PropertiesConstant';

export const dataAuthorize = {
  code_challenger: sha256(CRYPTO_RANDOM),
  redirect_uri: "",
  response_type: "code_pkce",
  state: "",
  wanted_scope: "read write"
}

