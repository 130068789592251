import React, {
  Fragment,
  useEffect,
} from 'react';

import { useSelector } from 'react-redux';
import {
  Route,
  Routes,
} from 'react-router-dom';

import Layout from '../Containers/Organisms/Layout/Layout';
import BroadcastPage from '../Containers/Page/BroadcastPage/BroadcastPage';
import NotFoundPage
  from '../Containers/Page/HTTPStatusPage/NotFoundPage/NotFoundPage';
import CompanyProfilePage
  from '../Containers/Page/ListPage/CompanyProfilePage/CompanyProfilePage';
import DivisionPage
  from '../Containers/Page/ListPage/DivisionPage/DivisionPage';
import PersonProfilePage
  from '../Containers/Page/ListPage/PersonProfilePage/PersonProfilePage';
import PrincipalProductUserDefinedCategoryPage
  from '../Containers/Page/ListPage/PrincipalProductUserDefinedCategoryPage/PrincipalProductUserDefinedCategoryPage';
import ProductBrandPage
  from '../Containers/Page/ListPage/ProductBrandPage/ProductBrandPage';
import ProductCategoryPage
  from '../Containers/Page/ListPage/ProductCategoryPage/ProductCategoryPage';
import ProductGroupPage
  from '../Containers/Page/ListPage/ProductGroupPage/ProductGroupPage';
import ProductKeyAccountPage
  from '../Containers/Page/ListPage/ProductKeyAccountPage/ProductKeyAccountPage';
import ProductPage from '../Containers/Page/ListPage/ProductPage/ProductPage';
import MainPage from '../Containers/Page/MainPage/MainPage';
import CompanyProfileMappingPage
  from '../Containers/Page/MappingPage/CompanyProfileMappingPage/CompanyProfileMappingPage';
import DivisionMappingPage
  from '../Containers/Page/MappingPage/DivisionMappingPage/DivisionMappingPage';
import PersonProfileMappingPage
  from '../Containers/Page/MappingPage/PersonProfileMappingPage/PersonProfileMappingPage';
import PrincipalProductUserDefinedCategoryMappingPage
  from '../Containers/Page/MappingPage/PrincipalProductUserDefinedCategoryMappingPage/PrincipalProductUserDefinedCategoryMappingPage';
import ProductBrandMappingPage
  from '../Containers/Page/MappingPage/ProductBrandMappingPage/ProductBrandMappingPage';
import ProductCategoryMappingPage
  from '../Containers/Page/MappingPage/ProductCategoryMappingPage/ProductCategoryMappingPage';
import ProductGroupMappingPage
  from '../Containers/Page/MappingPage/ProductGroupMappingPage/ProductGroupMappingPage';
import ProductKeyAccountMappingPage
  from '../Containers/Page/MappingPage/ProductKeyAccountMappingPage/ProductKeyAccountMappingPage';
import ProductMappingPage
  from '../Containers/Page/MappingPage/ProductMappingPage/ProductMappingPage';
import RejectPage from '../Containers/Page/RejectPage/RejectPage';
import CompanyProfileRejectReasonPage
  from '../Containers/Page/RejectReasonPage/CompanyProfileRejectReasonPage/CompanyProfileRejectReasonPage';
import DivisionRejectReasonPage
  from '../Containers/Page/RejectReasonPage/DivisionRejectReasonPage/DivisionRejectReasonPage';
import PersonProfileRejectReasonPage
  from '../Containers/Page/RejectReasonPage/PersonProfileRejectReasonPage/PersonProfileRejectReasonPage';
import PrincipalProductUserDefinedCategoryRejectReasonPage
  from '../Containers/Page/RejectReasonPage/PrincipalProductUserDefinedCategoryRejectReasonPage/PrincipalProductUserDefinedCategoryRejectReasonPage';
import ProductBrandRejectReasonPage
  from '../Containers/Page/RejectReasonPage/ProductBrandRejectReasonPage/ProductBrandRejectReasonPage';
import ProductCategoryRejectReasonPage
  from '../Containers/Page/RejectReasonPage/ProductCategoryRejectReasonPage/ProductCategoryRejectReasonPage';
import ProductGroupRejectReasonPage
  from '../Containers/Page/RejectReasonPage/ProductGroupRejectReasonPage/ProductGroupRejectReasonPage';
import ProductKeyAccountRejectReasonPage
  from '../Containers/Page/RejectReasonPage/ProductKeyAccountRejectReasonPage/ProductKeyAccountRejectReasonPage';
import ProductRejectReasonPage
  from '../Containers/Page/RejectReasonPage/ProductRejectReasonPage/ProductRejectReasonPage';
import CompanyProfileSearchPage
  from '../Containers/Page/SearchPage/CompanyProfileSearchPage/CompanyProfileSearchPage';
import DivisionSearchPage
  from '../Containers/Page/SearchPage/DivisionSearchPage/DivisionSearchPage';
import PersonProfileSearchPage
  from '../Containers/Page/SearchPage/PersonProfileSearchPage/PersonProfileSearchPage';
import PrincipalProductUserDefinedCategorySearchPage
  from '../Containers/Page/SearchPage/PrincipalProductUserDefinedCategorySearchPage/PrincipalProductUserDefinedCategorySearchPage';
import ProductBrandSearchPage
  from '../Containers/Page/SearchPage/ProductBrandSearchPage/ProductBrandSearchPage';
import ProductCategorySearchPage
  from '../Containers/Page/SearchPage/ProductCategorySearchPage/ProductCategorySearchPage';
import ProductGroupSearchPage
  from '../Containers/Page/SearchPage/ProductGroupSearchPage/ProductGroupSearchPage';
import ProductKeyAccountSearchPage
  from '../Containers/Page/SearchPage/ProductKeyAccountSearchPage/ProductKeyAccountSearchPage';
import ProductSearchPage
  from '../Containers/Page/SearchPage/ProductSearchPage/ProductSearchPage';
import { ROUTER_CONSTANT } from '../Enum/RouterConstant/RouterConstant';
import { activePageAction } from '../Store/Action/ActivePageAction';
import { validateToken } from '../Utils/Action';
import NotificationPage from '../Containers/Page/NotificationPage/NotificationPage';
import CreateNotificationPage from '../Containers/Page/NotificationPage/CreateNotificationPage';
import EditNotificationPage from '../Containers/Page/NotificationPage/EditNotificationPage';

const Authorize = props => {
  const refreshToken = useSelector(state => state.authentication.refreshToken);
  const menuLevel3 = useSelector(state => state.dashboard.menuLevel3);

  const validToken = () => validateToken(props.token, refreshToken, props.notify, props.dispatch);

  useEffect(() => {
    activePageAction(props.activePage, props.location.pathname, menuLevel3, props.dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dispatch, props.activePage, props.location.pathname]);

  return (
    <Layout
      t={props.t}
      dispatch={props.dispatch}
      navigate={props.navigate}
      location={props.location}
      notify={props.notify}
      validToken={() => validToken()}
      content={
        <Fragment>
          <Routes>
            <Route
              path={ROUTER_CONSTANT.INDEX}
              element={<MainPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.BROADCAST}
              element={<BroadcastPage validToken={() => validToken()} {...props} />}
            />
             <Route
              path={ROUTER_CONSTANT.REJECT}
              element={<RejectPage validToken={() => validToken()} {...props} />}
            />
            {/* redirect unknown path */}
            <Route path={ROUTER_CONSTANT.UNIVERSAL} element={<NotFoundPage {...props} />} />
            {/* Company Profile */}
            <Route
              path={ROUTER_CONSTANT.COMPANY_PROFILE.LIST}
              element={<CompanyProfilePage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.COMPANY_PROFILE.REJECT_REASON}
              element={
                <CompanyProfileRejectReasonPage validToken={() => validToken()} {...props} />
              }
            />
            <Route
              path={ROUTER_CONSTANT.COMPANY_PROFILE.MAPPING}
              element={<CompanyProfileMappingPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.COMPANY_PROFILE.SEARCH}
              element={<CompanyProfileSearchPage validToken={() => validToken()} {...props} />}
            />

            <Route
              path={ROUTER_CONSTANT.COMPANY_PROFILE.REJECT_REASON}
              element={
                <CompanyProfileRejectReasonPage validToken={() => validToken()} {...props} />
              }
            />
            <Route
              path={ROUTER_CONSTANT.COMPANY_PROFILE.MAPPING}
              element={<CompanyProfileMappingPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.COMPANY_PROFILE.SEARCH}
              element={<CompanyProfileSearchPage validToken={() => validToken()} {...props} />}
            />
            {/* Division Profile */}
            <Route
              path={ROUTER_CONSTANT.DIVISION.LIST}
              element={<DivisionPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.DIVISION.REJECT_REASON}
              element={<DivisionRejectReasonPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.DIVISION.MAPPING}
              element={<DivisionMappingPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.DIVISION.SEARCH}
              element={<DivisionSearchPage validToken={() => validToken()} {...props} />}
            />

            {/* Person Profile */}
            <Route
              path={ROUTER_CONSTANT.PERSON_PROFILE.LIST}
              element={<PersonProfilePage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.PERSON_PROFILE.REJECT_REASON}
              element={<PersonProfileRejectReasonPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.PERSON_PROFILE.MAPPING}
              element={<PersonProfileMappingPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.PERSON_PROFILE.SEARCH}
              element={<PersonProfileSearchPage validToken={() => validToken()} {...props} />}
            />
            {/* Mapping */}
            {/* Group Product Group*/}
            <Route
              path={ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.LIST}
              element={<ProductGroupPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.REJECT_REASON}
              element={<ProductGroupRejectReasonPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.MAPPING}
              element={<ProductGroupMappingPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.SEARCH}
              element={<ProductGroupSearchPage validToken={() => validToken()} {...props} />}
            />
            {/* Princiapl Product Group*/}
            <Route
              path={ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.PRINCIPAL.LIST}
              element={<ProductGroupPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.PRINCIPAL.REJECT_REASON}
              element={<ProductGroupRejectReasonPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.PRINCIPAL.MAPPING}
              element={<ProductGroupMappingPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.PRODUCT_GROUP_HIERARCHY.PRINCIPAL.SEARCH}
              element={<ProductGroupSearchPage validToken={() => validToken()} {...props} />}
            />
            {/* Product Category */}
            <Route
              path={ROUTER_CONSTANT.PRODUCT_CATEGORY.LIST}
              element={<ProductCategoryPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.PRODUCT_CATEGORY.REJECT_REASON}
              element={
                <ProductCategoryRejectReasonPage validToken={() => validToken()} {...props} />
              }
            />
            <Route
              path={ROUTER_CONSTANT.PRODUCT_CATEGORY.MAPPING}
              element={<ProductCategoryMappingPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.PRODUCT_CATEGORY.SEARCH}
              element={<ProductCategorySearchPage validToken={() => validToken()} {...props} />}
            />
            {/* Product Brand */}
            <Route
              path={ROUTER_CONSTANT.PRODUCT_BRAND.LIST}
              element={<ProductBrandPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.PRODUCT_BRAND.REJECT_REASON}
              element={<ProductBrandRejectReasonPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.PRODUCT_BRAND.MAPPING}
              element={<ProductBrandMappingPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.PRODUCT_BRAND.SEARCH}
              element={<ProductBrandSearchPage validToken={() => validToken()} {...props} />}
            />

             {/* Principal Product Brand */}
             <Route
              path={ROUTER_CONSTANT.PRODUCT_BRAND.PRINCIPAL.LIST}
              element={<ProductBrandPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.PRODUCT_BRAND.PRINCIPAL.REJECT_REASON}
              element={<ProductBrandRejectReasonPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.PRODUCT_BRAND.PRINCIPAL.MAPPING}
              element={<ProductBrandMappingPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.PRODUCT_BRAND.PRINCIPAL.SEARCH}
              element={<ProductBrandSearchPage validToken={() => validToken()} {...props} />}
            />
            {/* Product Key Account */}
            <Route
              path={ROUTER_CONSTANT.PRODUCT_KEY_ACCOUNT.LIST}
              element={<ProductKeyAccountPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.PRODUCT_KEY_ACCOUNT.REJECT_REASON}
              element={
                <ProductKeyAccountRejectReasonPage validToken={() => validToken()} {...props} />
              }
            />
            <Route
              path={ROUTER_CONSTANT.PRODUCT_KEY_ACCOUNT.MAPPING}
              element={<ProductKeyAccountMappingPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.PRODUCT_KEY_ACCOUNT.SEARCH}
              element={<ProductKeyAccountSearchPage validToken={() => validToken()} {...props} />}
            />
            {/* Product */}
            <Route
              path={ROUTER_CONSTANT.PRODUCT.LIST}
              element={<ProductPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.PRODUCT.REJECT_REASON}
              element={<ProductRejectReasonPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.PRODUCT.MAPPING}
              element={<ProductMappingPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.PRODUCT.SEARCH}
              element={<ProductSearchPage validToken={() => validToken()} {...props} />}
            />
            {/* Product */}
            <Route
              path={ROUTER_CONSTANT.PRODUCT.PRINCIPAL.LIST}
              element={<ProductPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.PRODUCT.PRINCIPAL.REJECT_REASON}
              element={<ProductRejectReasonPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.PRODUCT.PRINCIPAL.MAPPING}
              element={<ProductMappingPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.PRODUCT.PRINCIPAL.SEARCH}
              element={<ProductSearchPage validToken={() => validToken()} {...props} />}
            />
            {/* Principal User Defined */}
            <Route
              path={ROUTER_CONSTANT.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY.LIST}
              element={
                <PrincipalProductUserDefinedCategoryPage
                  validToken={() => validToken()}
                  {...props}
                />
              }
            />
            <Route
              path={ROUTER_CONSTANT.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY.DETAIL}
              element={""}
            />
            <Route
              path={ROUTER_CONSTANT.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY.ADD}
              element={""}
            />
            <Route
              path={ROUTER_CONSTANT.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY.EDIT}
              element={""}
            />
            <Route
              path={ROUTER_CONSTANT.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY.REJECT_REASON}
              element={
                <PrincipalProductUserDefinedCategoryRejectReasonPage
                  validToken={() => validToken()}
                  {...props}
                />
              }
            />
            <Route
              path={ROUTER_CONSTANT.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY.MAPPING}
              element={
                <PrincipalProductUserDefinedCategoryMappingPage
                  validToken={() => validToken()}
                  {...props}
                />
              }
            />
            <Route
              path={ROUTER_CONSTANT.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY.SEARCH}
              element={
                <PrincipalProductUserDefinedCategorySearchPage
                  validToken={() => validToken()}
                  {...props}
                />
              }
            />
            <Route
              path={ROUTER_CONSTANT.NOTIFICATION.LIST}
              element={<NotificationPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.NOTIFICATION.CREATE}
              element={<CreateNotificationPage validToken={() => validToken()} {...props} />}
            />
            <Route
              path={ROUTER_CONSTANT.NOTIFICATION.EDIT}
              element={<EditNotificationPage validToken={() => validToken()} {...props} />}
            />
          </Routes>
        </Fragment>
      }
    />
  );
};

export default Authorize;
