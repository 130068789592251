import React, { useEffect } from 'react';

import _ from 'lodash';
import { Div } from 'nexcomponent-ui';
import { useSelector } from 'react-redux';

import {
  personProfileBreadCrumb,
} from '../../../../Enum/Breadcrumb/PersonProfileBreadcrumb';
import { MAPPING_CODE } from '../../../../Enum/MappingCode/MappingCode';
import {
  ROUTER_CONSTANT,
} from '../../../../Enum/RouterConstant/RouterConstant';
import {
  fetchDataApprovalPersonProfile,
  fetchPersonProfileMappingForm,
} from '../../../../Fetch/FetchData/PersonProfileData';
import { mappingFormPageStateGroup } from '../../../../Store/StateGroup';
import CancelOverwrite
  from '../../../Organisms/Button/CancelOverwrite/CancelOverwrite';
import MappingForm
  from '../../../Organisms/Form/MappingForm/MappingForm/MappingForm';
import HeaderPageMapping
  from '../../../Organisms/Header/HeaderPageMapping/HeaderPageMapping';
import ModalMapping from '../../../Organisms/Modal/ModalMapping/ModalMapping';

const PersonProfileMappingPage = props => {
  const [, list, , mappingForm] = useSelector(mappingFormPageStateGroup);
  useEffect(() => {
    if (_.has(props.location.state, "data")) {
      const first = _.first(list);
      fetchPersonProfileMappingForm(
        props.validToken(),
        props.dispatch,
        "person_profile_id",
        first.id,
        props.location.state.data.person_profile_id,
        list,
        props.notify,
        () => props.navigate(ROUTER_CONSTANT.PERSON_PROFILE.MAPPING)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, props.location.state, props.dispatch, props.validToken]);
  let nik = _.find(mappingForm, { 'key': 'nik'})
  let checkNIK = nik.isChecked ? nik.new : nik.exist
  return (
    <Div>
      <ModalMapping
        t={props.t}
        dispatch={props.dispatch}
        navigate={props.navigate}
        listNavigate={ROUTER_CONSTANT.PERSON_PROFILE.LIST}
        customSaveMessageCondition={checkNIK === ''}
        customSaveMessage={props.t("PERSON_PROFILE:NIK_GENERATE_CONFIRM")}
        approveFuc={(data, mappingList, mappingCount) =>
          fetchDataApprovalPersonProfile(
            props.validToken(),
            data,
            mappingList,
            mappingCount,
            props.notify,
            props.dispatch
          )
        }
        uniqueKey="person_profile_id"
        nextMapping={(uniqueKey, newId, existId, mappingList) =>
          fetchPersonProfileMappingForm(
            props.validToken(),
            props.dispatch,
            uniqueKey,
            newId,
            existId,
            mappingList,
            props.notify,
            () => props.navigate(ROUTER_CONSTANT.PERSON_PROFILE.MAPPING)
          )
        }
      />

      <HeaderPageMapping
        title={props.t("PERSON_PROFILE:TITLE")}
        listBreadcrumb={personProfileBreadCrumb(props.t)}
        defaultBreadcrumb={props.t("PERSON_PROFILE:MAPPING.TITLE")}
        to={ROUTER_CONSTANT.PERSON_PROFILE.LIST}
      />
      <MappingForm
        t={props.t}
        validToken={props.validToken}
        dispatch={props.dispatch}
        navigate={props.navigate}
        idKey="person_profile_id"
        mappingCode={MAPPING_CODE.PERSON_PROFILE}
        mappingForm={mappingForm}
      />
      <CancelOverwrite
        t={props.t}
        dispatch={props.dispatch}
        navigate={props.navigate}
        mappingForm={mappingForm}
        idKey="person_profile_id"
        mappingCode={12}
      />
    </Div>
  );
};

export default PersonProfileMappingPage;
