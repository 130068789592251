import { COMMON } from '../../Enum/EndPoint/CommonEndpoint';

export const NOTIFICATION_URL = Object.freeze({
  LIST: (page, limit, order, filter) =>
    COMMON.NOTIFICATION + COMMON.LIST_FILTER(page, limit, order, filter),
  DELETE: (id) => COMMON.NOTIFICATION + COMMON.INDEX + id,
  INITIATE: (filter) => COMMON.NOTIFICATION + COMMON.INITIATE + COMMON.FILTER(filter),
  CREATE: COMMON.NOTIFICATION,
  EDIT: (id) => COMMON.NOTIFICATION + COMMON.INDEX + id
});
