import Error400 from './400.png';
import Error401 from './401.png';
import Error403 from './403.png';
import Error404 from './404.png';
import Error408 from './408.png';
import Error500 from './500.png';
import Error502 from './502.png';
import Offline from './Offline.png';

export const httpStatusImg = {Error400, Error401, Error403,Error404, Error408, Error500, Error502,Offline};
