import {
  isStatus,
  isStatusErr,
} from '../../Utils/Response/Response';
import { PRODUCT_URL } from '../EndPoint';
import * as BaseAPI from './BaseAPIs';

export const postProductCountData = (token, notify, dispatch, action) =>
  BaseAPI.doPost(PRODUCT_URL.COUNT, token, null, notify, dispatch, res => action(res));


export const postProductList =  (token, page, limit, sortDate,notify,dispatch,action) =>
  BaseAPI.doPost(PRODUCT_URL.LIST(page, limit, sortDate), token, null,notify,dispatch,(res)=>action(res));


export const postRejectProductList =(token, data,notify,dispatch) => 
BaseAPI.doPost(PRODUCT_URL.REJECT, token, data,notify,dispatch,
  res=>isStatus(res),err=>isStatusErr(err))

export const getDetailProduct = (token, id,notify,dispatch,action,errAction) => 
  BaseAPI.doGet(PRODUCT_URL.DETAIL + id, token, null,null,notify,dispatch,
  res=>action(res),err=>errAction(err));

export const getDetailExistingProduct = (token, id,notify,dispatch,action,errAction) => 
  BaseAPI.doGet(PRODUCT_URL.DETAIL_EXISTING + id, token, null,null,notify,dispatch,
  res=>action(res),err=>errAction(err));

export const postSearchProductList = (token, data,dispatch, notify,action,errAction) => {
  return BaseAPI.doPost(PRODUCT_URL.SEARCH,  token, data ,notify,dispatch,
    (res)=>action(res),err=>errAction(err));
};
export const postApproveMappingProduct = (token, data,notify,dispatch,action,errAction) => {
  return BaseAPI.doPost(PRODUCT_URL.MAPPING_APPROVE, token, data,notify,dispatch,
      (res)=>action(res),err=>errAction(err));
};
export const postProductSearchCountData = (token,data,notify,dispatch,action) =>  
  BaseAPI.doPost(PRODUCT_URL.COUNT_SEARCH, token, data,notify,dispatch,(res)=>action(res));


// Principal

export const postProductPrincipalCountData = (token, notify, dispatch, action) =>
  BaseAPI.doPost(PRODUCT_URL.PRINCIPAL.COUNT, token, null, notify, dispatch, res => action(res));


export const postProductPrincipalList =  (token, page, limit, sortDate,notify,dispatch,action) =>
  BaseAPI.doPost(PRODUCT_URL.PRINCIPAL.LIST(page, limit, sortDate), token, null,notify,dispatch,(res)=>action(res));


export const postRejectProductPrincipalList =(token, data,notify,dispatch) => 
BaseAPI.doPost(PRODUCT_URL.PRINCIPAL.REJECT, token, data,notify,dispatch,
  res=>isStatus(res),err=>isStatusErr(err))

export const getDetailProductPrincipal = (token, id,notify,dispatch,action,errAction) => 
  BaseAPI.doGet(PRODUCT_URL.PRINCIPAL.DETAIL + id, token, null,null,notify,dispatch,
  res=>action(res),err=>errAction(err));

export const getDetailExistingProductPrincipal = (token, id,notify,dispatch,action,errAction) => 
  BaseAPI.doGet(PRODUCT_URL.PRINCIPAL.DETAIL_EXISTING + id, token, null,null,notify,dispatch,
  res=>action(res),err=>errAction(err));

export const postSearchProductPrincipalList = (token, data,dispatch, notify,action,errAction) => {
  return BaseAPI.doPost(PRODUCT_URL.PRINCIPAL.SEARCH,  token, data ,notify,dispatch,
    (res)=>action(res),err=>errAction(err));
};
export const postApproveMappingProductPrincipal = (token, data,notify,dispatch,action,errAction) => {
  return BaseAPI.doPost(PRODUCT_URL.PRINCIPAL.MAPPING_APPROVE, token, data,notify,dispatch,
      (res)=>action(res),err=>errAction(err));
};
export const postProductPrincipalSearchCountData = (token,data,notify,dispatch,action) =>  
  BaseAPI.doPost(PRODUCT_URL.PRINCIPAL.COUNT_SEARCH, token, data,notify,dispatch,(res)=>action(res));