import React from 'react'
import { useSelector } from 'react-redux'

import { Div } from 'nexcomponent-ui'

import { notificationActions } from '../../../Store/Reducers/NotificationReducer'

import { fetchNotificationList } from '../../../Fetch/FetchData/NotificationData'

import { PAGE } from '../../../Enum/IdSet'
import { ROUTER_CONSTANT } from '../../../Enum/RouterConstant/RouterConstant'
import { notificationBreadcrumb } from '../../../Enum/Breadcrumb/NotificationBreadcrumb'

import HeaderPage from '../../Organisms/Header/HeaderPage/HeaderPage'
import NotificationList from '../../Organisms/TableList/NotificationList/NotificationList'
import NotificationFilterForm from '../../Organisms/Form/SearchForm/NotificationFilterForm/NotificationFilterForm'

const NotificationPage = props => {
  const filter = useSelector(state => state.notification.filter)
  const searchValue = useSelector(state => state.notification.searchValue)
  const searchKey = useSelector(state => state.notification.searchKey)

  return (
    <Div id={PAGE.NOTIFICATION}>
      <HeaderPage
        title={props.t("NOTIFICATION:TITLE")}
        listBreadcrumb={notificationBreadcrumb(props.t)}
        defaultBreadcrumb={props.t("NOTIFICATION:TITLE_LIST")}
        toTitlePageto={ROUTER_CONSTANT.NOTIFICATION.LIST}
      />
      
      <NotificationList
        customHeader={
          <NotificationFilterForm
            validToken={props.validToken}
            dispatch={props.dispatch}
            t={props.t}
            notify={props.notify}
            filter={filter}
            action={notificationActions}
            list={fetchNotificationList}
            searchValue={searchValue}
            searchKey={searchKey}
          />
        }
        t={props.t}
        validToken={props.validToken}
        dispatch={props.dispatch}
        navigate={props.navigate}
        notify={props.notify}
      />
    </Div>
  )
}

export default NotificationPage
