import { ROUTER_CONSTANT } from '../RouterConstant/RouterConstant';

export const divisionBreadCrumb = t => [
  {value: t("COMMON:DATA_PROCESS"), to: ROUTER_CONSTANT.DIVISION.LIST},
  {value: t("COMMON:MAPPINGS"), to: ROUTER_CONSTANT.DIVISION.LIST},
  {value: t("COMMON:FIELD.DIVISION"), to: ROUTER_CONSTANT.DIVISION.LIST},
];
export const divisionSearchBreadCrumb = t => [
  {value: t("COMMON:DATA_PROCESS"), to: "/"},
  {value: t("COMMON:MAPPINGS"), to: "/"},
  {value: t("COMMON:FIELD.DIVISION"), to: ROUTER_CONSTANT.DIVISION.LIST},
  {value: `${t("COMMON:MAPPINGS")} ${t("COMMON:FIELD.DIVISION")}`, to: ROUTER_CONSTANT.DIVISION.MAPPING},
];
