export const personProfilesMappingSelect = (state) => {
  return [
    state.searchMapping.personTitle,
    state.searchMapping.country,
    state.searchMapping.postalCode,
    state.searchMapping.province,
    state.searchMapping.district,
    state.searchMapping.subDistrict,
    state.searchMapping.urbanVillage,
    state.searchMapping.island,
  ];
};
export const companyProfileMappingOptions = (state) => {
  return [
    state.searchMapping.companyTitle,
    state.searchMapping.country,
    state.searchMapping.postalCode,
    state.searchMapping.province,
    state.searchMapping.district,
    state.searchMapping.subDistrict,
    state.searchMapping.urbanVillage,
    state.searchMapping.island,
  ];
};
