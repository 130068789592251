import { MAPPING_CODE } from './MappingCode/MappingCode';

export function mappingType(mapping_type){
  if(mapping_type===MAPPING_CODE.PERSON_PROFILE){
    return("/personprofile")
  }else if(mapping_type===MAPPING_CODE.COMPANY_PROFILE){
    return("/companyprofile")
  }else if(mapping_type===MAPPING_CODE.DIVISION){
    return("/division")
  }else if(mapping_type===MAPPING_CODE.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY){
    return("/principalproductuserdefinedcategory")
  }else if(mapping_type===MAPPING_CODE.PRODUCT_BRAND){
    return("/productbrand")
  }else if(mapping_type===MAPPING_CODE.PRODUCT_CATEGORY){
    return("/productcategory")
  }else if(mapping_type===MAPPING_CODE.PRODUCT_GROUP){
    return("/productgroup")
  }else if(mapping_type===MAPPING_CODE.PRODUCT_KEY_ACCOUNT){
    return("/productkeyaccount")
  }else if(mapping_type===MAPPING_CODE.PRODUCT){
    return("/product")
  }else{
    return("/")
  }
}