import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  broadcastList: [],
  broadcastDetail: {},
  initiate: null,
  rangeDate: "",
  admin: [],
  pageAdmin: 1,
  resource: [],
  mappingTitle: [
    "Person Profile",
    "Division",
    "Product Brand",
    "Product Group",
    "Company Profile",
    "Principal Product User Defined Category",
    "Product Category",
    "Product key Account",
    "Product",
  ],
  filter: "",
};
const broadcastSlice = createSlice({
  name: "broadcast",
  initialState: initialState,
  reducers: {
    setBroadcastList(state, data) {
      state.broadcastList = data.payload;
    },
    setBroadcastDetail(state, data) {
      state.broadcastDetail = data.payload;
    },
    setBroadcastInitiate(state, data) {
      state.initiate = data.payload;
    },
    setRangeDate(state, data) {
      state.rangeDate = data.payload;
    },
    setBroadcastAdmin(state, data) {
      state.admin = data.payload;
    },
    setBroadcastPageAdmin(state, data) {
      state.pageAdmin = data.payload;
    },
    setAddAdmin(state, data) {
      state.admin.push(data.payload);
    },
    setFilter(state, data) {
      state.filter = data.payload;
    },
  },
});

export const broadcastsActions = broadcastSlice.actions;
export default broadcastSlice.reducer;
