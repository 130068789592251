import { formatDD_MMMM_YYYY } from '../../Utils/Date/DateFormater';
import { itemDetailValue } from '../../Utils/Value';

export const detailBroadcastPersonProfile = (t, data) => [
  {
    id: "tslid",
    title: t("PERSON_PROFILE:FIELD.PERSON_PROFILE_ID"),
    value: itemDetailValue(data.id),
  },
  {
    id: "tslpersontitlename",
    title: t("PERSON_PROFILE:FIELD.PERSON_TITLE_NAME"),
    value: itemDetailValue(data.person_title_name),
  },
  {
    id: "tslpersontitleid",
    title: t("PERSON_PROFILE:FIELD.PERSON_TITLE_ID"),
    value: itemDetailValue(data.person_title_id),
  },
  {
    id: "tslauthuserid",
    title: t("PERSON_PROFILE:FIELD.AUTH_USER_ID"),
    value: itemDetailValue(data.auth_user_id),
  },
  {
    id: "tslclientid",
    title: t("PERSON_PROFILE:FIELD.CLIENT_ID"),
    value: itemDetailValue(data.client_id),
  },
  {
    id: "tslnik",
    title: t("PERSON_PROFILE:FIELD.NIK"),
    value: itemDetailValue(data.nik),
  },
  {
    id: "tslnpwp",
    title: t("PERSON_PROFILE:FIELD.NPWP"),
    value: itemDetailValue(data.npwp),
  },
  {
    id: "tslfirstname",
    title: t("PERSON_PROFILE:FIELD.FIRST_NAME"),
    value: itemDetailValue(data.first_name),
  },
  {
    id: "lastname",
    title: t("PERSON_PROFILE:FIELD.LAST_NAME"),
    value: itemDetailValue(data.last_name),
  },
  {
    id: "tslsex",
    title: t("PERSON_PROFILE:FIELD.SEX"),
    value: itemDetailValue(data.sex),
  },
  {
    id: "tsladdress1",
    title: t("PERSON_PROFILE:FIELD.ADDRESS_1"),
    value: itemDetailValue(data.address_1),
  },
  {
    id: "tsladdress2",
    title: t("PERSON_PROFILE:FIELD.ADDRESS_2"),
    value: itemDetailValue(data.address_2),
  },
  {
    id: "tsladdress3",
    title: t("PERSON_PROFILE:FIELD.ADDRESS_3"),
    value: itemDetailValue(data.address_3),
  },
  {
    id: "tslhamlet",
    title: t("PERSON_PROFILE:FIELD.HAMLET"),
    value: itemDetailValue(data.hamlet),
  },
  {
    id: "tslneighbourhood",
    title: t("PERSON_PROFILE:FIELD.NEIGHBOURHOOD"),
    value: itemDetailValue(data.neighbourhood),
  },
  {
    id: "tslcountryname",
    title: t("PERSON_PROFILE:FIELD.COUNTRY_NAME"),
    value: itemDetailValue(data.country_name),
  },
  {
    id: "tslcountryid",
    title: t("PERSON_PROFILE:FIELD.COUNTRY_ID"),
    value: itemDetailValue(data.country_id),
  },
  {
    id: "tslprovincecode",
    title: t("PERSON_PROFILE:FIELD.PROVINCE_CODE"),
    value: itemDetailValue(data.province_code),
  },
  {
    id: "tslprovincname",
    title: t("PERSON_PROFILE:FIELD.PROVINCE_NAME"),
    value: itemDetailValue(data.province_name),
  },
  {
    id: "tslprovinceid",
    title: t("PERSON_PROFILE:FIELD.PROVINCE_ID"),
    value: itemDetailValue(data.province_id),
  },
  {
    id: "tsldistrictcode",
    title: t("PERSON_PROFILE:FIELD.DISTRICT_CODE"),
    value: itemDetailValue(data.district_code),
  },
  {
    id: "tsldistricname",
    title: t("PERSON_PROFILE:FIELD.DISTRICT_NAME"),
    value: itemDetailValue(data.district_name),
  },
  {
    id: "tsldistrictid",
    title: t("PERSON_PROFILE:FIELD.DISTRICT_ID"),
    value: itemDetailValue(data.district_id),
  },
  {
    id: "tslsubdistrictcode",
    title: t("PERSON_PROFILE:FIELD.SUBDISTRICT_CODE"),
    value: itemDetailValue(data.sub_district_code),
  },
  {
    id: "tslsub_districname",
    title: t("PERSON_PROFILE:FIELD.SUBDISTRICT_NAME"),
    value: itemDetailValue(data.sub_district_name),
  },
  {
    id: "tslsubdistrict",
    title: t("PERSON_PROFILE:FIELD.SUBDISTRICT_ID"),
    value: itemDetailValue(data.sub_district_id),
  },
  {
    id: "tslurbanvillagecode",
    title: t("PERSON_PROFILE:FIELD.URBAN_VILLAGE_CODE"),
    value: itemDetailValue(data.urban_village_code),
  },
  {
    id: "tslurbavillagname",
    title: t("PERSON_PROFILE:FIELD.URBAN_VILLAGE_NAME"),
    value: itemDetailValue(data.urban_village_name),
  },
  {
    id: "tslurbanvillageid",
    title: t("PERSON_PROFILE:FIELD.URBAN_VILLAGE_ID"),
    value: itemDetailValue(data.urban_village_id),
  },
  {
    id: "tslpostacode",
    title: t("PERSON_PROFILE:FIELD.POSTAL_CODE_CODE"),
    value: itemDetailValue(data.postal_code),
  },
  {
    id: "tslpostalcodeid",
    title: t("PERSON_PROFILE:FIELD.POSTAL_CODE_ID"),
    value: itemDetailValue(data.postal_code_id),
  },
  {
    id: "tslislancode",
    title: t("PERSON_PROFILE:FIELD.ISLAND_CODE"),
    value: itemDetailValue(data.island_code),
  },
  {
    id: "tslislandname",
    title: t("PERSON_PROFILE:FIELD.ISLAND_NAME"),
    value: itemDetailValue(data.island_name),
  },
  {
    id: "tslislandid",
    title: t("PERSON_PROFILE:FIELD.ISLAND_ID"),
    value: itemDetailValue(data.island_id),
  },
  {
    id: "tslphone",
    title: t("PERSON_PROFILE:FIELD.PHONE"),
    value: itemDetailValue(data.phone),
  },
  {
    id: "tslalternativephone",
    title: t("PERSON_PROFILE:FIELD.ALTERNATIVE_PHONE"),
    value: itemDetailValue(data.alternative_phone),
  },
  {
    id: "tslemail",
    title: t("PERSON_PROFILE:FIELD.EMAIL"),
    value: itemDetailValue(data.email),
  },
  {
    id: "tslalternativeemail",
    title: t("PERSON_PROFILE:FIELD.ALTERNATIVE_EMAIL"),
    value: itemDetailValue(data.alternative_email),
  },
  {
    id: "tslbirthplace",
    title: t("PERSON_PROFILE:FIELD.BIRTH_PLACE"),
    value: itemDetailValue(data.birth_place),
  },
  {
    id: "tslbirthdate",
    title: t("PERSON_PROFILE:FIELD.BIRTH_DATE"),
    value: itemDetailValue(formatDD_MMMM_YYYY(data.birth_date)),
  },
  {
    id: "tsloccupation",
    title: t("PERSON_PROFILE:FIELD.OCCUPATION"),
    value: itemDetailValue(data.occupation),
  },
  {
    id: "tslspousefirstname",
    title: t("PERSON_PROFILE:FIELD.SPOUSE_FIRST_NAME"),
    value: itemDetailValue(data.spouse_firstname),
  },
  {
    id: "tslspouselastname",
    title: t("PERSON_PROFILE:FIELD.SPOUSE_LAST_NAME"),
    value: itemDetailValue(data.spouse_lastname),
  },
  {
    id: "tslannivesarydate",
    title: t("PERSON_PROFILE:FIELD.ANNIVERSARY_DATE"),
    value: itemDetailValue(formatDD_MMMM_YYYY(data.anniversary_date)),
  },
  {
    id: "tsleducationhistory",
    title: t("PERSON_PROFILE:FIELD.EDUCATION_HISTORY"),
    value: itemDetailValue(data.education_history),
  },
  {
    id: "tsljobexperiencehistory",
    title: t("PERSON_PROFILE:FIELD.JOB_EXPERIENCE_HISTORY"),
    value: itemDetailValue(data.job_experience_history),
  },
  {
    id: "tslremark",
    title: t("PERSON_PROFILE:FIELD.REMARK"),
    value: itemDetailValue(data.remark),
  },
  {
    id: "tsladdtionalinfo",
    title: t("PERSON_PROFILE:FIELD.ADDITIONAL_INFO"),
    value: itemDetailValue(data.additional_info),
    obj:true
  },
  {
    id: "tslresource",
    title: t("PERSON_PROFILE:FIELD.RESOURCE"),
    value: itemDetailValue(data.resource),
  },
];
