import React from 'react';

import {
  Button,
  Div,
  Heading,
  Image,
  Paragraph,
} from 'nexcomponent-ui';

import style from './HttpStatusBox.module.css';

const HttpStatusBox = props => {
  return (
    <Div>
      <Image className={style.Img} src={props.img} />
      <Div className={style.BoxMessage}>
        <Heading className={style.TitleMessage} size={5} value={props.title} />
        <Div className={style.DetailMessageGroup}>
          <Paragraph className={style.DetailMessage} value={props.MessageFirst} />
          <Paragraph className={style.DetailMessage} value={props.MessageSecond} />
        </Div>
        {props.labelButton === undefined ? (
          <></>
        ) : (
          <Button onClick={props.onClick} label={props.labelButton} />
        )}
      </Div>
    </Div>
  );
};

export default HttpStatusBox;
