import React from 'react';

import _ from 'lodash';
import { CheckBox } from 'nexcomponent-ui';
import { useSelector } from 'react-redux';

import { CHECKBOX } from '../../../../Enum/IdSet';
import { checkedPerItem } from '../../../../Store/Action/CheckBoxAction';
import SpinnerLoad from '../../Loader/SpinnerLoad/SpinnerLoad';

function CheckBoxPartial(props) {
  const checkedArray = useSelector((state) => state.utils.checkedArray);
  const handleChecked = (e) => {
    checkedPerItem(e, props.item, checkedArray, props.dispatch,props.actionType);
  };
  let status = _.find(checkedArray, ["id", Number(props.item.id)]);
  if (status === undefined) {
    return <SpinnerLoad t={props.t} />;
  }

  return (
    <CheckBox 
    className={props.className}
    id={CHECKBOX.Partial+"_"+props.item.id}
    name={props.item.id}
    type="checkbox"
    onChange={handleChecked}
    checked={status.isChecked}/>
  );
}

export default CheckBoxPartial;
