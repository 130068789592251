export const searchProductPrincipalColumn = (t) => [
  {
    name: t("COMMON:FIELD.PRODUCT_ID"),
    component:true,
    selector: row=>row.principal_product_id?row.principal_product_id:"-",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.PRINCIPAL_CODE"),
    component:true,
    selector: row=>row.principal_code?row.principal_code:"-",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.PRINCIPAL_NAME"),
    component:true,
    selector: row=>row.principal_name?row.principal_name:"-",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.PRINCIPAL_ID"),
    component:true,
    selector: row=>row.principal_id?row.principal_id:"-",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.PRODUCT_CODE"),
    component:true,
    selector: row=>row.product_code?row.product_code:"-",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.PRODUCT_PRINCIPAL_CODE"),
    component:true,
    selector: row=>row.product_principal_code?row.product_principal_code:"-",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.PARENT_CODE"),
    component:true,
    selector: row=>row.parent_code?row.parent_code:"-",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.SHORT_CODE"),
    component:true,
    selector: row=>row.short_code?row.short_code:"-",
    width: "100px",
  },
  {
    name: t("COMMON:FIELD.PRODUCT_NAME"),
    component:true,
    selector: row=>row.product_name?row.product_name:"-",
    width: "100px",
  },
 
];
