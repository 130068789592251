import React, {
  useEffect,
  useState,
} from 'react';

import _ from 'lodash';
import {
  Div,
  Input,
  Row,
  Span,
} from 'nexcomponent-ui';

import { DROPDOWNLIST } from '../../../../Enum/IdSet';
import { SIZESET } from '../../../../Enum/Props/SizeSet';
import { multiMappingSelect } from '../../../../Store/Action/CheckBoxAction';
import { selectLevel } from '../../../../Store/Action/SelectAction';
import CheckBoxMapping from '../../CheckBox/CheckBoxMapping/CheckBoxMapping';
import DropDownSearch from '../../DropDown/DropDownSearch/DropDownSearch';
import style from './FieldGroupComparison.module.css';

function FieldGroupComparison(props) {
  const [, setIndex] = useState(0);
  const find = (arr, value) => {
    let result = _.findIndex(arr, ["value", value]);
    setIndex(result);
  };
  const mappingCheckedPartial = async e => {
    const {checked} = e.target;
    await props.onCheck(e);
    multiMappingSelect(
      props.secondKey,
      props.mainKey,
      props.thirdKey,
      checked,
      props.mapping,
      props.dispatch,
      props.submitMapping
    );
  };
  const validation = (valueNew, valueExist) => {
    if (
      (valueNew === "" && valueExist === null) ||
      // eslint-disable-next-line eqeqeq
      valueNew == valueExist ||
      (valueNew === null && valueExist === "")
    ) {
      return style.InputMultiComparisonItem;
    } else {
      return style.InputMultiComparisonItemDiff;
    }
  };
  const resultMatch = (paramType, values) => {
    if (paramType === "number") {
      return Number(values);
    } else {
      return values;
    }
  };
  const selectHandle = async (e, param, param2, paramType, option, data, dispatch, arr) => {
    const checked = props.valueNew(param, "isChecked");
    const {name, value, reset} = e.target;
    find(arr, value);
    const realated = await _.find(option, [name, resultMatch(paramType, value)]);

    let submited = () =>
      reset
        ? {[name]: null, [param]: null, [param2]: null}
        : {
            [name]: resultMatch(paramType, value),
            [param]: realated[param],
            [param2]: realated[param2],
          };

    props.onSelect(submited());
    await selectLevel(
      name,
      value,
      param,
      param2,
      paramType,
      option,
      data,
      checked,
      props.submitMapping,
      dispatch,
      reset
    );
  };
  const autoClear=()=>{
    const checked = props.valueNew(props.mainKey, "isChecked");
    if(props.thirdKey!==undefined){
      selectLevel(
        props.mainKey,
        null,
        props,
        props.secondKey,
        props.thirdKey,
        props.option,
        props.mapping,
        checked,
        props.submitMapping,
        props.dispatch,
        props.reset
      );
    }else{
      selectLevel(
        props.mainKey,
        null,
        props,
        props.secondKey,
        undefined,
        props.option,
        props.mapping,
        checked,
        props.submitMapping,
        props.dispatch,
        props.reset
      );
    }
  }
  useEffect(()=>{
    if(props.clearDds&&props.mainKey!==null){
      autoClear()
    }
  })

  const exist = "_exist";
  const onClick = (e, type) => {
    const {name, value} = e.target;
    props.onClick({target: {name: name, type: type, value: value}});
  };
  const empty = "-";
  const defaultValue = key =>
    props.detailDefault[key] === null ? empty : props.detailDefault[key];
  return (
    <Div className={style.InputMultiComparison}>
      <Div className={style.CheckBox}>
        <CheckBoxMapping
          size={SIZESET.SM}
          label={props.label}
          name={props.mainKey}
          onCheck={mappingCheckedPartial}
          checked={props.disabledCheck ? false : props.valueNew(props.mainKey, "isChecked")}
          required={props.required}
          disabled={props.disabledCheck}
        />
      </Div>

      {props.thirdKey !== undefined ? (
        <>
          <Div
            className={validation(
              props.valueNew(props.thirdKey, "new"),
              props.valueNew(props.thirdKey, "exist")
            )}
          >
            <Div className={style.Label}>
              <Span value={props.t(props.subLabelThree)} className={style.SubLabel} />
            </Div>
            <Div className={style.InputArea}>
              <Div className={style.NewData}>
                <Row>
                  <Div className={style.Col}>
                    <Input
                      type="text"
                      name={props.thirdKey}
                      className={style.InputMultiComparisonInput}
                      value={defaultValue(props.thirdKey)}
                      disabled={true}
                    />
                  </Div>
                  <Div className={style.Space}></Div>
                  <Div className={style.Col}>
                    <DropDownSearch
                      resetSelect={props.resetSelect}
                      id={DROPDOWNLIST.SELECT + props.thirdKey}
                      name={props.thirdKey}
                      value={props.clearDds ? null : props.valueNew(props.thirdKey, "new")}
                      disabled={props.disabled}
                      labelKey={props.thirdKey}
                      valueKey={props.thirdKey}
                      placeholder={props.placeholderThird}
                      onChange={e => props.onChange(e, props.typeThird)}
                      onClick={e => onClick(e, props.typeThird)}
                      onScroll={e => props.onScroll(e)}
                      onSelect={e =>
                        selectHandle(
                          e,
                          props.secondKey,
                          props.mainKey,
                          "text",
                          props.option,
                          props.mapping,
                          props.dispatch,
                          props.option
                        )
                      }
                      suggestions={props.option}
                    />
                  </Div>
                </Row>
              </Div>
              <Div className={style.Existing}>
                <Input
                  type="text"
                  name={props.thirdKey + exist}
                  className={style.InputComparisonInput}
                  value={props.valueNew(props.thirdKey, "exist")}
                  disabled={true}
                />
              </Div>
            </Div>
          </Div>
          <Div
            className={validation(
              props.valueNew(props.secondKey, "new"),
              props.valueNew(props.secondKey, "exist")
            )}
          >
            <Div className={style.Label}>
              <Span value={props.t(props.subLabelTwo)} className={style.SubLabel} />
            </Div>
            <Div className={style.InputArea}>
              <Div className={style.NewData}>
                <Row>
                  <Div className={style.Col}>
                    <Input
                      type="text"
                      name={props.secondKey}
                      className={style.InputMultiComparisonInput}
                      value={defaultValue(props.secondKey)}
                      disabled={true}
                    />
                  </Div>
                  <Div className={style.Space}></Div>
                  <Div className={style.Col}>
                    <DropDownSearch
                      resetSelect={props.resetSelect}
                      id={DROPDOWNLIST.SELECT + props.secondKey}
                      name={props.secondKey}
                      value={props.clearDds ? null : props.valueNew(props.secondKey, "new")}
                      disabled={props.disabled}
                      labelKey={props.secondKey}
                      valueKey={props.secondKey}
                      placeholder={props.placeholderSecond}
                      onChange={e => props.onChange(e, props.typeSecond)}
                      onClick={e => onClick(e, props.typeSecond)}
                      onScroll={e => props.onScroll(e)}
                      onSelect={e =>
                        selectHandle(
                          e,
                          props.mainKey,
                          props.thirdKey,
                          "text",
                          props.option,
                          props.mapping,
                          props.dispatch,
                          props.option
                        )
                      }
                      suggestions={props.option}
                    />
                  </Div>
                </Row>
              </Div>
              <Div className={style.Existing}>
                <Input
                  type="text"
                  name={props.secondKey + exist}
                  className={style.InputComparisonInput}
                  value={props.valueNew(props.secondKey, "exist")}
                  disabled={true}
                />
              </Div>
            </Div>
          </Div>
          <Div
            className={validation(
              props.valueNew(props.mainKey, "new"),
              props.valueNew(props.mainKey, "exist")
            )}
          >
            <Div className={style.Label}>
              <Span value={props.t(props.subLabelOne)} className={style.SubLabel} />
            </Div>
            <Div className={style.InputArea}>
              <Div className={style.NewData}>
                <Row>
                  <Div className={style.Col}>
                    <Input
                      type="text"
                      name={props.mainKey}
                      className={style.InputMultiComparisonInput}
                      value={defaultValue(props.mainKey)}
                      disabled={true}
                    />
                  </Div>
                  <Div className={style.Space}></Div>
                  <Div className={style.Col}>
                    <DropDownSearch
                      resetSelect={props.resetSelect}
                      id={DROPDOWNLIST.SELECT + props.mainKey}
                      name={props.mainKey}
                      value={props.clearDds ? null : props.valueNew(props.mainKey, "new")}
                      disabled={props.disabled}
                      labelKey={props.mainKey}
                      valueKey={props.mainKey}
                      placeholder={props.placeholderFirst}
                      onChange={e => props.onChange(e, props.typeFirst)}
                      onClick={e => onClick(e, props.typeFirst)}
                      onScroll={e => props.onScroll(e)}
                      onSelect={e =>
                        selectHandle(
                          e,
                          props.thirdKey,
                          props.secondKey,
                          "number",
                          props.option,
                          props.mapping,
                          props.dispatch,
                          props.option
                        )
                      }
                      suggestions={props.option}
                    />
                  </Div>
                </Row>
              </Div>
              <Div className={style.Existing}>
                <Input
                  type="text"
                  name={props.mainKey + exist}
                  className={style.InputComparisonInput}
                  value={props.valueNew(props.mainKey, "exist")}
                  disabled={true}
                />
              </Div>
            </Div>
          </Div>
        </>
      ) : (
        <>
          <Div
            className={validation(
              props.valueNew(props.secondKey, "new"),
              props.valueNew(props.secondKey, "exist")
            )}
          >
            <Div className={style.Label}>
              <Span value={props.t(props.subLabelTwo)} className={style.SubLabel} />
            </Div>
            <Div className={style.InputArea}>
              <Div className={style.NewData}>
                <Row>
                  <Div className={style.Col}>
                    <Input
                      type="text"
                      name={props.secondKey}
                      className={style.InputMultiComparisonInput}
                      value={defaultValue(props.secondKey)}
                      disabled={true}
                    />
                  </Div>
                  <Div className={style.Space}></Div>
                  <Div className={style.Col}>
                    <DropDownSearch
                      resetSelect={props.resetSelect}
                      id={DROPDOWNLIST.SELECT + props.secondKey}
                      name={props.secondKey}
                      value={props.clearDds ? null : props.valueNew(props.secondKey, "new")}
                      disabled={props.disabled}
                      labelKey={props.secondKey}
                      valueKey={props.secondKey}
                      placeholder={props.placeholderSecond}
                      onChange={e => props.onChange(e, props.typeSecond)}
                      onClick={e => onClick(e, props.typeSecond)}
                      onScroll={e => props.onScroll(e)}
                      onSelect={e =>
                        selectHandle(
                          e,
                          props.secondKey,
                          props.mainKey,
                          "text",
                          props.option,
                          props.mapping,
                          props.dispatch,
                          props.option
                        )
                      }
                      suggestions={props.option}
                    />
                  </Div>
                </Row>
              </Div>
              <Div className={style.Existing}>
                <Input
                  type="text"
                  name="person_title_name_exist"
                  className={style.InputComparisonInput}
                  value={props.valueNew(props.secondKey, "exist")}
                  disabled={true}
                />
              </Div>
            </Div>
          </Div>
          <Div
            className={validation(
              props.valueNew(props.mainKey, "new"),
              props.valueNew(props.mainKey, "exist")
            )}
          >
            <Div className={style.Label}>
              <Span value={props.t(props.subLabelOne)} className={style.SubLabel} />
            </Div>
            <Div className={style.InputArea}>
              <Div className={style.NewData}>
                <Row>
                  <Div className={style.Col}>
                    <Input
                      type="text"
                      name={props.mainKey}
                      className={style.InputMultiComparisonInput}
                      value={defaultValue(props.mainKey)}
                      disabled={true}
                    />
                  </Div>
                  <Div className={style.Space}></Div>
                  <Div className={style.Col}>
                    <DropDownSearch
                      resetSelect={props.resetSelect}
                      id={DROPDOWNLIST.SELECT + props.mainKey}
                      name={props.mainKey}
                      value={props.clearDds ? null : props.valueNew(props.mainKey, "new")}
                      disabled={props.disabled}
                      labelKey={props.mainKey}
                      valueKey={props.mainKey}
                      placeholder={props.placeholderFirst}
                      onChange={e => props.onChange(e, props.typeFirst)}
                      onClick={e => onClick(e, props.typeFirst)}
                      onScroll={e => props.onScroll(e)}
                      onSelect={e =>
                        selectHandle(
                          e,
                          props.secondKey,
                          props.mainKey,
                          "number",
                          props.option,
                          props.mapping,
                          props.dispatch,
                          props.option
                        )
                      }
                      suggestions={props.option}
                    />
                  </Div>
                </Row>
              </Div>
              <Div className={style.Existing}>
                <Input
                  type="text"
                  name="person_title_id_exist"
                  className={style.InputComparisonInput}
                  value={props.valueNew(props.mainKey, "exist")}
                  disabled={true}
                />
              </Div>
            </Div>
          </Div>
        </>
      )}
    </Div>
  );
}

export default FieldGroupComparison;
FieldGroupComparison.defaultProps = {
  onSelect: () => {},
};
