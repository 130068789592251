export const companyProfileFieldMapping = [
  "company_profile_id",
  "company_title_id",
  "company_title_name",
  "npwp",
  "name",
  "address_1",
  "address_2",
  "address_3",
  "hamlet",
  "neighbourhood",
  "country_id",
  "country_name",
  "province_name",
  "province_code",
  "province_id",
  "district_name",
  "district_code",
  "district_id",
  "sub_district_name",
  "sub_district_id",
  "sub_district_code",
  "urban_village_name",
  "urban_village_code",
  "urban_village_id",
  "island_name",
  "island_code",
  "island_id",
  "postal_code_id",
  "postal_code",
  "latitude",
  "longitude",
  "phone",
  "fax",
  "email",
  "alternative_email",
  "pkp_status",
  "bumn_status",
  "pbf_status",
  "pbf_license_number",
  "pbf_license_end_date",
  "pbf_trustee_sipa_number",
  "pbf_sipa_license_end_date",
  "additional_info",
  "parent_company_id",
  "parent_company_npwp",
  "parent_company_name",
];
