import { ID } from './StandartId';

export const TABLE = {
  MAPPING_LIST: ID.TABLE + "mappinglist",
  REJECT_LIST: ID.TABLE + "rejectlist",
  AL_MAPPING_LIST: ID.TABLE + "allmappinglist",
  SEARCH_LIST: ID.TABLE + "searchlist",
  BROADCAST_LIST: ID.TABLE + "broadcastlist",
  NOTIFICATION_LIST: ID.TABLE + "notificationList"
};
