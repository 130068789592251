import {
  isStatus,
  isStatusErr,
} from '../../Utils/Response/Response';
import { COMPANY_PROFILE_URL } from '../EndPoint';
import * as BaseAPI from './BaseAPIs';

export const postCompanyProfileCountData = (token,notify,dispatch,action) =>  
  BaseAPI.doPost(COMPANY_PROFILE_URL.COUNT, token, null,notify,dispatch,(res)=>action(res));

export const postCompanyProfileList = (token, page, limit, sortDate,notify,dispatch,action) => 
   BaseAPI.doPost(COMPANY_PROFILE_URL.LIST(page, limit, sortDate), token, null,notify,dispatch,
   (res)=>action(res));

export const postRejectCompanyProfileList =  (token, data,notify,dispatch) => 
  BaseAPI.doPost(COMPANY_PROFILE_URL.REJECT, token, data,notify,dispatch,
    res=>isStatus(res),err=>isStatusErr(err))

export const getDetailCompanyProfile = (token, id,notify,dispatch,action,errAction) => 
  BaseAPI.doGet(COMPANY_PROFILE_URL.DETAIL + id, token, null,null,notify,dispatch,
    res=>action(res),err=>errAction(err));

export const getDetailExistingCompanyProfile = (token, id,notify,dispatch,action,errAction) => 
  BaseAPI.doGet(COMPANY_PROFILE_URL.DETAIL_EXISTING + id, token, null,null,notify,dispatch,
    res=>action(res),err=>errAction(err));

export const postSearchCompanyProfileList = (token, data,dispatch, notify,action,errAction) => {
  return BaseAPI.doPost(COMPANY_PROFILE_URL.SEARCH, token, data,notify,dispatch,
    (res)=>action(res),err=>errAction(err));
};
export const postApproveMappingCompanyProfile = (token, data,notify,dispatch,action,errAction) => {
  return BaseAPI.doPost(COMPANY_PROFILE_URL.MAPPING_APPROVE,token, data,notify,dispatch,
      (res)=>action(res),err=>errAction(err));
};
export const postCompanyProfileSearchCountData = (token,data,notify,dispatch,action) =>  
  BaseAPI.doPost(COMPANY_PROFILE_URL.COUNT_SEARCH, token, data,notify,dispatch,(res)=>action(res));
