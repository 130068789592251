import { ROUTER_CONSTANT } from '../RouterConstant/RouterConstant';

export const companyProfileBreadCrumb = t => [
  {value: t("COMMON:DATA_PROCESS") , to: ROUTER_CONSTANT.COMPANY_PROFILE.LIST},
  {value: t("COMMON:MAPPINGS"), to: ROUTER_CONSTANT.COMPANY_PROFILE.LIST},
  {value: t("COMMON:FIELD.COMPANY_PROFILE"), to: ROUTER_CONSTANT.COMPANY_PROFILE.LIST},
];
export const companyProfileSearchBreadCrumb =t=> [
  {value: t("COMMON:DATA_PROCESS"), to: "/"},
  {value: t("COMMON:MAPPINGS"), to: "/"},
  {value: t("COMMON:FIELD.COMPANY_PROFILE"), to: ROUTER_CONSTANT.COMPANY_PROFILE.LIST},
  {value: `${t("COMMON:MAPPINGS")} ${t("COMMON:FIELD.COMPANY_PROFILE")}`, to: ROUTER_CONSTANT.COMPANY_PROFILE.MAPPING},
];
