import { NotifyType } from '../../Enum/Notification/NotificationType';
import { openModal } from '../../Store/Action/ModalAction';
import { authActions } from '../../Store/Reducers/AuthenticationReducer';
import { utilsActions } from '../../Store/Reducers/UtilsReducer';
import { rememberAction } from '../../Utils/Action';
import {
  isAuthorize,
  isContent,
  isStatusErr,
} from '../../Utils/Response/Response';
import {
  postAuthorize,
  postGenerateToken,
  postUserVerify,
  postVerify,
} from '../Api/AuthenticationAPI';
import { getBroadcastInitiate } from '../Api/BroadcastAPI';
import { getRejectInitiate } from '../Api/RejectAPI';
import {
  fetchDataBroadcastAdmin,
  setInitiateBroadcast,
} from './BroadcastData';
import { fetchRejectReason } from './InitiateData';
import {
  fetchDataRejectAdmin,
  setInitiateReject,
} from './RejectData';

const setProfile = async (res, dispatch) => {
  await dispatch(authActions.setContent(isContent(res)));
  await dispatch(authActions.setLanguage(isContent(res).locale));
  dispatch(utilsActions.setModal(false));
};
export const setAuthorization = async (res, dispatch) => {
  await dispatch(authActions.setToken(isAuthorize(res)));
  await dispatch(authActions.setRefreshToken(isContent(res).refresh_token));
  return isAuthorize(res);
};
// }
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
export const fetchDataLogin = async (
  dataAuthorize,
  form,
  CRYPTO_RANDOM,
  dispatch,
  notify,
  t,
  remember
) => {
  openModal(dispatch);
  await sleep(1000);
  const res = await postAuthorize(dataAuthorize, notify, dispatch, err =>
    errorHandlerLogin(err, dispatch, notify)
  );
  verify(res, dispatch, form, CRYPTO_RANDOM, notify, t, remember);
};
export const verify = (res, dispatch, form, CRYPTO_RANDOM, notify, t, remember) => {
  openModal(dispatch);
  rememberAction(remember, form);
  postVerify(
    isAuthorize(res),
    form,
    notify,
    dispatch,
    response => generateToken(response, dispatch, CRYPTO_RANDOM, notify, t),
    err => errorHandlerLogin(err, dispatch, notify)
  );
};

const generateToken = async (res, dispatch, CRYPTO_RANDOM, notify, t) => {
  await postGenerateToken(
    isContent(res).authentication_code,
    CRYPTO_RANDOM,
    notify,
    dispatch,
    response => userVerify(response, dispatch, notify, t),
    err => errorHandlerLogin(err, dispatch, notify)
  );
};

const userVerify = async (res, dispatch, notify, t) => {
  await postUserVerify(isAuthorize(res), notify, dispatch, response =>
    setProfile(response, dispatch)
  );
  await setAuthorization(res, dispatch);
  await getBroadcastInitiate(isAuthorize(res), "", notify, dispatch, response =>
    setInitiateBroadcast(response, dispatch)
  );
  await fetchDataBroadcastAdmin(isAuthorize(res), 1, 10, dispatch, notify);
  await getRejectInitiate(isAuthorize(res), "", notify, dispatch, response =>
    setInitiateReject(response, dispatch)
  );
  await fetchDataRejectAdmin(isAuthorize(res), 1, 10, dispatch, notify);
  await fetchRejectReason(isAuthorize(res), dispatch, notify);
  await notify({type: "SUCCESS", message: t("COMMON:SUCCESS_LOGIN")});
};

export const errorHandlerLogin = async (err, dispatch, notify) => {
  switch (err.code) {
    case "ECONNABORTED":
      await dispatch(utilsActions.setTimeOut(true));
      await notify({
        type: NotifyType.ERROR,
        message: "Request Timeout",
      });
      break;
    default:
      await notify({
        type: NotifyType.ERROR,
        message: isStatusErr(err).message,
      });
  }
  dispatch(utilsActions.setModal(false));
  return err;
};
