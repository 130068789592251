import { ROUTER_CONSTANT } from '../RouterConstant/RouterConstant';

export const productBrandBreadCrumb = t => [
  {value: t("COMMON:DATA_PROCESS"), to: ROUTER_CONSTANT.PRODUCT_BRAND.LIST},
  {value: t("COMMON:MAPPINGS"), to: ROUTER_CONSTANT.PRODUCT_BRAND.LIST},
  {value: t("COMMON:FIELD.PRODUCT_BRAND"), to: ROUTER_CONSTANT.PRODUCT_BRAND.LIST},
];
export const productBrandSearchBreadCrumb = t => [
  {value: t("COMMON:DATA_PROCESS"), to: "/"},
  {value: t("COMMON:MAPPINGS"), to: "/"},
  {value: t("COMMON:FIELD.PRODUCT_BRAND"), to: ROUTER_CONSTANT.PRODUCT_BRAND.LIST},
  {value: `${t("COMMON:MAPPINGS")} ${t("COMMON:FIELD.PRODUCT_BRAND")}`, to: ROUTER_CONSTANT.PRODUCT_BRAND.MAPPING},
];
export const productBrandPrinciaplSearchBreadCrumb = t => [
  {value: t("COMMON:DATA_PROCESS"), to: "/"},
  {value: t("COMMON:MAPPINGS"), to: "/"},
  {value: t("COMMON:FIELD.PRODUCT_BRAND"), to: ROUTER_CONSTANT.PRODUCT_BRAND.LIST},
  {value: `${t("COMMON:MAPPINGS")} ${t("COMMON:FIELD.PRODUCT_BRAND")}`, to: `${ROUTER_CONSTANT.PRODUCT_BRAND.PRINCIPAL.MAPPING}`},
];
