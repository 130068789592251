import { useState } from 'react';

import { useSelector } from 'react-redux';

import FieldComparison
  from '../../../../../../Components/Molecules/Field/FieldComparison/FieldComparison';
import FieldGroupComparison
  from '../../../../../../Components/Molecules/Field/FieldGroupComparison/FieldGroupComparison';
import { Max } from '../../../../../../Enum/DefaultProps/MaxLength';
import { REGEX_CONSTANT } from '../../../../../../Enum/RegexConstant';
import {
  postCompanyProfileParent,
  postCompanyTitle,
  postCountry,
  postDistrict,
  postIsland,
  postPostalCode,
  postProvince,
  postSubDistrict,
  postUrbanVillage,
} from '../../../../../../Fetch/Api/SearchMappingAPI';
import {
  fetchCompanyProfileParent,
  fetchCompanyTitle,
  fetchCountryList,
  fetchDistrictList,
  fetchIslandList,
  fetchPostalCodeList,
  fetchProvinceList,
  fetchSubDistrictList,
  fetchUrbanVillageList,
} from '../../../../../../Fetch/FetchData/SearchMappingData';
import {
  searchMappingActions,
} from '../../../../../../Store/Reducers/SearchMappingReducer';
import {
  companyProfileMappingOptions,
} from '../../../../../../Store/StateGroup/SerchMappingGroup';
import {
  DDSonChange,
  DDSonChangeCompanyParent,
  DDSOnClick,
  DDSOnScroll,
} from '../../../../../../Utils/Action/DropDownSearch/DropDownSearch';
import {
  fieldRequired,
} from '../../../../../../Utils/Action/Field/FieldRequired';
import {
  dateFormater,
  dateFormater3,
} from '../../../../../../Utils/Date/formater';

function FieldCompanyProfileMappingForm(props) {
  const {t} = props;
  const [companyTitle, country, postalCode, province, district, subDistrict, urbanVillage, island] =
    useSelector(companyProfileMappingOptions);

  const parentList = useSelector(state => state.searchMapping.companyParent);
  const [filter, setFilter] = useState();
  const [regional, setRegion] = useState({
    country_id: 0,
    district_id: 0,
    province_id: 0,
    sub_district_id: 0,
    urban_village_id: 0,
  });
  const [limitation, setLimitation] = useState({page: 1, limit: 10});
  const regionalSelect = (e, key) => {
    setRegion({...regional, [key]: e[key]});
  };
  const clickSelect = fetch => {
    DDSOnClick(fetch, setLimitation, filter, props.validToken, props.dispatch, props.notify);
  };
  const clickSelectRegion = (fetch, key) => {
    DDSOnClick(
      fetch,
      setLimitation,
      {...filter, [key]: regional[key]},
      props.validToken,
      props.dispatch,
      props.notify
    );
  };
  const onScrollRegion = (fetch, prevList, key) => {
    DDSOnScroll(
      fetch,
      prevList,
      setLimitation,
      limitation,
      {...filter, [key]: regional[key]},
      props.validToken,
      props.dispatch,
      props.notify
    );
  };
  const onChangeRegion = async (e, type, fetch, setOption, key) => {
    DDSonChange(
      e,
      type,
      props.validToken,
      fetch,
      setLimitation,
      setFilter,
      setOption,
      {...filter, [key]: regional[key]},
      props.notify,
      props.dispatch
    );
  };
  const onScroll = (fetch, prevList) => {
    DDSOnScroll(
      fetch,
      prevList,
      setLimitation,
      limitation,
      filter,
      props.validToken,
      props.dispatch,
      props.notify
    );
  };
  const onChange = async (e, type, fetch, setOption) => {
    DDSonChange(
      e,
      type,
      props.validToken,
      fetch,
      setLimitation,
      setFilter,
      setOption,
      filter,
      props.notify,
      props.dispatch
    );
  };
  const onChangeTypeParent = async (e, type, fetch, setOption, idKey, nameKey, codeKey) => {
    DDSonChangeCompanyParent(
      e,
      type,
      props.validToken,
      fetch,
      setLimitation,
      setFilter,
      setOption,
      filter,
      props.notify,
      props.dispatch,
      idKey,
      nameKey,
      codeKey
    );
  };

  const pbfStatus = () => {
    let checked = props.value(props.mappingForm, "pbf_status", "isChecked");
    let pbf = props.value(props.mappingForm, "pbf_status", "exist");
    if (checked) {
      pbf = props.value(props.mappingForm, "pbf_status", "new");
    }
    return pbf;
  };

  const pbfGroupEnabler = () => {
    let parameter = pbfStatus();
    let disabled = true;
    if (parameter === "Y") {
      disabled = false;
    }
    return disabled;
  };
  const pbfGroupRequired = () => {
    let parameter = pbfStatus();
    let required = false;
    if (parameter === "Y") {
      required = true;
    }
    return required;
  };
  const handleRequired = key =>
    fieldRequired(
      props.value(props.mappingForm, "company_profile_id", "exist"),
      props.value(props.mappingForm, key, "isChecked")
    );
  return (
    <>
      <FieldComparison
        label={t("COMMON:FIELD.COMPANY_PROFILE_ID")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.COMPANY_PROFILE_ID")})}
        typeNew="text"
        typeExist="text"
        nameNew="company_profile_id"
        valueNew={props.value(props.mappingForm, "company_profile_id", "new")}
        nameExist="company_profile_id_exist"
        valueExist={props.value(props.mappingForm, "company_profile_id", "exist")}
        checked={
          props.value(props.mappingForm, "company_profile_id", "exist") !== null
            ? false
            : props.value(props.mappingForm, "company_profile_id", "isChecked")
        }
        disabledCheck={
          props.value(props.mappingForm, "company_profile_id", "exist") !== null ? true : false
        }
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        disabled={true}
      />
      <FieldGroupComparison
        label={t("COMPANY_PROFILE:FIELD.COMPANY_TITLE")}
        t={t}
        detailDefault={props.detail}
        mapping={props.mappingForm}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        option={companyTitle}
        mainKey="company_title_id"
        secondKey="company_title_name"
        typeFirst="number"
        typeSecond="text"
        onChange={(e, type) =>
          onChange(e, type, postCompanyTitle, searchMappingActions.setCompanyTitle)
        }
        onClick={() => clickSelect(fetchCompanyTitle)}
        onScroll={() => onScroll(fetchCompanyTitle, companyTitle)}
        subLabelOne={"COMPANY_PROFILE:FIELD.COMPANY_TITLE_ID"}
        subLabelTwo={"COMPANY_PROFILE:FIELD.COMPANY_TITLE_NAME"}
        placeholderSecond={t("COMMON:SELECT", {field: t("COMMON:FIELD.COMPANY_TITLE_NAME")})}
        placeholderFirst={t("COMMON:SELECT", {field: t("COMMON:FIELD.COMPANY_TITLE_ID")})}
        valueNew={(key, item) => props.value(props.mappingForm, key, item)}
        onCheck={e => props.checkedPartial(e)}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.NPWP")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.NPWP")})}
        typeNew="text"
        typeExist="text"
        nameNew="npwp"
        valueNew={props.value(props.mappingForm, "npwp", "new")}
        nameExist="npwp_exist"
        valueExist={props.value(props.mappingForm, "npwp", "exist")}
        checked={props.value(props.mappingForm, "npwp", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        maxLength={Max.length30}
        pattern={REGEX_CONSTANT.NPWP}
        message={t("COMMON:ERROR.NPWP", {field: t("COMMON:FIELD.NPWP")})}
        required={handleRequired("npwp")}
      />
      <FieldComparison
        label={t("COMMON:FIELD.NAME")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.NAME")})}
        typeNew="text"
        typeExist="text"
        nameNew="name"
        valueNew={props.value(props.mappingForm, "name", "new")}
        nameExist="name_exist"
        valueExist={props.value(props.mappingForm, "name", "exist")}
        checked={props.value(props.mappingForm, "name", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        maxLength={Max.length50}
        pattern={REGEX_CONSTANT.NAME}
        message={t("COMMON:ERROR.NAME", {field: t("COMMON:FIELD.NAME"), exp: "Jaya Perkasa Utama"})}
        required={handleRequired("name")}
      />
      <FieldComparison
        label={t("COMMON:FIELD.ADDRESS1")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.ADDRESS1")})}
        typeNew="text"
        typeExist="text"
        nameNew="address_1"
        valueNew={props.value(props.mappingForm, "address_1", "new")}
        nameExist="address_1_exist"
        valueExist={props.value(props.mappingForm, "address_1", "exist")}
        checked={props.value(props.mappingForm, "address_1", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        required={handleRequired("address_1")}
      />
      <FieldComparison
        label={t("COMMON:FIELD.ADDRESS2")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.ADDRESS2")})}
        typeNew="text"
        typeExist="text"
        nameNew="address_2"
        valueNew={props.value(props.mappingForm, "address_2", "new")}
        nameExist="address_1_exist"
        valueExist={props.value(props.mappingForm, "address_2", "exist")}
        checked={props.value(props.mappingForm, "address_2", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.ADDRESS3")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.ADDRESS3")})}
        typeNew="text"
        typeExist="text"
        nameNew="address_3"
        valueNew={props.value(props.mappingForm, "address_3", "new")}
        nameExist="address_2_exist"
        valueExist={props.value(props.mappingForm, "address_3", "exist")}
        checked={props.value(props.mappingForm, "address_3", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.HAMLET")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.HAMLET")})}
        typeNew="text"
        typeExist="text"
        nameNew="hamlet"
        valueNew={props.value(props.mappingForm, "hamlet", "new")}
        nameExist="hamlet_exist"
        valueExist={props.value(props.mappingForm, "hamlet", "exist")}
        checked={props.value(props.mappingForm, "hamlet", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        maxLength={Max.length3}
        pattern={REGEX_CONSTANT.INTEGER}
        message={t("COMMON:ERROR.SMALL_REGIONAL", {field: t("COMMON:FIELD.HAMLET")})}
      />
      <FieldComparison
        label={t("COMMON:FIELD.NEIGHBOURHOOD")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.NEIGHBOURHOOD")})}
        typeNew="text"
        typeExist="text"
        nameNew="neighbourhood"
        valueNew={props.value(props.mappingForm, "neighbourhood", "new")}
        nameExist="neighbourhood_exist"
        valueExist={props.value(props.mappingForm, "neighbourhood", "exist")}
        checked={props.value(props.mappingForm, "neighbourhood", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        maxLength={Max.length3}
        pattern={REGEX_CONSTANT.INTEGER}
        message={t("COMMON:ERROR.SMALL_REGIONAL", {field: t("COMMON:FIELD.NEIGHBOURHOOD")})}
      />
      <FieldGroupComparison
        label={t("PERSON_PROFILE:FIELD_COUNTRY")}
        t={t}
        detailDefault={props.detail}
        mapping={props.mappingForm}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        option={country}
        mainKey="country_id"
        secondKey="country_name"
        typeFirst="number"
        typeSecond="text"
        onChange={(e, type) => onChange(e, type, postCountry, searchMappingActions.setCountry)}
        onClick={() => clickSelect(fetchCountryList)}
        onScroll={() => onScroll(fetchCountryList, country)}
        onSelect={e => regionalSelect(e, "country_id")}
        subLabelOne={"PERSON_PROFILE:FIELD_COUNTRY_ID"}
        subLabelTwo={"PERSON_PROFILE:FIELD_COUNTRY_NAME"}
        placeholderSecond={t("COMMON:SELECT", {field: t("COMMON:FIELD.COUNTRY_NAME")})}
        placeholderFirst={t("COMMON:SELECT", {field: t("COMMON:FIELD.COUNTRY_ID")})}
        valueNew={(key, item) => props.value(props.mappingForm, key, item)}
        onCheck={e => props.checkedPartial(e)}
        dispatch={props.dispatch}
      />
      <FieldGroupComparison
        label={t("PERSON_PROFILE:FIELD_ISLAND")}
        t={t}
        detailDefault={props.detail}
        mapping={props.mappingForm}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        option={island}
        mainKey="island_id"
        secondKey="island_name"
        thirdKey="island_code"
        typeFirst="number"
        typeSecond="text"
        typeThird="text"
        disabled={regional.country_id === 0 ? true : false}
        onChange={(e, type) =>
          onChangeRegion(e, type, postIsland, searchMappingActions.setIsland, "country_id")
        }
        onClick={() => clickSelectRegion(fetchIslandList, "country_id")}
        onScroll={() => onScrollRegion(fetchIslandList, island, "country_id")}
        subLabelOne={"PERSON_PROFILE:FIELD_ISLAND_ID"}
        subLabelTwo={"PERSON_PROFILE:FIELD_ISLAND_NAME"}
        subLabelThree={"PERSON_PROFILE:FIELD_ISLAND_CODE"}
        placeholderThird={t("COMMON:SELECT", {field: t("COMMON:FIELD.ISLAND_CODE")})}
        placeholderSecond={t("COMMON:SELECT", {field: t("COMMON:FIELD.ISLAND_NAME")})}
        placeholderFirst={t("COMMON:SELECT", {field: t("COMMON:FIELD.ISLAND_ID")})}
        valueNew={(key, item) => props.value(props.mappingForm, key, item)}
        onCheck={e => props.checkedPartial(e)}
        dispatch={props.dispatch}
      />
      <FieldGroupComparison
        label={t("PERSON_PROFILE:FIELD_PROVINCE")}
        t={t}
        detailDefault={props.detail}
        mapping={props.mappingForm}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        option={province}
        mainKey="province_id"
        secondKey="province_name"
        thirdKey="province_code"
        typeFirst="number"
        typeSecond="text"
        typeThird="text"
        disabled={regional.country_id === 0 ? true : false}
        onSelect={e => regionalSelect(e, "province_id")}
        onChange={(e, type) =>
          onChangeRegion(e, type, postProvince, searchMappingActions.setProvince, "country_id")
        }
        onClick={() => clickSelectRegion(fetchProvinceList, "country_id")}
        onScroll={() => onScrollRegion(fetchProvinceList, province, "country_id")}
        subLabelOne={"PERSON_PROFILE:FIELD_PROVINCE_ID"}
        subLabelTwo={"PERSON_PROFILE:FIELD_PROVINCE_NAME"}
        subLabelThree={"PERSON_PROFILE:FIELD_PROVINCE_CODE"}
        placeholderThird={t("COMMON:SELECT", {field: t("COMMON:FIELD.PROVINCE_CODE")})}
        placeholderSecond={t("COMMON:SELECT", {field: t("COMMON:FIELD.PROVINCE_NAME")})}
        placeholderFirst={t("COMMON:SELECT", {field: t("COMMON:FIELD.PROVINCE_ID")})}
        valueNew={(key, item) => props.value(props.mappingForm, key, item)}
        onCheck={e => props.checkedPartial(e)}
        dispatch={props.dispatch}
      />

      <FieldGroupComparison
        label={t("PERSON_PROFILE:FIELD_DISTRICT")}
        t={t}
        detailDefault={props.detail}
        mapping={props.mappingForm}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        option={district}
        mainKey="district_id"
        secondKey="district_name"
        thirdKey="district_code"
        typeFirst="number"
        typeSecond="text"
        typeThird="text"
        disabled={regional.province_id === 0 ? true : false}
        onSelect={e => regionalSelect(e, "district_id")}
        onChange={(e, type) =>
          onChangeRegion(e, type, postDistrict, searchMappingActions.setDistrict, "province_id")
        }
        onClick={() => clickSelectRegion(fetchDistrictList, "province_id")}
        onScroll={() => onScrollRegion(fetchDistrictList, district, "province_id")}
        subLabelOne={"PERSON_PROFILE:FIELD_DISTRICT_ID"}
        subLabelTwo={"PERSON_PROFILE:FIELD_DISTRICT_NAME"}
        subLabelThree={"PERSON_PROFILE:FIELD_DISTRICT_CODE"}
        placeholderThird={t("COMMON:SELECT", {field: t("COMMON:FIELD.DISTRICT_CODE")})}
        placeholderSecond={t("COMMON:SELECT", {field: t("COMMON:FIELD.DISTRICT_NAME")})}
        placeholderFirst={t("COMMON:SELECT", {field: t("COMMON:FIELD.DISTRICT_ID")})}
        valueNew={(key, item) => props.value(props.mappingForm, key, item)}
        onCheck={e => props.checkedPartial(e)}
        dispatch={props.dispatch}
      />
      <FieldGroupComparison
        label={t("PERSON_PROFILE:FIELD_SUBDISTRICT")}
        t={t}
        detailDefault={props.detail}
        mapping={props.mappingForm}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        option={subDistrict}
        mainKey="sub_district_id"
        secondKey="sub_district_name"
        thirdKey="sub_district_code"
        typeFirst="number"
        typeSecond="text"
        typeThird="text"
        disabled={regional.district_id === 0 ? true : false}
        onSelect={e => regionalSelect(e, "sub_district_id")}
        onChange={(e, type) =>
          onChangeRegion(
            e,
            type,
            postSubDistrict,
            searchMappingActions.setSubDistrict,
            "district_id"
          )
        }
        onClick={() => clickSelectRegion(fetchSubDistrictList, "district_id")}
        onScroll={() => onScrollRegion(fetchSubDistrictList, subDistrict, "district_id")}
        subLabelOne={"PERSON_PROFILE:FIELD_SUBDISTRICT_ID"}
        subLabelTwo={"PERSON_PROFILE:FIELD_SUBDISTRICT_NAME"}
        subLabelThree={"PERSON_PROFILE:FIELD_SUBDISTRICT_CODE"}
        placeholderThird={t("COMMON:SELECT", {field: t("COMMON:FIELD.SUB_DISTRICT_CODE")})}
        placeholderSecond={t("COMMON:SELECT", {field: t("COMMON:FIELD.SUB_DISTRICT_NAME")})}
        placeholderFirst={t("COMMON:SELECT", {field: t("COMMON:FIELD.SUB_DISTRICT_ID")})}
        valueNew={(key, item) => props.value(props.mappingForm, key, item)}
        onCheck={e => props.checkedPartial(e)}
        dispatch={props.dispatch}
      />
      <FieldGroupComparison
        label={t("PERSON_PROFILE:FIELD_URBAN_VILLAGE")}
        t={t}
        detailDefault={props.detail}
        mapping={props.mappingForm}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        option={urbanVillage}
        mainKey="urban_village_id"
        secondKey="urban_village_name"
        thirdKey="urban_village_code"
        typeFirst="number"
        typeSecond="text"
        typeThird="text"
        disabled={regional.sub_district_id === 0 ? true : false}
        onSelect={e => regionalSelect(e, "urban_village_id")}
        onChange={(e, type) =>
          onChangeRegion(
            e,
            type,
            postUrbanVillage,
            searchMappingActions.setUrbanVillage,
            "sub_district_id"
          )
        }
        onClick={() => clickSelectRegion(fetchUrbanVillageList, "sub_district_id")}
        onScroll={() => onScrollRegion(fetchUrbanVillageList, urbanVillage, "sub_district_id")}
        subLabelOne={"PERSON_PROFILE:FIELD_URBAN_VILLAGE_ID"}
        subLabelTwo={"PERSON_PROFILE:FIELD_URBAN_VILLAGE_NAME"}
        subLabelThree={"PERSON_PROFILE:FIELD_URBAN_VILLAGE_CODE"}
        placeholderThird={t("COMMON:SELECT", {field: t("COMMON:FIELD.URBAN_VILLAGE_CODE")})}
        placeholderSecond={t("COMMON:SELECT", {field: t("COMMON:FIELD.URBAN_VILLAGE_NAME")})}
        placeholderFirst={t("COMMON:SELECT", {field: t("COMMON:FIELD.URBAN_VILLAGE_ID")})}
        valueNew={(key, item) => props.value(props.mappingForm, key, item)}
        onCheck={e => props.checkedPartial(e)}
        dispatch={props.dispatch}
      />
      <FieldGroupComparison
        label={t("PERSON_PROFILE:FIELD_POSTAL_CODE")}
        t={t}
        detailDefault={props.detail}
        mapping={props.mappingForm}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        option={postalCode}
        mainKey="postal_code_id"
        secondKey="postal_code"
        typeFirst="number"
        typeSecond="text"
        disabled={regional.urban_village_id === 0 ? true : false}
        onChange={(e, type) =>
          onChangeRegion(
            e,
            type,
            postPostalCode,
            searchMappingActions.setPostalCode,
            "urban_village_id"
          )
        }
        onClick={() => clickSelectRegion(fetchPostalCodeList, "urban_village_id")}
        onScroll={() => onScrollRegion(fetchPostalCodeList, postalCode, "urban_village_id")}
        subLabelOne={"PERSON_PROFILE:FIELD_POSTAL_CODE_ID"}
        subLabelTwo={"PERSON_PROFILE:FIELD_POSTAL_CODE_CODE"}
        placeholderSecond={t("COMMON:SELECT", {field: t("COMMON:FIELD.POSTAL_CODE_CODE")})}
        placeholderFirst={t("COMMON:SELECT", {field: t("COMMON:FIELD.POSTAL_CODE_ID")})}
        valueNew={(key, item) => props.value(props.mappingForm, key, item)}
        onCheck={e => props.checkedPartial(e)}
        dispatch={props.dispatch}
      />

      <FieldComparison
        label={t("COMMON:FIELD.LATITUDE")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.LATITUDE")})}
        typeNew="text"
        typeExist="number"
        negatifValue={true}
        nameNew="latitude"
        valueNew={props.value(props.mappingForm, "latitude", "new")}
        nameExist="latitude"
        valueExist={props.value(props.mappingForm, "latitude", "exist")}
        checked={props.value(props.mappingForm, "latitude", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        pattern={REGEX_CONSTANT.FLOAT}
        message={t("COMMON:ERROR.NUMBER", {field: t("COMMON:FIELD.LATITUDE")})}
      />
      <FieldComparison
        label={t("COMMON:FIELD.LONGITUDE")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.LONGITUDE")})}
        typeNew="text"
        typeExist="number"
        negatifValue={true}
        nameNew="longitude"
        valueNew={props.value(props.mappingForm, "longitude", "new")}
        nameExist="longitude"
        valueExist={props.value(props.mappingForm, "longitude", "exist")}
        checked={props.value(props.mappingForm, "longitude", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        pattern={REGEX_CONSTANT.FLOAT}
        message={t("COMMON:ERROR.NUMBER", {field: t("COMMON:FIELD.LONGITUDE")})}
      />
      <FieldComparison
        label={t("COMMON:FIELD.PHONE")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PHONE")})}
        typeNew="text"
        typeExist="text"
        nameNew="phone"
        valueNew={props.value(props.mappingForm, "phone", "new")}
        nameExist="phone"
        valueExist={props.value(props.mappingForm, "phone", "exist")}
        checked={props.value(props.mappingForm, "phone", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        maxLength={Max.length35}
        pattern={REGEX_CONSTANT.PHONE_NUMBER}
        message={t("COMMON:ERROR.PHONE", {field: t("COMMON:FIELD.PHONE")})}
        required={handleRequired("phone")}
      />
      <FieldComparison
        label={t("COMMON:FIELD.FAX")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.FAX")})}
        typeNew="text"
        typeExist="text"
        nameNew="fax"
        valueNew={props.value(props.mappingForm, "fax", "new")}
        nameExist="fax"
        valueExist={props.value(props.mappingForm, "fax", "exist")}
        checked={props.value(props.mappingForm, "fax", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        maxLength={Max.length35}
        pattern={REGEX_CONSTANT.PHONE_NUMBER}
        message={t("COMMON:ERROR.PHONE", {field: t("COMMON:FIELD.PHONE")})}
      />
      <FieldComparison
        label={t("COMMON:FIELD.EMAIL")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.EMAIL")})}
        typeNew="email"
        typeExist="email"
        nameNew="email"
        valueNew={props.value(props.mappingForm, "email", "new")}
        nameExist="email"
        valueExist={props.value(props.mappingForm, "email", "exist")}
        checked={props.value(props.mappingForm, "email", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        maxLength={Max.length100}
        pattern={REGEX_CONSTANT.EMAIL}
        message={t("COMMON:ERROR.EMAIL", {field: t("COMMON:FIELD.EMAIL")})}
        required={handleRequired("email")}
      />
      <FieldComparison
        label={t("COMMON:FIELD.ALTERNATIVE_EMAIL")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.ALTERNATIVE_EMAIL")})}
        typeNew="email"
        typeExist="email"
        nameNew="alternative_email"
        valueNew={props.value(props.mappingForm, "alternative_email", "new")}
        nameExist="alternative_email"
        valueExist={props.value(props.mappingForm, "alternative_email", "exist")}
        checked={props.value(props.mappingForm, "alternative_email", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        maxLength={Max.length100}
        pattern={REGEX_CONSTANT.EMAIL}
        message={t("COMMON:ERROR.EMAIL", {field: t("COMMON:FIELD.ALTERNATIVE_EMAIL")})}
      />
      <FieldComparison
        label={t("COMPANY_PROFILE:FIELD.PKP_STATUS")}
        typeNew="switch"
        typeExist="text"
        nameNew="pkp_status"
        valueNew={props.value(props.mappingForm, "pkp_status", "new")}
        nameExist="pkp_status_exist"
        valueExist={props.value(props.mappingForm, "pkp_status", "exist")}
        checked={props.value(props.mappingForm, "pkp_status", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMPANY_PROFILE:FIELD.BUMN_STATUS")}
        typeNew="switch"
        typeExist="text"
        nameNew="bumn_status"
        valueNew={props.value(props.mappingForm, "bumn_status", "new")}
        nameExist="bumn_status_exist"
        valueExist={props.value(props.mappingForm, "bumn_status", "exist")}
        checked={props.value(props.mappingForm, "bumn_status", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMPANY_PROFILE:FIELD.PBF_STATUS")}
        typeNew="switch"
        typeExist="text"
        nameNew="pbf_status"
        valueNew={props.value(props.mappingForm, "pbf_status", "new")}
        nameExist="pbf_status_exist"
        valueExist={props.value(props.mappingForm, "pbf_status", "exist")}
        checked={props.value(props.mappingForm, "pbf_status", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
      />
      <FieldComparison
        label={t("COMMON:FIELD.PBF_LICENSE_NUMBER")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PBF_LICENSE_NUMBER")})}
        typeNew="text"
        typeExist="text"
        nameNew="pbf_license_number"
        valueNew={props.value(props.mappingForm, "pbf_license_number", "new")}
        nameExist="pbf_license_number"
        valueExist={props.value(props.mappingForm, "pbf_license_number", "exist")}
        checked={props.value(props.mappingForm, "pbf_license_number", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        required={pbfGroupRequired()}
        // disabledCheck={pbfGroupEnabler()}
        // disabled={pbfGroupEnabler()}
      />
      <FieldComparison
        label={t("COMMON:FIELD.PBF_LICENSE_END_DATE")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PBF_LICENSE_END_DATE")})}
        typeNew="date"
        typeExist="text"
        nameNew="pbf_license_end_date"
        valueNew={dateFormater(props.value(props.mappingForm, "pbf_license_end_date", "new"))}
        nameExist="pbf_license_end_date"
        valueExist={dateFormater3(props.value(props.mappingForm, "pbf_license_end_date", "exist"))}
        checked={props.value(props.mappingForm, "pbf_license_end_date", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        required={pbfGroupRequired()}
        // disabledCheck={pbfGroupEnabler()}
        // disabled={pbfGroupEnabler()}
      />
      <FieldComparison
        label={t("COMMON:FIELD.PBF_TRUSTEE_SIPA_NUMBER")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PBF_TRUSTEE_SIPA_NUMBER")})}
        typeNew="text"
        typeExist="text"
        nameNew="pbf_trustee_sipa_number"
        valueNew={props.value(props.mappingForm, "pbf_trustee_sipa_number", "new")}
        nameExist="pbf_trustee_sipa_number"
        valueExist={props.value(props.mappingForm, "pbf_trustee_sipa_number", "exist")}
        checked={props.value(props.mappingForm, "pbf_trustee_sipa_number", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        dispatch={props.dispatch}
        required={pbfGroupRequired()}
        // disabledCheck={pbfGroupEnabler()}
        // disabled={pbfGroupEnabler()}
      />
      <FieldComparison
        label={t("COMMON:FIELD.PBF_SIPA_LICENCE_END_DATE")}
        placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PBF_SIPA_LICENCE_END_DATE")})}
        typeNew="date"
        typeExist="text"
        nameNew="pbf_sipa_license_end_date"
        valueNew={dateFormater(props.value(props.mappingForm, "pbf_sipa_license_end_date", "new"))}
        nameExist="pbf_license_end_date"
        valueExist={dateFormater3(
          props.value(props.mappingForm, "pbf_sipa_license_end_date", "exist")
        )}
        checked={props.value(props.mappingForm, "pbf_sipa_license_end_date", "isChecked")}
        submitMapping={props.submitMapping}
        mappingCode={props.mappingCode}
        onCheck={props.checkedPartial}
        mapping={props.mappingForm}
        required={pbfGroupRequired()}
        dispatch={props.dispatch}
        // disabledCheck={pbfGroupEnabler()}
        // disabled={pbfGroupEnabler()}
      />
      <FieldComparison
        label={t("COMMON:FIELD.ADDITIONAL_INFO")}
        placeholderKey={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.KEY")})}
        placeholderValue={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.VALUE")})}
        typeNew="multiField"
        typeExist="text"
        nameNew="additional_info"
        valueNew={props.value(props.mappingForm, "additional_info", "new")}
        nameExist="additional_info_exist"
        valueExist={props.value(props.mappingForm, "additional_info", "exist")}
        checked={props.value(props.mappingForm, "additional_info", "isChecked")}
        onCheck={props.checkedPartial}
        submitMapping={props.submitMapping}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        dispatch={props.dispatch}
      />
      <FieldGroupComparison
        label={t("COMMON:FIELD.PARENT_COMPANY")}
        t={t}
        detailDefault={props.detail}
        mapping={props.mappingForm}
        mappingCode={props.mappingCode}
        submitMapping={props.submitMapping}
        option={parentList}
        mainKey="parent_company_id"
        secondKey="parent_company_name"
        thirdKey="parent_company_npwp"
        typeFirst="number"
        typeSecond="text"
        typeThird="text"
        onChange={(e, type) =>
          onChangeTypeParent(
            e,
            type,
            postCompanyProfileParent,
            searchMappingActions.setCompanyParent,
            "company_profile_id",
            "company_profile_name",
            "company_profile_npwp"
          )
        }
        onClick={() => clickSelect(fetchCompanyProfileParent, filter)}
        onScroll={() => onScroll(fetchCompanyProfileParent, parentList, filter)}
        subLabelOne={"COMMON:FIELD.PARENT_COMPANY_ID"}
        subLabelTwo={"COMMON:FIELD.PARENT_COMPANY_NAME"}
        subLabelThree={"COMMON:FIELD.PARENT_COMPANY_NPWP"}
        placeholderThird={t("COMMON:SELECT", {field: t("COMMON:FIELD.PARENT_COMPANY_ID")})}
        placeholderSecond={t("COMMON:SELECT", {field: t("COMMON:FIELD.PARENT_COMPANY_NAME")})}
        placeholderFirst={t("COMMON:SELECT", {field: t("COMMON:FIELD.PARENT_COMPANY_NPWP")})}
        valueNew={(key, item) => props.value(props.mappingForm, key, item)}
        onCheck={e => props.checkedPartial(e)}
        dispatch={props.dispatch}
      />
    </>
  );
}

export default FieldCompanyProfileMappingForm;
