import { Span } from 'nexcomponent-ui';

import {
  MAPPING_DATA_TYPE,
} from '../../../../Enum/MappingDataType/MappingDataType';
import style from './TitleModalDetail.module.css';

export const TitleModalDetail = (mappingDataType, t) => {
  let title = <Span className={style.Title} value={"Undefined"} />;
  if (mappingDataType === MAPPING_DATA_TYPE.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY) {
    title = (
      <Span className={style.Title} value={t("PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY:TITLE")} />
    );
  } else if (mappingDataType === MAPPING_DATA_TYPE.PRODUCT_CATEGORY) {
    title = <Span className={style.Title} value={t("PRODUCT_CATEGORY:TITLE")} />;
  } else if (mappingDataType === MAPPING_DATA_TYPE.PRODUCT_KEY_ACCOUNT) {
    title = <Span className={style.Title} value={t("PRODUCT_KEY_ACCOUNT:TITLE")} />;
  } else if (mappingDataType === MAPPING_DATA_TYPE.PRODUCT) {
    title = <Span className={style.Title} value={t("PRODUCT:TITLE")} />;
  } else if (mappingDataType === MAPPING_DATA_TYPE.PERSON_PROFILE) {
    title = <Span className={style.Title} value={t("PERSON_PROFILE:TITLE")} />;
  } else if (mappingDataType === MAPPING_DATA_TYPE.DIVISION) {
    title = <Span className={style.Title} value={t("DIVISION:TITLE")} />;
  } else if (mappingDataType === MAPPING_DATA_TYPE.COMPANY_PROFILE) {
    title = <Span className={style.Title} value={t("COMPANY_PROFILE:TITLE")} />;
  } else if (mappingDataType === MAPPING_DATA_TYPE.PRODUCT_BRAND) {
    title = <Span className={style.Title} value={t("PRODUCT_BRAND:TITLE")} />;
  } else if (mappingDataType === MAPPING_DATA_TYPE.PRODUCT_GROUP) {
    title = <Span className={style.Title} value={t("PRODUCT_GROUP:TITLE")} />;
  }
  return title;
};
