export const currentPageLoadingRowLimitSortDate = (state) => {
  return [
    state.utils.currentPage,
    state.utils.loading,
    state.utils.rowLimit,
    state.utils.sortDateAsc,
  ];
};
export const currentPageRowSelectLimit = (state) => {
  return [state.utils.currentPage, state.utils.rowLimit, state.utils.selectLimit];
};

export const paginationSate = (state) => {
  return [
    state.utils.rowLimit,
    state.utils.selectLimit,
    state.utils.currentPage,
    state.utils.startPage,
    state.utils.endPage,
    state.utils.pageNumber,
  ];
};


