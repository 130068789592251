import React from 'react';

import {
  Col,
  Div,
  Heading,
  Row,
} from 'nexcomponent-ui';

import CancelSearch from '../../Button/CancelSearch/CancelSearch';
import AdvanceSearch from '../AdvanceSearch/AdvanceSearch';
import style from './HeaderSearch.module.css';

function HeaderSearch(props) {
  return (
    <Row className={style.Header}>
      <Col  size="lg" width={6} className={style.HeaderSideLeft}>
        <Div className={style.HeaderSideLeft}>
          <Heading size={6} value={props.t(props.title)} className={style.Title} />
          {props.advance ? (
            <AdvanceSearch
              t={props.t}
              dispatch={props.dispatch}
              mappingCode={props.mappingCode}
              option={props.option}
            />
          ) : (
            ""
          )}
        </Div>
      </Col>
      <Col size="lg" width={6} className={style.HiderSide}>
        <CancelSearch
          t={props.t}
          mappingCode={props.mappingCode}
          dispatch={props.dispatch}
          navigate={props.navigate}
          advance={props.advance}
          backNavigate={props.backNavigate}
          searchFunc={(data, column) => props.searchFunc(data, column)}
        />
      </Col>
    </Row>
  );
}

export default HeaderSearch;
