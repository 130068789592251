import React, { useState } from 'react';

import _ from 'lodash';
import {
  CheckBox,
  Div,
  Input,
  nexIcon,
  Span,
  Ul,
} from 'nexcomponent-ui';

import { SIZESET } from '../../../Enum/Props';
import useComponentVisible from '../Multi/useComponentVisible';
import Chip from './Chip';
import style from './SelectMulti.module.css';

const SelectMulti = props => {
  const {
    ref: componentRef,
    isComponentVisible: menuOpen,
    setIsComponentVisible: setMenuOpen,
  } = useComponentVisible({
    initialIsVisible: false,
    onClickOutside: props.onMenuClose,
  });
  const getActiveClass = () => {
    const el = componentRef.current;
    var rect = el.getBoundingClientRect();
    let position = style.SlcOptionUp;
    if (rect.top < 400) {
      position = style.SlcOptionDow;
    }
    return position;
  };

  const getWidth = () => {
    const el = componentRef.current;
    var rect = el.getBoundingClientRect();
    let position = rect.width;

    return position;
  };

  const openMenu = () => {
    setMenuOpen(true);
  };
  const handleVisibility = menuOpen => {
    let display = "hidden";
    if (menuOpen) {
      display = "visible";
    }
    return display;
  };
  const [opt, setOpt] = useState([]);
  const [actionScroll, setActionScroll] = useState(true);

  let vl = [];
  for (var j = 0; j < opt.length; j++) {
    if (opt[j].isChecked === true) {
      vl.push(opt[j].value);
    }
  }

  const clearAll = () => {
    let option = [];
    for (var i = 0; i < props.option.length; i++) {
      option.push({
        value: props.option[i][props.valueKey],
        label: props.option[i][props.labelKey],
        isChecked: false,
      });
    }
    setOpt(option);
  };

  const statusChecked = () => {
    if (props.option.length <= 0) {
      return false;
    } else {
      return props.option.some(data => data?.isChecked !== false);
    }
  };
  const controlValue = tempArr => {
   
    let val = [];
    for (var j = 0; j < tempArr.length; j++) {
      if (tempArr[j].isChecked === true) {
         val.push(tempArr[j][props.valueKey]);
      }
    }
    let stringvalue = "";
    stringvalue += val.map(itm => itm);
    return stringvalue;
  };
  const handleChecked = async evt => {
    await props.onChecked(evt, props.valueKey);
  };
  const removeChip = (valueKey,  value, ) => {
    props.onChecked({target: {value: value, checked: false}}, valueKey);
  };
 
 const handleScroll = async e => {
    const containerHeight = e.currentTarget.clientHeight;
    const scrollHeight = e.currentTarget.scrollHeight;
    const scrollTop = e.currentTarget.scrollTop;
    const progress = (scrollTop + containerHeight) / scrollHeight;
    setActionScroll(props.option.length !== opt.length);
    if (progress === 1) {
      if ((await props.onScroll(1)) !== null) {
        var newArr = _.map(await props.onScroll(1), function (a) {
          return {value: a[props.valueKey], label: a[props.labelKey], isChecked: false};
        });
        setOpt(opt.concat(newArr));
      }
    }
  };

  function search(myArray) {
    let filtered = myArray.filter(data => {
      let val =
        data[props.labelKey] === null || data[props.labelKey] === undefined
          ? ""
          : data[props.labelKey];
      let toLowercase = val.toLowerCase();
      let searchVal =
        props.valueSearch === null || props.valueSearch === undefined ? "" : props.valueSearch;
      let searchLowercase = searchVal.toLowerCase();
      return toLowercase.includes(searchLowercase);
    });
    return filtered;
  }
  const listComponent = list => {
    if (list.length > 0) {
      return _.map(list, detail => (
        <li
          id={"li" + props.id}
          className={`${style.SlcOptionLi} multi-select-option-li`}
          key={detail[props.valueKey]}
        >
          <li key={detail[props.valueKey]}></li>
          <Span className={style.SlcOptionLiSpan} value={detail[props.labelKey]} />
          <CheckBox
            name={detail[props.valueKey]}
            size={SIZESET.SM}
            value={detail[props.valueKey]}
            className="msl-option-checkbox"
            onChange={handleChecked}
            checked={detail[props.checkedKey]}
          />
        </li>
      ));
    } else {
      return (
        <li id={"li" + props.id} className={`${style.SlcOptionLi} multi-select-option-li`} key={0}>
          <Span className={style.SlcOptionLiSpan} value={props.valueNotFound} />
        </li>
      );
    }
  };
  const chipArr = _.filter(props.option, {[props.checkedKey]: true});
  return (
    <Div
      id={props.id}
      ref={componentRef}
      onClick={openMenu}
      tabIndex="0"
      className={style.SlcContainer}
    >
      <Div
        id={"box" + props.id}
        className={`${style.SlcInputWithChip} ${menuOpen ? "select-input-active" : ""}`}
        onClick={() => {
          props.onClick();
        }}
      >
        {chipArr.length > 0 ? (
          <Div className={style.Inputwrp}>
            {_.map(chipArr, detail => (
              <Div key={props.key} id="chip" clickable="true" className={style.Chip}>
                <Chip
                  deleteAction={e =>
                    removeChip(
                      props.valueKey,
                      detail[props.valueKey],
                    )
                  }
                  value={detail[props.labelKey]}
                />
              </Div>
            ))}
          </Div>
        ) : (
          <Div
            id={"tsl" + props.id}
            data-placeholder={props.placeholder}
            className={`${style.SlcInputs} `}
          />
        )}

        <Div className={style.ArrowIcon}>
          <nexIcon.NcSelectOption className={style.IconSelect} width={8} height={8} />
        </Div>
      </Div>
      <Div
        id={"ddl" + props.id}
        style={{
          visibility: handleVisibility(menuOpen),
          width: menuOpen && getWidth(),
        }}
        display={""}
        className={`${style.SlcOption} ${menuOpen && getActiveClass()} `}
      >
        <Div>
          {props.search ? (
            <Div className={style.BoxInputSearch}>
              <Input
                id={props.id}
                size="xs"
                className={style.InputSearch}
                name={props.name}
                value={props.valueSearch}
                onChange={e => {
                  props.onInputSearch(e);
                }}
                placeholder={props.placeholder}
                type="text"
              />
              <Div className={style.IconSearch}>
                <nexIcon.NcSearch color="rgba(153, 153, 153, 1)" width={5} height={5} />
              </Div>
            </Div>
          ) : (
            <></>
          )}

          <Ul
            id={"ul" + props.id}
            className={`${props.search ? style.SlcOptionUlSearch : style.SlcOptionUl}`}
            onScroll={handleScroll}
          >
            {listComponent(search(props.option))}
          </Ul>
        </Div>
      </Div>
    </Div>
  );
};

export default SelectMulti;
SelectMulti.defaultProps = {
  onMenuClose: () => {},
  option: [],
  id: "",
  onScroll: () => {},
  onChecked: () => {},
  valueNotFound: "Not Found",
  onClick: () => {},
};
