import { isContent } from '../../Utils/Response/Response';
import { SEARCH_URL } from '../EndPoint/SearchMappingEndPoint';
import * as BaseAPI from './BaseAPIs';

export const postCountry = (token, data, notify, dispatch, action, errAction) => {
  return BaseAPI.doPost(
    SEARCH_URL.COUNTRY,
    token,
    data,
    notify,
    dispatch,
    res => action(res),
    err => errAction(err)
  );
};
export const postDistrict = (token, data, notify, dispatch, action, errAction) => {
  return BaseAPI.doPost(
    SEARCH_URL.DISTRICT,
    token,
    data,
    notify,
    dispatch,
    res => action(res),
    err => errAction(err)
  );
};
export const postIsland = (token, data, notify, dispatch, action, errAction) => {
  return BaseAPI.doPost(
    SEARCH_URL.ISLAND,
    token,
    data,
    notify,
    dispatch,
    res => action(res),
    err => errAction(err)
  );
};
export const postPostalCode = (token, data, notify, dispatch, action, errAction) => {
  return BaseAPI.doPost(
    SEARCH_URL.POSTAL_CODE,
    token,
    data,
    notify,
    dispatch,
    res => action(res),
    err => errAction(err)
  );
};
export const postProvince = (token, data, notify, dispatch, action, errAction) => {
  return BaseAPI.doPost(
    SEARCH_URL.PROVINCE,
    token,
    data,
    notify,
    dispatch,
    res => action(res),
    err => errAction(err)
  );
};
export const postSubDistrict = (token, data, notify, dispatch, action, errAction) => {
  return BaseAPI.doPost(
    SEARCH_URL.SUB_DISTRICT,
    token,
    data,
    notify,
    dispatch,
    res => action(res),
    err => errAction(err)
  );
};
export const postUrbanVillage = (token, data, notify, dispatch, action, errAction) => {
  return BaseAPI.doPost(
    SEARCH_URL.URBAN_VILLAGE,
    token,
    data,
    notify,
    dispatch,
    res => action(res),
    err => errAction(err)
  );
};
export const postPersonTitle = (token, data, notify, dispatch, action, errAction) => {
  return BaseAPI.doPost(
    SEARCH_URL.PERSON_TITLE,
    token,
    data,
    notify,
    dispatch,
    res => action(res),
    err => errAction(err)
  );
};
export const postCompanyTitle = (token, data, notify, dispatch, action, errAction) => {
  return BaseAPI.doPost(
    SEARCH_URL.COMPANY_TITLE,
    token,
    data,
    notify,
    dispatch,
    res => action(res),
    err => errAction(err)
  );
};
export const postCompanyProfileParent = (token, data, notify, dispatch, action, errAction) => {
  return BaseAPI.doPost(
    SEARCH_URL.COMPANY_PARENT,
    token,
    data,
    notify,
    dispatch,
    res => action(res),
    err => errAction(err)
  );
};
export const postPrincipal = (token, data, notify, dispatch, action, errAction) => {
  return BaseAPI.doPost(
    SEARCH_URL.PRINCIPAL,
    token,
    data,
    notify,
    dispatch,
    res => action(res),
    err => errAction(err)
  );
};
export const postProductBrand = (token, data, notify, dispatch, action, errAction) => {
  return BaseAPI.doPost(
    SEARCH_URL.PRODUCT_BRAND,
    token,
    data,
    notify,
    dispatch,
    res => action(res),
    err => errAction(err)
  );
};
export const postProductBrandPrincipal = (token, data, notify, dispatch, action, errAction) => {
  return BaseAPI.doPost(
    SEARCH_URL.PRODUCT_BRAND_PRINCIPAL,
    token,
    data,
    notify,
    dispatch,
    res => action(res),
    err => errAction(err)
  );
};

export const postProductGroupPrincipal = (token, data, notify, dispatch, action, errAction) => {
  
  return BaseAPI.doPost(
    SEARCH_URL.PRODUCT_GROUP_PRINCIPAL,
    token,
    data,
    notify,
    dispatch,
    res => action(res),
    err => errAction(err)
  );
};
export const postProductGroup = (token, data, notify, dispatch, action, errAction) => {
  return BaseAPI.doPost(
    SEARCH_URL.PRODUCT_GROUP,
    token,
    data,
    notify,
    dispatch,
    res => action(res),
    err => errAction(err)
  );
};
export const postProductCategory = (token, data, notify, dispatch, action, errAction) => {
  return BaseAPI.doPost(
    SEARCH_URL.PRODUCT_CATEGORY,
    token,
    data,
    notify,
    dispatch,
    res => action(res),
    err => errAction(err)
  );
};
export const postProductKeyAccount = (token, data, notify, dispatch, action, errAction) => {
  return BaseAPI.doPost(
    SEARCH_URL.PRODUCT_KEY_ACCOUNT,
    token,
    data,
    notify,
    dispatch,
    res => action(res),
    err => errAction(err)
  );
};