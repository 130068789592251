import React from 'react';

import {
  Col,
  Row,
} from 'nexcomponent-ui';
import { useSelector } from 'react-redux';

import { searchFormData } from '../../../../../Utils/Form';
import style from '../FieldSearch/Field.module.css';
import FieldSearch from '../FieldSearch/FieldSearch';

function FieldPPUDCSearchForm(props) {
  const {t} = props;
  const searchData = useSelector(state => state.utils.searchData);

  const formData = e => {
    searchFormData(e, searchData, props.dispatch);
  };
  return (
    <Row>
      <Col className={style.Col} size="lg" width={6}>
        <FieldSearch
          className={style.InputLeft}
          label={t("COMMON:FIELD.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY_ID")}
          type="number"
          name="principal_product_user_defined_category_id"
          placeholder={t("COMMON:PLACEHOLDER", {
            field: t("COMMON:FIELD.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY_ID"),
          })}
          onChange={formData}
        />
        <FieldSearch
          className={style.InputLeft}
          label={t("COMMON:FIELD.PRINCIPAL_CODE")}
          type={"text"}
          name={"principal_code"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PRINCIPAL_CODE")})}
          onChange={formData}
        />
        <FieldSearch
          className={style.InputLeft}
          type={"text"}
          name={"principal_name"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PRINCIPAL_NAME")})}
          onChange={formData}
          label={t("COMMON:FIELD.PRINCIPAL_NAME")}
        />
        <FieldSearch
          className={style.InputLeft}
          type={"number"}
          name={"principal_id"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PRINCIPAL_ID")})}
          onChange={formData}
          label={t("COMMON:FIELD.PRINCIPAL_ID")}
        />
        <FieldSearch
          className={style.InputLeft}
          type={"text"}
          name={"code"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.CODE")})}
          onChange={formData}
          label={t("COMMON:FIELD.CODE")}
        />
      </Col>
      <Col className={style.Col} size="lg" width={6}>
        <FieldSearch
          name={"category_number"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.CATEGORY_NUMBER")})}
          onChange={formData}
          label={t("COMMON:FIELD.CATEGORY_NUMBER")}
          className={style.InputRight}
          type={"number"}
        />
        <FieldSearch
          className={style.InputRight}
          type={"text"}
          name={"value"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.VALUE")})}
          onChange={formData}
          label={t("COMMON:FIELD.VALUE")}
        />
        {/* <FieldSearch
          className={style.InputRight}
          label={t("COMMON:FIELD.RESOURCE")}
          type={"text"}
          name={"parent_code"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.RESOURCE")})}
          onChange={formData}
          valid={formValidation.parent_code}
          pattern={REGEX_CONSTANT.NO_SPACE}
          message={t("COMMON:ERROR.NO_SPACE_SPECIAL", {
            field: t("COMMON:FIELD.RESOURCE"),
          })}
          maxLength={20}
        /> */}
      </Col>
    </Row>
  );
}

export default FieldPPUDCSearchForm;
