import { utilsActions } from '../../Store/Reducers/UtilsReducer'
import { notificationActions } from '../../Store/Reducers/NotificationReducer'
import { getInitiateNotification, getNotificationList, addNotification, editNotification } from '../Api/NotificationAPI'

import { isContent } from '../../Utils/Response/Response'

const errContent = err => {
    const { content } = err.response.data.nexsoft.payload.data
    return content
}

const setListNotification = (res, dispatch) => {
    dispatch(notificationActions.setNotificationList(isContent(res)))
}

const setListNotificationErr = (res, dispatch) => {
    dispatch(notificationActions.setNotificationList(errContent(res)))
}

const listData = async (token, currentPage, rowLimit, sort, filter, dispatch, notify) => {
    if (sort === "created_at asc") {
        sort = "updated_at desc"
    }

    await getNotificationList(
        token,
        currentPage,
        rowLimit,
        sort,
        filter,
        notify,
        dispatch,
        res => setListNotification(res, dispatch),
        err => setListNotificationErr(err, dispatch)
    )

    dispatch(utilsActions.setLoading(false))
}

export async function fetchNotificationList(
    currentPage,
    sortDate,
    props,
    limit,
    filter
) {
    await getInitiateNotification(props.validToken(), filter, props.notify, props.dispatch, response => {
        props.dispatch(notificationActions.setNotificationTotalRow(isContent(response).count_data))
        props.dispatch(notificationActions.setNotificationValidLimit(isContent(response).valid_limit))
    })

    listData(props.validToken(), currentPage, limit, sortDate, filter, props.dispatch, props.notify)
}

export async function createNotification(
    data,
    props
) {
    return await addNotification(props.validToken(), data, props.notify, props.dispatch)
}

export async function updateNotification(
    data,
    props
) {
    return await editNotification(props.validToken(), data, props.notify, props.dispatch)
}