import './App.css';

import { useEffect } from 'react';

import { useNotification } from 'nexcomponent-ui';
import { useTranslation } from 'react-i18next';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';

import NexHub from '../../Router';

const App = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  let notify = useNotification();
  let {i18n, t} = useTranslation();
  let token = useSelector(state => state.authentication.token);
  let activePage = useSelector(state => state.utils.activePage);
  let language = useSelector(state => state.authentication.language);
  let props = {notify, dispatch, navigate, language, token, t, activePage, location};
  
  useEffect(() => {
  
    i18n.changeLanguage(language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, i18n, language]);

  return <NexHub {...props} />;
};

export default App;
