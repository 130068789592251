import React from 'react';

import {
  Col,
  Row,
} from 'nexcomponent-ui';
import { useSelector } from 'react-redux';

import { searchFormData } from '../../../../../Utils/Form';
import style from '../FieldSearch/Field.module.css';
import FieldSearch from '../FieldSearch/FieldSearch';

function FieldProductCategorySearchForm(props) {
  const {t} = props;
  const searchData = useSelector(state => state.utils.searchData);

  const formData = e => {
    searchFormData(e, searchData, props.dispatch);
  };
  return (
    <Row>
      <Col className={style.Col} size="lg" width={6}>
        <FieldSearch
          className={style.InputLeft}
          label={t("COMMON:FIELD.PRODUCT_CATEGORY_ID")}
          type={"number"}
          name="product_category_id"
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PRODUCT_CATEGORY_ID")})}
          onChange={formData}
        />
        <FieldSearch
          className={style.InputLeft}
          label={t("COMMON:FIELD.PRINCIPAL_CODE")}
          type={"text"}
          name={"principal_code"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PRINCIPAL_CODE")})}
          onChange={formData}
        />
        <FieldSearch
          className={style.InputLeft}
          type={"text"}
          name={"principal_name"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PRINCIPAL_NAME")})}
          onChange={formData}
          label={t("COMMON:FIELD.PRINCIPAL_NAME")}
        />
        <FieldSearch
          className={style.InputLeft}
          type={"number"}
          name={"principal_id"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.PRINCIPAL_ID")})}
          onChange={formData}
          label={t("COMMON:FIELD.PRINCIPAL_ID")}
        />
        <FieldSearch
          className={style.InputLeft}
          type={"text"}
          name={"code"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.CODE")})}
          onChange={formData}
          label={t("COMMON:FIELD.CODE")}
        />
      </Col>
      <Col className={style.Col} size="lg" width={6}>
        <FieldSearch
          className={style.InputRight}
          name={"name"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.NAME")})}
          onChange={formData}
          label={t("COMMON:FIELD.NAME")}
        />

        {/* <FieldSearch
          className={style.InputRight}
          type={"text"}
          name={"resources"}
          placeholder={t("COMMON:PLACEHOLDER", {field: t("COMMON:FIELD.RESOURCE")})}
          onChange={formData}
          valid={formValidation.resources}
          maxLength={20}
          label={t("PRODUCT_BRAND:FIELD.RESOURCE")}/> */}
      </Col>
    </Row>
  );
}

export default FieldProductCategorySearchForm;
