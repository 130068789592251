import { ROUTER_CONSTANT } from '../RouterConstant/RouterConstant';

export const principalProductUserDefinedCategoryBreadCrumb = t => [
  {
    value: t("COMMON:DATA_PROCESS"),
    to: ROUTER_CONSTANT.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY.LIST,
  },
  {value: t("COMMON:MAPPINGS"), to: ROUTER_CONSTANT.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY.LIST},
  {
    value: t("COMMON:FIELD.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY"),
    to: ROUTER_CONSTANT.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY.LIST,
  },
];
export const principalProductUserDefinedCategorySearchBreadCrumb =t=> [
  {value: t("COMMON:DATA_PROCESS"), to: "/"},
  {value: t("COMMON:MAPPINGS"), to: "/"},
  {
    value: t("COMMON:FIELD.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY"),
    to: ROUTER_CONSTANT.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY.LIST,
  },
  {
    value: `${t("COMMON:MAPPINGS")} ${t("COMMON:FIELD.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY")}`,
    to: ROUTER_CONSTANT.PRINCIPAL_PRODUCT_USER_DEFINED_CATEGORY.MAPPING,
  },
];
