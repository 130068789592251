import _ from 'lodash';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  actionType: null,
  activePage: null,
  loading: false,
  //sidebar
  sideBarCollapse: false,
  sideMenu: null,
  //tables state
  sortDateAsc: "created_at asc",
  selectableRow: false,

  //pagination state
  rowLimit: 10,
  selectLimit: false,
  currentPage: 1,
  startPage: 0,
  endPage: 4,
  pageNumber: [],
  selectAllData: [],

  actionReject: false,
  checked: false,
  checkedAll: null,
  checkedArray: [],
  mappingForm: [],
  mappingList: [],
  rejectList: [],
  clear: false,
  rejectPage: false,
  rejectData: {
    list_data_mapping: [],
    reject_reason_option: null,
    reject_reason_custom: null,
  },
  rejectReasonSelect: {
    reject_reason_custom:"Data It's Not Complete",
    reject_reason_option: 1,
  },
  //Select reject option
  customReason: false,
  customReasonValue: null,

  //modals
  modal: false,
  resultModal: false,
  reset: false,
  modalType: null,

  overwrite: true,
  submitMapping: {},

  //
  advanceSearch: [{id: 1, searchTitle: "", searchName: "", searchValue: ""}],
  applyAdvanceSearch: {},
  searchOption: {},
  searchData: [],
  searchColumn: [],
  formValidation: [],
  form: null,
  remember: false,
  timeOut:false
};
const utilSlice = createSlice({
  name: "utils",
  initialState: initialState,
  reducers: {
    setActionType(state, data) {
      state.actionType = data.payload;
    },
    setActivePage(state, data) {
      state.activePage = data.payload;
    },
    setSideBarCollapse(state, data) {
      state.sideBarCollapse = data.payload;
    },
    setSideMenu(state, data) {
      state.sideMenu = data.payload;
    },
    setSortDateAsc(state, data) {
      state.sortDateAsc = data.payload;
    },
    setSelectableRow(state, data) {
      state.selectableRow = data.payload;
    },
    setRowLimit(state, data) {
      state.rowLimit = data.payload;
    },
    setCurrentPage(state, data) {
      state.currentPage = data.payload;
    },
    setSelectLimit(state, data) {
      state.selectLimit = data.payload;
    },
    setLoading(state, data) {
      state.loading = data.payload;
    },
    setStarPage(state, data) {
      state.startPage = data.payload;
    },
    setEndPage(state, data) {
      state.endPage = data.payload;
    },
    setPageNumber(state, data) {
      state.pageNumber = data.payload;
    },
    setActionReject(state, data) {
      state.actionReject = data.payload;
    },
    setChecked(state, data) {
      state.checked = data.payload;
    },
    setCheckedAll(state, data) {
      state.checkedAll = data.payload;
    },
    setCheckedArray(state, data) {
      state.checkedArray = data.payload;
    },
    setClear(state, data) {
      state.clear = data.payload;
    },
    setAddCheckedArray(state, data) {
      state.checkedArray.push(data.payload);
    },
    setAddMappingForm(state, data) {
      state.mappingForm.push(data.payload);
    },
    setRemoveCheckedArray(state, data) {
      state.checkedArray.splice(
        _.findIndex(state.checkedArray, function (item) {
          return item.id === data.payload;
        }),
        1
      );
    },
    setRejectPage(state, data) {
      state.rejectPage = data.payload;
    },
    setRejectData(state, data) {
      state.rejectData = data.payload;
    },
    setMappingList(state, data) {
      state.mappingList = data.payload;
    },
    setAddMappingList(state, data) {
      state.mappingList.push(data.payload);
    },
    setRemoveMappingList(state, data) {
      state.mappingList.splice(
        _.findIndex(state.mappingList, function (item) {
          return item.id === data.payload;
        }),
        1
      );
    },
    setRejectList(state, data) {
      state.rejectList = data.payload;
    },
    setAddRejectList(state, data) {
      state.rejectList.push(data.payload);
    },
    setRemoveRejectList(state, data) {
      state.rejectList.splice(
        _.findIndex(state.rejectList, function (item) {
          return item.id === data.payload;
        }),
        1
      );
    },
    setRejectReasonSelect(state, data) {
      state.rejectReasonSelect = data.payload;
    },
    setCustomReason(state, data) {
      state.customReason = data.payload;
    },
    setCustomReasonValue(state, data) {
      state.customReasonValue = data.payload;
    },
    setModal(state, data) {
      state.modal = data.payload;
    },
    setResultModal(state, data) {
      state.resultModal = data.payload;
    },
    setReset(state, data) {
      state.reset = data.payload;
    },
    setModalType(state, data) {
      state.modalType = data.payload;
    },
    setOverwrite(state, data) {
      state.overwrite = data.payload;
    },
    setSubmitMapping(state, data) {
      state.submitMapping = data.payload;
    },
    setAdvanceSearch(state, data) {
      state.advanceSearch = data.payload;
    },
    setAddAdvanceSearch(state, data) {
      state.advanceSearch.push(data.payload);
    },
    setRemoveAdvanceSearch(state, data) {
      _.remove(state.advanceSearch, {id: data.payload});
    },
    setApplyAdvanceSearch(state, data) {
      state.applyAdvanceSearch = data.payload;
    },
    setSearchOption(state, data) {
      state.searchOption = data.payload;
    },
    setSearchData(state, data) {
      state.searchData = data.payload;
    },
    setSearchColumn(state, data) {
      state.searchColumn = data.payload;
    },
    setFormValidation(state, data) {
      state.formValidation = data.payload;
    },
    setForm(state, data) {
      state.form = data.payload;
    },
    setRemember(state, data) {
      state.remember = data.payload;
    }, setTimeOut(state, data) {
      state.timeOut = data.payload;
    },
  },
});

export const utilsActions = utilSlice.actions;
export default utilSlice.reducer;
