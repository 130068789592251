import React from 'react';

import {
  Div,
  Row,
} from 'nexcomponent-ui';

import RejectMappingButton from '../RejectMappingButton/RejectMappingButton';
import style from './MappingTypeActionButton.module.css';

const MappingTypeActionButton = props => {
  return (
    <Div className={`${style.Container} ${props.className}`}>
      <Row className={style.Row}>
        <RejectMappingButton
          notify={props.notify}
          mappingCode={props.mappingCode}
          t={props.t}
          dispatch={props.dispatch}
          navigate={props.navigate}
          uniqueKey={props.uniqueKey}
          principal={props.principal}
          rejectNavigate={props.rejectNavigate}
          rejectLength={props.rejectLength}
          mappingNavigate={props.mappingNavigate}
        />
      </Row>
    </Div>
  );
};

export default MappingTypeActionButton;
